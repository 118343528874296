import ShopService from "@/_services/shop.services";


const state = {
    shop: null,
    success : null,
    errorshop: null
};

const mutations = {
    SET_SHOP(state, shop){
        state.shop = shop
    },

    SET_ERROR(state, errorshop){
        state.errorshop = errorshop;
    },

    setSuccess(state, success) {
        state.success = success;
    },

    clearSuccess(state) {
        state.success = null;
      },
};

const actions = {
    
    submitShop: async ({commit}, shopData) => {
        commit('setError', null);
        commit('setSuccess', null);
        const token = localStorage.getItem('authToken');
        try{
            const shop = new ShopService()
            const response = await shop.addShop(shopData, token);
            /*commit('SET_SHOP',[...state.shop, response]);
            commit('SET_ERROR', null)
    
            commit('setSuccess', 'Enregistrement successful.');

            setTimeout(() => {
                commit('clearSuccess');
              }, 3000);*/

            return response;
        }

        catch(error) {
            //commit('SET_ERROR', error.message);
            throw error;
        }

    },

    async editShop({commit}, data){

        const token = localStorage.getItem('authToken');

        try{

            const category = new ShopService()
            const response = await category.editShop(token, data)

            return response


        }catch(err) {
            throw err
        }

    },

    async dropShop({commit}, id){

        const token = localStorage.getItem('authToken');
        try{

            const category = new ShopService()
            const response = await category.dropShop(token, id)

            return response


        }catch(err) {
            throw err
        }

    },

};

const getters = {
    Shop: state => state.Shop,
    errorShop: state => state.errorShop
}

export default{
    state,
    getters,
    mutations,
    actions
}

