<template>
    
</template>

<script>

export default{
    name: "FiltreDashboard",

    data(){
        return{
            roles : null,
        }
    },

    created(){
        this.roles = localStorage.getItem("role");
    }
}

</script>