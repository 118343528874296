<template>
    <div>
        <div class="w-screen h-screen overflox-x-hidden">
            <Header></Header>

            <div class="w-full bg-white mb-12 overflow-x-hidden">
                <div class="w-full h-full">
                    <div class="w-full px-4 sm:px-36">
                        <div class="my-7">
                            <div class="grid hidden  grid-cols-2 md:grid-cols-3">
                            
                                <div class="flex w-full mb-4">
                                    <div class="w-1/4 mx-4">
                                        <label for="" class=" font-bold">Catégorie</label>
                                    </div>
                                    <div class="w-3/4">
                                        <select name="" id="" class="block w-full px-4 py-1.5 rounded-lg " style="background-color: #FBF7EE;">
                                            <option value="Toutes">Toutes</option>
                                            <option value="option2">Option 2</option>
                                            <option value="option3">Option 3</option>
                                            <option value="option4">Option 4</option>
                                            <option value="option5">Option 5</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="flex w-full mb-4">
                                    <div class="w-1/4 mx-4">
                                        <label for="" class=" font-bold">Produit</label>
                                    </div>
                                    <div class="w-3/4">
                                        <select name="" id="" class="block w-full px-4 py-1.5 rounded-lg " style="background-color: #FBF7EE;">
                                            <option value="Aujourd'hui">Aujourd'hui</option>
                                            <option value="option2">Option 2</option>
                                            <option value="option3">Option 3</option>
                                            <option value="option4">Option 4</option>
                                            <option value="option5">Option 5</option>
                                        </select>
                                    </div>
                                </div>
                                
                            </div>

                            <div class=" hidden w-full  mb-14">
                            <button type="submit" class="w-40 px-4 text-white py-1.5 rounded-lg" style="background-color: #DEA20A;">Filtrer le resultat</button>
                        </div>
                        </div>
                    <div class="w-full mb-14" style="background-color: #FBF7EE;">
                        <div>
                            <div class="flex w-full" style="background-color: #DEA20A;">
                                <div class="text-white text-left pl-2 w-40 py-5 text-m">
                                    Nom
                                </div>
                                <div class="text-white text-left pl-2 w-40 py-5 text-m">
                                    Produit
                                </div>
                                <div class="text-white text-left pl-2 w-40 py-5 text-m">
                                    Quantité disponible
                                </div>
                                <div class="text-white text-left pl-2 w-40 py-5 text-m">
                                    Prix unitaire
                                </div>
                                <div class="text-white text-left pl-2 w-40 py-5 text-m">
                                    Taille
                                </div>
                                <div class="text-white text-left pl-2 w-40 py-5 text-m">
                                    Produits expirés
                                </div>
                            </div>
                            <div class="mb-12 w-full  overflow-y-auto">
                                <div class="flex w-full my-1.5 " v-for="(stock, index) in data" :key="index">
                                        <div class="w-40  py-2 px-2 text-sm line-clamp-2 leadin-none overflow-hidden text-ellipsis h-12 text-black text-left">
                                            {{ stock.nom }}
                                        </div>
                                        <div class="hidden sm:inline w-40  py-2 px-2 text-sm line-clamp-2 leadin-none overflow-hidden text-ellipsis h-12 text-black text-left">
                                            <p v-for="(categorie, index) in stock.categories" :key="index">{{ categorie.nom }}</p>
                                        </div>
                                        <div class="w-40  py-2 px-2 text-sm line-clamp-2 leadin-none overflow-hidden text-ellipsis h-12 text-black text-left">
                                            <!--p v-if="stock.stock_total == null">0</p-->
                                            <p>{{ stock.stock_total  }}</p>
                                        </div>
                                        <div class="w-40  py-2 px-2 text-sm line-clamp-2 leadin-none overflow-hidden text-ellipsis h-12 text-black text-left">
                                            {{ stock.prix }} $
                                        </div>
                                        <div class="w-40  py-2 px-2 text-sm line-clamp-2 leadin-none overflow-hidden text-ellipsis h-12 text-black text-left">
                                            {{ stock.taille }}
                                        </div>
                                        <div class="w-40  py-2 px-2 text-sm line-clamp-2 leadin-none overflow-hidden text-ellipsis h-12 text-black text-left">
                                            {{ stock.nombre_produits_expires }} 
                                        </div>
                                </div>
                            </div>
                        </div>
                        <!-- Les autres colonnes -->
                    </div>
                </div>
            </div>
        </div>
    
            <Footer></Footer>
        </div>
    </div>
</template>

<script>

import Header from '@/components/layout/Header.vue'
import Footer from '@/components/layout/Footer.vue'
import Boutique from '@/_services/shop.services'
import {mapActions} from 'vuex'


    export default{
        name: 'stockShop',

        components:{
            Header,
            Footer
        },

        data() {
            return{
                roles: null,
                data: null,
                itemUsers: null,
                idLivreur: null,
                IdUserLivreur: null,
                itemboutique:[]
            }
        },

        methods:{
            //manager
            ...mapActions(['getStockBotique']),
            getData(){
                this.getStockBotique()
                    .then(response => {
                        this.data = response['data'];
                        //console.log(this.data);
                    })
                    .catch(error => {
                        // Une erreur s'est produite lors de la récupération des données
                        //.error(error);
                    });
            },

            //liste des tout les livreurs de genesisdream unique pour le agent
            ...mapActions(['getLivreur']),
            viewLivreur(){
                this.getLivreur()
                    .then(response =>{
                        this.data = response['data']
                        

                    })
                    .catch(error => {
                        //.error(error);
                    });

            },

        },

        created(){
            this.roles = localStorage.getItem("role");

            if(this.roles == null){
                this.$router.push('/authentification')
            }
            this.idLivreur = localStorage.getItem("IdUser");
            if(this.roles == 'manager'){
                this.getData()
            }else if(this.roles == 'admin'){
                this.viewLivreur(); 
            }
            
        },

        mounted(){
            const boutique = new Boutique();
            boutique.getAllShop()
                .then(res => {
                    this.itemboutique = res.data;
                    const idShop = this.itemboutique.find(shop => shop.manager.user.id == this.idLivreur)
                    localStorage.setItem('idShop', idShop.id)
                }).catch(error => {
                    //(error)
                });
        }
    }

</script>