<template>
    <div class="w-full h-full overflow-x-hidden">
        <Header></Header>
        <div class="w-full bg-white mb-20">
            <div class="px-4 md:px-36 ">
                <div class="flex h-10 my-8">
                    <p class="text-3xl">Notifications</p>
                </div>
                <div class="w-full">
                    <div class="rounded-md mb-4 h-max px-8 py-2 shadow-md" style="background-color: #FBF7EE;" v-for="(item, index) in items" :key="index">
                        <div class="flex h-full">
                            <div class="w-48 border-r flex justify-center items-center">
                              <img src="@/images/LOGO_GEN.png" class="w-48" alt="">
                            </div>
                            <div class="w-4/5 px-2 py-2">
                                <div class="w-full py-4 font-bold text-left text-xl">{{ item.data.titre }}</div>
                                <div class="w-full h-max py-1 text-left text-sm">
                                    <p class="">
                                        {{ item.data.message }}
                                    </p>
                                </div>
                                <div class="w-full flex justify-end py-1 mt-2 text-left text-sm" style="color: #DEA20A;">
                                    <p v-text="formData(item.created_at)"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="my-8">
                    <button class="p-2 rounded-lg text-white cursor-pointer" style="background-color: #DEA20A;" @click="loadMore" v-if="displayedItems.length < items.length">Afficher plus</button>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>

</template>

<script>
import Header from '@/components/layout/Header.vue'
import Footer from '@/components/layout/Footer.vue'
import {mapActions} from 'vuex'


export default {
    name: 'notification',

    components: {
        Header,
        Footer
    },

    data() {
    return {
      items: [], // Vos données d'éléments
      displayedItems: [] // Les éléments affichés actuellement
    }
  },
  mounted() {
    // Chargez vos données d'éléments ici
    // Par exemple, vous pouvez faire une requête API pour récupérer les éléments
    // et les stocker dans la propriété "items"
    var div = document.getElementById('noti');
    //div.style.display = 'none';
    //this.items = ['Élément 1', 'Élément 2', 'Élément 3','Élément 1', 'Élément 2', 'Élément 3','Élément 1', 'Élément 2', 'Élément 3','Élément 1', 'Élément 2', 'Élément 3','Élément 1', 'Élément 2', 'Élément 3','Élément 1', 'Élément 2', 'Élément 3','Élément 1', 'Élément 2', 'Élément 3','Élément 1', 'Élément 2', 'Élément 3','Élément 1', 'Élément 2', 'Élément 3','Élément 1', 'Élément 2', 'Élément 3','Élément 1', 'Élément 2', 'Élément 3','Élément 1', 'Élément 2', 'Élément 3','Élément 1', 'Élément 2', 'Élément 3','Élément 1', 'Élément 2', 'Élément 3','Élément 1', 'Élément 2', 'Élément 3','Élément 1', 'Élément 2', 'Élément 3','Élément 1', 'Élément 2', 'Élément 3','Élément 1', 'Élément 2', 'Élément 3','Élément 1', 'Élément 2', 'Élément 3' ]; // Remplacez avec vos propres données
    this.displayedItems = this.items.slice(0, 5); // Affichez les 10 premiers éléments initialement
  },
  methods: {
    loadMore() {
      const currentLength = this.displayedItems.length;
      const remainingItems = this.items.slice(currentLength, currentLength + 5);
      this.displayedItems = this.displayedItems.concat(remainingItems);
    },

    //get notification
    ...mapActions(['getNoti']),
    getNotification(){
        this.getNoti()
            .then(reponse =>{
                this.items = reponse.data.data
            })
            .catch(error =>{
                //.log(error)
            })
            .finally(() =>{
                ////.log("esili")
            });
    },

    formData(date){
            const dateObj = new Date(date);

            const year = dateObj.getFullYear();
            const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
            const day = dateObj.getDate().toString().padStart(2, '0');

            return `${day}/${month}/${year}`
        },

  },

  created(){
    this.getNotification();
  }

}

</script>