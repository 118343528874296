<template>
    <div class="h-full">
        <div class="w-full h-full overflox-x-hidden">
            <Header></Header>

            <div class="w-full h-full mb-24 mt-0.5" style="background: #FBF7EE;">
              <div class="px-4 sm:px-14 md:px-24">
                <Filtre></Filtre>
                <ResultFiltre></ResultFiltre>
              </div>
            </div>
            <Footer class="border-t"></Footer>
        </div>
    </div>
</template>
  

<script>
import Header from '@/components/layout/Header.vue'
import Footer from '@/components/layout/Footer.vue'
import Filtre from '@/components/FiltreDashboard.vue'
import ResultFiltre from '@/components/ResultFiltre.vue'

export default{
    name: 'Dashboard',
   
    components:{
        Header,
        Footer,
        Filtre,
        ResultFiltre
    },

    data(){
        return{
            roles: null
        }
    },

    created(){
        this.roles = localStorage.getItem("role");
        if(this.roles == null){
            this.$router.push('/authentification')
        }
    }

}

</script>