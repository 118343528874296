import Axios from './caller.services'

export default class ShopService {

    //Récupérer la liste de shop
    async getAllShop(){
        try {
            const response = await Axios.get('/api/boutique');
            return response.data;
        } catch (error) {
            //.error(error);
            throw error
        }
    };

    //Ajouter une nouvelle boutique 
    async addShop(shopData, token){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }

        try {
            const response = await Axios.post(
                '/api/backoffice/boutique', 
                shopData, 
                {headers});
            return response.data;
        } catch (error) {
            
            throw error.response.data;
        }
    };

    //modifier 
    async editShop(token, data){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }

        
        try {
            const response = await Axios.post(
                `/api/backoffice/boutique/${data.id}`, 
                {
                  'nom':  data.nom,
                  'ville_id': data.ville_id,
                  'manager_id': data.manager_id
                }, 
                {headers});

            return response;
        } catch (error) {
            throw error
        }   
    };

     //supprimer 
     async dropShop(token, id){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        try {
            const response = await Axios.delete(
                `/api/backoffice/boutique/${id.id}`,
                {headers});

            return response;
        } catch (error) {
            throw error
        }   
    }
    



}