import CommandInterne from '@/_services/commandInterne.services'


const state = {
    command : [],
    errorcommandInter : null
};

const mutations = {
    SET_COMMAND(state, command){
        state.command = command
    },

    SET_ERROR(state, errorcommand){
        state.errorcommand = errorcommand;
    }   
}


const actions = {
    
    submitCommandIn: async ({commit}, commandData) => {
        const token = localStorage.getItem('authToken');
        try{
            const requeteData = new CommandInterne()
            const response = await requeteData.addcommandIn(commandData, token);
            commit('SET_COMMAND',[...state.command, response]);
            commit('SET_ERROR', null)
            

            return response;
        }

        catch(error) {
            commit('SET_ERROR', error.message);
            throw error;
        }

    },

    sendcommdeclient: async ({commit}, commandData) => {

        try{
            const requeteData = new CommandInterne()
            const response = await requeteData.addcommandInCl(commandData);
            commit('SET_COMMAND',[...state.command, response]);
            commit('SET_ERROR', null)
            

            return response;
        }

        catch(error) {
            commit('SET_ERROR', error.message);
            throw error;
        }

    },

    async modifCom({commit}, data){
        const token = localStorage.getItem('authToken');

        try{

          

            const requeteData = new CommandInterne()
            const response = await requeteData.modifCom(data, token);
            commit('SET_COMMAND',[...state.command, response]);
            commit('SET_ERROR', null)
            

            return response;
        }

        catch(error) {
            commit('SET_ERROR', error.message);
            throw error;
        } 

    },

    async reportcommande({commit}, data){
        const token = localStorage.getItem('authToken')

        try{
            const requeteData = new CommandInterne()
            const response = await requeteData.reportcommande(data, token)

            return response
        }catch(error){
            throw error
        }

    },

    async injoincommande({commit}, data){
        const token = localStorage.getItem('authToken')

        try{
            const requeteData = new CommandInterne()
            const response = await requeteData.injoincommande(data, token)

            return response
        }catch(error){
            throw error
        }

    }
};

const getters = {
    command: state => state.command,
    errorcommand: state => state.errorcommand
}

export default{
    state,
    getters,
    mutations,
    actions
}

