import ProductService from '@/_services/produit.services';


const state = {
    product: [],
    errorproduct: null,
    item: null
};

const mutations = {
    SET_product(state, product){
        state.product = product
    },

    SET_ERROR(state, errorproduct){
        state.errorproduct = errorproduct;
    },

    SET_ITEM(state, item){
        state.item = item;
    }
};

const actions = {
    
    submitProduct: async ({commit}, productData) => {
        const token = localStorage.getItem('authToken');
        try{
            const product = new ProductService()
            const response = await product.addproduct(productData, token);
            commit('SET_product',[...state.product, response]);
            commit('SET_ERROR', null)

            return response;
        }

        catch(error) {
            commit('SET_ERROR', error.message);
            throw error;
        }

    },

    fechItem: async ({commit}, id)=>{
        try{
            const product = new ProductService()
            const reponse = await product.getProduitById(id);
            console.log(reponse)
            commit('SET_ITEM', reponse.data);
        }catch(error) {
            throw error;
        }
    },

    dropProduct({commit}, id){
        const token = localStorage.getItem('authToken')

        try{
            const request = new ProductService()
            const reponse = request.dropProduct(id, token)

            return reponse
        }catch(error) {
            throw error;
        }

    }

};

const getters = {
    product: state => state.product,
    errorproduct: state => state.errorproduct
}

export default{
    state,
    getters,
    mutations,
    actions
}

