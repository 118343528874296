<template>
   
  <div class="w-full h-full overflow-x-hidden mb-14">
      <Header></Header>
      <div class="w-full bg-white mb-10">
          <div class="w-full">
              <div class="px-4 md:px-36">
                  <div class="my-16">
                      <div class="flex">
                          <button class="px-4 text-white py-1.5 rounded-lg" style="background-color: #DEA20A;">
                              <router-link to="/newcategorie">Ajouter une catégorie</router-link>
                          </button>
                      </div>
                  </div>
                 <!--Affichage de message de successfull-->
                    <div class="px-4 flex justify-center" v-show="succes">
                        <p class="bg-green-400 px-1.5 py-2 text-white">{{succes}}</p>
                    </div>
                    <!--Affichage de message de successfull-->


                    <!--Affichage de message de error-->
                    <div class="px-4 flex justify-center" v-show="erreur">
                        <p class="bg-red-600 px-4 py-2 text-white">{{erreur}}</p>
                    </div>
                    <!--Affichage de message de error-->
                      <div class="w-full">
                          <div class="flex w-full overflow-x-hidden" style="background-color: #DEA20A;">
                              <div class="w-20 text-white text-center text-black text:xs sm:text-sm pl-2 py-5 text-md">
                                  N°
                              </div>
                              <div class="w-52 text-white text-center text-black text:xs sm:text-sm pl-2 py-5 text-md">
                                  Nom Catégories
                              </div>
                              <div class="p-2 overflow-x-auto w-full flex justify-end ">
                                 
                              </div>
                             
                          </div>
                          <div class="px-2 w-full overflow-y-auto h-80" style="background-color: #FBF7EE;">
                              <div class="flex w-full my-1.5  overflow-y-auto" v-for="(items, index) in itemCategory" :key="index">
                                  <div class="w-20 p-2 overflow-x-auto whitespace-nowrap   text-sm  text-center text-black">
                                      {{ index + 1 }}
                                  </div>
                                  <div class="w-52 p-2 overflow-x-auto whitespace-nowrap   text-sm  text-center text-black">
                                      {{ items.nom }}
                                  </div>
                                  <div class="p-2 overflow-x-auto w-full flex justify-end">
                                      <button @click="openModal(items.id)" class="px-2 sm:px-4 text-white text-xs sm:text-sm py-1.5 mr-5 rounded-lg text-sm  text-center" style="background-color: #25A4EC;">
                                              Modifier
                                      </button>
                                      <button @click="drop(items.id)" class="px-2 sm:px-4 text-white text-xs sm:text-sm py-1.5 rounded-lg text-sm  text-center bg-red-500">
                                              Supprimer
                                      </button>
                                  </div>
                                  
                              </div>
                          </div>
                      </div>
                      <!-- Les autres colonnes -->
                  
                  <!--Modal-->
              </div>
          </div>
        </div>
        <div v-show="modal" v-if="getId" class="fixed inset-0 flex items-center justify-center">
            <div class="bg-white shadow-md rounded-lg p-6 max-w-sm mx-auto z-50">
                <h2 class="text-lg font-bold mb-4">Modification</h2>
                <div class="text-left flex mb-5">
                    <p class="mr-3 font-bold">Nom :</p> <input type="text" v-model="nameCate" class="border px-1 border-black" name="" id="">
                </div>
                <p class="text-red-500">{{ msg }}</p>
                <div class="text-left flex justify-between">                    
                    <button @click="modifCat" id="closeModalButton" class="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                        Modifier
                    </button>
                    <button @click="close" id="closeModalButton" class="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                        Fermer
                    </button>
                </div>
            </div>
        </div>
        <div v-if="IdDrop" class="fixed inset-0 flex items-center justify-center">
                <div class="bg-white shadow-md rounded-lg p-6 max-w-sm mx-auto z-50">
                    <h2 class="text-lg font-bold mb-4">Voulez-vous supprimer cet élément?</h2>
                    <div class="text-left flex justify-between">                    
                        <button @click="dropCat(IdDrop)" id="closeModalButton" class="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                            Supprimer
                        </button>
                        <button @click="close" id="closeModalButton" class="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                            Fermer
                        </button>
                    </div>
                </div>
            </div>
      <Footer></Footer>
  </div>

</template>



<script>

import Header from '@/components/layout/Header.vue'
import Footer from '@/components/layout/Footer.vue'
import Category from '@/_services/category.services'


export default{
  name: 'category',

  components:{
      Header,
      Footer,
  },

  data(){
      return {
          itemCategory: [],
          nameCate: '',
          modal: false,
          idCat: '',
          getId: [],
          succes: null,
          erreur: null,
          msg: null,
          IdDrop: null
      }
  },

  mounted(){
      const category = new Category();
      category.getAllCat()
          .then(categorie => {
              this.itemCategory = categorie.data;
          })
  },

  computed:{
      sortedItems(){
          return this.itemCategory.sort((a,b) => a.id - b.id);
      }
  },

  methods: {

    close(){
        this.modal = false;
        this.IdDrop = null
    },

    openModal(id){
        this.idCat = id;

        if(this.modal === id){
            this.modal = null;
        }else{
            this.modal = id
            const idCom = this.itemCategory.find(com => com.id == id);
            this.getId = idCom
            this.nameCate = this.getId.nom

        }

    },

    drop(id){

        this.IdDrop = id;

    },

    modifCat(){
        if(!this.nameCate){
            this.msg = "Aucun champ n'est peut être vide"
        }else{
            this.$store.dispatch('editCat',{
                nom : this.nameCate,
                id : this.idCat
            })
            .then((response) => {
                this.modal = false;
                if(response.status === 200){
                    this.succes = "Catégories modifier " + response.data.nom
                    setTimeout(() =>{
                                    this.succes = false;
                                    location.reload();
                                }, 
                                5000)
                }
            })
            .catch((error) => {
                
            })
            .finally(() => {
                this.nameCate =''
            });
        }
    },

    dropCat(id){
        
        this.IdDrop = null
        this.$store.dispatch('dropCat',{
            id : id
        })
        .then((response) => {
            if(response.status === 200){
                this.succes = "Catégories supprimer "
                setTimeout(() =>{
                                this.succes = false;
                                location.reload();
                            }, 
                            5000)
            }else{
                
            }
        })
        .catch((error) => {
            
        })
        .finally(() => {
            return
        });
    }

  }

}

</script>