import Axios from './caller.services'


export default class AssignerLivreur{

    //assignation à un livreur action réaliser par un manager et agent
    async postAssign(data, token){

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        //return data
        const Senddata = {
            'livreur_id' : data.livreur_id
        }
        
        try{

            const response = await Axios.post(
                `/api/commande/add/livreur/${data.id}`,
                
                    Senddata
                ,
                {
                    headers
                }
            );

            return response

        }catch(e){
            throw e;
        }

    };

    //assigner une commande a un agent
    async postAsComAgent(data, token){

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Access-Control-Allow-Origin':'*',
            'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,PATCH,OPTIONS',
            
            'Access-Control-Allow-Headers': "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'"
        }

        const agent_id = {
            'agent_id': data.agent_id
        }

        try{
            const reponse = await Axios.post(
                `/api/backoffice/commande/agent/${data.id}`,
                agent_id,
                {headers}
            );

            return reponse.data;
        }catch(error) {
            throw error;
        };
        
    };

    //assigner a une boutique la commande

    async postAsComShop(data, token){

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Access-Control-Allow-Origin':'*',
            'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,PATCH,OPTIONS',
            
            'Access-Control-Allow-Headers': "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'"
        }
        
        const boutique_id= {
            'boutique_id': data.boutique_id
        };
        try{
            const reponse = await Axios.post(
                `/api/commande/change-boutique/${data.id}`,
                boutique_id,
                {headers}
            );

            return reponse;
        }catch(error) {
            throw error;
        };
        
    }

    //Modification de l'horaire de livraison
    async editHoraire(data, token){

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Access-Control-Allow-Origin':'*',
            'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,PATCH,OPTIONS',
            
            'Access-Control-Allow-Headers': "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'"
        }

        const date = {
            'date_livraison' : data.date_livraison
        }

        try{
            const response = await Axios.post(
                `/api/livraison/change-date/${data.id}`,
                date,
                {headers}
            )

            return response
        }
        catch(error) {
            throw error
        }

    }

    

}