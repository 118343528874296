<template>
    <div @click="hideDiv">
        <div class="w-screen overflow-x-hidden">
            <Header></Header>

            <div class="w-full bg-white mt-2 mb-12 overflow-x-auto">
                <div class="w-full h-full">
                    <div class="w-full px-4 sm:px-36">
                        <div class="my-16">
                            <form @submit.prevent="sendDatafiltre">
                                <div class="flex w-full mb-4">
                                        <div class="w-max mr-4">
                                            <label for="" class="font-bold text:xs sm:text-sm">Ville</label>
                                        </div>
                                        <div class="w-40 sm:w-72 mr-4">
                                            <select name="" id="" v-model="filters.ville" class="block text:xs sm:text-sm w-full px-4 py-1.5 rounded-lg " style="background-color: #FBF7EE;">
                                                <option :value="item.id" v-for="(item, index) in getVille" :key="index">{{ item.nom }}</option>
                                            </select>
                                        </div>
                                        <div class=" w-40">
                                            <button type="submit" class="w-40 px-4 text:xs sm:text-sm text-white py-1.5 rounded-lg" style="background-color: #DEA20A;">Filtrer le resultat</button>
                                        </div>
                                    
                                </div>
                            </form>
                            <div class="flex" v-if="roles === 'manager'">
                                <router-link to="/enregistrement">
                                    <button class="px-4 text-white py-1.5 rounded-lg" style="background-color: #DEA20A;">Enregistrer un livreur</button>
                                </router-link>
                            </div>
                        </div>
                        <div class="w-full h-96 mb-14" style="background-color: #FBF7EE;">
                            <div>
                                <div class="flex w-full" style="background-color: #DEA20A;">
                                    <div class="text-white w-52 py-5 text-md">
                                        Nom
                                    </div>
                                    <div class="text-white w-52 py-5 text-md text-left">
                                        Ville
                                    </div>
                                    <div class="text-white w-52 py-5 text-md text-left">
                                        Total à livré
                                    </div>
                                    <div class="text-white w-52 py-5 text-md text-left">
                                        Total livré
                                    </div>
                                    <div class="text-white w-52 py-5 text-md text-left">
                                        Chiffre d'affaires
                                    </div>
                                    <div class="text-white w-52 py-5 text-md text-left">
                                        Options
                                    </div>
                                </div>
                                <div class="h-80 w-full overflow-y-auto">
                                    <div class="flex w-full my-1.5 p-1" v-for="(livrer, index) in fetchedData" :key="index">
                                        <div class="w-52 py-2 px-2 text-sm line-clamp-2 leadin-none overflow-hidden text-ellipsis h-12 text-black text-left">
                                            {{ livrer.user['nom'] }} {{ livrer.user['prenom'] }}
                                        </div>
                                        <div class="w-52  py-2 px-2 text-sm line-clamp-2 leadin-none overflow-hidden text-ellipsis h-12 text-black text-left">
                                            {{ livrer.boutique.ville.nom }}
                                        </div>
                                        <div class="w-52  py-2 px-2 text-sm line-clamp-2 leadin-none overflow-hidden text-ellipsis h-12 text-black text-left">
                                            {{ livrer.total_a_livrer }}
                                        </div>
                                        <div class="w-52  py-2 px-2 text-sm line-clamp-2 leadin-none overflow-hidden text-ellipsis h-12 text-black text-left">
                                            {{ livrer.total_livré }}
                                        </div>
                                        <div class="w-52  py-2 px-2 text-sm line-clamp-2 leadin-none overflow-hidden text-ellipsis h-12 text-black text-left">
                                            {{ livrer.total_sum }}
                                        </div>
                                        <div class="w-52  py-2 px-2 text-sm line-clamp-2 leadin-none overflow-hidden text-ellipsis h-12 text-black text-left">
                                            <div class="relative inline-flex">
                                                <div class="flex items-center">
                                                    <img @click="toggleOptions(livrer.id, $event)" src="@/images/option.png" alt="" class="w-6 h-6 mr-2 cursor-pointer">
                                                </div>
                                            </div>
                                            <div class="absolute z-10 flex ">
                                                <div class="py-4 px-2 text-left shadow-lg w-40 bg-white text-black rounded-lg" v-if="option === livrer.id" @click="preventHideDiv">
                                                    <button class="text-left my-1.5 w-full hover:text-blue-600" @click="viewHoraire(livrer.id)">Voir les horaires de livraison</button>
                                                    <button class="text-left my-1.5 w-full hover:text-blue-600" v-if="roles === 'admin' || roles === 'gestionnaire'" @click="viewStock(livrer.id)">Voir le stock</button>
                                                    <button class="text-left my-1.5 w-full hover:text-blue-600" @click="redirigerWhaysapp(livrer.user.telephone)">Contacter</button>
                                                    <button class="text-left hidden my-1.5 w-full hover:text-blue-600" v-if="roles === 'admin' ">Bloquer le livreur</button>
                                                    <button class="text-left my-1.5 w-full hover:text-blue-600"  @click="ViewsDashbord(livrer.id)" v-if="roles === 'admin' || roles === 'gestionnaire'">Dashboard</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Les autres colonnes -->
                        </div>
                        <!--Modal-->
                        <div class="absolute flex items-center justify-center inset-0" v-if="getHLiv"  v-show="modifHoraire" @click="preventHideDiv">
                            <div class="shadow-lg w-96 h-56 bg-white">
                                <div class="flex justify-end py-2 pr-2">
                                    <img src="@/images/close.png" alt="" @click="close" class="w-3 cursor-pointer" srcset="">
                                </div>
                                <div class="p-2 h-48 overflow-y-auto">
                                    <div class="mb-2 w-full" v-for="(com, index) in getHLiv" :key="index"  style="background-color: #FBF7EE;">
                                        <div class="flex text-left text-sm p-1.5">
                                            <div class="flex mr-1">
                                                <p class="pr-1.5">Lvraison à {{ com.adresse }},</p>
                                                <p v-text="formDate(com.date_livraison)"></p>
                                            </div>
                                            <p class="font-bold linie-clamp-1 leadin-none overflow-hidden text-ellipsis">({{ com.statut }})</p>
                                        </div>
                                        <button class="text-white w-72 rounded-lg" v-if="com.statut !== 'livrée'" @click="changeDate(com.id)" style="background-color: #DEA20A;">modifier l'horaire</button>
                                    </div>        
                                </div>
                            </div>
                            <div class="shadow-lg w-56 h-32 bg-white" v-if="modifDate">
                                <div class="flex justify-end py-2 pr-2">
                                    <img src="@/images/close.png" alt="" @click="closeDate" class="w-3 cursor-pointer" srcset="">
                                </div>
                                <form @submit.prevent="sendModi(modifDate)">
                                    <div class="w-full py-2 px-4 h-24">
                                        <div>
                                            <input type="date" name="" v-model="date" id="" class="mb-4 w-48 px-1" style="background-color: #FBF7EE;">
                                        </div>
                                        <button type="submit" class="py-1 px-2 w-48 text-white" style="background-color: #DEA20A;">Modifier</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <!--Voir le stock du livreur-->
                        <div class="absolute flex items-center justify-center inset-0" v-if="getStock" v-show="Stk" @click="preventHideDiv">
                            <div class="shadow-lg w-96 h-56 bg-white">
                                <div class="flex justify-end py-2 pr-2">
                                    <img src="@/images/close.png" alt="" @click="close" class="w-3 cursor-pointer" srcset="">
                                </div>
                                <div class="p-2 h-48 overflow-y-auto">
                                    <div class="mb-2 min-h-16 w-full" v-for="(com, index) in getStock" :key="index"  style="background-color: #FBF7EE;">
                                        <div class="text-left text-sm p-1.5">
                                            <div class="flex mr-1">
                                                <p class="pr-1.5">Produit : {{ com.nom }}</p>
                                            </div>
                                            <div class="flex mr-1" v-for="(qdt, index) in com.commandes" :key="index">
                                                <p class="pr-1.5">Commandes {{ index + 1  }} : </p>
                                                <p class="mr-2">(Quantité {{ qdt.pivot.quantite }})</p>
                                                <p v-if="qdt.statut === 'livée'" class="text-red-500">Livrée</p>
                                                <p v-else class="text-green-500">En livraison</p>
                                            </div>
                                        </div>
                                    </div>        
                                </div>
                            </div>
                        </div>
                        <!--Fin Modal-->
                    </div>
                </div>
            </div>
            <div v-if="idLivreur" class="fixed inset-0 flex items-center justify-center">
                <div class="bg-white shadow-md rounded-lg p-2 max-w-sm mx-auto z-50">
                    <div class="text-red-500 cursor-pointer font-bold flex mb-2 justify-end" @click="close">Fermer</div>
                    <MiniDash :id="idLivreur"/>
                </div>
            </div>
            <Footer></Footer>
        </div>
    </div>
</template>

<script>

import Header from '@/components/layout/Header.vue'
import Footer from '@/components/layout/Footer.vue'
import {mapActions} from 'vuex'
import Ville from '@/_services/province.services'
import Axios from '@/_services/caller.services'
import MiniDash from '@/components/minidashoard.vue'


    export default{
        name: 'listlivrer',

        components:{
            Header,
            Footer,
            MiniDash
        },

        data(){
            return{
                fetchedData: null,
                roles: null,
                token: null,
                idLivreur: null,
                modifHoraire : false,
                Stk: false,
                getHLiv: [],
                modifDate : null,
                option: null,
                chiffreA : null,
                getVille: [],
                cachB: '',
                filters:{
                    ville: "",
                },
                date: null,
                getStock: null
            }
        },

        methods:{
            //action pour admin, gestionnaire
           ...mapActions(['fechDataLivrer']),
            fetchData(){
                this.fechDataLivrer()
                    .then(response => {
                        this.fetchedData = response.data;
                        //.log(this.fetchedData)
                    })
                    .catch(error => {
                        // Une erreur s'est produite lors de la récupération des données
                        //.error(error);
                    });
            },

            //action qui affiche le stock de livreur seul admin, gestionnaire peut l'utilisé
            viewStock(id){
                this.Stk = true
                this.option = null
                this.$store.dispatch('getStocks',{
                    'id': id
                })
                .then((res)=>{
                    this.getStock = res.data.data
                    ////.log(this.getStock)
                })
                .catch((err)=>{
                    ////.log(err)
                })
                .finally(()=>{
                    
                });
            },

            
            //action pour la manager
           ...mapActions(['fetchLiveShop']),
            fetchDataMana(){
                this.fetchLiveShop()
                    .then(response => {
                        this.fetchedData = response.data.data;
                        ////.log(this.fetchedData);
                        
                    })
                    .catch(error => {
                        // Une erreur s'est produite lors de la récupération des données
                        
                    });
            },

            //action pour l'agent
            //liste des tout les livreurs de genesisdream unique pour le agent
            ...mapActions(['getLivreur']),
            viewLivreur(){

                this.getLivreur()
                    .then(response =>{
                        this.fetchedData = response.data
                    })
                    .catch(error => {
                        //.error(error);
                    });

            },

            //modification de l'horaire
            viewHoraire(id){
                this.modifHoraire = true;
                this.option = false
                
                const content = this.fetchedData.find(liv => liv.id == id)
                this.getHLiv = content.commandes
                this.cachB = content
                
            },

            formDate(date){
                const dateObj = new Date(date);

                const year = dateObj.getFullYear();
                const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
                const day = dateObj.getDate().toString().padStart(2, '0');

                return `${day}/${month}/${year}`
            },

            toggleOptions(id, event){
                event.stopPropagation();
                
                if(this.option === id){
                    this.option = null;
                }else{
                    this.option = id;
                }

            },

            hideDiv(){
                this.option = false;
                this.modifDate = null;
                this.modifHoraire = false;
                this.Stk = false
            },

            close(){
                this.modifHoraire = false;
                this.modifDate = null;
                this.Stk = null
                this.idLivreur = null
            },

            closeDate(){
                this.modifDate = null;
            },

            changeDate(id){
                this.modifDate = id;
            },

            
            preventHideDiv(e){
                e.stopPropagation(); 
            },

            async sendDatafiltre(){
                const headers = {
                    'Authorization': `Bearer ${this.token}`
                }

                try{
                    const response = await Axios.get(
                        '/api/livreur',
                        {
                            params : this.filter,
                            headers
                        }
                    )

                    this.fetchedData = response.data.data
                    ////.log(this.fetchedData)

                }catch(e){
                    //.log(e)
                }finally{
                    this.filters={
                        ville : ""
                    }
                }
            },

            async sendModi(id){
                this.$store.dispatch('EditHoraire',{
                    'id' : id,
                    'date_livraison' : this.date
                })
                .then((res)=>{
                    //.log(res)
                    if(res.data.status_code === 200){
                        alert("La date a été modifier avec succes")
                    }else{
                        alert(res.data.message)
                    }
                })
                .catch(err=>{
                    if(err.response.status === 422){
                        alert(err.response.data.message)
                    }
                })
                .finally(()=>{
                    this.date = "",
                    this.modifDate = null,
                    this.modifHoraire = false
                    setTimeout(() =>{
                        location.reload();
                    }, 
                    1000)
                })
            },

            //redirection vers whatsapp du client 
            redirigerWhaysapp(numero){
                //.log(numero)
                const message = 'GenesisDream vous salut';

                const url = `https://wa.me/${numero}`;
                window.open(url, '_blank');
                return url;

            },

            //Views Dashboard
            ViewsDashbord(id){
                this.idLivreur = id;
                this.option = null               
            }

        },

        created(){
            
            this.roles = localStorage.getItem("role");
            this.token =  localStorage.getItem("authToken");
            if(this.roles == null){
                this.$router.push('/authentification')
            }
            if(this.roles === 'admin' || this.roles === 'gestionnaire'){
                this.fetchData();
            }else if(this.roles === 'manager'){
                this.fetchDataMana();
            }
            else{
                this.viewLivreur()
            }
            
        },
        mounted(){
             //get ville
            const ville = new Ville();
            ville.getAllPro()
                .then(prov => {
                    this.getVille = prov.data;
                }).catch(err => {
                    ////.log(err)
                });

        }
                
        

    }

</script>