<template>
    <div>
        <div class="w-screen h-full overflow-x-hidden">
            <Header></Header>
            <div class="w-full bg-white">
                <div class="w-full">
                    <div class="px-4 md:px-36">
                        <div class="my-16">
                            <div class="flex">
                                <button type="submit" class="px-4 text-white py-1.5 rounded-lg" style="background-color: #DEA20A;">
                                    <router-link to="/newprovince">
                                        Ajouter Ville
                                    </router-link>
                                </button>
                            </div>
                        </div>
                        <!--Affichage de message de successfull-->
                        <div class="px-4 flex justify-center" v-show="succes">
                            <p class="bg-green-400 px-1.5 py-2 text-white">{{succes}}</p>
                        </div>
                        <!--Affichage de message de successfull-->


                        <!--Affichage de message de error-->
                        <div class="px-4 flex justify-center" v-show="erreur">
                            <p class="bg-red-600 px-4 py-2 text-white">{{erreur}}</p>
                        </div>
                        <!--Affichage de message de error-->
                        <div class="w-full h-96" style="background-color: #FBF7EE;">
                            <div>
                                <div class="flex w-full" style="background-color: #DEA20A;">
                                    <div class="w-40 text-white text-center text-black pl-2 py-5 text-md">
                                        N°
                                    </div>
                                    <div class="w-52 text-white text-center text-black pl-2 py-5 text-md">
                                        Nom
                                    </div>
                                    <div class="w-52 text-white text-center text-black pl-2 py-5 text-md">
                                        Pays
                                    </div>
                                    <div class="w-52 text-white text-center text-black pl-2 py-5 text-md">
                                        Monnaie
                                    </div>
                                    <div class="w-52 text-white text-center text-black pl-2 py-5 text-md">
                                        Taux
                                    </div>
                                    <div class="w-52 text-white text-center text-black pl-2  py-5 text-md">
                                         Options
                                    </div>
                                </div>
                                <div class="h-96 w-full overflow-y-auto">
                                    <div class="flex w-full my-1.5" v-for="(items, index) in sortedItems" :key="index">
                                        <div class="w-40 p-2 overflow-x-auto whitespace-nowrap   text-sm  text-center text-black">
                                            {{ index + 1 }}
                                        </div>
                                        <div class="w-52 p-2 overflow-x-auto whitespace-nowrap   text-sm  text-center text-black">
                                            {{ items.nom }}
                                        </div>
                                        <div class="w-52 p-2 overflow-x-auto whitespace-nowrap   text-sm  text-center text-black">
                                            {{ items.pays}}
                                        </div>
                                        <div class="w-52 p-2 overflow-x-auto whitespace-nowrap   text-sm  text-center text-black">
                                            {{ items.monnaie['nom']}}
                                        </div>
                                        <div class="w-52 p-2 overflow-x-auto whitespace-nowrap   text-sm  text-center text-black">
                                            {{ items.taux}}
                                        </div>
                                        <div class="w-52 p-2 overflow-x-auto whitespace-nowrap   text-sm  text-center text-black  overflow-x-auto">
                                            <button  @click="openModal(items.id)"  class="px-4 text-white py-1.5 rounded-lg" style="background-color: #25A4EC;">
                                                    Modifier
                                            </button>
                                            <button @click="drop(items.id)" class="px-4 text-white py-1.5 rounded-lg bg-red-500">
                                                    Supprimer
                                            </button>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <!-- Les autres colonnes -->
                        </div>
                    </div>
                </div>
            </div>
            <div v-show="modal" v-if="getId" class="fixed inset-0 flex items-center justify-center">
                <div class="bg-white shadow-md rounded-lg p-6 max-w-sm mx-auto z-50">
                    <form action="" @submit.prevent="modifCat">
                        <h2 class="text-lg font-bold mb-4">Modification</h2>
                        <div class="text-left flex justify-between mb-5">
                            <p class="mr-3 font-bold">ville :</p> <input type="text" v-model="name" :placeholder="getId.nom" class="border px-1 border-black" name="" id="">
                        </div>
                        <div class="text-left flex justify-between mb-5">
                            <p class="mr-3 font-bold">Pays :</p> 
                            <select name="" v-model="pays"  class="w-52 border border-gray-500" id="">
                                <option disabled selected class="text-red-500">{{ getId.pays }}</option>
                                <option  :value="pays.value" v-for="pays in list_pays" :key="pays">{{ pays.value }}</option>
                            </select>
                        </div>
                        <div class="text-left flex justify-between mb-5">
                            <p class="mr-3 font-bold">Monnaie :</p> 
                            <select name="" v-model="monnaie"  class="w-52 border border-gray-500" id="">
                                <option disabled selected class="text-red-500" v-if="getId.monnaie">{{ getId.monnaie.nom }}</option>
                                <option  :value="money.id" v-for="money in itemMoney" :key="money">{{ money.nom }}</option>
                            </select>
                        </div>
                        <div class="text-left flex justify-between mb-5">
                            <p class="mr-3 font-bold">Taux :</p> <input type="text" v-model="taux" :placeholder="getId.taux" class="border px-1 border-black" name="" id="" >
                        </div>
                        <p class="text-red-500">{{msg}}</p>
                        <div class="text-left flex justify-between">                    
                            <button id="closeModalButton" class="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                Modifier
                            </button>
                        
                            <button @click="close" id="closeModalButton" class="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                                Fermer
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div v-if="IdDrop" class="fixed inset-0 flex items-center justify-center">
                <div class="bg-white shadow-md rounded-lg p-6 max-w-sm mx-auto z-50">
                    <h2 class="text-lg font-bold mb-4">Voulez-vous supprimer cet élément?</h2>
                    <div class="text-left flex justify-between">                    
                        <button @click="dropCat(IdDrop)" id="closeModalButton" class="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                            Supprimer
                        </button>
                        <button @click="close" id="closeModalButton" class="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                            Fermer
                        </button>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    </div>
</template>

<script>

import Header from '@/components/layout/Header.vue'
import Footer from '@/components/layout/Footer.vue'
import Province from '@/_services/province.services'
import Money from '@/_services/money.services'


    export default{
        name: 'province',

        components:{
            Header,
            Footer,
        },

        data(){
            return {
                itemProvince: [],
                itemMoney: [],
                nameCate: '',
                modal: false,
                idCat: '',
                name: '',
                pays: '',
                monnaie: '',
                msg: null,
                taux: '',
                getId: [],
                succes: null,
                erreur: null,
                IdDrop: null,
                list_pays : [
                    {value:"Afghanistan"},
                    {value:"Albania"},
                    {value:"Algeria"},
                    {value:"Andorra"},
                    {value:"Angola"},
                    {value:"Antigua and Barbuda"},
                    {value:"Argentina"},
                    {value:"Armenia"},
                    {value:"Australia"},
                    {value:"Austria"},
                    {value:"Azerbaijan"},
                    {value:"Bahamas"},
                    {value:"Bahrain"},
                    {value:"Bangladesh"},
                    {value:"Barbados"},
                    {value:"Belarus"},
                    {value:"Belgium"},
                    {value:"Belize"},
                    {value:"Benin"},
                    {value:"Bhutan"},
                    {value:"Bolivia"},
                    {value:"Bosnia and Herzegovina"},
                    {value:"Botswana"},
                    {value:"Brazil"},
                    {value:"Brunei"},
                    {value:"Bulgaria"},
                    {value:"Burkina Faso"},
                    {value:"Burundi"},
                    {value:"Cabo Verde"},
                    {value:"Cambodia"},
                    {value:"Cameroon"},
                    {value:"Canada"},
                    {value:"Central African Republic"},
                    {value:"Chad"},
                    {value:"Chile"},
                    {value:"China"},
                    {value:"Colombia"},
                    {value:"Comoros"},
                    {value:"Congo (Brazzaville)"},
                    {value:"Congo (Kinshasa)"},
                    {value:"Costa Rica"},
                    {value:"Croatia"},
                    {value:"Cuba"},
                    {value:"Cyprus"},
                    {value:"Czech Republic"},
                    {value:"Denmark"},
                    {value:"Djibouti"},
                    {value:"Dominica"},
                    {value:"Dominican Republic"},
                    {value:"Ecuador"},
                    {value:"Egypt"},
                    {value:"El Salvador"},
                    {value:"Equatorial Guinea"},
                    {value:"Eritrea"},
                    {value:"Estonia"},
                    {value:"Eswatini"},
                    {value:"Ethiopia"},
                    {value:"Fiji"},
                    {value:"Finland"},
                    {value:"France"},
                    {value:"Gabon"},
                    {value:"Gambia"},
                    {value:"Georgia"},
                    {value:"Germany"},
                    {value:"Ghana"},
                    {value:"Greece"},
                    {value:"Grenada"},
                    {value:"Guatemala"},
                    {value:"Guinea"},
                    {value:"Guinea-Bissau"},
                    {value:"Guyana"},
                    {value:"Haiti"},
                    {value:"Honduras"},
                    {value:"Hungary"},
                    {value:"Iceland"},
                    {value:"India"},
                    {value:"Indonesia"},
                    {value:"Iran"},
                    {value:"Iraq"},
                    {value:"Ireland"},
                    {value:"Israel"},
                    {value:"Italy"},
                    {value:"Jamaica"},
                    {value:"Japan"},
                    {value:"Jordan"},
                    {value:"Kazakhstan"},
                    {value:"Kenya"},
                    {value:"Kiribati"},
                    {value:"Korea, North"},
                    {value:"Korea, South"},
                    {value:"Kosovo"},
                    {value:"Kuwait"},
                    {value:"Kyrgyzstan"},
                    {value:"Laos"},
                    {value:"Latvia"},
                    {value:"Lebanon"},
                    {value:"Lesotho"},
                    {value:"Liberia"},
                    {value:"Libya"},
                    {value:"Liechtenstein"},
                    {value:"Lithuania"},
                    {value:"Luxembourg"},
                    {value:"Madagascar"},
                    {value:"Malawi"},
                    {value:"Malaysia"},
                    {value:"Maldives"},
                    {value:"Mali"},
                    {value:"Malta"},
                    {value:"Marshall Islands"},
                    {value:"Mauritania"},
                    {value:"Mauritius"},
                    {value:"Mexico"},
                    {value:"Micronesia"},
                    {value:"Moldova"},
                    {value:"Monaco"},
                    {value:"Mongolia"},
                    {value:"Montenegro"},
                    {value:"Morocco"},
                    {value:"Mozambique"},
                    {value:"Myanmar"},
                    {value:"Namibia"},
                    {value:"Nauru"},
                    {value:"Nepal"},
                    {value:"Netherlands"},
                    {value:"New Zealand"},
                    {value:"Nicaragua"},
                    {value:"Niger"},
                    {value:"Nigeria"},
                    {value:"North Macedonia"},
                    {value:"Norway"},
                    {value:"Oman"},
                    {value:"Pakistan"},
                    {value:"Palau"},
                    {value:"Panama"},
                    {value:"Papua New Guinea"},
                    {value:"Paraguay"},
                    {value:"Peru"},
                    {value:"Philippines"},
                    {value:"Poland"},
                    {value:"Portugal"},
                    {value:"Qatar"},
                    {value:"Romania"},
                    {value:"Russia"},
                    {value:"Rwanda"},
                    {value:"Saint Kitts and Nevis"},
                    {value:"Saint Lucia"},
                    {value:"Saint Vincent and the Grenadines"},
                    {value:"Samoa"},
                    {value:"San Marino"},
                    {value:"Sao Tome and Principe"},
                    {value:"Saudi Arabia"},
                    {value:"Senegal"},
                    {value:"Serbia"},
                    {value:"Seychelles"},
                    {value:"Sierra Leone"},
                    {value:"Singapore"},
                    {value:"Slovakia"},
                    {value:"Slovenia"},
                    {value:"Solomon Islands"},
                    {value:"Somalia"},
                    {value:"South Africa"},
                    {value:"South Sudan"},
                    {value:"Spain"},
                    {value:"Sri Lanka"},
                    {value:"Sudan"},
                    {value:"Suriname"},
                    {value:"Sweden"},
                    {value:"Switzerland"},
                    {value:"Syria"},
                    {value:"Taiwan"},
                    {value:"Tajikistan"},
                    {value:"Tanzania"},
                    {value:"Thailand"},
                    {value:"Timor-Leste"},
                    {value:"Togo"},
                    {value:"Tonga"},
                    {value:"Trinidad and Tobago"},
                    {value:"Tunisia"},
                    {value:"Turkey"},
                    {value:"Turkmenistan"},
                    {value:"Tuvalu"},
                    {value:"Uganda"},
                    {value:"Ukraine"},
                    {value:"United Arab Emirates"},
                    {value:"United Kingdom"},
                    {value:"United States"},
                    {value:"Uruguay"},
                    {value:"Uzbekistan"},
                    {value:"Vanuatu"},
                    {value:"Vatican City"},
                    {value:"Venezuela"},
                    {value:"Vietnam"},
                    {value:"Yemen"},
                    {value:"Zambia"},
                    {value:"Zimbabwe"},
                ]
            }
        },

        mounted(){
            const province = new Province();
            
            province.getAllPro()
                .then(prov => {
                    this.itemProvince = prov.data;
                })

            const money = new Money();
            money.getAllS()
                .then(monnaie => {
                    this.itemMoney = monnaie.data;
                })
        },

        computed:{
            sortedItems(){
                return this.itemProvince.sort((a,b) => a.id - b.id);
            }
        },

        methods: {

            close(){
                this.modal = false;
                this.name = '';
                this.idCat = '';
                this.taux = '';
                this.pays = '';
                this.monnaie = '';
                this.msg = null
                this.IdDrop = null
            },

            openModal(id){
                this.idCat = id;

                if(this.modal === id){
                    this.modal = null;
                }else{
                    this.modal = id
                    const idCom = this.itemProvince.find(com => com.id == id);
                    this.getId = idCom
                    this.name = this.getId.nom
                    this.taux = this.getId.taux
                    this.pays = this.getId.pays
                    this.monnaie = this.getId.monnaie.id
                }

            },

            drop(id){

                this.IdDrop = id;

            },

            modifCat(){
                if(!this.monnaie || !this.name || !this.taux || !this.pays){
                    this.msg = "Aucun champ n'est peut être vide"
                }else{
                    this.$store.dispatch('editVille',{
                        nom : this.name,
                        id : this.idCat,
                        taux: this.taux,
                        pays: this.pays,
                        monnaie: this.monnaie
                    })
                    .then((response) => {
                            //.log(response)
                        this.modal = false;
                        if(response.status === 200){
                            this.succes = "Ville modifier " + response.data.nom
                            setTimeout(() =>{
                                            this.succes = false;
                                            location.reload();
                                        }, 
                                        5000)
                        }
                    })
                    .catch((error) => {
                            this.modal = false;
                    })
                    .finally(() => {
                        this.name = '';
                        this.idCat = '';
                        this.taux = '';
                        this.pays = '';
                        this.monnaie = '';
                    });
                }
            },

            dropCat(id){
                this.IdDrop = null
                this.$store.dispatch('dropVille',{
                    id : id
                })
                .then((response) => {
                    if(response.status === 200){
                        this.succes = "Ville supprimer "
                        setTimeout(() =>{
                                        this.succes = false;
                                        location.reload();
                                    }, 
                                    5000)
                    }else{
                        
                    }
                })
                .catch((error) => {
                    
                })
                .finally(() => {
                    return
                });
            }

            }

    }

</script>