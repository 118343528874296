<template>
    
    <div class="flex items-center justify-center h-screen">
        <div class="bg-gray-200 md:w-3/5 h-2/3">
            <div class="flex h-full flex-col md:flex-row">
                <div class="w-full h-54 md:h-full  md:w-2/4" style="background: linear-gradient(to bottom, #DEA20A , #FBF7EE );">
                    <div class="w-full px-10 pt-2 md:pt-14">
                        <div class="flex items-center justify-center">
                            <img src="@/images/LOGO_GEN_PNG_BLANC.png" class="w-32 md:w-72" alt="">
                        </div>
                        <div class="flex items-center justify-center bg-white py-5 rounded-lg">
                            <p class="sm:text-2xl  text-2xl font-bold uppercase drop-shadow-md">Nouveau produit</p>
                        </div>
                    </div>
                </div>
                <div class="w-full pt-10 md:w-2/4 md:overflow-y-auto md-overflow-x-hidden px-4 md:px-10 pb-20 md:pb-0 md:pb-10 md:pt-10 text-left md:text-left md:mt-0" style="background-color: #FBF7EE;">
                    <form enctype="multipart/form-data" @submit.prevent="submitProduct" >                            
                        <div class="mb-2 w-full">
                            <div class="pl-2 mb-2">
                                <label class="text-xl font-bold">Nom du produit</label>
                            </div>
                            <input type="text" name="" v-model="nouveauProduit.nom" id="" class="px-4 py-2 w-full border rounded-lg border-gray-600 shadow-md shadow-gray-400" required>
                        </div>
                        <div class=" grid grid-cols-2">
                            <div class="mb-3">
                                <div class="pl-2  mb-2">
                                    <label class="text-xl font-bold">Taille</label>
                                </div>
                                <div>
                                    <select name="" v-model="nouveauProduit.taille" id="" class="block w-full px-4 py-2 border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                                        <option value="" disabled selected hidden></option>
                                        <option value="grande">Grande</option>
                                        <option value="moyenne">Moyenne</option>
                                        <option value="petite">Petite</option>
                                    </select>
                                </div>
                            </div>
                            <div class="ml-5">
                                <div class="pl-2 mb-2">
                                    <label  class="text-xl font-bold">Prix</label>
                                </div>
                                <div>
                                    <input type="text" v-model="nouveauProduit.prix" name="" id="" class="px-4 py-1.5 w-full border rounded-lg border-gray-600 shadow-md shadow-gray-400" required>
                                </div>
                            </div>
                        </div>
                        <div class="mb-2 w-full">
                            <div class="pl-2 mb-2">
                                <label class="text-xl font-bold">Description</label>
                            </div>
                            <textarea v-model="nouveauProduit.description" class="border   w-full rounded-lg border-gray-600 shadow-md px-4 py-2 shadow-gray-400" name="" id="" rows="3" required></textarea>
                        </div>
                        <div class="mb-2 w-full">
                            <div class="pl-2 mb-2">
                                <label  class="text-xl font-bold">Catégorie</label>
                            </div>
                            
                                <div class="grid grid-cols-2">
                                    <div class="flex" v-for="(items, index) in sortedItems" :key="index">
                                        <input type="checkbox" v-model="nouveauProduit.categorie" :id="`checkbox-${index}`" :value="items.id">
                                        <label class="w-max pl-2 " :for="`checkbox-${index}`">{{ items.nom }}</label>
                                    </div>
                                </div>
                        
                        </div>
                        
                            

                        <div class="w-full mt-10">                           
                            <div class="grid grid-cols-1">
                                <div class="flex justify-end">                                    
                                    <button class="bg-green-500 text-white text-center w-32 py-2 flex inline-block rounded-md relative">
                                        <input type="file" ref="image" accept="image/*" class="h-12 w-18 opacity-0 absolute z-10 inset-0" id="photos" @change="handleFileUpload">
                                        <p class="relative w-full">Ajouter image</p>
                                    </button>
                                </div>
                            </div> 
                            <div class="pt-2">
                                <label for="photos" class="text-xl font-bold">Photos</label>
                            </div> 
                        
                            
                            <div class=" flex w-full h-20 overflow-y-hidden  overflow-x-auto">
                                <div class="photo mb-4" v-for="(photo, index) in nouveauProduit.photos" :key="index">
                                    <img :src="photo.url" :alt="'Photo ' + (index + 1)">
                                    <button @click="supprimerPhoto(index)" class="delete-photo">X</button>
                                </div>
                            </div>
                        </div>
                        <div class="mt-4">
                            <button type="submit" class="px-4 py-2 w-full rounded-lg text-white font-bold text-2xl mb-14" style="background-color: #DEA20A;">Ajouter</button>
                        </div>
                        <div class="text-red-500 mt-">{{ error }}</div>
                        <div class="text-green-500 mt-">{{ succes }}</div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    

</template>
<style>
  .photo {
    position: relative;
    display: inline-block;
    margin-right: 10px;
  }

  .photo img {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }

  .photo .delete-photo {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: red;
    color: white;
    font-size: 12px;
    border: none;
    cursor: pointer;
  }
</style>

<script>

import Category from '@/_services/category.services'
import Axios from '@/_services/caller.services'


export default{
    name: 'newproduct',

    data(){

        return {
            nouveauProduit: {
                prix: '',
                description: '',
                categorie: [],
                photos: [],  
                taille: '',  
                nom: '',   
            },
                    
            itemCategory: [],
            imageData:'',
            formData : new FormData(),
            error:'',
            succes:''
            }
    },

    computed:{
        sortedItems(){
            return this.itemCategory.sort((a,b) => a.id - b.id);
        }
    },
    methods:{

        handleFileUpload(event) {
        const files = event.target.files;
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                const reader = new FileReader();
                reader.onload = (e) => {
                const url = e.target.result;
                this.nouveauProduit.photos.push({ file, url });
                };
                reader.readAsDataURL(file);
            }
        },
        supprimerPhoto(index) {
            this.nouveauProduit.photos.splice(index, 1);
        },

        validateNumberInput() {
            
            this.nouveauProduit.prix = parseInt(this.nouveauProduit.prix);
            if (isNaN(this.nouveauProduit.prix)) {
                this.nouveauProduit.prix = null; // Réinitialiser la valeur si ce n'est pas un nombre valide
            }
        },

        /*restlogin(){
                this.$refs.restlogin.reset()
            }*/

        submitProduct: function(){
            const nbr_categories = this.nouveauProduit.categorie.length
            const nbr_images = this.nouveauProduit.photos.length
             
            const imageData = this.nouveauProduit.photos.map(photo => {
                return {
                    url: photo.url,
                    file: photo.file
                };
            });
            
            let token = localStorage.getItem('authToken')
            const headers = {
                'Authorization': `Bearer ${token}`
            }

            

            const max = 5
            let hasErrors = false;

            if(this.nouveauProduit.categorie.length > max){
                this.error = "Nombre des catégories selectionnées est supperieur à 5";
                hasErrors = true;
            }
            
            if(imageData.length > max){
                this.error = "Nombre des catégories selectionnées est supperieur à 5";
                hasErrors = true;
            }
            
            if(hasErrors) {

            }else{

                for(let i= 0; i < 5; i++) {
                    const imageKey = `image${i}`;
                    const image = (imageData[i] && imageData[i].file) ? imageData[i].file :null;
                    if(image){
                        this.formData.append(imageKey, image);
                    }
                } 


                for(let i= 0; i < 5; i++) {
                    const categorieKey = `categorie${i}`;
                    const categorie = (this.nouveauProduit.categorie[i] && this.nouveauProduit.categorie[i])
                    if(categorie !== null && categorie !== undefined) {
                        this.formData.append(categorieKey, categorie);
                    }
                } 

                this.formData.append('nom', this.nouveauProduit.nom)
                this.formData.append('prix', this.nouveauProduit.prix);
                this.formData.append('description', this.nouveauProduit.description);
                this.formData.append('taille', this.nouveauProduit.taille);
                this.formData.append('nbr_categories', nbr_categories)
                this.formData.append('nbr_images', nbr_images)
                
            
                /*for (let [key, value] of this.formData.entries()) {
                    //.log(key, value);
                }*/
                Axios.post('/api/backoffice/produit',
                    this.formData,
                    {headers}
                )
                .then(response => {
                    //this.$router.push('/produit')
                    //console.log(response)
                    if(response.data.status_code === 200){
                        this.succes = 'Votre produit à été créer avec succes'
                        setTimeout(() =>{
                            this.$router.push('/produit')
                        },3000)
                    }else{
                        this.error = response.data.message
                    }
                })
                .catch(error => {
                    this.error = error.response.data.message
                })
                .finally(() => {
                    this.nouveauProduit = {
                        description : "",
                        categorie : "",
                        photos : "",  
                        taille : "",  
                        nom : "", 
                    }
                    
                }); 
            }
        }
    },

    mounted(){
        const category = new Category();
            category.getAllCat()
                .then(categorie => {
                    this.itemCategory = categorie.data;
                })
    }

}

</script>