import Axios from './caller.services'

export default class MoneyService {

    //Récupérer la liste de monnaie
    async getAllS(){
        try {
            const response = await Axios.get('/api/monnaie');
            return response.data;
        } catch (error) {
            //.error(error);
            throw error;
        }
    };

    //Ajouter une nouvelle boutique 
    async addMoney(moneyData, token){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        try {
            const response = await Axios.post(
                '/api/backoffice/monnaie', 
                moneyData, 
                {headers});

            return response;
        } catch (error) {
            throw new Error(error.response);
        }
    };

    //modifier 
    async editMoney(token, data){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }

        
        try {
            const response = await Axios.post(
                `/api/backoffice/monnaie/${data.id}`, 
                {
                  'nom':  data.nom,
                  'symbole': data.symbole
                }, 
                {headers});

            return response;
        } catch (error) {
            throw error
        }   
    };

     //modifier 
     async dropMoney(token, id){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        try {
            const response = await Axios.delete(
                `/api/backoffice/monnaie/${id.id}`,
                {headers});

            return response;
        } catch (error) {
            throw error
        }   
    }
}