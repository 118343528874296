<template>
    <div class="w-screen overflow-x-hidden">
        <Header/>
        <div class="w-full bg-white mb-12 mt-1.5 overflow-x-auto">
            <div class="w-full h-full">
                <div class="w-full px-4 sm:px-36">
                    <div class="my-16 ">
                        <div class="grid grid-cols-2 md:grid-cols-3" v-show="roles != null">
                            <div class="flex w-full mb-4" v-show="roles === 'admin' || roles === 'gestionnaire'">
                                <div class="w-1/4 mr-4">
                                    <label for="" class="font-bold">Agents</label>
                                </div>
                                    <div class="w-3/4">
                                        <select name="" id="" v-model="filters.agent" @change="handleChangeA" class="block w-full px-4 py-1.5 rounded-lg " style="background-color: #FBF7EE;">
                                            <option :value="agent.id" v-for="(agent, index) in itemBtAg" :key="index">{{ agent.nom }} {{ agent.prenom }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="flex w-full mb-4" v-show="roles === 'admin' || roles === 'gestionnaire'">
                                    <div class="w-1/4 mr-4">
                                        <label for="" class="font-bold">Managers</label>
                                    </div>
                                    <div class="w-3/4">
                                        <select name="" id="" v-model="filters.manager" @change="handleChangeM" class="block w-full px-4 py-1.5 rounded-lg " style="background-color: #FBF7EE;">
                                            <option :value="item.manager['id']" v-for="(item, index) in itemBtM" :key="index">{{ item.nom }} {{ item.prenom }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="flex w-full mb-4" v-show="roles === 'admin' || roles === 'gestionnaire'">
                                    <div class="w-1/4 mr-4">
                                        <label for="" class="font-bold">Livreurs</label>
                                    </div>
                                    <div class="w-3/4">
                                        <select name="" id="" v-model="filters.livreur" @change="handleChangeL" class="block w-full px-4 py-1.5 rounded-lg " style="background-color: #FBF7EE;">
                                            <option :value="item.id" v-for="(item, index) in listLiv" :key="index">{{ item.user.nom }} {{ item.user.prenom }}</option>
                                        </select>
                                    </div>
                                </div>
                                <!--div class="flex w-full mb-4">
                                    <div class="w-1/4 mr-4">
                                        <label for="" class="font-bold">Date</label>
                                    </div>
                                    <div class="w-3/4">
                                        <select name="" id="" v-model="filters.date" class="block w-full px-4 py-1.5 rounded-lg " style="background-color: #FBF7EE;">
                                            <option value="aujourd'hui">Aujourd'hui</option>
                                            <option value="hier">Hier</option>
                                            <option value="semaine">Cette semaine</option>
                                            <option value="mois">Ce mois</option>
                                            <option value="dernier-mois">Mois dernier</option>
                                            <option value="année">Année</option>
                                            <option value="dernière-année">Année dernière</option>
                                        </select>
                                    </div>
                                </div-->
                                <div class="flex w-full mb-4">
                                    <div class="w-1/4 mr-4">
                                        <label for="" class="font-bold">Du</label>
                                    </div>
                                    <div class="w-3/4">
                                        <input type="date" name="" id="" v-model="filters.debut" class="block w-full px-4 py-1.5 rounded-lg " style="background-color: #FBF7EE;">
                                    </div>
                                </div>
                                <div class="flex w-full mb-4">
                                    <div class="w-1/4 mr-4">
                                        <label for="" class="font-bold">Au</label>
                                    </div>
                                    <div class="w-3/4">
                                        <input type="date" name="" id="" v-model="filters.fin" @change="sendDataFiltre" class="block w-full px-4 py-1.5 rounded-lg " style="background-color: #FBF7EE;">
                                            
                                    </div>
                                </div>
                                <div class="flex w-full mb-4">
                                    <div class="w-1/4 mx-4">
                                        <label for="" class="font-bold">Produit</label>
                                    </div>
                                    <div class="w-3/4">
                                        <select name="" @change="sendDataFiltre" v-model="filters.produit" id="" class="block w-full px-4 py-1.5 rounded-lg " style="background-color: #FBF7EE;">
                                            <option :value="pro.id" v-for="(pro, index) in itemProduit" :key="index">{{ pro.nom }}</option>
                                        </select>
                                    </div>
                                </div>                                
                                <div class="flex w-full mb-4">
                                    <div class="w-1/4 mr-2">
                                        <label for="" class="font-bold">Ville</label>
                                    </div>
                                    <div class="w-3/4">
                                        <select name="" @change="sendDataFiltre" id="" v-model="filters.ville" class="block w-full px-4 py-1.5 rounded-lg " style="background-color: #FBF7EE;">
                                            <option :value="ville.id" v-for="(ville, index) in getVille" :key="index">{{ ville.nom }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="flex mb-4">
                                    <div class="w-1/4 mr-4">
                                        <label for="" class="font-bold">Num Client</label>
                                    </div>
                                    <div class="w-3/4">
                                        <input type="text" @input="sendDataFiltre" name="" id="" v-model="filters.telephone" placeholder="+24***" class="block w-full border px-4 py-1.5 rounded-lg " style="background-color: #FBF7EE;">
                                    </div>
                                </div>
                            </div>
                            <div class="w-full mt-7" v-if="roles !== null">
                                <form action="">
                                    <button type="submit" class="w-40 px-4 text-white py-1.5 rounded-lg" style="background-color: #DEA20A;">Générale</button>
                                </form>
                            </div>
                    </div>
                    <div class="pb-20">
                        <div class="w-full mb-14" style="background-color: #FBF7EE;">
                            <div>
                                <div class="flex w-full" style="background-color: #DEA20A;">
                                    <div class="text-white text-left pl-2 w-40 py-5 text-md">
                                        Date
                                    </div>
                                    <div class="text-white text-left pl-2 w-40 py-5 text-md">
                                        Nom d'article
                                    </div>
                                    <div class="text-white text-left pl-2 w-40 py-5 text-md">
                                        Total
                                    </div>
                                    <div class="text-white hidden sm:inline text-left pl-2 w-40 py-5 text-md">
                                        Type de commande
                                    </div>
                                    <div class="text-white text-left pl-2 w-40 py-5 text-md">
                                        Adresse de livraison
                                    </div>
                                    <div class="text-white text-left pl-2 w-40 py-5 text-md">
                                        Téléphone client    
                                    </div>
                                    <div class="text-white text-left pl-2 w-40 py-5 text-md">
                                        État
                                    </div>
                                    <div class="hidden text-white text-left w-40 py-5 text-md" >
                                        Options
                                    </div>
                                </div>
                                <div class=" w-full overflow-y-auto">
                                    <div class="flex w-full my-1.5 p-1" v-for="(com, index) in fetchedData" :key="index">
                                        <div class="w-40 py-2 px-2 text-sm line-clamp-2 leadin-none overflow-hidden text-ellipsis h-12 text-black text-left">
                                            <p v-text="formData(com.created_at)"></p>
                                        </div>
                                        <div class="w-40  py-2 px-2 text-sm line-clamp-2 leadin-none overflow-hidden text-ellipsis h-12 text-black text-left">
                                            <p class="mr-1 5" v-for="(product, index) in com.produits" :key="index" v-text=" product.nom" ></p>
                                        </div>
                                        <div class="w-40  py-2 px-2 text-sm line-clamp-2 leadin-none overflow-hidden text-ellipsis h-12 text-black text-left">
                                            {{ com.total }}
                                        </div>
                                        <div class="w-40  py-2 px-2 text-sm line-clamp-2 hidden sm:inline leadin-none overflow-hidden text-ellipsis h-12 text-black text-left">
                                            {{ com.type }}
                                        </div>
                                        <div class="w-40  py-2 px-2 text-sm line-clamp-2 leadin-none overflow-hidden text-ellipsis h-12 text-black text-left">
                                            <p v-if="com.adresse !== 'null'">{{ com.adresse }}</p>
                                            <p v-else>...............</p>
                                        </div>
                                        <div class="w-40  py-2 px-2 text-sm line-clamp-2 leadin-none overflow-hidden text-ellipsis h-12 text-black text-left">
                                            {{ com.telephone }}    
                                        </div>
                                        <div class="w-40  py-2 px-2 text-sm line-clamp-2 leadin-none overflow-hidden text-ellipsis h-12 text-black text-left">
                                            {{ com.statut }}
                                        </div>
                                        <!--div class="w-40  py-2 px-1 text-sm line-clamp-2 leadin-none  text-ellipsis h-12 text-black">
                                            <div class="relative flex">
                                                <div class="flex items-center">                                                    
                                                    <img @click="toggleOptions(com.id, $event)" src="@/images/option.png" alt="" class="w-6 h-6 mr-2 cursor-pointer">
                                                </div>
                                            </div>
                                            
                                            <div class="absolute right-0 md:right-10 z-10" v-if="activeId === com.id" @click="preventHideDiv">
                                                
                                                <div class="py-4 px-2 text-left shadow-lg w-40 bg-white text-black rounded-lg">
                                                    <button class="text-left my-1.5 hover:text-blue-600" v-if="(roles === 'admin' || roles === 'gestionnaire') && com.statut !== 'livrée' && com.statut !== 'annulée'" @click="assignerLi('liv')">Assigner à un Livreur</button>

                                                    <button class="text-left my-1.5 hover:text-blue-600" v-if="(roles === 'manager' ) && com.statut !== 'livrée' && com.statut != 'annulée'" @click="assignerAB">Assigner à un Livreur</button>

                                                    <button class="text-left my-1.5 hover:text-blue-600" v-if="(roles === 'admin' || roles === 'gestionnaire') && com.statut !== 'en livraison' && com.statut !== 'annulée'" @click="assignerMa('man')">Assigner à une Manager</button>
                                                    
                                                    <button class="text-left my-1.5 hover:text-blue-600" v-if="roles == 'admin' && com.statut != 'en livraison' && com.statut != 'livrée' && com.statut != 'annulée'" @click="assignerAg('ag')">Assigner à l'Agent</button>

                                                    <button class="text-left my-1.5 hover:text-blue-600" v-if="roles === 'agent'" @click="assignerAB">Assigner au gestionnaire</button>
                                                    
                                                    <button class="text-left my-1.5 hover:text-blue-600" v-if="(roles == 'agent' || roles == 'livreur') && com.statut !== 'livrée' && com.statut != 'annulée'" @click="actionClient(com.id)">Contacter client</button>
                                                    
                                                    <button class="text-left my-1.5 hover:text-blue-600" v-if="roles == 'livreur' && com.statut !== 'annulée' && com.statut !== 'livrée'" @click="redirigerWhaysapp(com.user.telephone)">Contacter l'agent</button>
                                                    
                                                    <button class="text-left my-1.5 hover:text-blue-600" v-if="(roles === 'livreur' || roles === 'manager') && com.statut !== 'annulée' && com.statut !== 'livrée'" @click="comfirmation">Confirmer livraison</button>
                                                    
                                                    <button class="text-left my-1.5 hover:text-blue-600" v-if="(roles == 'admin' || roles === 'gestionnaire' || roles === 'agent') && com.statut != 'livrée' && com.statut != 'annulée'" @click="annulCom(com.id)">Annuler Commande</button>
                                                    
                                                    <button class="text-left my-1.5 hover:text-blue-600" v-if="(roles == 'admin' || roles === 'gestionnaire') && com.statut != 'livrée' && com.statut != 'annulée'" @click="dropCom(com.id)">Supprimer Commande</button>
                                                    
                                                    <button class="text-left my-1.5 hover:text-blue-600" @click="detail(com.id)">Afficher détail</button>
                                                    
                                                    <button class="text-left my-1.5 hover:text-blue-600" v-if="(roles === 'admin' || roles === 'gestionnaire' || roles === 'agent') && com.statut !== 'livrée' && com.statut !== 'annulée'" @click="modifCom(com.id)">Modifier Commande</button>

                                                    <button class="text-left my-1.5 hover:text-blue-600" v-if="(roles === 'agent' || roles === 'admin' || roles === 'gestionnaire') && com.statut !== 'livrée' && com.statut !== 'annulée'" @click="reporter(com.id)">Réporter Commande</button>

                                                    <button class="text-left my-1.5 hover:text-blue-600" v-if="(roles === 'agent' || roles === 'admin' || roles === 'gestionnaire') && com.statut !== 'livrée' && com.statut !== 'annulée'" @click="injoign(com.id)">Client Injoignable</button>

                                                </div>
                                            </div>
                                        </div-->
                                    </div>
                                </div>
                            </div>
                            <!-- Les autres colonnes -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import Footer from '@/components/layout/Footer.vue'
import Header from '@/components/layout/Header.vue'
import Axios from '@/_services/caller.services'
import {mapActions} from 'vuex'
import Produit from '@/_services/produit.services'
import Ville from '@/_services/province.services'

    export default {
        name: 'Ventes',

        components: {
            Footer,
            Header
        },

        data(){
            return{
                token: null,
                roles: null,
                //filter
                filters: {
                    fin: '',
                    ville:'',
                    produit:'',
                    agent:'',
                    livreur:'',
                    telephone:'',
                    manager:'',
                },
                    
                itemBtM: [],
                listLiv: [],
                itemBtAg: [],
                itemProduit: [],
                getVille: [],
                fetchedData: [],
            }
        },

        created(){
            this.token = localStorage.getItem('authToken')
            this.roles = localStorage.getItem('role')
            this.viewvente()
            if(this.roles === 'admin' || this.roles === 'gestionnaire'){
                this.fetchData()
                this.ListMana()
                this.ListAgent()
            }
        },

        methods:{

            //Liste de manager
        ...mapActions(['viewprovince']),
            ListMana(){

                this.viewprovince()
                    .then(response =>{
                        this.itemBtM = response.data
                        ////.log(this.itemBtM)
                    })
                    .catch(error => {
                        //.error(error);
                    });

            },
        
        //liste livreur
        ...mapActions(['fechDataLivrer']),
            fetchData(){
                this.fechDataLivrer()
                    .then(response => {
                        this.listLiv = response.data;
                        ////.log(this.listLiv)
                    })
                    .catch(error => {
                        // Une erreur s'est produite lors de la récupération des données
                        //.error(error);
                    });
            },

        //Liste de agent
        ...mapActions(['getAgent']),
            ListAgent(){

                this.getAgent()
                    .then(response =>{
                        this.itemBtAg = response.data.data
                        ////.log(this.itemBtAg)
                    })
                    .catch(error => {
                        //.error(error);
                    });

            },

            formData(date){
                const dateObj = new Date(date);

                const year = dateObj.getFullYear();
                const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
                const day = dateObj.getDate().toString().padStart(2, '0');

                return `${year}/${month}/${day}`
            },

            async viewvente(){
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`,
                    'Access-Control-Allow-Origin':'*',
                    'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,PATCH,OPTIONS',
                    
                    'Access-Control-Allow-Headers': "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'"
                }

                try{
                    const reponse = await Axios.get(
                        `/api/ventes/${this.roles}`,
                        {headers, params: this.filters }
                        );
                        
                        if(!this.filters.agent && !this.filters.debut && !this.filters.fin && !this.filters.livreur && !this.filters.manager && !this.filters.produit && !this.filters.ville && !this.filters.telephone){
                            this.fetchedData = reponse.data.data;
                        }
                    
                    
                } catch(error) {
                    ////.log(error)
                };
            },

            //debut filtre
            handleChangeA(){
                this.sendDataFiltre()
            },

            handleChangeM(){
                this.sendDataFiltre()
            },

            handleChangeL(){
                this.sendDataFiltre()
            },

            //filtre
            async sendDataFiltre(){

                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`,
                    'Access-Control-Allow-Origin':'*',
                    'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,PATCH,OPTIONS',
                    
                    'Access-Control-Allow-Headers': "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'"
                }

                try{
                    const reponse = await Axios.get(
                        `/api/ventes/${this.roles}`,
                        {headers, params: this.filters }
                        );
                        this.fetchedData = reponse.data.data;
                        ////.log(reponse);
                    
                } catch(error) {
                    //.log(error)
                };
            },

        },

        mounted(){
            //liste de produit
            const produit = new Produit();
            produit.getAllProduit()
                .then(product => {
                    this.itemProduit = product.data
                    ////.log(this.itemProduit)
                })
                .catch(error => {
                    ////.log(error.product.data.message)
                })

            //get ville
            const ville = new Ville();
            ville.getAllPro()
                    .then(prov => {
                        this.getVille = prov.data;
                    }).catch(err => {
                        ////.log(err)
                    });
        }
    }

</script>