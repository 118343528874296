import Axios from './caller.services'

export default class DashboardService{

     //filtre du dashoard
     async getDashFiltre(token, data){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Access-Control-Allow-Origin':'*',
            'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,PATCH,OPTIONS',
            'Access-Control-Allow-Headers': "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'"
        }

        
        try{
            const reponse = await Axios.get(
                `/api/dashboard/kpi/${date_}`,
                {headers, }
                );
            
            return reponse.data;
        } catch(error) {
            //.error(error);
            throw error;
        };
    };

    //recupere le données du dashoard
    async dashbordAdGe(token){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Access-Control-Allow-Origin':'*',
            'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,PATCH,OPTIONS',
            
            'Access-Control-Allow-Headers': "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'"
        }
        try{
            const reponse = await Axios.get(
                '/api/dashboard/kpi',
                {headers, }
                );
            return reponse.data;
        } catch(error) {
            throw error;
        };
    };

}