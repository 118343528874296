<template>
    
    <div class="w-screen h-sreen overflow-x-hidden">
        <Header></Header>
        <!--Affichage de message de successfull-->
        <div class="px-4 flex justify-center" v-show="succes">
            <p class="bg-green-500 py-2 text-white">{{succes}}</p>
        </div>
        <!--Affichage de message de successfull-->
        <!--Affichage de message de error-->
        <div class="px-4 flex justify-center" v-show="erreur">
            <p class="bg-red-600 px-4 py-2 text-white">{{erreur}}</p>
        </div>
        <!--Affichage de message de error-->
        
        <div class="w-full bg-white mb-20">
            <div class="w-full">
                <div class="px-4 md:px-36">
                    <div class="my-16">
                        <div class="flex mb-5">
                                <button class="px-4 text-white py-1.5 rounded-lg" style="background-color: #DEA20A;">
                                    <router-link to="/newproduct">
                                        Ajouter Produit
                                    </router-link>
                                </button>
                        </div>
                        <div class="flex mb-5">
                            <button class="px-4 mr-5 text-white py-1.5 rounded-lg bg-red-500 hover:bg-red-700" :disabled="!canDelete" @click="drop">Supprimer Produit</button>
                            <select name="" id="" v-model="selectDrop" class="block w-52 px-4 py-1.5 border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                                    <option value="" disabled selected hidden></option>
                                    <option :value="items.id" v-for="(items, index) in itemProduit" :key="index">{{ items.nom }}</option>
                            </select>
                        </div>
                        <div class="flex mb-5">
                            <button class="px-4 mr-5 text-white py-1.5 rounded-lg bg-blue-500 hover:bg-blue-700" :disabled="!canEdit" @click="editItem">Modifier Produit</button>
                            <select name="" id="" v-model="selectEdit" class="block w-52 px-4 py-1.5 border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                                    <option value="" disabled selected hidden></option>
                                    <option :value="items.id" v-for="(items, index) in itemProduit" :key="index">{{ items.nom }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="w-full">
                        
                            <div class="flex w-full px-2" style="background-color: #DEA20A;">
                                <div class="w-52 text-white text-center text-black text:xs sm:text-sm pl-2 py-5 text-md">
                                    N°
                                </div>
                                <div class="w-52 text-white text-center text-black text:xs sm:text-sm pl-2 py-5 text-md">
                                    Nom
                                </div>
                                <div class="w-52 text-white text-center text-black text:xs sm:text-sm pl-2 py-5 text-md">
                                    Description
                                </div>
                                <div class="w-52 text-white text-center text-black text:xs sm:text-sm pl-2 py-5 text-md">
                                    Prix
                                </div>
                                <div class="w-52 text-white text-center text-black text:xs sm:text-sm pl-2 py-5 text-md">
                                    Categories
                                </div>
                                <div class="w-52 hidden sm:block text-white text-center text-black text:xs sm:text-sm pl-2 py-5 text-md ">
                                    Photos
                                </div>
                            </div>
                            <div class="px-2 w-full overflow-y-auto h-80"  style="background-color: #FBF7EE;">
                                <div class="flex w-full my-1.5  overflow-y-auto " v-for="(items, index) in itemProduit" :key="index">
                                    <div class="w-52 p-2 overflow-x-auto whitespace-nowrap   text-sm  text-center text-black">
                                        {{ index + 1 }}
                                    </div>
                                    <div class="w-52 p-2 overflow-x-auto whitespace-nowrap  text-sm  text-center text-black">
                                        {{ items.nom }}   
                                    </div>
                                    <div class="w-52 p-2 overflow-x-auto whitespace-nowrap   text-sm  text-center text-black">
                                        {{ items.description }}
                                    </div>
                                    <div class="w-52 p-2 overflow-x-auto whitespace-nowrap   text-sm  text-center text-black">
                                        {{ items.prix }}
                                    </div>
                                    <div class="w-52 p-2 overflow-x-auto whitespace-nowrap   text-sm  text-center text-black">
                                        {{ items.categories }}
                                        <p class="hidden">{{ countElements(items.categories) }}</p>
                                    </div>
                                    <div class="w-52  hidden sm:flex px-1 text-sm line-clamp-2 leadin-none overflow-hidden text-ellipsis h-12 text-black">
                                        <div class="h-full mr-1 w-14" v-for="(photo, photoIndex) in items.images" :key="photoIndex">
                                            <img :src="getPhotoUrl(photo.chemin)" class="h-full w-14">
                                        </div>
                                    </div>

                                </div>
                            </div>
                        
                        <!-- Les autres colonnes -->
                    </div>
                </div>
            </div>
        </div>
        <div v-show="modal" class="fixed inset-0 flex items-center justify-center">
            <div class="bg-white shadow-md rounded-lg p-6 max-w-sm mx-auto z-50">
                <div class="flex justify-between">
                    <h2 class="text-lg font-bold mb-4">Que voulez-vous modifier</h2>
                </div>
                <div class="">
                    <div class="mr-5 mb-2 flex justify-between">
                        <div class="pl-2 mb-2 text-left items-center justify-center">
                            <label class="text-xl font-bold">Nom</label>
                        </div>
                        <div>
                            <button @click="choix(choix='nom')" id="closeModalButton" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                Modifier
                            </button>
                        </div>
                    </div>
                    <div class="mr-5 mb-2 flex justify-between">
                        <div class="pl-2 mb-2 text-left items-center justify-center">
                            <label class="text-xl font-bold">Prix</label>
                        </div>
                        <div>
                            <button @click="choix(choix='prix')" id="closeModalButton" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                Modifier
                            </button>
                        </div>
                    </div>
                    <div class="mr-5 mb-2 flex justify-between">
                        <div class="pl-2 mb-2 text-left items-center justify-center">
                            <label class="text-xl font-bold">Description</label>
                        </div>
                        <div>
                            <button @click="choix(choix='desc')" id="closeModalButton" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                Modifier
                            </button>
                        </div>
                    </div>
                    <div class="mr-5 mb-2 flex justify-between">
                        <div class="pl-2 mb-2 text-left items-center justify-center">
                            <label class="text-xl font-bold">Catégorie</label>
                        </div>
                        <div>
                            <button @click="choix(choix='cat')" id="closeModalButton" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                Modifier
                            </button>
                        </div>
                    </div>
                    <div class="mr-5 mb-2 flex justify-between">
                        <div class="pl-2 mb-2 text-left items-center justify-center">
                            <label class="text-xl font-bold">Photo</label>
                        </div>
                        <div>
                            <button @click="choix(choix='img')" id="closeModalButton" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                Modifier
                            </button>
                        </div>
                    </div>
                    <div class="flex items-center justify-center">
                        <button @click="choix(choix='all')" id="closeModalButton" class="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                            Tout modifier
                        </button>
                    </div>
                </div>
                <div class="flex items-center justify-center">
                    <button @click="close" id="closeModalButton" class="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                        Quitter
                    </button>
                </div>
                
            </div>
        </div>
        <div v-show="modalSuivant" class="fixed inset-0 flex items-center justify-center">
            <div class="bg-white shadow-md rounded-lg p-6 max-w-sm mx-auto z-50">
                <div class="max-h-96 overflow-y-auto text-left">
                    <form enctype="multipart/form-data" @submit.prevent="submitProduct(getchoix)" >                            
                        <div class="mb-2 w-full" v-if="getchoix === 'nom' || getchoix === 'all'">
                            <div class="pl-2 mb-2">
                                <label class="text-xl font-bold">Nom du produit</label>
                            </div>
                            <input type="text" v-model="nom" name="" :placeholder="itemsExist.nom"  id="" class="px-4 py-2 w-full border rounded-lg border-gray-600 shadow-md shadow-gray-400" required>
                        </div>
                        <div class=" grid grid-cols-2">
                            <div class="" v-if="getchoix === 'prix' || getchoix === 'all'">
                                <div class="pl-2 mb-2">
                                    <label  class="text-xl font-bold">Prix</label>
                                </div>
                                <div>
                                    <input type="text" v-model="prix" :placeholder="itemsExist.prix" @input="validateNumberInput" name="" id="" class="px-4 py-1.5 w-full border rounded-lg border-gray-600 shadow-md shadow-gray-400" required>
                                </div>
                            </div>
                        </div>
                        <div class="mb-2 w-full" v-if="getchoix === 'desc' || getchoix === 'all'">
                            <div class="pl-2 mb-2">
                                <label class="text-xl font-bold">Description</label>
                            </div>
                            <textarea v-model="description" :placeholder="itemsExist.description" class="border  w-full rounded-lg border-gray-600 shadow-md px-4 py-2 shadow-gray-400" name="" id="" rows="3" required></textarea>
                        </div>
                        <div class="mb-2 w-full" v-if="getchoix === 'cat' || getchoix === 'all'">
                            <div class="pl-2 mb-2">
                                <label  class="text-xl font-bold">Catégorie</label>
                            </div>
                            
                                <div class="grid grid-cols-2">
                                    <div class="flex" v-for="(items, index) in itemCategory" :key="index">
                                        <input type="checkbox" v-model="category" :id="`checkbox-${index}`" :checked="isCategoryChecked(items.id)" :value="items.id">
                                        <label class="w-max pl-2 " :for="`checkbox-${index}`">{{ items.nom }}</label>
                                    </div>
                                </div>
                        
                        </div>
                        
                            

                        <div class="w-full mt-10" v-if="getchoix === 'img' || getchoix === 'all'">                           
                            <div class="grid grid-cols-1">
                                <div class="flex justify-end">                                    
                                    <button class="bg-green-500 text-white text-center w-32 py-2 flex inline-block rounded-md relative">
                                        <input type="file" ref="image" accept="image/*" class="h-12 w-18 opacity-0 absolute z-10 inset-0" id="photos" @change="handleFileUpload">
                                        <p class="relative w-full">Ajouter image</p>
                                    </button>
                                </div>
                            </div> 
                            <div class="pt-2">
                                <label for="photos" class="text-xl font-bold">Photos</label>
                            </div> 
                        
                            
                            <div class=" flex w-full h-20 overflow-y-hidden  overflow-x-auto">
                                <div class="photo mb-4" v-for="(photo, index) in photos" :key="index">
                                    <img :src="photo.url" :alt="'Photo ' + (index + 1)">
                                    <button @click="supprimerPhoto(index)" class="delete-photo">X</button>
                                </div>
                            </div>
                        </div>
                        <div class="mt-4">
                            <button type="submit" class="px-4 py-2 w-full rounded-lg text-white font-bold text-2xl" style="background-color: #DEA20A;">Modifier</button>
                        </div>
                        <!--div class="text-red-500 mt-">{{ error }}</div-->
                    </form>
                </div>
                <div class="flex items-center justify-center">
                    <button @click="close" id="closeModalButton" class="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                        Quitter
                    </button>
                </div>
                
            </div>
        </div>
        <div v-if="IdDrop" class="fixed inset-0 flex items-center justify-center">
                <div class="bg-white shadow-md rounded-lg p-6 max-w-sm mx-auto z-50">
                    <h2 class="text-lg font-bold mb-4">Voulez-vous supprimer cet élément?</h2>
                    <div class="text-left flex justify-between">                    
                        <button @click="deleteItem(IdDrop)" id="closeModalButton" class="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                            Supprimer
                        </button>
                        <button @click="close" id="closeModalButton" class="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                            Fermer
                        </button>
                    </div>
                </div>
            </div>
        <Footer></Footer>
    </div>
    
</template>

<script>

import Header from '@/components/layout/Header.vue'
import Footer from '@/components/layout/Footer.vue'
import Produit from '@/_services/produit.services'
import Category from '@/_services/category.services'
import Axios from '@/_services/caller.services'

export default{
name: 'produit',

components: {
    Header,
    Footer
},

data(){
    return{
        itemProduit: [],
        succes: null,
        erreur: null,
        canDelete: false,
        canEdit: false,

        selectDrop: null,
        selectEdit: null,
        getchoix: null,

        //items 
        itemsExist: [],

        //modal
        modal: false,
        modalSuivant: false,
        IdDrop: null,

        itemCategory: [],
        categorieID : [],

        //send formulaire
        photos: [],
        nom: '',
        prix: '',
        category: [],
        description: '',
        formData : new FormData(),
    }
},

mounted(){
        const produit = new Produit();
        produit.getAllProduit()
            .then(product => {
                this.itemProduit = product.data
                ////.log(this.itemProduit)
            })
            .catch(error => {
                ////.log(error.product.data.message)
            })

            const category = new Category();
            category.getAllCat()
                .then(categorie => {
                    this.itemCategory = categorie.data;
                })
    },

    methods:{
        getPhotoUrl(url){
            let params = 'https://api.genesisdream.store/public/storage/' + url;
            //let params = 'http://127.0.0.1:8000/storage/' + url
            return params
        },

        countElements(str) {
            const elements = str.split(","); // Divise la chaîne en un tableau d'éléments en utilisant la virgule comme séparateur
            return elements.length;
        },

        drop(){

            this.IdDrop = this.selectDrop

        },

        deleteItem(id){
            const selectValue = id
            
            this.IdDrop = null
            this.$store.dispatch('dropProduct',
                selectValue
            )
            .then(res =>{
                if(res.data.status_code === 200){
                        this.succes = res.data.message
                        setTimeout(()=>{
                            this.succes = false;
                            location.reload()
                        }, 5000)
                    }
            })
            .catch(err =>{
                ////.log(err)
            })
        },

        editItem(){
            this.modal = true
            const selectValue = this.selectEdit
            this.itemsExist = this.itemProduit.find(prod => prod.id === this.selectEdit)
            this.nom = this.itemsExist.nom
            this.prix = this.itemsExist.prix
            this.description = this.itemsExist.description
            
            return selectValue
        },

        isCategoryChecked(categoryId) {

            /*const category = this.itemProduit.find(prod => prod.id === this.selectEdit)
            
            //console.log(category)
            //return category.categorie.includes(categoryId);
            for(let i = 0; i < 5; i++){
                    
                    const categorie = (category.categories.split(",")[i])
                    if(categorie !== null && categorie !== undefined) {
                        this.categorieID = this.itemCategory.find(cat => cat.nom === categorie)
                        console.log(this.categorieID)
                        categoryId = this.categorieID.id
                        
                    }
                    //this.categorieID = this.itemCategory.find(cat => cat.nom === categorie)
                    //this.category = category && category.categories.includes(categoryId)
                    
            }*/

                
            
            //.log(this.category)
        },

        choix(ch){
            this.modal = false
            this.modalSuivant = true
            this.getchoix = ch
        },

        close(){
            this.modal = false
            this.IdDrop = null
            this.modalSuivant= false
            setTimeout(()=>{
                            location.reload()
                        }, 1000)
        },

    //SendData
        handleFileUpload(event) {
            const files = event.target.files;
                for (let i = 0; i < files.length; i++) {
                    const file = files[i];
                    const reader = new FileReader();
                    reader.onload = (e) => {
                    const url = e.target.result;
                    this.photos.push({ file, url });
                    };
                reader.readAsDataURL(file);
            }
        },
        
        supprimerPhoto(index) {
            this.photos.splice(index, 1);
        },

        async submitProduct(ch){
            const selectValue = this.selectEdit
            let selectPro = []
            selectPro =  this.itemProduit.find(prod => prod.id == selectValue)

            
            //edit nom
            if(ch === 'nom'){
                this.formData.append('nom', this.nom)
                this.formData.append('prix', selectPro['prix'])
                this.formData.append('description', selectPro['description']);
                this.formData.append('nbr_categories', selectPro['categories'].split(",").length)
                //this.formData.append('nbr_images', selectPro.images.length)

                for(let i = 0; i < 5; i++){

                    const categorieKey = `categorie${i}`
                    const categorie = (selectPro.categories.split(",")[i])
                    this.categorieID = this.itemCategory.find(cat => cat.nom === categorie)
                    if(categorie !== null && categorie !== undefined) {
                        this.formData.append(categorieKey, this.categorieID.id);
                    }
                    
                }

                /*for(let i= 0; i < selectPro.images.length; i++) {
                    const imageKey = `image${i}`;
                    const filename = selectPro.images[i].chemin
                    let ext = filename.split('.').pop()
                    if(!ext){
                        ext = 'jpeg'
                    }
                    const file = new File([filename],`image.${ext}`,{type:`image/${ext}`})
                    if(file){
                        this.formData.append(imageKey, filename);
                    }
                } */

                
            }else if(ch === 'prix'){
                this.formData.append('nom', selectPro['nom'])
                this.formData.append('prix', this.prix)
                this.formData.append('description', selectPro['description']);
                this.formData.append('nbr_categories', selectPro['categories'].split(",").length)
                //this.formData.append('nbr_images', selectPro.images.length)

                for(let i = 0; i < 5; i++){
                    const categorieKey = `categorie${i}`
                    const categorie = (selectPro.categories.split(",")[i])
                    this.categorieID = this.itemCategory.find(cat => cat.nom === categorie)
                    if(categorie !== null && categorie !== undefined) {
                        this.formData.append(categorieKey, this.categorieID.id);
                    }
                }

                /*for(let i= 0; i < selectPro.images.length; i++) {
                    const imageKey = `image${i}`;
                    const image = selectPro.images[i].chemin.split('images/produits/')[1];
                    if(image){
                        this.formData.append(imageKey, image);
                    }
                }*/ 

            }else if(ch === 'desc'){
                this.formData.append('nom', selectPro['nom'])
                this.formData.append('prix', selectPro['prix'])
                this.formData.append('description', this.description);
                this.formData.append('nbr_categories', selectPro['categories'].split(",").length)
                //this.formData.append('nbr_images', selectPro.images.length)

                for(let i = 0; i < 5; i++){
                    const categorieKey = `categorie${i}`
                    const categorie = (selectPro.categories.split(",")[i])
                    this.categorieID = this.itemCategory.find(cat => cat.nom === categorie)
                    if(categorie !== null && categorie !== undefined) {
                        this.formData.append(categorieKey, this.categorieID.id);
                    }
                }

                /*for(let i= 0; i < selectPro.images.length; i++) {
                    const imageKey = `image${i}`;
                    const image = selectPro.images[i].chemin.split('images/produits/')[1];
                    if(image){
                        this.formData.append(imageKey, image);
                    }
                }*/ 
            }else if(ch === 'cat'){
                this.formData.append('nom', selectPro['nom'])
                this.formData.append('prix', selectPro['prix'])
                this.formData.append('description', selectPro['description']);
                this.formData.append('nbr_categories', this.category.length)
                //this.formData.append('nbr_images', selectPro.images.length)

                for(let i = 0; i < 5; i++){
                    const categorieKey = `categorie${i}`
                    const categorie = (this.category[i] && this.category[i])
                    if(categorie !== null && categorie !== undefined) {
                        this.formData.append(categorieKey, categorie);
                    }
                }

                /*for(let i= 0; i < selectPro.images.length; i++) {
                    const imageKey = `image${i}`;
                    const image = selectPro.images[i].chemin.split('images/produits/')[1];
                    if(image){
                        this.formData.append(imageKey, image);
                    }
                }*/ 
            }else if(ch === 'img'){
                const imageData = this.photos.map(photo => {
                    return {
                        url: photo.url,
                        file: photo.file
                    };
                });

                this.formData.append('nom', selectPro['nom'])
                this.formData.append('prix', selectPro['prix'])
                this.formData.append('description', selectPro['description']);
                this.formData.append('nbr_categories', selectPro['categories'].split(",").length)
                this.formData.append('nbr_images', this.photos.length)

                for(let i = 0; i < 5; i++){
                    const categorieKey = `categorie${i}`
                    const categorie = (selectPro.categories.split(",")[i])
                    this.categorieID = this.itemCategory.find(cat => cat.nom === categorie)
                    if(categorie !== null && categorie !== undefined) {
                        this.formData.append(categorieKey, this.categorieID.id);
                    }
                }

                for(let i= 0; i < this.photos.length; i++) {
                    const imageKey = `image${i}`;
                    const image = (imageData[i] && imageData[i].file) ? imageData[i].file :null;
                    if(image){
                        this.formData.append(imageKey, image);
                    }
                } 
            }else{

                this.formData.append('nom', this.nom)
                this.formData.append('prix', this.prix)
                this.formData.append('description', this.description);
                this.formData.append('nbr_categories', this.category)
                this.formData.append('nbr_images', this.photos.length)

                for(let i = 0; i < 5; i++){
                    const categorieKey = `categorie${i}`
                    const categorie = (this.category[i] && this.category[i])
                    if(categorie !== null && categorie !== undefined) {
                        this.formData.append(categorieKey, categorie);
                    }
                }

                for(let i= 0; i < this.photos.length; i++) {
                    const imageKey = `image${i}`;
                    const image = (imageData[i] && imageData[i].file) ? imageData[i].file :null;
                    if(image){
                        this.formData.append(imageKey, image);
                    }
                } 

            }

            let token = localStorage.getItem('authToken')
            const headers = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }

            

                Axios.post(`/api/backoffice/produit/${selectValue}`,
                    this.formData,
                    {headers}
                )
                .then(response => {
                    ////.log(response)
                    if(response.data.status_code === 200){
                        this.succes = "Produit modifier avec Succes"
                        setTimeout(() => {
                            this.succes = false
                            location.reload()
                        },5000)
                    }
                })
                .catch(error => {
                    this.error = error.response.data.message
                })
                .finally(() => {
                    this.modalSuivant = false
                });


        }

    },

    watch:{
        selectDrop() {
            this.canDelete = this.selectDrop !== null;
        },
        selectEdit() {
            this.canEdit = this.selectEdit !== null;
        }
    }


}

</script>
