import DashAg from '@/_services/agent.services'
import DashLiv from '@/_services/livreurs.services'

const actions = {

    //dashboard agent
    async GetDashAgent({commit}){

        const token = localStorage.getItem('authToken');

        try{

            const getData = new DashAg()
            const reponse = await getData.dashbordAgent(token)

            return reponse

        }catch(e){
            throw e
        }

    },

    //dashboard livreur
    async GetDashLivreur({commit}){

        const token = localStorage.getItem('authToken');

        try{

            const getData = new DashLiv()
            const reponse = await getData.dashbordLivreur(token)

            return reponse

        }catch(e){
            throw e
        }

    },

    //dashboard gestionnaire
    async GetDashGestion({commit}){

        const token = localStorage.getItem('authToken');

        try{

            const getData = new DashAg()
            const reponse = await getData.dashbordGestion(token)

            return reponse

        }catch(e){
            throw e
        }

    }

} 

export default{
    actions
}