<template>

    <div class="w-full h-full overflow-x-hidden mb-14">
        <Header></Header>
        <!--div v-show="success" class="w-full bg-white mb-10">
            <div class="bg-green-500 px-2 py-2 text-white rounded-lg">
                Vous venez d'jouter une boutique au nom de {{ nameShop }}
            </div>
        </div-->
        <div class="w-full bg-white mb-10">
            <div class="w-full">
                <div class="px-4 md:px-36">
                    <div class="my-16">
                        <div class="flex">
                            <button type="submit" class="px-4 text-white py-1.5 rounded-lg" style="background-color: #DEA20A;">
                                <router-link to="/newshop">
                                    Ajouter Boutique
                                </router-link>
                            </button>
                        </div>
                    </div>
                    <!--Affichage de message de successfull-->
                    <div class="px-4 flex justify-center" v-show="succes">
                            <p class="bg-green-400 px-1.5 py-2 text-white">{{succes}}</p>
                        </div>
                        <!--Affichage de message de successfull-->


                        <!--Affichage de message de error-->
                        <div class="px-4 flex justify-center" v-show="erreur">
                            <p class="bg-red-600 px-4 py-2 text-white">{{erreur}}</p>
                        </div>
                        <!--Affichage de message de error-->
                        
                    <div class="w-full ">
                        
                            <div class="flex w-full overflow-x-hidden" style="background-color: #DEA20A;">
                                <div class="hidden sm:flex w-20 text-white text-center text-black text:xs sm:text-sm pl-2 py-5 text-md">
                                    N°
                                </div>
                                <div class="w-32 sm:w-64 text-white text-center text-black text:xs sm:text-sm pl-2 py-5 text-md">
                                    Nom
                                </div>
                                <div class="w-32 sm:w-64 text-white text-center text-black text:xs sm:text-sm pl-2 py-5 text-md">
                                    Manager
                                </div>
                                <div class="w-32 sm:w-64 text-white text-center text-black text:xs sm:text-sm pl-2 py-5 text-md">
                                    Province
                                </div>
                                <div class="p-2 overflow-x-auto w-full flex justify-end">
                                       
                                </div>
                                
                            </div>
                            <div class="px-2 w-full overflow-y-auto h-80" style="background-color: #FBF7EE;">
                                <div class="flex w-full my-1.5  overflow-y-auto" v-for="(items, index) in sortedItems" :key="index">
                                    <div class="hidden sm:flex w-20 p-2 overflow-x-auto whitespace-nowrap   text-sm  text-center text-black">
                                        {{ index + 1 }}
                                    </div>
                                    <div class="w-32 sm:w-64 p-2 overflow-x-auto whitespace-nowrap   text-sm  text-center text-black">
                                        {{ items.nom }}   
                                    </div>
                                    <div class="w-32 sm:w-64 p-2 overflow-x-auto whitespace-nowrap   text-sm  text-center text-black">
                                        {{ items.manager.user.nom }} {{ items.manager.user.prenom }}
                                    </div>
                                    <div class="w-32 sm:w-64 p-2 overflow-x-auto whitespace-nowrap   text-sm  text-center text-black">
                                        {{ items.ville.nom }}
                                    </div>
                                    <div class="p-2 overflow-x-auto w-full flex justify-end">
                                        <button @click="openModal(items.id)"  class="px-2 sm:px-4 text-white text-xs sm:text-sm py-1 mr-2 md:mr-5 rounded-lg text-sm  text-center" style="background-color: #25A4EC;">
                                                Modifier
                                        </button>
                                        <button @click="drop(items.id)" class="px-2 sm:px-4 text-white text-xs sm:text-sm py-1 rounded-lg text-sm  text-center bg-red-500">
                                                Supprimer
                                        </button>
                                    </div>
                                   
                                </div>
                            </div>
                        
                        <!-- Les autres colonnes -->
                    </div>
                </div>
            </div>
        </div>
            <div v-show="modal" v-if="getId" class="fixed inset-0 flex items-center justify-center">
                <div class="bg-white shadow-md rounded-lg p-6 max-w-sm mx-auto z-50">
                    <h2 class="text-lg font-bold mb-4">Modification</h2>
                    <div class="text-left flex justify-between mb-5">
                        <p class="mr-3 font-bold">Nom :</p> <input type="text" v-model="name" :placeholder="getId.nom" class="border px-1 border-black" name="" id="">
                    </div>
                    <div class="text-left flex justify-between mb-5">
                        <p class="mr-3 font-bold">Manager :</p> 
                        <select name="" v-model="manager"  class="w-52 border border-gray-500" id="">
                            <option disabled selected class="text-red-500" v-if=getId.manager>{{ getId.manager.user.nom }} {{ getId.manager.user.prenom }}</option>
                            <option :value="manager.manager.id" v-for="manager in fetchedData" :key="manager">{{ manager.nom }} {{ manager.prenom }}</option>
                            
                        </select>
                    </div>
                    <div class="text-left flex justify-between mb-5">
                        <p class="mr-3 font-bold">Ville :</p> 
                        <select name="" v-model="ville"  class="w-52 border border-gray-500" id="">
                            <option disabled selected class="text-red-500" v-if="getId.ville">{{ getId.ville.nom }}</option>
                            <option :value="ville.id" v-for="ville in itemProvince" :key="ville">{{ ville.nom }}</option>
                        </select>
                    </div>
                    <div class="text-left flex justify-between">                    
                        <button @click="modifShop" id="closeModalButton" class="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                            Modifier
                        </button>
                        <button @click="close" id="closeModalButton" class="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                            Fermer
                        </button>
                    </div>
                </div>
            </div>
            <div v-if="IdDrop" class="fixed inset-0 flex items-center justify-center">
                <div class="bg-white shadow-md rounded-lg p-6 max-w-sm mx-auto z-50">
                    <h2 class="text-lg font-bold mb-4">Voulez-vous supprimer cet élément?</h2>
                    <div class="text-left flex justify-between">                    
                        <button @click="dropShop(IdDrop)" id="closeModalButton" class="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                            Supprimer
                        </button>
                        <button @click="close" id="closeModalButton" class="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                            Fermer
                        </button>
                    </div>
                </div>
            </div>
        <Footer></Footer>
    </div>

</template>

<script>
import {mapActions} from 'vuex'
import Header from '@/components/layout/Header.vue'
import Footer from '@/components/layout/Footer.vue'
import Shop from '@/_services/shop.services'
import Province from '@/_services/province.services'


export default{
    name: 'shopVue',

    components:{
        Header,
        Footer,
    },

    data(){
        return {
            itemShop: [],
            getId: [], 
            itemProvince: [],
            fetchedData: [],

            modal: false,
            succes: null,
            erreur: null,

            name: '',
            manager: '',
            ville: '',
            idCat: '',
            IdDrop: null,
        }
    },

    mounted(){
        const shop = new Shop();
        shop.getAllShop()
            .then(monnaie => {
                this.itemShop = monnaie.data;
                //console.log(this.itemShop)
            })

        const province = new Province();
            
        province.getAllPro()
            .then(prov => {
                this.itemProvince = prov.data;
                ////.log(this.itemProvince)
            })
            .catch((error) => {
                ////.log(error);
            });
    },

    computed:{
        sortedItems(){
            return this.itemShop.sort((a,b) => a.id - b.id);
        },

        success(){
            return this.$store.state.submitShop.success
        },

        /*nameShop(){
            return this.$store.state.submitShop.shop
        }*/
    },
    created(){
        this.fetchData()
        this.roles = localStorage.getItem("role");
        if(this.roles == null){
                this.$router.push('/authentification')
            }
    },

    methods: {

        ...mapActions(['fectDataManager']),

        fetchData() {
            
            this.fectDataManager()
                .then(response => {
                    this.fetchedData = response;
                    //console.log(response)
                })
                .catch(error => {
                    // Une erreur s'est produite lors de la récupération des données
                    //.error(error);
                });
            },

        close(){
            this.modal = false;
            this.IdDrop = null
        },

        openModal(id){
            this.idCat = id;

            if(this.modal === id){
                this.modal = null;
            }else{
                this.modal = id
                const idCom = this.itemShop.find(com => com.id == id);
                this.getId = idCom
                ////.log(this.getId)
                this.name = this.getId.nom
                this.manager = this.getId.manager.id
                this.ville = this.getId.ville.id
            }

        },

        drop(id){

            this.IdDrop = id;

        },

        modifShop(){
            this.$store.dispatch('editShop',{
                nom : this.name,
                manager_id : this.manager,
                id : this.idCat,
                ville_id: this.ville
            })
            .then((response) => {
                this.modal = false;
                if(response.status === 200){
                    this.succes = "Boutique modifier " + response.data.nom
                    setTimeout(() =>{
                                    this.succes = false;
                                    location.reload();
                                }, 
                                5000)
                }
            })
            .catch((error) => {
                //.log(error)  
            })
            .finally(() => {
                this.nameCate =''
            });
        },

        dropShop(id){
 
            this.IdDrop = null
            this.$store.dispatch('dropShop',{
                id : id
            })
            .then((response) => {
                //(response)
                if(response.status === 200){
                    this.succes = "Boutique supprimer avec succes "
                    setTimeout(() =>{
                                    this.succes = false;
                                    location.reload();
                                }, 
                                5000)
                }else{
                    
                }
            })
            .catch((error) => {
                
            })
            .finally(() => {
                return
            });
        }

    }
}

</script>