<template>
    <div class="w-screen overflow-x-hidden">
        <Header></Header>
    
        <div class="w-full bg-white mb-12 mt-1.5 overflow-x-hidden">
            <div class="w-full h-full ">
                <div class="w-full px-4 sm:px-36">
                    <div class="my-10">
                        <form action="" @submit.prevent="sendDataFiltre">
                            <div class=" grid  grid-cols-2 md:grid-cols-2" v-show="roles != null">
                                <div class="flex w-full mb-4">
                                    <div class="w-1/4 flex items.center justify-center mr-4">
                                        <label for="" class="font-bold">Date</label>
                                    </div>
                                    <div class="w-3/4">
                                        <input type="date" v-model="filters.date" name="" id="" class="block w-full px-4 py-1.5 rounded-lg " style="background-color: #FBF7EE;">     
                                    </div>
                                </div>
                                <div class="flex w-full mb-4">
                                    <div class="w-1/4 mx-4">
                                        <label for="" class="font-bold">Boutique</label>
                                    </div>
                                    <div class="w-3/4">
                                        <select name="" id="" v-model="filters.boutique" class="block w-full px-4 py-1.5 rounded-lg " style="background-color: #FBF7EE;">
                                            <option :value="vil.id" v-for="(vil, index) in itemboutique" :key="index">{{ vil.nom }}</option>
                                        </select>
                                    </div>
                                </div>
                                
                            </div>
                            <div class="w-full  mt-7 mb-14">
                                    <button type="submit" class="w-40 px-4 text-white py-1.5 rounded-lg" style="background-color: #DEA20A;">Filtrer le resultat</button>
                            </div>
                        </form>
    
                        <div class="mb-16 "  v-if="roles == 'admin' || roles == 'gestionnaire'">
                            <div class="flex">
                            
                                <button class="px-4 text-white py-1.5 rounded-lg" style="background-color: #DEA20A;"><router-link to="/ravitaillement">Ravitailler Boutique </router-link></button>
                            
                            </div>
                            </div>
                    </div>
                    
                    <div class="w-full mb-14 overflow-x-hidden" style="background-color: #FBF7EE;">
                       
                            <div class="flex w-full overflow-x-hidden" style="background-color: #DEA20A;">
                                <div class="w-40 text-white text-center text-black text:xs sm:text-sm py-5 text-md">
                                    Date
                                </div>
                                <div class="w-52 text-white text-center text-black text:xs sm:text-sm pl-2 py-5 text-md">
                                    Boutique
                                </div>
                                <div class="w-52 text-white text-center text-black text:xs sm:text-sm pl-2 py-5 text-md">
                                    Produit
                                </div>
                                <div class="w-52 text-white text-center text-black text:xs sm:text-sm pl-2 py-5 text-md">
                                    Prix Acquisition
                                </div>
                                <div class="w-52 text-white text-center text-black text:xs sm:text-sm pl-2 py-5 text-md">
                                    Stock Initial
                                </div>
                            </div>
                            <div class="h-80 w-full overflow-x-hidden">
                                <div class="flex w-full my-1.5" v-for="(com, index) in getdata" :key="index">
                                    <div class="w-52 p-2 overflow-x-auto whitespace-nowrap   text-sm  text-center text-black">
                                        <p v-text="formData(com.created_at)"></p>
                                    </div>
                                    <div class="w-52 p-2 overflow-x-auto whitespace-nowrap   text-sm  text-center text-black">
                                        {{ com.boutique_nom }}
                                    </div>
                                    <div class="w-52 p-2 overflow-x-auto whitespace-nowrap   text-sm  text-center text-black ">
                                        {{ com.nombre_produits }}
                                    </div>
                                    <div class="w-52 p-2 overflow-x-auto whitespace-nowrap   text-sm  text-center text-black ">
                                        {{ com.somme_prix_acquisition }}
                                    </div>
                                    <div class="w-52 p-2 overflow-x-auto whitespace-nowrap   text-sm  text-center text-black ">
                                        {{ com.somme_stock_initial }}
                                    </div>
                                </div>
                            </div>
                        
                        <!-- Les autres colonnes -->
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
        
    </template>
    
<script>
    
    import Header from '@/components/layout/Header.vue'
    import Footer from '@/components/layout/Footer.vue'
    import { mapActions } from 'vuex'
    import Boutique from '@/_services/shop.services'
import Axios from '@/_services/caller.services'
    
    
    
        export default{
            name: 'hitoryRavi',
    
            components:{
                Header,
                Footer
            },
    
            data(){
                return{
                    getdata : null,
                    roles : null,
                    itemboutique: [],
                    token : null,
                    filters:{
                        date: '',
                        boutique: '',
                    }
                }
            },
    
            created(){
              this.roles = localStorage.getItem("role");
              this.token = localStorage.getItem('authToken');
              if(this.roles == null){
                this.$router.push('/authentification')
            }
    
              if(this.roles == 'admin' || this.roles == 'gestionnaire'){
                this.showHivRav();
              }
            },
    
            methods: {
    
                ...mapActions(['getHisRav']),
                showHivRav(){
                   if(!this.filters.boutique && !this.filters.date){ 
                    this.getHisRav()
                        .then(res =>{
                            this.getdata = res.data
                            //.log(this.getdata)
                        })
                        .catch(err =>{
                            ////.log(err)
                        });
                    }
                },
    
                formData(date){
                    const dateObj = new Date(date);
    
                    const year = dateObj.getFullYear();
                    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
                    const day = dateObj.getDate().toString().padStart(2, '0');
    
                    return `${year}/${month}/${day}`
                },

                //filtre request
                async sendDataFiltre(){
                    const headers = {
                        'Authorization': `Bearer ${this.token}`,
                    }

                    
                    try{
                        const response = await Axios.get(
                            '/api/stock/ravitaillement',
                            { 
                                params: this.filters,
                                headers
                            }
                        )
                        this.getdata = response.data.data
                        ////.log(response.data.data)
                    }catch(e) {
                        //.log(e)
                    }finally {
                        this.filters = {
                                                
                            boutique:"",
                            date:"",
                        }
                    }
                }
    
            },
    
            mounted(){
                //get ville
                const boutique = new Boutique();
                boutique.getAllShop()
                    .then(res => {
                        this.itemboutique = res.data;
                    }).catch(error => {
                        //(error)
                    })
            }
        }
    
    </script>