import Axios from './caller.services'

export default class ConfigurationService{

    async configInfo(data, token){

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Access-Control-Allow-Origin':'*',
            'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,PATCH,OPTIONS',
            
            'Access-Control-Allow-Headers': 'Content-Type, Origin, X-Auth-Token, Authorization'
        }

        try{

            const reponse = await Axios.post(
                '/api/configuration/info',
                data,
                {headers}
            )

            return reponse

        }catch(e){
            throw e
        }

    };

    async configContact(data, token){

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Access-Control-Allow-Origin':'*',
            'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,PATCH,OPTIONS',
            
            'Access-Control-Allow-Headers': 'Content-Type, Origin, X-Auth-Token, Authorization'
        }

        try{

            const reponse = await Axios.post(
                '/api/configuration/contact',
                data,
                {headers}
            )

            return reponse

        }catch(e){
            throw e
        }

    };

    async configSecurite(data, token){

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Access-Control-Allow-Origin':'*',
            'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,PATCH,OPTIONS',
            
            'Access-Control-Allow-Headers': 'Content-Type, Origin, X-Auth-Token, Authorization'
        }

        try{

            const reponse = await Axios.post(
                '/api/configuration/securite',
                data,
                {headers}
            )

            return reponse

        }catch(e){
            throw e
        }

    }

}