import Axios from './caller.services'


class UserService{
    async login(telephone, password){
        try {
            const response = await Axios.post('/api/login', { telephone, password });
            //const access_token = response.data['data']['access_token'];
            //console.log(response.data);
            //localStorage.setItem('access_token', access_token);
            return response;
        } catch (err) {
            throw err;
        }
    }

    async logout(token){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }

        try {
            const response = await Axios.get('/api/logout', {headers});
            return response.data.message;
        } catch (error) {
            throw error;
        }
    }

    async register(userRegister) {
        try {
            const response = await Axios.post('/api/register', userRegister);
            const access_token = response.data['data']['access_token'];
            //console.log(response.data);
            localStorage.setItem('access_token', access_token);
            return response;
        } catch (err) { 
            console.log(err);
        }
    
    }
}


export default new UserService();

/*
let login = (credentials) => {
    return Axios.post('/api/login', credentials);
}

let logout = () =>{
    localStorage.removeItem('token');
}

let saveToken = (token) => {
    localStorage.setItem('token', token);
}

let isLoggedIn = () => {
    let token = localStorage.getItem('token')
    return !!token
}

login:({commit}, userInfo) =>{
        return new Promise((resolve, reject) =>{
            commit;
            Axios.post('/login', userInfo)
            .then(function(response){
                resolve(response);
            })
            .catch(function(err){
                reject(err);
            });
        })
    },

export const accountService = {
    login,
    logout,
    saveToken,
    isLoggedIn
}
*/