<template>
    <div class="shadow-lg rounded-b-lg mb-10">
        <ViewsPhotos></ViewsPhotos>
        <ContentDet></ContentDet>
    </div>

</template>

<script>

import ViewsPhotos from '@/components/layout/ViewsPhoto.vue'
import ContentDet from '@/components/layout/ContentDetail.vue'
import Province from '@/_services/province.services'

    export default{
        name: 'ViewDetails',

        components: {
            ViewsPhotos,
            ContentDet
        },

        data(){
            return{
                //localization: localStorage.getItem('localisation'),
                itemProvince: [],
                //taux_ville : null, // Récupérer la valeur sélectionnée
                //devise_ville : null, // Récupérer la valeur sélectionnée
                //pays : null, // Récupérer la valeur sélectionnée
                //code_pays : null,  // Récupérer la valeur sélectionnée
                /*liste_pays: [
                    
                                                    {key:"243", value: "Congo (Kinshasa)"},
                                                    {key:"93", value: "Afghanistan"},
                                                    {key:"27", value: "Afrique du Sud"},
                                                    {key:"355", value: "Albanie"},
                                                    {key:"213", value: "Algérie"},
                                                    {key:"49", value: "Allemagne"},
                                                    {key:"371", value: "Andorre"},
                                                    {key:"244", value: "Angola"},
                                                    {key:"966", value: "Arabie saoudite"},
                                                    {key:"54", value: "Argentine"},
                                                    {key:"374", value: "Arménie"},
                                                    {key:"297", value: "Aruba"},
                                                    {key:"61", value: "Australie"},
                                                    {key:"43", value: " Autriche"},
                                                    {key:"994", value: "Azerbaïdjan"},
                                                    {key:"1", value: "Bahamas"},
                                                    {key:"973", value: "Bahreïn"},
                                                    {key:"880", value: "Bangladesh"},
                                
                                                    {key:"501", value: "Belize"},
                        
                                                    {key:"32", value: "Belgique"},
                                                    {key:"229", value: "Bénin"},
                                                    {key:"1", value: "Bermudes"},
                                                    {key:"975", value: "Bhoutan"},
                                
                                                    {key:"374", value: "Biélorussie"},
                                                    {key:"591", value: "Bolivie"},
                                                    {key:"387", value: "Bosnie-Herzégovine"},
                                                    {key:"55", value: "Bresil"},
                                                    {key:"673", value: " Brunei"},
                            
                                                    {key:"267", value: "Botswana"},
                                                    {key:"359", value: "Bulgarie"},
                                                    {key:"226", value: "Burkina Faso"},
                                                    {key:"257", value: "Burundi"},
                                                    {key:"885", value: "Cambodge"},

                            
                                                    {key:"237", value: "Cameroun"},
                                                    {key:"1", value: "Canada"},
                                                    {key:"238", value: " Cap-vert"},
                                                    {key:"56", value: "Chili"},
                                                    {key:"86", value: "Chine"},
                                                    {key:"357", value: "Chypre"},
                                                    {key:"57", value: "Colombie"},
                                                    {key:"269", value: " Comores"},
                                                    {key:"225", value: "Côte d\'Ivoire"},
                                                    {key:"82", value: "Corée du Sud"},

                                                    {key:"506", value: "Costa Rica"},
                                                    {key:"385", value: "Croatie"},
                                                    {key:"53", value: "Cuba"},
                            
                                                    {key:"45", value: " Danemark"},
                                                    {key:"253", value: "Djibouti"},
                                                    {key:"20", value: "Égypte"},
                                                    {key:"971", value: "Émirats arabes unis"},
                                                    {key:"34", value: "Espagne"},
                                                    {key:"1", value: "Etats Unis"},
                                
                                                    {key:"372", value: "Estonie"},
                                                    {key:"251", value: "Éthiopie"},
                                                    {key:"593", value: "Équateur"},
                                                    {key:"679", value: "Fidji"},

                                                    {key:"358", value: "Finlande"},
                                                    {key:"33", value: "France"},
                                                    {key:"241", value: "Gabon"},
                                                    {key:"220", value: "Gambie"},
                                                    {key:"995", value: "Géorgie"},
                                
                                                    {key:"233", value: "Ghana"},
                                                    {key:"350", value: "Gibraltar"},
                                                    {key:"590", value: "Guadeloupe"},
                                                    {key:"502", value: "Guatemala"},
                                                    {key:"224", value: "Guinée"},
                                                    {key:"245", value: "Guinée-Bissau"},
                                                    {key:"240", value: "Guinée équatoriale"},
                                                    {key:"592", value: "Guyana"},
                            
                                                    {key:"594", value: "Guyane française"},
                                                    {key:"30", value: "Grèce"},
                                                    {key:"1", value: "Grenade"},
                                
                                                    {key:"299", value: "Groenland"},
                                                    {key:"509", value: "Haiti"},
                                                    {key:"504", value: "Honduras"},

                                                    {key:"36", value: "Hongrie"},
                                                    {key:"262", value: "Ile de La Réunion"},
                                
                                                    {key:"230", value: "Ile Maurice"},
                                
                                                    {key:"91", value: "Inde"},
                                                    {key:"62", value: "Indonésie"},
                                                    {key:"964", value: "Irak"},
                                                    {key:"98", value: "Iran"},
                                                    {key:"353", value: "Irlande"},
                                                    {key:"354", value: "Islande"},
                                                    {key:"972", value: "Israël"},
                                                    {key:"39", value: "Italie"},
                                                    {key:"1", value: "Jamaïque"},
                                                    {key:"81", value: "Japon"},

                                                    {key:"44", value: "Jersey"},
                                                    {key:"962", value: "Jordanie"},
                                                    {key:"7", value: "Kazakhstan"},
                                                    {key:"254", value: "Kazakhstan"},
                                                    {key:"996", value: "Kirghizistan"},
                                                    {key:"965", value: "Koweït"},
                                                    {key:"856", value: "Laos"},
                            
                                                    {key:"266", value: "Lesotho"},
                                                    {key:"371", value: "Lettonie"},
                                                    {key:"961", value: "Liban"},
                                                    {key:"231", value: "Liberia"},
                                                    {key:"218", value: "Libye"},
                                                    {key:"423", value: "Lechtenstein"},
                                                    {key:"370", value: "Lituanie"},
                                                    {key:"352", value: "Luxembourg"},
                                                    {key:"389", value: "Macédoine"},
                                                    {key:"261", value: "Madagascar"},
                                
                                                    {key:"60", value: "Malaisie"},
                                                    {key:"265", value: "Malawi"},
                                                    {key:"960", value: "Maldives"},
                                                    {key:"223", value: "Mali"},
                                                    {key:"356", value: "Malte"},
                                                    {key:"212", value: "Maroc"},
                                                    {key:"596", value: "Martinique"},
                                
                                                    {key:"222", value: "Mauritanie"},
                                                    {key:"52", value: "Mexique"},
                                
                                                    {key:"373", value: "Moldavie"},
                                                    {key:"377", value: "Monaco"},
                                                    {key:"976", value: "Mongolie"},
                                
                                                    {key:"382", value: "Monténégro"},
                                                    {key:"258", value: "Mozambique"},
                                                    {key:"264", value: "Namibie"},
                                                    {key:"977", value: "Nepal"},
                                                    {key:"505", value: "Nicaragua"},

                                                    {key:"227", value: "Niger"},
                                                    {key:"234", value: "Nigeria"},
                                                    {key:"47", value: "Norvège"},
                                                    {key:"64", value: "Nouvelle-Zélande"},
                                                    {key:"968", value: "Oman"},
                                                    {key:"256", value: "Ouganda"},
                                                    {key:"998", value: "Ouzbékistan"},
                                                    {key:"92", value: "Pakistan"},
                                                    {key:"970", value: "Palestine"},
                                                    {key:"507", value: "Panama"},
                                                    {key:"595", value: "Paraguay"},
                                
                                                    {key:"31", value: "Pays-Bas"},
                                                    {key:"51", value: "Pérou"},
                                                    {key:"51", value: "Philippines"},
                                
                                                    {key:"48", value: "Pologne"},
                                                    {key:"63", value: "Polynésie française"},
                                                    {key:"1", value: "Porto Rico"},
                                                    {key:"351", value: "Portugal"},
                                                    {key:"974", value: "Qatar"},
                                                    {key:"236", value: "République centrafricaine"},
                                                    {key:"243", value: "Congo (Kinshasa)"},
                                                    {key:"242", value: "Congo (Brazzaville)"},
                                                    {key:"1", value: "République Dominicaine"},
                                                    {key:"503", value: "République du Salvador"},
                                                    {key:"420", value: "République tchèque"},
                                                    {key:"40", value: "Roumanie"},
                                                    {key:"44", value: "Royaume-Uni"},
                                                    {key:"7", value: "Russie"},
                                                    {key:"250", value: "Rwanda"},
                                                    {key:"378", value: "Saint-Marin"},
                                                    {key:"239", value: "Sao Tomé-et-Principe"},
                                
                                                    {key:"221", value: "Sénégal"},
                                                    {key:"381", value: "Serbie"},
                                                    {key:"248", value: "Seychelles"},
                                                    {key:"232", value: "Sierra Leone"},
                                                    {key:"65", value: "Singapour"},
                            
                                                    {key:"421", value: "Slovaquie"},
                                                    {key:"386", value: "Slovénie"},
                                                    {key:"252", value: "Somalie"},
                                                    {key:"249", value: "Soudan"},
                                                    {key:"46", value: "Suède"},
                                                    {key:"41", value: "Suisse"},
                                                    {key:"94", value: "Sri Lanka"},
                                                    {key:"268", value: "Swaziland"},
                                                    {key:"963", value: "Syrie"},
                                
                                                    {key:"992", value: "Tadjikistan"},
                                                    {key:"255", value: "Tanzanie"},
                                                    {key:"235", value: "Tchad"},
                                                    {key:"66", value: "Thaïlande"},
                                                    {key:"886", value: "Taïwan"},
                                
                                                    {key:"228", value: "Togo"},
                                                    {key:"676", value: "Tonga"},
                                                    
                                                    {key:"216", value: "Tunisie"},
                                                    {key:"993", value: "Turkménistan"},
                                                    {key:"90", value: "Turquie"},
                                                    {key:"1", value: "Trinité-et-Tobago"},
                                                    
                                                    {key:"380", value: "Ukraine"},
                                                    {key:"598", value: "Uruguay"},
                                                    {key:"58", value: "Venezuela"},
                                                    {key:"84", value: "Vietnam"},
                                                    
                                                    {key:"967", value: "Yémen"},
                                                    {key:"260", value: "Zambie"},
                                                    {key:"263", value: "Zimbabwe"},
                    
                ]*/

            }
        },
        created(){
            
        },
        methods: {
            /*showDialog() {

                const province = new Province();
            
                province.getAllPro()
                    .then(prov => {
                        this.itemProvince = prov.data;
                        ////.log(this.itemProvince)

                        this.$swal.fire({
                            title: 'Sélectionnez votre Pays',
                            html:`
                            <!--div class="w-full text-left mb-2">
                                <label class="font-bold text-xl">Pays :</label>
                            </div>
                            <select id="pays" name="pays" v-model="countries" class="w-full border border-gray-500 px-3 mb-5 py-1 rounded-xl"  value="{{old('pays')}}">
                                <option value="" disabled selected hidden></option>                    
                                ${this.liste_pays.map((province) => `<option value="${[province.key,province.value]}">${province.value}</option>`).join('')}                    
                                
                            </select-->
                                                <div class="w-full text-left mb-2">
                                                    <label class="font-bold text-xl">Ville :</label>
                                                </div>
                                                <select id="villeSelect" v-model="ville" class="w-full border border-gray-500 px-3 mb-5 py-1 rounded-xl">
                                                    <option value="" disabled selected hidden></option>
                                                    ${this.itemProvince.map((province) => `<option value="${[province.nom]}">${province.nom}</option>`).join('')}
                                                </select>
                            `,
                            showCancelButton: true,
                            cancelButtonText: 'Annuler',
                            allowOutsideClick: false,
                            

                        }).then((result) => {
                            if (result.value) {
                                this.ville = document.getElementById('villeSelect').value; // Récupérer la valeur sélectionnée
                                //this.code_pays = document.getElementById('pays').value.split(',')[0]; // Récupérer la valeur sélectionnée
                                //this.nom_pays = document.getElementById('pays').value.split(',')[1]; // Récupérer la valeur sélectionnée
    

                                if(!this.ville){
                                    
                                    
                                    alert("Veuillez sélectionner des informations valides.");
                                    
                                
                                }else{

                                    const data = this.itemProvince.find(ville => ville.nom == this.ville)
                                    const nom_pays = this.liste_pays.find(pays => pays.value == data.pays)
                                    
                                    this.code_pays = nom_pays.key
                                    //if(this.nom_pays == data.pays ){
                                        
                                        if(nom_pays.key == 243){

                                            localStorage.setItem("localisation", nom_pays.key);
                                            localStorage.setItem("devis_ville", "$");
                                            
                                            localStorage.setItem("ville", data.nom)                                        
                                            
                                            

                                        }else{
                                            
                                            localStorage.setItem("ville", data.nom)                                               
                                            localStorage.setItem("localisation", nom_pays.key);
                                            localStorage.setItem("devis_ville", data.monnaie.symbole);
                                            localStorage.setItem("taux_ville", data.taux);

                                        }
                                    
                                    }else{

                                        alert("Le Pays sélectionné ne correspond pas à la ville")
                                    
                                    }

                                }

                                
                                
                                
                            }
                            location.reload();
                        });

                        
                    })
                
            }*/
        }
    }

</script>