import Axios from './caller.services'


export default class ManagerService{

    // recupere les stock de la boutique
    async getStock(boutique_id, token){

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        
        try{

            const reponse = await Axios.get(
                `/api/boutique/stock/${boutique_id}`,
                {headers});
            return reponse.data;

        } catch(error){
            //.log(error);
            throw error;
        }
    };

    // insert une demande de ravitaille
    async demandeRavi(demandData, token){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }

        try{
            const reponse = await Axios.post(
                '/api/demande',
                demandData,
                {headers}
                );
            
            return reponse.data;
        } catch(error) {
            //.error(error);
            throw error;
        };
    };

    //afficher la liste de demande ravitaille effectuer
    async getDemand(token){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }

        try{
            const reponse = await Axios.get(
                '/api/demande',
                {headers}
                );
            
            return reponse.data;
        } catch(error) {
            //.error(error);
            throw error;
        };
    };

    //afficher la liste de commande a traité
    async getCommandeManger(token){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }

        try{
            const reponse = await Axios.get(
                '/api/boutique/manager/commandes',
                {headers}
                );
            
            return reponse.data;
        } catch(error) {
            //.error(error);
            throw error;
        };
    };

    //recupere la liste de livreur du manager
    async getLivreurManger(token){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }

        try{
            const reponse = await Axios.get(
                '/api/boutique/liste/livreur',
                {headers}
                );
            
            return reponse;
        } catch(error) {
            throw error;
        };
    };

    //filtre du dashoard du manager
    async getDashFiltre(token, date_){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }

        try{
            const reponse = await Axios.get(
                `/dashboard/kpi-manager/${date_}`,
                {headers}
                );
            
            return reponse.data;
        } catch(error) {
            //.error(error);
            throw error;
        };
    };

    //recupere le données du dashoard du manager
    async getDashoard(token){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }

        try{
            const reponse = await Axios.get(
                '/api/dashboard/kpi-manager',
                {headers}
                );
            
            return reponse.data;
        } catch(error) {
            //.error(error);
            throw error;
        };
    }

    //confirmation de la livraison action pour le manager et livraison
    async ConfirmationLiv(token, id){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }

        try{
            const reponse = await Axios.get(
                `/api/livraison/confirmation/${id}`,
                {headers}
                );
            
            return reponse.data;
        } catch(error) {
            //.error(error);
            throw error;
        };
    };

    //assigne une commande à un livreur action admin, gestionnaire, agent et manager
    async AssignerLiv(token, id){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }

        try{
            const reponse = await Axios.post(
                `/api/commande/add/livreur/${id}`,
                {headers}
                );
            
            return reponse.data;
        } catch(error) {
            //.error(error);
            throw error;
        };
    };

    

}