<template>
    <div class="grid grid-cols-2 md:grid-cols-3">
        <div class="flex w-82 mb-4" v-if="roles === 'agent'">
            <div class="w-1/4  mr-4">
                <label for="" class="font-bold">
                    Gestionnaire
                </label>
            </div>
            <div class="w-3/4">
                <select name="" v-model="itemId" @change="viewDate('gest')" id="" class="block px-4 py-1.5 rounded-lg " style="background-color: #FBF7EE;">
                    <option :value="item.gestionnaire['id']" v-for="(item, index) in itemUsers" :key="index">{{ item.nom }} {{ item.prenom }}</option>
                </select>
            </div>
        </div>
        <div class="flex w-82 mb-4" v-if="roles === 'admin' || roles === 'gestionnaire' || roles === 'manager'">
            <div class="w-1/4  mr-4">
                <label for="" class="font-bold">
                    Livreurs
                </label>
            </div>
            <div class="w-3/4">
                <select name="" v-model="selectedOption1" @change="viewDate('liv')" id="" class="block px-4 py-1.5 rounded-lg " style="background-color: #FBF7EE;">
                    <option :value="item.id" v-for="(item, index) in listLiv" :key="index">{{ item.user.nom }} {{ item.user.prenom }}</option>
                </select>
            </div>
        </div>
        <div class="flex w-82 mb-4" v-if="roles === 'admin' || roles === 'gestionnaire'">
            <div class="w-1/4  mr-4">
                <label for="" class="font-bold">
                    Managers
                </label>
            </div>
            <div class="w-3/4">
                <select name="" v-model="selectedOption2" @change="viewDate('man')" id="" class="block px-4 py-1.5 rounded-lg " style="background-color: #FBF7EE;">
                    <option :value="item.manager['id']" v-for="(item, index) in itemBtM" :key="index">{{ item.nom }} {{ item.prenom }}</option>
                </select>
            </div>
        </div>
        <div class="flex w-82 mb-4" v-if="roles === 'admin'">
            <div class="w-1/4  mr-4">
                <label for="" class="font-bold">
                    Agents
                </label>
            </div>
            <div class="w-3/4">
                <select name="" v-model="selectedOption3" @change="viewDate('ag')" id="" class="block px-4 py-1.5 rounded-lg " style="background-color: #FBF7EE;">
                    <option :value="item.id" v-for="(item, index) in itemBtAg" :key="index">{{ item.nom }} {{ item.prenom }}</option>
                </select>
            </div>
        </div>
    </div>
    <!--div class="absolute flex items-center justify-center inset-0" v-show="modifHoraire" @click="preventHideDiv">
        <div class="shadow-lg w-56 h-32 bg-white">
            <div class="flex justify-end py-2 pr-2">
                <img src="@/images/close.png" alt="" @click="closeDate" class="w-3 cursor-pointer" srcset="">
            </div>
            <form @submit.prevent="updateSelect1">
                <div class="w-full py-2 px-4 h-24">
                    <div>
                        <input type="date" name="" v-model="date" id="" class="mb-4 w-48 px-1" style="background-color: #FBF7EE;">
                    </div>
                    <button type="submit" class="py-1 px-2 w-48 text-white" style="background-color: #DEA20A;">Modifier</button>
                </div>
            </form>
        </div>
    </div-->
    <div v-show="conf" class="fixed inset-0 flex items-center justify-center">
        <div class="bg-white shadow-md rounded-lg p-6 max-w-sm mx-auto z-50">
            <h2 class="text-lg font-bold mb-4 line-clamp-4">Voulez-vous assigner ces commandes au 
                <p v-if="idName.user && chx==='liv'">Livreur : {{ idName.user.nom }} {{ idName.user.prenom }} </p>
                <p v-if="idName && chx==='gest'">Gestionnaire : {{ idName.nom }} {{ idName.prenom }} </p>
                <p v-if="idName && chx==='man'">Manager : {{ idName.nom }} {{ idName.prenom }} </p>
                <p v-if="idName && chx==='ag'">Agent : {{ idName.nom }} {{ idName.prenom }} </p>
            </h2>
            <div class="text-left flex justify-between">                    
                <button v-if="chx === 'gest'" @click="sendId" id="closeModalButton" class="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                        Oui
                </button>                    
                <button v-if="chx === 'liv'" @click="updateSelect1" id="closeModalButton" class="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                        Oui
                </button>                    
                <button v-if="chx === 'ag'" @click="updateSelect3" id="closeModalButton" class="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                        Oui
                </button>                    
                <button v-if="chx === 'man'" @click="updateSelect2" id="closeModalButton" class="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                        Oui
                </button>
                <button @click="close" id="closeModalButton" class="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                        Non
                </button>
            </div>
        </div>
    </div> 
</template>

<script>
import {mapActions} from 'vuex'
import Axios from '@/_services/caller.services'
import UserVueCompte from '@/_services/usersComptes.services'

    export default{
        name: "AssignerMultiple",

        data(){
            return{
                roles: null,
                itemUsers: [],
                itemId: null,
                token: null,
                itemBtAg: [],
                itemBtM: [],
                listLiv: [],
                date: null,
                itemUsersAgent: [],
                
                modifHoraire : false,

                selectedOption1: null,
                selectedOption2: null,
                selectedOption3: null,
                conf: false,
                idName :[],
                chx: null,
            }
        },

        props:{
            id:{
                type: Array,
                required: true,
            }
        },

        computed:{
            idRecu(){
                return this.id
            }
        },

        created(){
            this.roles = localStorage.getItem("role")
            this.token = localStorage.getItem("authToken")
            if(this.roles === 'agent'){
                this.viewLivreurs()
            }else if(this.roles === 'admin' || this.roles === 'gestionnaire'){
                this.ListAgent()
                this.ListMana()
                this.fetchData()
            }else if(this.roles === 'manager'){
                this.viewLivreurManager()
            }
        },

        methods:{
            preventHideDiv(e){
                e.stopPropagation(); 
            },

            viewDate(id){
                this.conf = true
                if(id === 'liv'){
                    const idcont = this.listLiv.find(liv => liv.id == this.selectedOption1)
                    this.idName = idcont 
                    this.chx = id
                    this.selectedOption2 = null
                    this.selectedOption3 =null
                }
                else if(id === 'man'){
                    this.chx = id
                    const idcont = this.itemBtM.find(liv => liv.manager.id == this.selectedOption2)
                    this.idName = idcont
                    this.selectedOption1 = null
                    this.selectedOption3 =null
                }
                else if(id === 'gest'){
                    this.chx = id
                    const idcont = this.itemUsers.find(liv => liv.gestionnaire.id == this.itemId)
                    this.idName = idcont
                    //console.log(this.idName)
                }
                else if(id === 'ag'){
                    this.chx = id
                    const idcont = this.itemBtAg.find(liv => liv.id == this.selectedOption3)
                    this.idName = idcont                    
                    this.selectedOption2 = null
                    this.selectedOption1 =null
                }
                               
            },

            close(){
                this.modifHoraire = false
                this.selectedOption1 = null
                this.selectedOption2 = null
                this.selectedOption3 =null
                this.conf = false
                this.idRecu = []
            },

            //liste gestionnaire
            ...mapActions(['getListeGest']),
            viewLivreurs(){

                this.getListeGest()
                    .then(response =>{
                        this.itemUsers = response.data
                        //console.log(this.itemUsers)
                    })
                    .catch(error => {
                        console.error(error);
                    });

            },

            //Liste de agent
            ...mapActions(['getAgent']),
            ListAgent(){

                this.getAgent()
                    .then(response =>{
                        this.itemBtAg = response.data.data
                        ////.log('agent', this.itemBtAg)
                    })
                    .catch(error => {
                        //.error(error);
                    });

            },

            //Liste de manager
            ...mapActions(['viewprovince']),
            ListMana(){

                this.viewprovince()
                    .then(response =>{
                        this.itemBtM = response.data
                        ////.log('manager', this.itemBtM)
                    })
                    .catch(error => {
                        //.error(error);
                    });

            },

            //liste des tout les livreurs de genesisdream unique pour le manager
            ...mapActions(['fetchLiveShop']),
            viewLivreurManager(){

                this.fetchLiveShop()
                    .then(response =>{
                        this.listLiv = response.data.data
                    })
                    .catch(error => {
                        //.error('liste livreur erreur :',error);
                    });

            },

            //liste livreur
            ...mapActions(['fechDataLivrer']),
            fetchData(){
                this.fechDataLivrer()
                    .then(response => {
                        this.listLiv = response.data;
                        //.log('livreur', this.listLiv)
                    })
                    .catch(error => {
                        // Une erreur s'est produite lors de la récupération des données
                        //.error(error);
                    });
            },

            //agent assigni plusieur commande aux ges$tionnaire
            async sendId(){
                if (this.idRecu.length === 0) {
                    // Si idRecu est vide, affiche une erreur
                    alert('Selectionnez les commandes')
                    this.itemId = null
                    return;
                }

                if(this.idRecu.length > 10) {
                    alert('Le nombre de commandes doit être inferieur à 10')
                    return;
                }

                ////.log(this.idRecu)

                const formData = new FormData();
                
                if(this.roles === 'agent'){
                    for(let i = 0; i < this.idRecu.length; i++){
                        
                        const commandKey = `commande${i}`
                        const commandValue = this.idRecu[i]

                        formData.append(commandKey, commandValue)

                    }

                    formData.append('gestionnaire_id', this.itemId)
                    formData.append('nbr_commandes', this.idRecu.length)

                    /*for (let [key, value] of formData.entries()) {
                        //.log(key, value);
                    }*/

                    const headers = {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.token}`,
                        'Access-Control-Allow-Origin':'*',
                        'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,PATCH,OPTIONS',                
                        'Access-Control-Allow-Headers': "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'"
                    }

                    try{

                        const reponse = await Axios.post(
                            '/api/backoffice/commandes/gestionnaire',
                            formData,
                            {headers}
                        )

                        //.log(reponse)
                        if(reponse.data.status_code === 200){
                            alert("Les commandes sont assignées")
                            setTimeout(() =>{
                                location.reload();
                            },1000)
                        }

                    }catch (e) {    
                        
                        //.log(e)

                    }finally {
                        this.idRecu = null
                        this.itemId = null
                        this.conf = false
                    }
                }
            },

            //Livreur
            async updateSelect1() {
                this.selectedOption2 = null;
                this.selectedOption3 = null;

                if (this.idRecu.length === 0) {
                    // Si idRecu est vide, affiche une erreur
                    alert('Selectionnez les commandes')
                    this.selectedOption1 = null
                    return;
                }

                if(this.idRecu.length > 10) {
                    alert('Le nombre de commandes doit être inferieur à 10')
                    return;
                }
                const formData = new FormData()

                for(let i = 0; i < this.idRecu.length; i++){
                        
                        const commandKey = `commande${i}`
                        const commandValue = this.idRecu[i]
                        const dateKey = `date_livraison${i}`
                        const dateValue = this.date

                        formData.append(commandKey, commandValue)
                        formData.append(dateKey, dateValue)

                    }

                    formData.append('livreur_id', this.selectedOption1)
                    formData.append('nbr_commandes', this.idRecu.length)

                    /*for (let [key, value] of formData.entries()) {
                        //.log(key, value);
                    }*/
                    
                    const headers = {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.token}`,
                        'Access-Control-Allow-Origin':'*',
                        'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,PATCH,OPTIONS',                
                        'Access-Control-Allow-Headers': "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'"
                    }

                    try{

                        const reponse = await Axios.post(
                            '/api/commandes/add/livreur',
                            formData,
                            {headers}
                        )

                        //.log(reponse)
                        if(reponse.data.status_code === 200){
                            alert("Les commandes sont assignées")
                            setTimeout(() =>{
                                location.reload();
                            },1000)
                        }

                    }catch (e) {    
                        
                        //.log(e)

                    }finally {
                        this.idRecu = null
                        this.selectedOption1 = null
                        this.modifHoraire = false
                        this.conf = false
                    }

            },

            //Manager
            async updateSelect2() {
                this.selectedOption1 = null;
                this.selectedOption3 = null;

                if (this.idRecu.length === 0) {
                    // Si idRecu est vide, affiche une erreur
                    alert('Selectionnez les commandes')
                    this.selectedOption2 = null
                    return;
                }

                if(this.idRecu.length > 10) {
                    alert('Le nombre de commandes doit être inferieur à 10')
                    return;
                }

                const formData = new FormData()

                for(let i = 0; i < this.idRecu.length; i++){
                        
                        const commandKey = `commande${i}`
                        const commandValue = this.idRecu[i]

                        formData.append(commandKey, commandValue)

                    }

                    formData.append('boutique_id', this.selectedOption2)
                    formData.append('nbr_commandes', this.idRecu.length)

                    for (let [key, value] of formData.entries()) {
                        //.log(key, value);
                    }
                    
                    const headers = {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.token}`,
                        'Access-Control-Allow-Origin':'*',
                        'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,PATCH,OPTIONS',                
                        'Access-Control-Allow-Headers': "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'"
                    }

                    try{

                        const reponse = await Axios.post(
                            '/api/commandes/change-boutique',
                            formData,
                            {headers}
                        )

                        //.log(reponse)
                        if(reponse.data.status_code === 200){
                            alert("Les commandes sont assignées")
                            setTimeout(() =>{
                                location.reload();
                            },1000)
                        }

                    }catch (e) {    
                        
                        //.log(e)

                    }finally {
                        this.idRecu = null
                        this.selectedOption2 = null
                        this.conf = false
                    }

            },

            //agent
            async updateSelect3() {
                this.selectedOption1 = null;
                this.selectedOption2 = null;

                if (this.idRecu.length === 0) {
                    // Si idRecu est vide, affiche une erreur
                    alert('Selectionnez les commandes')
                    this.selectedOption3 = null
                    return;
                }

                if(this.idRecu.length > 10) {
                    alert('Le nombre de commandes doit être inferieur à 10')
                    return;
                }

                const formData = new FormData()

                for(let i = 0; i < this.idRecu.length; i++){
                        
                        const commandKey = `commande${i}`
                        const commandValue = this.idRecu[i]

                        formData.append(commandKey, commandValue)

                    }

                    formData.append('agent_id', this.selectedOption3)
                    formData.append('nbr_commandes', this.idRecu.length)

                    for (let [key, value] of formData.entries()) {
                        //.log(key, value);
                    }
                    
                    const headers = {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.token}`,
                        'Access-Control-Allow-Origin':'*',
                        'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,PATCH,OPTIONS',                
                        'Access-Control-Allow-Headers': "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'"
                    }

                    try{

                        const reponse = await Axios.post(
                            '/api/backoffice/commandes/agent',
                            formData,
                            {headers}
                        )

                        //.log(reponse)
                        if(reponse.data.status_code === 200){
                            alert("Les commandes sont assignées")
                            setTimeout(() =>{
                                location.reload();
                            },1000)
                        }

                    }catch (e) {    
                        
                        //.log(e)

                    }finally {
                        this.idRecu = null
                        this.selectedOption3 = null
                        this.conf = false
                    }

            }

        },

        mounted(){
            const Users = new UserVueCompte();
            Users.getUsersAlls() 
            .then(usercompte => {

                if(this.roles === 'admin'){
                    this.itemUsersAgent = usercompte.data.filter(user => {
                    const roles = user.roles.map(role => role.name);
                    
                        return roles.includes("agent")
                    });

                    //.log('agent', this.itemUsersAgent)

                }

            }).catch(error => {
                //(error)
            });
        }
    }

</script>