import Axios from './caller.services'

export default class ProduitService  {

    //Récupérer la liste de produit
    async getAllProduit(){
        try {
            const response = await Axios.get('/api/produit');
            return response.data;
        } catch (error) {
            //.log(error);
            throw error;
        }
    }

    //Récupérer le produit par son ID
    async getProduitById(id){
        try {
            const response = await Axios.get(`/api/produit/${id}`);
            return response.data;
        } catch (error) {
            //.error(error);
             throw error;
        }
    }

    //Récupérer le produit par sa categories
    async getProduitByCat(cat){
        try {
            const response = await Axios.get(`/api/produits/${cat}`);
            return response.data;
        } catch (error) {
            //.error(error);
             throw error;
        }
    }

    //Ajouter une nouvelle produit 
    async addproduct(productData, token) {
        const headers = {
            'Authorization': `Bearer ${token}`
        }

        try {
            
            const response = await Axios.post(
                '/api/backoffice/produit', 
                productData,
                {headers});
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async dropProduct(id, token){

        const headers = {
            'Authorization': `Bearer ${token}`
        }

        try {
            
            const response = await Axios.delete(
                `/api/backoffice/produit/${id}`,
                {headers});
            return response;
        } catch (error) {
            throw error;
        }

    }
}
 