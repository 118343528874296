<template>
    <div  v-if="productData" class="px-4  pb-5 shadow-lg rounded-b-lg">
        <div class="">
            <div class="border-b h-auto">
                <div class="text-left pt-1 h-12 mb-1 overflow-hidden text-ellipsis">
                    <p class="line-clamp-2">{{ productData['nom'] }}</p>
                </div>
                <div class="text-left overflow-hidden text-ellipsis">
                    <h1 class="text-2xl font-bold text-black">Description</h1>
                    <p class="text-xs">{{ productData['description'] }}</p>
                </div>
            </div>
            <div class="z-10 flex justify-center">
                <div class="py-2 px-1 shadow-lg shadow-gray-400 w-auto bg-green-500 text-white rounded-lg" :class="{ 'fade-in': copieUr}" v-show="copieUr">
                    <p>Copie d'url</p>
                </div>
            </div>
            <div class="border-b py-2 h-24 ">
                <div class="flex py-2 text-left h-10">
                    <p class="mr-5 text-black">Prix :</p>
                    <p class="font-bold text-black" v-if="!taux || devise === 'USD'"> {{ productData['prix']}} USD </p>
                    <p class="font-bold text-black" v-else> {{ productData['prix']* taux}} {{ devise }} </p>
                </div>
                <div class="flex py-2 text-left h-10">
                    <p class="mr-4">Quantité :</p>
                    <div class="flex mr-20 justify-center items-center space-x-1">
                        <button class="px-2 border rounded-l" @click="decrement">-</button>
                        <input class="px-2 py-1 w-14 text-center text-xs border border-gray-300" type="text"  v-model="counter">
                        <button class="px-2 border rounded-r mr-1" @click="increment">+</button> <p>Pièce</p>
                    </div>
                    <div class="cursor-pointer">
                        <img src="@/images/share-alt-square.png" @click="copyPageUrl" class="h-7" alt="">
                    </div>
                </div>
            </div>
            <div class="flex h-32 border-b">
                <div class="w-72 py-5 pr-3">
                    <div class="flex justify-between h-10 border-dotted border-b-2">
                        <div class="flex justify-start w-20">
                            <p class="mr-1"> {{counter}} </p>Pièce
                        </div>
                        <div class="flex justify-end font-bold pr-4">
                            <p class="mr-1">{{total}} </p> <p> {{devise}} </p> <p v-if="!taux || (devise === 'USD' && devise !== 'USD')">USD</p>
                        </div>
                    </div>
                    <div class="flex justify-between h-10 mt-2">
                        <div class="flex justify-start w-20">
                            <p class="mr-1 font-bold">Total</p>
                        </div>
                        <div class="flex justify-end font-bold pr-4">
                            <p class="mr-1"> {{ total }} </p> <p> {{devise}} </p> <p v-if="!taux ||  (devise === 'USD' && devise !== 'USD')">USD</p>
                        </div>
                    </div>
                </div>
            </div>
            <form action="" @submit.prevent="SendCommand">
                <div class="mb-2 border-b">
                    <div class="justify-star text-left">
                        <label class="text-lg font-bold text-black">Adresse de livraison & Contacte</label>
                    </div>
                        <div class="mb-3 grid grid-cols-2">
                            <div class="mr-5 mb-2">
                                <div class="pl-2 mb-2 text-left">
                                    <label class="text-sm font-bold">Nom</label>
                                </div>
                                <input required type="text"  name="" v-model="nom" id="" class="px-4 py-1 w-full border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                            </div>
                            <div class="mr-5 mb-2">
                                <div class="pl-2 mb-2 text-left">
                                    <label class="text-sm text-left font-bold">Adresse</label>
                                </div>
                                <input type="text"  name="" v-model="adresse" id="" class="px-4 py-1 w-full border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                            </div>
                            <div class="mr-5 mb-2">
                                <div class="pl-2 mb-2 text-left">
                                    <label class="text-sm font-bold">Téléphone</label>
                                </div>
                                <input required type="text"  name="" v-model="telephone" placeholder="08******" id="" class="px-4 py-1 w-full border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                            </div>
                            <div class="mr-5">
                                <div class="pl-2 mb-2 text-left">
                                    <label class="text-sm font-bold">Ville</label>
                                </div>
                                <div>
                                    <select name="" id="" @change="location" v-model="province" class="block w-full px-4 py-1 border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                                        <option value="" disabled selected hidden></option>
                                        <option :value="items.id" class="uppercase"  v-for="items in itemProvince" :key="items.id">{{ items.nom}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                </div>
                <div class="px-3 my-8">
                    <div class="">
                        <button class="w-full flex items-center border border-amber-500 text-amber-500 justify-center px-4 py-1 bg-white hover:bg-amber-500 hover:text-white rounded-full space-x-2">
                            <span v-if="loading">Chargement...</span>
                            <span v-else>Commander</span>
                        </button>
                    </div>
                    <p class="my-2 text-red-500 text-sm">{{error}}</p>
                    <p class="my-2 text-red-500 text-sm" v-show="errorAdresse">{{ errorAdresse }}</p>
                    <p v-show="sucess" class="text-sm text-green-500 font-bold">Votre commandez est entrée avec succès</p>
                </div>
            </form>
        </div>
    </div>
</template>

<style>

    .fade-in {
        transition : opacity 10s;
        opacity: 1;
    }

</style>

<script>
import ProductService from '@/_services/produit.services'
import Province from '@/_services/province.services'
import CryptoJS from 'crypto-js'

    export default{
        name: 'ContentDetails',

        data(){
            return{
                itemDetails:[],
                itemProvince: [],
                idPro: '',
                productData: null,
                counter : 1,
                prix : '',
                livraison: '',

                nom: '',
                prenom: '',
                telephone: '',
                adresse: '',
                province: '',

                formData : new FormData(),

                error: null,
                errorAdresse: null,
                localisation: null,
                sucess: false,

                taux: null, //
                devise: null,
                itemProvince: null,
                ville: null,

                copieUr : false,

                loading: false,
                liste_pays: [
                    
                    {key:"243", value: "Congo (Kinshasa)"},
                    {key:"93", value: "Afghanistan"},
                    {key:"27", value: "Afrique du Sud"},
                    {key:"355", value: "Albanie"},
                    {key:"213", value: "Algérie"},
                    {key:"49", value: "Allemagne"},
                    {key:"371", value: "Andorre"},
                    {key:"244", value: "Angola"},
                    {key:"966", value: "Arabie saoudite"},
                    {key:"54", value: "Argentine"},
                    {key:"374", value: "Arménie"},
                    {key:"297", value: "Aruba"},
                    {key:"61", value: "Australie"},
                    {key:"43", value: " Autriche"},
                    {key:"994", value: "Azerbaïdjan"},
                    {key:"1", value: "Bahamas"},
                    {key:"973", value: "Bahreïn"},
                    {key:"880", value: "Bangladesh"},
                                
                    {key:"501", value: "Belize"},
                        
                    {key:"32", value: "Belgique"},
                    {key:"229", value: "Bénin"},
                    {key:"1", value: "Bermudes"},
                    {key:"975", value: "Bhoutan"},
                                
                    {key:"374", value: "Biélorussie"},
                    {key:"591", value: "Bolivie"},
                    {key:"387", value: "Bosnie-Herzégovine"},
                    {key:"55", value: "Bresil"},
                    {key:"673", value: " Brunei"},
                            
                    {key:"267", value: "Botswana"},
                    {key:"359", value: "Bulgarie"},
                    {key:"226", value: "Burkina Faso"},
                    {key:"257", value: "Burundi"},
                    {key:"885", value: "Cambodge"},
                            
                    {key:"237", value: "Cameroun"},
                    {key:"1", value: "Canada"},
                    {key:"238", value: " Cap-vert"},
                    {key:"56", value: "Chili"},
                    {key:"86", value: "Chine"},
                    {key:"357", value: "Chypre"},
                    {key:"57", value: "Colombie"},
                    {key:"269", value: " Comores"},
                    {key:"225", value: "Côte d\'Ivoire"},
                    {key:"82", value: "Corée du Sud"},

                    {key:"506", value: "Costa Rica"},
                    {key:"385", value: "Croatie"},
                    {key:"53", value: "Cuba"},
                            
                    {key:"45", value: " Danemark"},
                    {key:"253", value: "Djibouti"},
                    {key:"20", value: "Égypte"},
                    {key:"971", value: "Émirats arabes unis"},
                    {key:"34", value: "Espagne"},
                    {key:"1", value: "Etats Unis"},
                                
                    {key:"372", value: "Estonie"},
                    {key:"251", value: "Éthiopie"},
                    {key:"593", value: "Équateur"},
                    {key:"679", value: "Fidji"},

                    {key:"358", value: "Finlande"},
                    {key:"33", value: "France"},
                    {key:"241", value: "Gabon"},
                    {key:"220", value: "Gambie"},
                    {key:"995", value: "Géorgie"},
                                
                    {key:"233", value: "Ghana"},
                    {key:"350", value: "Gibraltar"},
                    {key:"590", value: "Guadeloupe"},
                    {key:"502", value: "Guatemala"},
                    {key:"224", value: "Guinée"},
                    {key:"245", value: "Guinée-Bissau"},
                    {key:"240", value: "Guinée équatoriale"},
                    {key:"592", value: "Guyana"},
                            
                    {key:"594", value: "Guyane française"},
                    {key:"30", value: "Grèce"},
                    {key:"1", value: "Grenade"},
                                
                    {key:"299", value: "Groenland"},
                    {key:"509", value: "Haiti"},
                    {key:"504", value: "Honduras"},

                    {key:"36", value: "Hongrie"},
                    {key:"262", value: "Ile de La Réunion"},
                                
                    {key:"230", value: "Ile Maurice"},
                                
                    {key:"91", value: "Inde"},
                    {key:"62", value: "Indonésie"},
                    {key:"964", value: "Irak"},
                    {key:"98", value: "Iran"},
                    {key:"353", value: "Irlande"},
                    {key:"354", value: "Islande"},
                    {key:"972", value: "Israël"},
                    {key:"39", value: "Italie"},
                    {key:"1", value: "Jamaïque"},
                    {key:"81", value: "Japon"},

                    {key:"44", value: "Jersey"},
                    {key:"962", value: "Jordanie"},
                    {key:"7", value: "Kazakhstan"},
                    {key:"254", value: "Kazakhstan"},
                    {key:"996", value: "Kirghizistan"},
                    {key:"965", value: "Koweït"},
                    {key:"856", value: "Laos"},
                            
                    {key:"266", value: "Lesotho"},
                    {key:"371", value: "Lettonie"},
                    {key:"961", value: "Liban"},
                    {key:"231", value: "Liberia"},
                    {key:"218", value: "Libye"},
                    {key:"423", value: "Lechtenstein"},
                    {key:"370", value: "Lituanie"},
                    {key:"352", value: "Luxembourg"},
                    {key:"389", value: "Macédoine"},
                    {key:"261", value: "Madagascar"},
                                
                    {key:"60", value: "Malaisie"},
                    {key:"265", value: "Malawi"},
                    {key:"960", value: "Maldives"},
                    {key:"223", value: "Mali"},
                    {key:"356", value: "Malte"},
                    {key:"212", value: "Maroc"},
                    {key:"596", value: "Martinique"},
                                
                    {key:"222", value: "Mauritanie"},
                    {key:"52", value: "Mexique"},
                                
                    {key:"373", value: "Moldavie"},
                    {key:"377", value: "Monaco"},
                    {key:"976", value: "Mongolie"},
                                
                    {key:"382", value: "Monténégro"},
                    {key:"258", value: "Mozambique"},
                    {key:"264", value: "Namibie"},
                    {key:"977", value: "Nepal"},
                    {key:"505", value: "Nicaragua"},

                    {key:"227", value: "Niger"},
                    {key:"234", value: "Nigeria"},
                    {key:"47", value: "Norvège"},
                    {key:"64", value: "Nouvelle-Zélande"},
                    {key:"968", value: "Oman"},
                    {key:"256", value: "Ouganda"},
                    {key:"998", value: "Ouzbékistan"},
                    {key:"92", value: "Pakistan"},
                    {key:"970", value: "Palestine"},
                    {key:"507", value: "Panama"},
                    {key:"595", value: "Paraguay"},
                                
                    {key:"31", value: "Pays-Bas"},
                    {key:"51", value: "Pérou"},
                    {key:"51", value: "Philippines"},
                                
                    {key:"48", value: "Pologne"},
                    {key:"63", value: "Polynésie française"},
                    {key:"1", value: "Porto Rico"},
                    {key:"351", value: "Portugal"},
                    {key:"974", value: "Qatar"},
                    {key:"236", value: "République centrafricaine"},
                    {key:"243", value: "Congo (Kinshasa)"},
                    {key:"242", value: "Congo (Brazzaville)"},
                    {key:"1", value: "République Dominicaine"},
                    {key:"503", value: "République du Salvador"},
                    {key:"420", value: "République tchèque"},
                    {key:"40", value: "Roumanie"},
                    {key:"44", value: "Royaume-Uni"},
                    {key:"7", value: "Russie"},
                    {key:"250", value: "Rwanda"},
                    {key:"378", value: "Saint-Marin"},
                    {key:"239", value: "Sao Tomé-et-Principe"},
                                
                    {key:"221", value: "Sénégal"},
                    {key:"381", value: "Serbie"},
                    {key:"248", value: "Seychelles"},
                    {key:"232", value: "Sierra Leone"},
                    {key:"65", value: "Singapour"},
                            
                    {key:"421", value: "Slovaquie"},
                    {key:"386", value: "Slovénie"},
                    {key:"252", value: "Somalie"},
                    {key:"249", value: "Soudan"},
                    {key:"46", value: "Suède"},
                    {key:"41", value: "Suisse"},
                    {key:"94", value: "Sri Lanka"},
                    {key:"268", value: "Swaziland"},
                    {key:"963", value: "Syrie"},
                                
                    {key:"992", value: "Tadjikistan"},
                    {key:"255", value: "Tanzanie"},
                    {key:"235", value: "Tchad"},
                    {key:"66", value: "Thaïlande"},
                    {key:"886", value: "Taïwan"},
                                
                    {key:"228", value: "Togo"},
                    {key:"676", value: "Tonga"},
                                                    
                    {key:"216", value: "Tunisie"},
                    {key:"993", value: "Turkménistan"},
                    {key:"90", value: "Turquie"},
                    {key:"1", value: "Trinité-et-Tobago"},
                                                    
                    {key:"380", value: "Ukraine"},
                    {key:"598", value: "Uruguay"},
                    {key:"58", value: "Venezuela"},
                    {key:"84", value: "Vietnam"},
                                                    
                    {key:"967", value: "Yémen"},
                    {key:"260", value: "Zambie"},
                    {key:"263", value: "Zimbabwe"},
                    
                ]

            }
        },

        computed:{
            total() {
                
               if(!this.taux || this.devise === 'USD'){
                    return this.counter * this.productData['prix']
               }else{
                return this.counter * this.productData['prix'] * this.taux
               }

            },

        },
        methods: {
            increment() {
                this.counter++;
            },
            decrement() {
                if (this.counter > 0) {
                    this.counter--;
                }
            },

            multiplyVariables() {
                this.total = this.counter * productData['prix'];
            },

            com(){
                this.msgComm = true;
                setTimeout(() =>{
                    this.msgComm = false;
                }, 2000)
            },

            panier() {
                this.copieUr = true;
                setTimeout(() =>{
                    this.copieUr = false;
                }, 2000)
            }, 

            //decryptage url
            decryptId(encryptedId) {
                const decryptedText = CryptoJS.AES.decrypt(decodeURIComponent(encryptedId), 'myEncryptionKey').toString(CryptoJS.enc.Utf8);
                const [id, idShop] = decryptedText.split('-');
                return {
                    id: parseInt(id, 10),
                    idShop: parseInt(idShop, 10)
                };
            },
            
            //Envoi de la commande
            SendCommand: async function() {
                /*const nom = this.nom.split(' ')[0]
                const prenom = this.nom.split(' ')[1]*/

                //const hasSpace = this.nom.includes(" ")
                

                if(!this.province){
                    this.error = "Veuillez sélectionnez la ville"
                    return this.error
                }

                if (this.counter == 0) {
                    this.error = "La quantité ne peut pas être inférieure ou égale à 0"
                    this.nom = '';
                    this.adresse = '';
                    this.telephone = '';
                    this.province = '';
                    return
                }

                const phone = '+' + this.localisation + this.telephone.replace(/^0+(?=\d)/g, '')


                if (this.loading) {
                    return; // Sort de la méthode si la requête est déjà en cours
                }

                try {
                    const response = await this.$store.dispatch('sendcommdeclient', {
                        'produit0': this.productData['id'],
                        'nom': this.nom,
                        'telephone': phone,
                        'quantite0': this.counter,
                        'ville': this.province,
                        'adresse': this.adresse,
                        'nbr_produits': 1,
                        'type_livraison': 'à domicile'
                    })

                    if(response.data.status_code == 200){
                        this.sucess = true;
                        setTimeout(() =>{
                                this.succes = false;
                                location.reload();
                            }, 
                            5000)
                    }

                } catch (error) {
                    // Erreur
                    this.error = error.response.data.message.split(".")[0]
                    // this.errorAdresse = error.errors.adresse['0']
                } finally{
                    this.loading = false; // Désactive l'indicateur de chargement
                    this.nom = '';
                    this.adresse = '';
                    this.telephone = '';
                    this.province = '';
                }
            },

            //copier l'url
            copyPageUrl(){

                this.copieUr = true;
                

                const pageUrl = window.location.href

                // Vérifiez si vous avez des informations sur l'article (par exemple, productData)
                if (this.productData) {
                    // Mettez à jour les balises meta avec les informations de l'article actuel
                    const imageUrl = this.productData.images[0]['chemin'];
                    const description = this.productData['description'];

                    // Recherchez les balises meta dans le document
                    const ogImageMeta = document.querySelector('meta[property="og:image"]');
                    const ogDescriptionMeta = document.querySelector('meta[property="og:description"]');

                    // Mettez à jour les balises meta si elles existent
                    if (ogImageMeta) {
                        ogImageMeta.setAttribute('content', this.productData.images ? this.productData.images[0]['chemin'] : '');
                    }

                    if (ogDescriptionMeta) {
                        ogDescriptionMeta.setAttribute('content', this.productData.description);
                    }
                }

                navigator.clipboard.writeText(pageUrl)

                .then(()=>{
                    
                    setTimeout(() =>{
                        this.copieUr = false;
                    }, 2000)
                
                })
                .catch((error)=>{
                    
                });

                
                

            },

            location(){
                const data = this.itemProvince.find(id => id.id == this.province)
                const nom_pays = this.liste_pays.find(pays => pays.value == data.pays)
                this.localisation = nom_pays.key
                this.devise = data.monnaie.symbole
                this.taux = data.taux
            }
           
            
        },

        created() {
            const encryptedId = this.$route.params.encryptedId;
            const { id, idShop } = this.decryptId(encryptedId); 
            this.idPro = id;
            
            /*this.taux = localStorage.getItem('taux_ville')
            this.ville = localStorage.getItem('ville')
            this.devise = localStorage.getItem('devis_ville')            
            this.localisation = localStorage.getItem("localisation");
            */
            
        },

        mounted() {
            const getProduct = new ProductService()

            getProduct.getProduitById(this.idPro)
                .then(res =>{
                    this.productData = res.data['0']
                    //console.log(this.productData)
                })
                .catch(err =>{
                    
                });

            const province = new Province();
            
            province.getAllPro()
                .then(prov => {
                    this.itemProvince = prov.data;
                    const data = prov.data.find(ville => ville.nom == this.ville)
                     
                    if(!this.taux){
                        
                    }else{
                        if(data.taux != this.taux) {
                            localStorage.setItem("taux_ville", data.taux);
                        }
                    }
                })
                .catch((error) => {
                    //(error);
                });
        },
        
        

    }

</script>