<template>
    <div>
        <div class="w-screen h-screen overflow-x-hidden">
            <Header></Header>

            <div class="w-screen bg-white">
                <div class="w-auto px-36 pt-4 mb-10">
                    <div class="py-2" >
                        <div class="flex justify-between h-10 mt-4 mb-8">
                            <div class="flex justify-start">
                                <p class="text-3xl mr-4">Votre Panier</p>
                                <div class="py-2">
                                    <img src="@/images/dolly-flatbed-alt.png" class="w-6 h-6" alt="" srcset="">
                                </div>
                            </div>
                            <div class="justify-end mr-10">
                                <button class="w-full flex items-center text-white justify-center px-4 py-1 bg-yellow-500 space-x-2 rounded-md border hover:border-yellow-500 hover:bg-white hover:text-yellow-500 ">Commandez</button>  
                            </div>
                        </div>
                        <div class="grid grid-cols-5">
                            <div class="mb-3 bg-white shadow-md rounded-lg overflow-hidden h-96 w-60">
                                <router-link to="/detailProduit">
                                    <div class="h-60">
                                        <img src="@/images/H357f1f581a8d4ad297c0c2af26fdc80bW.jpg_250x250xz.webp" class="w-full h-60" alt="" srcset="">
                                    </div>
                                    <div class="h-20 p-1">
                                        <div class="text-left overflow-hidden text-ellipsis p-2 h-10 mb-2">
                                            <p class="line-clamp-2 leading-none">Casque de jeu audio multicolore réglable OEM, mains libres, casque sans fil P47 avec micro</p>
                                        </div>
                                        <div>
                                            <span class="font-bold">0,99 $US</span>
                                        </div>
                                    </div>
                                </router-link>
                                <div class="mt-4">
                                    <button class="w-full flex items-center text-white justify-center px-4 py-1 bg-red-500 space-x-2">Retirez</button>
                                </div>
                            </div>
                            <div class="mb-3 bg-white shadow-md rounded-lg overflow-hidden h-96 w-60">
                                <router-link to="/detailProduit">
                                    <div class="h-60">
                                        <img src="@/images/H357f1f581a8d4ad297c0c2af26fdc80bW.jpg_250x250xz.webp" class="w-full h-60" alt="" srcset="">
                                    </div>
                                    <div class="h-20 p-1">
                                        <div class="text-left overflow-hidden text-ellipsis p-2 h-10 mb-2">
                                            <p class="line-clamp-2 leading-none">Casque de jeu audio multicolore réglable OEM, mains libres, casque sans fil P47 avec micro</p>
                                        </div>
                                        <div>
                                            <span class="font-bold">0,99 $US</span>
                                        </div>
                                    </div>
                                </router-link>
                                <div class="mt-4">
                                    <button class="w-full flex items-center text-white justify-center px-4 py-1 bg-red-500 space-x-2">Retirez</button>
                                </div>
                            </div>
                            <div class="mb-3 bg-white shadow-md rounded-lg overflow-hidden h-96 w-60">
                                <router-link to="/detailProduit">
                                    <div class="h-60">
                                        <img src="@/images/H357f1f581a8d4ad297c0c2af26fdc80bW.jpg_250x250xz.webp" class="w-full h-60" alt="" srcset="">
                                    </div>
                                    <div class="h-20 p-1">
                                        <div class="text-left overflow-hidden text-ellipsis p-2 h-10 mb-2">
                                            <p class="line-clamp-2 leading-none">Casque de jeu audio multicolore réglable OEM, mains libres, casque sans fil P47 avec micro</p>
                                        </div>
                                        <div>
                                            <span class="font-bold">0,99 $US</span>
                                        </div>
                                    </div>
                                </router-link>
                                <div class="mt-4">
                                    <button class="w-full flex items-center text-white justify-center px-4 py-1 bg-red-500 space-x-2">Retirez</button>
                                </div>
                            </div>
                            <div class="mb-3 bg-white shadow-md rounded-lg overflow-hidden h-96 w-60">
                                <router-link to="/detailProduit">
                                    <div class="h-60">
                                        <img src="@/images/H357f1f581a8d4ad297c0c2af26fdc80bW.jpg_250x250xz.webp" class="w-full h-60" alt="" srcset="">
                                    </div>
                                    <div class="h-20 p-1">
                                        <div class="text-left overflow-hidden text-ellipsis p-2 h-10 mb-2">
                                            <p class="line-clamp-2 leading-none">Casque de jeu audio multicolore réglable OEM, mains libres, casque sans fil P47 avec micro</p>
                                        </div>
                                        <div>
                                            <span class="font-bold">0,99 $US</span>
                                        </div>
                                    </div>
                                </router-link>
                                <div class="mt-4">
                                    <button class="w-full flex items-center text-white justify-center px-4 py-1 bg-red-500 space-x-2">Retirez</button>
                                </div>
                            </div>
                            <div class="mb-3 bg-white shadow-md rounded-lg overflow-hidden h-96 w-60">
                                <router-link to="/detailProduit">
                                    <div class="h-60">
                                        <img src="@/images/H357f1f581a8d4ad297c0c2af26fdc80bW.jpg_250x250xz.webp" class="w-full h-60" alt="" srcset="">
                                    </div>
                                    <div class="h-20 p-1">
                                        <div class="text-left overflow-hidden text-ellipsis p-2 h-10 mb-2">
                                            <p class="line-clamp-2 leading-none">Casque de jeu audio multicolore réglable OEM, mains libres, casque sans fil P47 avec micro</p>
                                        </div>
                                        <div>
                                            <span class="font-bold">0,99 $US</span>
                                        </div>
                                    </div>
                                </router-link>
                                <div class="mt-4">
                                    <button class="w-full flex items-center text-white justify-center px-4 py-1 bg-red-500 space-x-2">Retirez</button>
                                </div>
                            </div>
                            <div class="mb-3 bg-white shadow-md rounded-lg overflow-hidden h-96 w-60">
                                <router-link to="/detailProduit">
                                    <div class="h-60">
                                        <img src="@/images/H357f1f581a8d4ad297c0c2af26fdc80bW.jpg_250x250xz.webp" class="w-full h-60" alt="" srcset="">
                                    </div>
                                    <div class="h-20 p-1">
                                        <div class="text-left overflow-hidden text-ellipsis p-2 h-10 mb-2">
                                            <p class="line-clamp-2 leading-none">Casque de jeu audio multicolore réglable OEM, mains libres, casque sans fil P47 avec micro</p>
                                        </div>
                                        <div>
                                            <span class="font-bold">0,99 $US</span>
                                        </div>
                                    </div>
                                </router-link>
                                <div class="mt-4">
                                    <button class="w-full flex items-center text-white justify-center px-4 py-1 bg-red-500 space-x-2">Retirez</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Header from '@/components/layout/Header.vue'

    export default{
        name: 'Panier',
        mounted(){
            var div = document.getElementById('panier');
            div.style.display = 'none';
        },

        components:{
            Header
        }
    }

</script>