<template>
    <div>
        <div class="flex items-center justify-center h-screen">
            <div class="bg-gray-200 h-2/3">
                <div class="flex h-full flex-col md:flex-row">
                    <div class="w-full md:w-2/4 md:order-first" style="background: linear-gradient(to bottom, #DEA20A , #FBF7EE );">
                        <div class="px-10 pt-14">
                            <div class="flex items-center justify-center">
                                <img src="@/images/LOGO_GEN_PNG_BLANC.png" class="w-72" alt="">
                            </div>
                            <div class="flex items-center justify-center bg-white py-10 rounded-lg">
                                <p class="text-3xl font-bold uppercase drop-shadow-md">Ravitaillement stock</p>
                            </div>
                        </div>
                    </div>
                    <div class="w-full pt-10 md:w-2/4 px-4 md:px-10 pb-20 md:pb-0 md:pb-10 md:pt-10 text-left md:mt-0 md:overflow-y-auto" style="background-color: #FBF7EE;">
                        <form action="" @submit.prevent="sendRavi">
                            <div class="mb-7" v-for="(item, index) in items" :key="index">
                                <div class="mb-3">
                                    <div class="pl-2 mb-2">
                                        <label for="password" class="text-xl font-bold">Produit{{index + 1}}</label>
                                    </div>
                                    <div>
                                        <select name="" id="" v-model="item.produit" class="block w-full px-4 py-1.5 border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                                            <option value="" disabled selected hidden></option>
                                            <option :value="items.id" v-for="(items, index) in itemProduit" :key="index">{{ items.nom }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <div class="pl-2 mb-2">
                                        <label for="password" class="text-xl font-bold">Coût unitaire{{index + 1}} ($)</label>
                                    </div>
                                    <div>
                                        <input type="text" name="" v-model="item.cout" id="" class="px-4 py-1 w-full border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <div class="pl-2 mb-2">
                                        <label for="password" class="text-xl font-bold">Quantité{{index + 1}}</label>
                                    </div>
                                    <div>
                                        <input type="text" v-model="item.quantite" name="" id="" class="px-4 py-1 w-full border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <div class="pl-2 mb-2">
                                        <label for="password" class="text-xl font-bold">Date expiration{{index + 1}}</label>
                                    </div>
                                    <div>
                                        <input type="date" name="" v-model="item.dateexpir" id="" class="px-4 py-1 w-full border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                                    </div>
                                </div>
                                <div class="flex w-full justify-between">
                                    <div @click="removeItem(index)" class="px-4 py-2 rounded-lg cursor-pointer text-center text-sm text-white bg-red-500 shadow-lg font-bold ">Retirer</div>
                                </div>
                            </div>
                            <div class="text-red-500" v-if="erreur">
                                {{ erreur }}
                            </div>
                            <div class="flex justify-between mb-5">
                                <div @click="addInput" class="px-4 py-2 rounded-lg cursor-pointer text-center text-2xl text-black bg-white shadow-lg font-bold ">Ajouter</div>
                                <button type="submit" class="px-4 py-2 rounded-lg text-white font-bold text-2xl" style="background-color: #DEA20A;">Enregistrer</button>
                            </div>
                        
                        </form>                        
                        <div class="flex hidden items-center justify-center">
                            <p class="font-bold text-lg">4 produits ajoutés</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import Produit from '@/_services/produit.services'
import CryptoJS from 'crypto-js'

export default{
    name: 'ravitaillementId',

    data(){
        return {
            idRav: null,
            idShop: null,
            itemProduit: [],
            encryptionKey: 'myEncryptionKey',
            erreur: null,
            items: []
        }
    },

    created(){
    },

    mounted(){
        this.addInput();

        const encryptedId = this.$route.params.encryptedId;
        const { id, idShop } = this.decryptId(encryptedId);

        this.idRav = id;
        this.idShop = idShop
        
        
        const produit = new Produit();
            produit.getAllProduit()
                .then(product => {
                    this.itemProduit = product.data
                    //(this.itemProduit)
                })
                .catch(error => {
                    //(error.product.data.message)
                })
    },

    methods: {
        //decryptage url
        decryptId(encryptedId) {
            const decryptedText = CryptoJS.AES.decrypt(decodeURIComponent(encryptedId), 'myEncryptionKey').toString(CryptoJS.enc.Utf8);
            const [id, idShop] = decryptedText.split('-');
            return {
                id: parseInt(id, 10),
                idShop: parseInt(idShop, 10)
            };
        },

        addInput() {
            this.items.push({
                produit: "",
                quantite: "",
                cout: "",
                dateExpir: ""
            });
        },

        removeItem(index){
            if(this.items.length > 1){
                this.items.splice(index, 1)
            }
        },

        validateNumberInput() {
                this.quantites = parseInt(this.quantites);
                if (isNaN(this.quantites)) {
                    this.quantites = null; // Réinitialiser la valeur si ce n'est pas un nombre valide
                }
            },

        sendRavi(){

            const produitsValues = this.items.map(item => parseInt(item.produit));
            const quantitesValues = this.items.map(item => parseInt(item.quantite));
            const dateExpirsValues = this.items.map(item => new Date(item.dateexpir));
            const coutsValues = this.items.map(item => parseInt(item.cout));
            

            const formattedDates = dateExpirsValues.reduce((acc, date, i) => {
                const formattedDate = new Date(date).toISOString().split('T')[0];
                acc["date_expiration" + i] = formattedDate;
                return acc;
            }, {});

            if (quantitesValues.some(isNaN)) {
                this.quantiteError = true;
            }
            
            this.$store.dispatch('sendDataRavi',{

                ...produitsValues.reduce((acc, prod, i) => {
                    acc["produit" + i] = prod;
                    return acc;
                }, {}),

                ...quantitesValues.reduce((acc, stock, i) => {
                    acc["stock_initial" + i] = stock;
                    return acc;
                },{}),

                ...coutsValues.reduce((acc, prix, i) => {
                    acc["prix_acquisition" + i] = prix;
                    return acc;
                }, {}),

                ...formattedDates,
                id_commande : parseInt(this.idRav),
                boutique : parseInt(this.idShop),
                nbr_produits : produitsValues.length
            })
            .then(res =>{
                if(res.status_code === 200) {
                        this.$router.push('/hitoryRavi')
                    }else{
                        this.erreur = res.message;
                    }
            })
            .catch(err =>{
                ////.log(err);
                this.erreur = err.response.data.message;
            })
            .finally(() =>{
                this.items = this.items.map(item =>({
                    produit: "",
                    quantite: "",
                    cout: "",
                    dateExpir: ""
                }));
            })

        }
    },
}

</script>