<template>
    <div>
        <Header></Header>
        <!--Affichage de message de successfull-->
        <div class="px-4 flex justify-center" v-show="succes">
            <p class="bg-green-400 py-2 text-white">{{succes}}</p>
        </div>
        <!--Affichage de message de successfull-->
        <!--Affichage de message de error-->
        <div class="px-4 flex justify-center" v-show="erreur">
            <p class="bg-red-600 px-4 py-2 text-white">{{erreur}}</p>
        </div>
        <!--Affichage de message de error-->
        <div class="flex items-center justify-center pt-14">
            
            <div class=" p-8 w-96 rounded shadow-md" v-if="getUser" style="background-color: #FBF7EE;">
                <!-- Contenu du profil -->
                <div class=" flex items-center justify-center" >
                    <div class="w-24 h-24 flex items-center justify-center rounded-full border" style="background-color: #DEA20A;">
                        <span class="text-center uppercase text-2xl inline-block text-white align-middle">{{ name && name.charAt(0) }}{{ firstname && firstname.charAt(0) }}</span>
                    </div>
                </div>
                <div class="mt-4 flex justify-between">
                    <div class="font-bold">
                        <div class="flex">
                            <p class="mr-2">Nom : </p> <p> {{ getUser.nom }}</p>
                        </div>
                        <div class="flex">
                            <p class="mr-2">Post-nom : </p> <p> {{ getUser.postnom }}</p>
                        </div>
                        <div class="flex">
                            <p class="mr-2">Prénom : </p> <p> {{ getUser.prenom }}</p>
                        </div>
                    </div>
                    <button @click="modifier(choix='infos')" class="px-4 py-2 mt-2 bg-blue-500 text-white rounded">Modifier</button>
                </div>
                <div class="mt-8">
                    <div class="mb-4 flex justify-between">
                        <div class="flex font-bold">
                            <p class="mr-2">Téléphone : </p> <p> {{ getUser.telephone }}</p>
                        </div>
                        <button @click="modifier(choix='contact')" class="px-4 py-2 mt-2 bg-blue-500 text-white rounded">Modifier</button>
                    </div>
                <div class="flex justify-start">
                    <button @click="modifier(choix='secu')" class="px-4 py-2 mt-2 bg-blue-500 text-white rounded">Modifier Mot de passe</button>
                </div>
                <div v-if="roles === 'admin'" class="flex justify-start">
                    <button @click="modifier(choix='export')" class="px-4 py-2 mt-2 bg-blue-500 text-white rounded">Export Excel</button>
                </div>
                </div>
            </div>
        </div>
        <!--Affiche si il veut change le données du user-->
        <div v-show="modal" class="fixed inset-0 flex items-center justify-center">
            <div class="bg-white shadow-md rounded-lg max-w-sm mx-auto z-50">
                <div class="flex justify-center mt-4">
                    <h2 class="text-lg font-bold mb-4" v-if="getChoix === 'export'">Export Fichier Excel</h2>
                    <h2 class="text-lg font-bold mb-4" v-else>Modification</h2>
                </div>
                <div v-if="getChoix === 'infos'">
                    <div class="mr-5 mb-2">
                        <div class="pl-2 mb-2 text-left">
                            <label class="text-sm font-bold">Nom</label>
                        </div>
                        <div>
                            <input type="text" v-model="nom" :placeholder="getUser.nom" name="" id="" class="px-4 py-1 w-full border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                        </div>
                    </div>
                    <div class="mr-5 mb-2">
                        <div class="pl-2 mb-2 text-left">
                            <label class="text-sm font-bold">Prénom</label>
                        </div>
                        <div>
                            <input type="text" v-model="prenom" :placeholder="getUser.prenom" name="" id="" class="px-4 py-1 w-full border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                        </div>
                    </div>
                </div>
                <div v-if="getChoix === 'contact'">
                    <div class="mr-5 mb-2">
                        <div class="pl-2 mb-2 text-left">
                            <label class="text-sm font-bold">Téléphone</label>
                        </div>
                        <div>
                            <input type="text" v-model="telephone" :placeholder="getUser.telephone" name="" id="" class="px-4 py-1 w-full border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                        </div>
                    </div>
                </div>
                <div v-if="getChoix === 'secu'">
                    <div class="mr-5 mb-2">
                        <div class="pl-2 mb-2 text-left">
                            <label class="text-sm font-bold">Ancien mot de pass</label>
                        </div>
                        <div>
                            <input type="password" v-model="password" name="" id="" class="px-4 py-1 w-full border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                        </div>
                    </div>
                    <div class="mr-5 mb-2">
                        <div class="pl-2 mb-2 text-left">
                            <label class="text-sm font-bold">Nouveau mot de pass</label>
                        </div>
                        <div>
                            <input type="password" v-model="newpassword" name="" id="" class="px-4 py-1 w-full border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                        </div>
                    </div>
                </div>
                <div v-if="getChoix === 'export'" class="p-4">
                    <div class="grid grid-cols-2">
                        <div class="flex mb-1.5">
                            <div class="w-auto mr-1.5">
                                <label for="" class="font-bold">Ag</label>
                            </div>
                            <div class="w-3/4">
                                <select name="" id="" v-model="filters.agent" class="block w-full px-4 py-1.5 rounded-lg " style="background-color: #FBF7EE;">
                                    <option :value="agent.id" v-for="(agent, index) in itemBtAg" :key="index">{{ agent.nom }} {{ agent.prenom }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="flex">
                            <div class="w-auto mr-1.5">
                                <label for="" class="font-bold">Mana</label>
                            </div>
                            <div class="w-3/4">
                                <select name="" id="" v-model="filters.manager" class="block w-full px-4 py-1.5 rounded-lg " style="background-color: #FBF7EE;">
                                    <option :value="item.manager['id']" v-for="(item, index) in itemBtM" :key="index">{{ item.nom }} {{ item.prenom }}</option>
                                </select>
                            </div>
                        </div>
                        <!--div class="flex">
                            <div class="w-auto mr-1.5">
                                <label for="" class="font-bold">Gest</label>
                            </div>
                            <div class="w-3/4">
                                <select name="" id="" v-model="filters.gestionnaire" class="block w-full px-4 py-1.5 rounded-lg " style="background-color: #FBF7EE;">
                                    <option :value="agent.id" v-for="(agent, index) in itemBtAg" :key="index">{{ agent.nom }} {{ agent.prenom }}</option>
                                </select>
                            </div>
                        </div-->
                        <div class="flex mb-1.5">
                            <div class="w-auto mr-1.5">
                                <label for="" class="font-bold">Liv</label>
                            </div>
                            <div class="w-3/4">
                                <select name="" id="" v-model="filters.livreur" class="block w-full px-4 py-1.5 rounded-lg " style="background-color: #FBF7EE;">
                                    <option :value="item.id" v-for="(item, index) in listLiv" :key="index">{{ item.user.nom }} {{ item.user.prenom }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="flex">
                            <div class="w-auto mr-1.5">
                                <label for="" class="font-bold">Du</label>
                            </div>
                            <div class="w-3/4">
                                <input type="date" name="" v-model="filters.debut"  id="" placeholder="+24***" class="block border w-62 px-1" style="background-color: #FBF7EE;">
                            </div>
                        </div>
                        <div class="flex">
                            <div class="w-auto mr-1.5">
                                <label for="" class="font-bold">Au</label>
                            </div>
                            <div class="w-3/4">
                                <input type="date" name="" v-model="filters.fin" id="" placeholder="+24***" class="block border w-62 px-1" style="background-color: #FBF7EE;">
                            </div>
                        </div>
                        <div class="flex w-full mb-4">
                                    <div class="w-1/4 mr-4">
                                        <label for="" class="font-bold">État</label>
                                    </div>
                                    <div class="w-3/4">
                                        <select name="" id="" v-model="filters.statut" class="block w-full px-4 py-1.5 rounded-lg " style="background-color: #FBF7EE;">
                                            <option value="entrant">Entrant</option>
                                            <option value="en cours de traitement">En cours de traitement</option>
                                            <option value="en livraison">En livraison</option>
                                            <option value="livrée">Livrer</option>
                                            <option value="en vérification stock">En vérification</option>
                                            <!--option value="à domicile">Réporter</option>
                                            <option value="à domicile">Injoignable</option-->
                                        </select>
                                    </div>
                                </div>
                    </div>
                    <div class="flex justify-between p-6">
                        <button @click="close" id="closeModalButton" class="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                            Non
                        </button>
                        <button @click="sendComData(getChoix)" id="closeModalButton" class="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                            Export
                        </button>
                    </div>
                </div>
                <div v-if="getChoix !== 'export'" class="flex justify-between">
                    <button @click="close" id="closeModalButton" class="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                        Non
                    </button>
                    <button @click="sendComData(getChoix)" id="closeModalButton" class="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                        Modifier
                    </button>
                </div>
                
            </div>
        </div>
        <Footer></Footer>
    </div>
  </template>
<script>
import Header from '@/components/layout/Header.vue'
import Footer from '@/components/layout/Footer.vue'
import User from '@/_services/usersComptes.services'
import Axios from '@/_services/caller.services'
import * as XLSX from 'xlsx';
import {mapActions} from 'vuex'
import UserVueCompte from '@/_services/usersComptes.services'



export default{

    name: 'configuration',

    components:{
        Header,
        Footer,
    },
    data(){
        return {
            nom: "",
            prenom: "",
            phone: "",
            password: "",
            newpassword: "",
            telephone: "",
            getUser: [],
            idUser: null,
            modal: false,
            getChoix: null,
            succes: null,
            erreur: null,
            name: null,
            firstname: null,
            token: null,
            roles: null,
            filters: {
                debut:'',
                fin: '',
                agent:'',
                livreur:'',
                manager:'',
                statut: '',
            },

            itemBtM: [],
            itemBtAg: [],
            listLiv: []
        };
    },

    mounted(){

        const user = new User();
        user.getUsersAlls()
            .then(users => {
                this.getUser = users.data.find(user => user.id == this.idUser)
                this.name = this.getUser.nom
                this.firstname= this.getUser.prenom
                this.nom = this.getUser.nom
                this.prenom = this.getUser.prenom
                this.telephone = this.getUser.telephone
            })


    },
    methods:{

        //liste livreur
        ...mapActions(['fechDataLivrer']),
            fetchData(){
                this.fechDataLivrer()
                    .then(response => {
                        this.listLiv = response.data;
                        ////.log(this.listLiv)
                    })
                    .catch(error => {
                        // Une erreur s'est produite lors de la récupération des données
                        //.error(error);
                    });
            },

        //Liste de manager
        ...mapActions(['viewprovince']),
            ListMana(){

                this.viewprovince()
                    .then(response =>{
                        this.itemBtM = response.data
                        ////.log(this.itemBtM)
                    })
                    .catch(error => {
                        //.error(error);
                    });

            },

        //Liste de agent
        ...mapActions(['getAgent']),
            ListAgent(){

                this.getAgent()
                    .then(response =>{
                        this.itemBtAg = response.data.data
                            //console.log(this.itemBtAg)
                    })
                    .catch(error => {
                        //.error(error);
                    });

            },

        modifier(choix){
            this.modal = true
            this.getChoix = choix
        },

        close(){
            this.modal = false
            this.nom = ""
            this.prenom = ""
            this.telephone = ""
            this.password = ""
            this.newpassword = ""
        },

        async sendComData(choix){

            if(choix === 'infos'){

                this.$store.dispatch('Info',{
                    nom: this.nom,
                    prenom: this.prenom
                })
                .then(res =>{
                    if(res.status === 200){
                        this.modal = false;
                        this.succes = 'Nom & Prenom modifier avec Succes ' + res.data.nom + ' ' + res.data.prenom
                        setTimeout(()=>{
                            this.succes = false;
                            location.reload()
                        }, 5000)
                    }
                    
                })
                .catch(err =>{
                    this.erreur = err.response.data.message
                    this.modal = false
                    setTimeout(()=>{
                            this.erreur = false;
                            location.reload()
                        }, 5000)
                });

            }else if(choix === 'contact'){
                this.$store.dispatch('contact',{
                    telephone: this.telephone,
                })
                .then(res =>{
                    if(res.status === 200){
                        this.modal = false;
                        this.succes = 'Numéro de téléphone modifier avec Succes ' + res.data.telephone
                        setTimeout(()=>{
                            this.succes = false;
                            location.reload()
                        }, 5000)
                    }
                    
                })
                .catch(err =>{
                    //.log(err)
                    this.erreur = err.response.data.message
                    this.modal = false
                    setTimeout(()=>{
                            this.erreur = false;
                            location.reload()
                        }, 5000)
                });
            }else if(choix === 'secu'){
                this.$store.dispatch('Securite',{
                    password: this.password,
                    nouveau_password: this.newpassword
                })
                .then(res =>{
                    if(res.status === 200){
                        this.modal = false;
                        this.succes = 'Mot de passe modifier avec Succes'
                        setTimeout(()=>{
                            this.succes = false;
                            location.reload()
                        }, 5000)
                    }
                    
                })
                .catch(err =>{
                    this.erreur = err.response.data.message
                    this.modal = false
                    setTimeout(()=>{
                            this.erreur = false;
                            location.reload()
                        }, 5000)
                });
            }else if(choix === 'export'){

                const headers = {
                    'Authorization': `Bearer ${this.token}`,
                }

                try{
                    const response = await Axios.get(
                        '/api/commandes/excel',
                        { 
                            params: this.filters,
                            headers
                        }
                    )

                    const tableData = response.data.data;

                    // Créer une feuille de calcul
                    const workbook = XLSX.utils.book_new();

                    // Convertir les données en format compatible avec XLSX
                    const worksheetData = XLSX.utils.json_to_sheet(tableData);

                    // Ajouter la feuille de calcul au classeur
                    XLSX.utils.book_append_sheet(workbook, worksheetData, 'Nom de la feuille');

                    // Générer le fichier Excel
                    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

                    // Créer un objet Blob pour le téléchargement
                    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                    // Générer un nom de fichier
                    const fileName = 'Export_Liste_Commandes.xlsx';

                    // Créer un lien de téléchargement
                    const downloadLink = document.createElement('a');
                    downloadLink.href = window.URL.createObjectURL(blob);
                    downloadLink.download = fileName;

                    // Ajouter le lien de téléchargement à la page
                    document.body.appendChild(downloadLink);

                    // Simuler un clic sur le lien de téléchargement
                    downloadLink.click();

                    // Supprimer le lien de téléchargement de la page
                    document.body.removeChild(downloadLink);
                    
                }catch(e) {
                    console.error('Erreur lors de la récupération des données:', e);
                    alert('Une erreur s\'est produite lors de la récupération des données.');
                }finally {
                    this.filters = {
                        debut:'',
                        fin: '',
                        agent:'',
                        livreur:'',
                        manager:'',
                    },
                    this.modal = false
                }

            }

        }
        
    },

    created(){
        this.idUser = localStorage.getItem("IdUser")
        this.token =  localStorage.getItem('authToken');
        this.roles = localStorage.getItem('role');

        if(this.roles === 'admin'){
            this.fetchData()
            this.ListMana()
            this.ListAgent()
            
        }        

    },

    /*mounted(){
            const Users = new UserVueCompte();
            Users.getUsersAlls() 
            .then(usercompte => {

                if(this.roles === 'admin'){
                    this.itemUsersAgent = usercompte.data.filter(user => {
                    const roles = user.roles.map(role => role.name);
                    
                        return roles.includes("agent")
                    });

                    console.log('agent', this.itemUsersAgent)

                }

            }).catch(error => {
                //(error)
            });
        }*/
    
}

</script>