<template>
    <div>
        <div class="flex items-center justify-center h-screen">
            <div class="bg-gray-200 w-3/5 h-3/4 mx-auto my-auto">
                <div class="flex h-full">
                    <div class="w-2/4" style="background: linear-gradient(to bottom, #DEA20A , #FBF7EE );">
                        <div class="px-10 pt-14">
                            <div class="flex items-center justify-center">
                                <img src="@/images/LOGO_GEN_PNG_BLANC.png" class="w-72" alt="">
                            </div>
                            <div class="flex items-center justify-center bg-white py-10 rounded-lg">
                                <p class="text-3xl font-bold uppercase drop-shadow-md">Configuration</p>
                            </div>
                        </div>
                    </div>
                    <div class="w-2/4 px-10 pt-10 text-left" style="background-color: #FBF7EE;">
                        <div class="mb-5">
                            <div class="text-center font-bold mb-3">
                                <p>Informations personnelles</p>
                            </div>
                            <div class="flex justify-between mb-5">
                                <div>
                                    <div class="mb-2">
                                        <label for="phone" class="text-lg font-bold">Nom</label>
                                    </div>
                                    <input type="text" name="" id="" class="px-4 py-1 w-40 border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                                </div>
                                <div>
                                    <div class="mb-2">
                                        <label for="phone" class="text-lg font-bold">Prenom</label>
                                    </div>
                                    <input type="text" name="" id="" class="px-4 py-1 w-40 border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                                </div>
                            </div>  
                            <div class="flex items-center justify-center">
                                <button type="submit" class="px-4 py-1 w-40 rounded-lg text-white font-bold text-lg" style="background-color: #DEA20A;">Modifier</button>
                            </div>                         
                        </div>
                        <div class="mb-5">
                            <div class="text-center font-bold mb-3">
                                <p>Contact & Adresse</p>
                            </div>
                            <div class="flex justify-between mb-5">
                                <div>
                                    <div class="mb-2">
                                        <label for="phone" class="text-lg font-bold">Téléphone</label>
                                    </div>
                                    <input type="text" name="" id="" class="px-4 py-1 w-40 border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                                </div>
                                <div>
                                    <div class="mb-2">
                                        <label for="phone" class="text-lg font-bold">Adresse</label>
                                    </div>
                                    <input type="text" name="" id="" class="px-4 py-1 w-40 border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                                </div>
                            </div>  
                            <div class="flex items-center justify-center">
                                <button type="submit" class="px-4 py-1 w-40 rounded-lg text-white font-bold text-lg" style="background-color: #DEA20A;">Modifier</button>
                            </div>                         
                        </div>
                        <div class="mb-5">
                            <div class="text-center font-bold mb-3">
                                <p>Mot de passe</p>
                            </div>
                            <div class="flex justify-between mb-5">
                                <div>
                                    <div class="mb-2">
                                        <label for="phone" class="text-lg font-bold">Ancien</label>
                                    </div>
                                    <input type="text" name="" id="" class="px-4 py-1 w-40 border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                                </div>
                                <div>
                                    <div class="mb-2">
                                        <label for="phone" class="text-lg font-bold">Nouveau</label>
                                    </div>
                                    <input type="text" name="" id="" class="px-4 py-1 w-40 border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                                </div>
                            </div>  
                            <div class="flex items-center justify-center">
                                <button type="submit" class="px-4 py-1 w-40 rounded-lg text-white font-bold text-lg" style="background-color: #DEA20A;">Modifier</button>
                            </div>                         
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>

export default{
    name: 'inscriptionclient',
}

</script>