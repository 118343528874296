<template>
  <header class="shadow w-screen mb-0 md:mb-0 sm:28 z-10 bg-white">
    <div class="w-full">
      <div class="flex pl-0 md:pl-1/6   w-full">
        <router-link to="/">
          <div class="mt-2 sm:mt-0 top-3   relative  sm:top-2 md:top-0 md:bottom-1 bottom-0">
            <img src="@/images/LOGO_GEN.png" class="w-24 sm:w-32 md:w-44" alt="">
          </div>
        </router-link>
        <div class="w-full">
          <div class="h-24 md:border-b w-full">
            <div class="flex  h-full py-6 ">
               <div class="flex mr-5 w-full"> 
                <form action="" method="get" class="flex w-full">
                  <input type="text" class="h-12 border-solid border-2 border-yellow-500 pl-6 w-3/4 text-xs sm:text-sm rounded-l-full focus:outline-none focus:border-yellow-550" placeholder="Qu'est-ce que vous recherchez..." name="" id="">
                  <input type="submit" class="px-2 rounded-r-full text-white text-xs sm:text-sm h-12 w-max" style="background-color: #DEA20A;" value="Rechercher">
                </form>
               </div>
               <div class="m-0" v-show="roles == null && roles != null">
                <router-view/>
               </div>
               
               
               <div class=" relative top-3 flex flex-col items-center md:hidden w-1/4 mr-2 cursor-pointer" @click="showSmallMenu">
                  <div class="h-1 w-8 bg-yellow-500 mb-1.5 rounded-lg"></div>
                  <div class="h-1 w-8 bg-yellow-500 mx-2 mb-1.5 rounded-lg"></div>
                  <div class="h-1 w-8 bg-yellow-500 rounded-lg"></div>
                </div>

                

            </div>

            
          </div>

        </div>

        
      </div>
      <div class="md:flex  md:justify-center" :class="{ 'block': !isHidden, 'hidden': isHidden }">
            <div class="py-2 px-4 border-r flex justify-center" v-if="roles == null" :class="navItemClass('')">
              <router-link to="/">
                <p>Accueil</p>
              </router-link>
            </div>
            <div class="px-4 py-2 border-r border-l flex justify-center" :class="navItemClass('listecommande')">
              <router-link to="/listecommande">
                <p>Commandes</p>
              </router-link>
            </div>
            <div class="px-4 py-2 border-r flex justify-center" v-if="roles !== null" :class="navItemClass('dashboard')">
              <router-link to="/dashboard">
                <p>Dashbord</p>
              </router-link>
            </div>
            <div class="px-4 py-2 border-r flex justify-center" v-if="roles == 'manager'" :class="navItemClass('stockShop')">
              <router-link to="/stockShop">
                <div class="flex">
                  <p class="mr-1">Stock</p>
                  <p v-if="roles == 'manager'">Boutique</p>
                  <!--p v-if="roles == 'admin' || roles == 'gestionnaire'">Livreur</p-->
                </div>
              </router-link>
            </div>
            <div class="cursor-pointer px-4 py-2 border-r flex justify-center" v-if="roles !== null" @click="showHoverDiv"  :class="navItemClass('gestion')">
              <p class="mr-2">Gestion </p>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 relative top-1" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 0 1 1.414 0L10 10.586l3.293-3.293a1 1 0 1 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4a1 1 0 0 1 0-1.414z" clip-rule="evenodd" />
              </svg>              
            </div>
            <div class="px-4 py-2 border-r flex justify-center" v-if="roles == 'admin' || roles == 'manager' " :class="navItemClass('ListRaviBoutique')">
              <router-link to="/ListRaviBoutique">
                <p v-if="roles == 'manager'">Ravitaillement Boutique</p>
                <p v-if="roles == 'admin'">Ravitaillement</p>
              </router-link>
            </div>
            <div class="px-4 py-2 border-r flex justify-center" v-if="roles != 'manager' && roles != null && roles != 'livreur'" :class="navItemClass('NewCommand')">
              <router-link to="/newcommandancient">
                <p>New Commande</p>
              </router-link>
            </div>
            <div class="px-4 py-2 border-r flex justify-center" v-if="roles == null" :class="navItemClass('newsletter')">
              <router-link to="/newsletter">
                <p>Newsletter</p>
              </router-link>
            </div>
            <div class="px-4 py-2  border-r flex justify-center" v-if="roles == null">
              <router-link to="" class="flex ">
                <p class="mr-1 uppercase">à</p>
                <p>propos</p>
              </router-link>
            </div>
            <div class="px-4 py-2 border-r flex justify-center" v-if="roles != null">
              <button class="relative bottom-1.5" @click="logout">Déconnexion</button>
            </div>

            <div class="flex px-4 py-2 flex border-r justify-center">
                    <div class="hidden" id="panier" v-if="roles != null">
                      <router-link to="/panier">
                        <div class="flex p-2">
                          <img class="" src="@/images/shopping-cart.png" alt="" srcset="">
                          <div class="w-6 h-6 text-white rounded-full" style="background-color: #25A4EC;">
                            1
                          </div>
                        </div>
                      </router-link>
                    </div>
                    <div class=" flex p-2 relative bottom-1" id="noti" v-if="roles != null">
                      <router-link to="/notification">
                        <div class="">
                          <img class="w-6" src="@/images/cowbell.png" alt="" srcset="">
                          <div v-show="count" class="w-6 h-6 absolute text-white rounded-full" style="background-color: #25A4EC; left: 20px; bottom: 15px">
                              <p v-if="count !== 0">{{ count }}</p>
                          </div>
                        </div>
                      </router-link>
                    </div>
                    <div class="flex relative bottom-1" id="" v-if="roles != null">
                      <router-link to="/configuration">
                        <div class="flex  p-2">
                          <img class="w-6" src="@/images/user-gear.png" alt="" srcset="">
                        </div>
                      </router-link>
                    </div>
               </div>
          </div>   
          
          
              
    </div> 

    
  </header>
  <div class="w-full absolute top-0">
    <div class="z-10 block   py-4 px-9 text-left bg-white shadow-2xl w-full" v-show="hoverVisible" v-if="menuHider" style="left: 43%">
      <div class=" flex w-full justify-end">
        
          <button class="text-left my-1.5  font-bold hover:text-blue-600" @click="hideHoverDiv">
            X
          </button>

        </div>
      <button class="text-left my-1.5 w-full hover:text-blue-600" v-if="roles == 'admin' || roles == 'gestionnaire'">
        <router-link to="/produit">
          PRODUITS
        </router-link>
      </button>
      <button class="text-left my-1.5 w-full hover:text-blue-600" v-if="roles == 'admin'">
        <router-link to="/category">
          CATEGORIES
        </router-link>  
      </button>
      <button class="text-left my-1.5 w-full hover:text-blue-600" v-if="roles === 'admin' ">
        <router-link to="/Ventes">
          VENTES
        </router-link>  
      </button>
      <button class="text-left my-1.5 w-full hover:text-blue-600" v-if="roles == 'admin'">
        <router-link to="/shopVue">
          BOUTIQUE
        </router-link>
      </button>
      <button class="text-left my-1.5 w-full hover:text-blue-600" v-if="roles == 'admin' || roles == 'gestionnaire' ">
        <router-link to="/hitoryRavi">
          HISTORIQUE RAVITAILLEMENT
        </router-link>
      </button>
      <button class="text-left my-1.5 w-full hover:text-blue-600" v-if="roles == 'admin' || roles == 'gestionnaire' || roles == 'manager'">
        <router-link to="/listlivrer">
          LIVREURS
        </router-link>
      </button>
      <button class="text-left my-1.5 w-full hover:text-blue-600" v-if="roles == 'admin'">
        <router-link to="/province">
          VILLE
        </router-link>
      </button>
      <button class="text-left my-1.5 w-full hover:text-blue-600" v-if="roles == 'admin'">
        <router-link to="/listUsers" class="text-black hover:text-blue-600">
            UTILISATEURS
          </router-link>
      </button>
      <button class="text-left my-1.5 w-full hover:text-blue-600" v-if="roles == 'admin' || roles == 'gestionnaire'">
        <router-link to="/stockGeneral">
          STOCK
        </router-link>
        
      </button>
      <button class="text-left my-1.5 w-full hover:text-blue-600" v-if="roles == 'admin'">
        <router-link to="/money">
          MONNAIE
        </router-link>
      </button>
    </div>
  </div>
</template>

  <style>
    .hidden{
      display: none;
    }

  </style>

  <script>
import { mapActions, mapGetters } from 'vuex';


    export default {
        name: "Header",   

        data(){
          return {
            hoverVisible : false,
            hoverUser : false,
            isHidden:true,
            menuHiderV:false,
            mesDonnees : [],
            roles : null,
            token : null,
            count: null,
          }
        },

        methods: {

          ...mapActions(['countNoti']),
          countNotification(){
              this.countNoti()
                  .then(reponse =>{
                    this.count = reponse.data.data
                      ////.log(reponse.data.data)
                  })
                  .catch(error =>{
                      //.log(error)
                  })
                  .finally(() =>{
                      ////.log("esili")
                  }); 
          },

          navItemClass(page) {
            return {
              'text-yellow-500 font-normal': this.$route.path  === `/${page}`,
              'inner hover:text-yellow-500': !this.$route.path !== `/${page}`,
            };
          },
          menuHider()
          {
            this.menuHiderV=true;
          },
          showSmallMenu()
          {
            if(this.isHidden==true)
            {
              this.isHidden=false;
            }
            else
            {
              this.isHidden=true;
            }
          },
         
          logout(){
            const self = this;
            this.$store.dispatch('logout')
              .then((response) =>{
                self.$router.replace('/authentification') 
                
                localStorage.removeItem('authToken');
                localStorage.removeItem('role');
                localStorage.removeItem('IdUser')
                localStorage.clear();
                //window.history.pushState(null, '', '/authentification');
                //(response);
              }).catch((err) =>{
                //(err);
              });
              
          },
          showHoverDiv(){
            this.hoverVisible = true;
            this.hoverUser = false;
          },
          hideHoverDiv(){
            this.hoverVisible = false;
            this.hoverUser = false;
          },
          showUser(option){
            if(option === "user"){
              this.hoverUser = true;
            }else{
              this.hoverUser = false;
            }
          },
          hideUser(hoverUser){
            if(hoverUser == hoverUser){
              this.hoverUser = true;
            }else{
              this.hoverUser = false;
            }
          },
          HideDivGestion(){
            this.hoverVisible = true;
          }
        },

        computed: {
          ...mapGetters(['user']),
          userGet(){
            return this.user
          },
        },
        created(){
          this.roles = localStorage.getItem("role");
          this.token = localStorage.getItem("authToken");

          if(this.roles !== null && this.token !== null){
            this.countNotification()
          }

          //alert(this.roles)
          /*const getData = this.userGet
          
          const setItm = localStorage.setItem('getDonne', this.getData);

          //(setItm);

          var mesDonnees  = localStorage.getItem('getDonne');
          if(this.mesDonnees.length == 0){
            //("Donnes not exist", this.mesDonnees);
          }else{
            //("Donnes exist", this.mesDonnees);
          }*/
        },
    }
  </script>