<template>
    <div>
        <div class="flex items-center justify-center h-screen">
            <div class="bg-gray-200 h-2/3">
                <div class="flex h-full flex-col md:flex-row">
                    <div class="w-full md:w-2/4 md:order-first" style="background: linear-gradient(to bottom, #DEA20A , #FBF7EE );">
                        <div class="px-10 pt-14">
                            <div class="flex items-center justify-center">
                                <img src="@/images/LOGO_GEN_PNG_BLANC.png" class="w-72" alt="">
                            </div>
                            <div class="flex items-center justify-center bg-white py-10 rounded-lg">
                                <p class="text-3xl font-bold uppercase drop-shadow-md">Demande de ravitaillement</p>
                            </div>
                        </div>
                    </div>
                    <div class="w-full pt-10 md:w-2/4 px-4 md:px-10 pb-20 md:pb-0 md:pb-10 md:pt-10 text-left md:mt-0 md:overflow-y-auto" style="background-color: #FBF7EE;">
                        <form action="" @submit.prevent="SubmitDemand">
                            <div class="mb-5" v-for="(produit, index) in produits" :key="index">
                                <div class="pl-2 mb-2">
                                    <label for="phone" class="text-2xl font-bold">Produit {{ index }}</label>
                                </div>
                                <div>
                                    <select name="" v-model="produit.value" id="" class="block w-full px-4 py-2 border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                                        <option value="" disabled selected hidden></option>
                                        <option :value="produit.id" v-for="(produit, index) in items" :key="index">{{ produit.nom }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="flex justify-between mb-16">
                                <div  @click="addInput" class="px-4 py-2 w-40 rounded-lg  text-center shadow-lg font-bold text-2xl bg-white text-black">Ajouter</div>
                                <button type="submit" class="px-4 py-2 w-40 rounded-lg text-white font-bold text-2xl" style="background-color: #DEA20A;">Enregistrer</button>
                            </div>
                            <p>{{ error }}</p>
                        </form>                        
                        <div class="flex items-center hidden justify-center">
                            <p class="font-bold text-lg">4 produits ajoutés</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import ProduitService from '@/_services/produit.services'

export default{
    name: 'demanderavi',

    data(){
        return {
            items: null,
            produits: [],
            error: null,
            roles: null,
            
        }
    },

    mounted(){
        //Liste produit
        const produitService = new ProduitService();
            produitService.getAllProduit()
            .then(produits => {
                this.items = produits.data;
            }).catch(err =>{ 
                ////.log(err)
            });
            
            //fin liste produit
            this.addInput()
    },

    methods:{
        addInput() {
            this.produits.push({ value: '' });
        },

        SubmitDemand(){
            const produitsValues = this.produits.map(produit => produit.value);
            const nbr_produits = this.produits.length



            if(nbr_produits > 10){
                this.error = 'Le nombre de produit ne doit pas être supérieur à 10';
            }else {
                
                this.$store.dispatch('SubmitDemand',{
                    nbr_produits,
                    ...produitsValues.reduce((acc, prod, i) => {
                        acc["produit" + i] = prod;
                        return acc;
                    }, {})
                })
                .then((response)=>{
                    this.$router.push('/ListRaviBoutique')
                })
                .catch((error)=>{
                    this.error = error;
                });
            
            }
        }

    },
    created(){
        this.roles = localStorage.getItem("role");
        if(this.roles == null){
                this.$router.push('/authentification')
            }
    }
}

</script>