import Axios from './caller.services'


export default class UserCompteService{

    async getUsersAlls(){
        try {
            const response = await Axios.get('/api/users');
            return response.data;
        } catch (error) {
            //.error(error);
            throw error;
        }
    };

    async getManager(token){


        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }

        try {
            const response = await Axios.get('/api/manager',{headers});
            return response.data;
        } catch (error) {
            throw error;
        }
    };


    async addUserComptey(userCompteData, token){

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        

        //Admin, Agent, Gestionnaire de stock
        if(userCompteData.role === 'admin' || userCompteData.role === 'agent' || userCompteData.role === 'gestionnaire') {
            
            const Compresse = {
                nom : userCompteData.nom,
                prenom : userCompteData.prenom,
                telephone : userCompteData.telephone,
                role : userCompteData.role,
                password : userCompteData.password
            }
            
            try {
                const response = await Axios.post(
                    '/api/register/compte', 
                    Compresse, 
                    {headers});
    
                return response;
            } catch (error) {
                throw error;
            
            }
        }
        
        //Manager
        if(userCompteData.role === 'manager') {

            const CompresseData = {
                nom : userCompteData.nom,
                prenom : userCompteData.prenom,
                telephone : userCompteData.telephone,
                ville : userCompteData.province,
                password : userCompteData.password
            }
            
            try {
                const response = await Axios.post(
                    '/api/register/manager', 
                    CompresseData, 
                    {headers});
    
                return response;
            } catch (error) {
                throw error;
            
            }
        }

        //livreur
        if(userCompteData.role === 'livreur') {

            const CompresseData = {
                nom : userCompteData.nom,
                prenom : userCompteData.prenom,
                telephone : userCompteData.telephone,
                boutique : userCompteData.boutique,
                password : userCompteData.password
            }
            
            try {
                const response = await Axios.post(
                    '/api/register/livreur', 
                    CompresseData,
                    {headers});
    
                return response;
            } catch (error) {
                throw error;
            
            }
        }
    };

    async getDataManager(token){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }

        try {
            const response = await Axios.get('/api/manager', {headers});
            return response.data;
        } catch (error) {
            throw error;
        }
    };

    async getDataLivrer(token){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }

        try {
            const response = await Axios.get('/api/livreur', {headers});
            return response;
        } catch (error) {
            throw error;
        }
    };

    async getDataLAgent(token){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }

        try {
            const response = await Axios.get('/api/agent', {headers});
            return response;
        } catch (error) {
            throw error;
        }
    }

}