import Axios from './caller.services'

export default class NotificationService{

    async getNotification(token){

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Access-Control-Allow-Origin':'*',
            'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,PATCH,OPTIONS',
            
            'Access-Control-Allow-Headers': 'Content-Type, Origin, X-Auth-Token, Authorization'
        }

        try{

            const reponse = await Axios.get(
                'api/notification',
                {headers}
            )

            return reponse

        }catch(e){
            throw e;
        }

    };

    async countNotification(token){

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Access-Control-Allow-Origin':'*',
            'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,PATCH,OPTIONS',
            
            'Access-Control-Allow-Headers': 'Content-Type, Origin, X-Auth-Token, Authorization'
        }

        try{

            const reponse = await Axios.get(
                'api/unread_notification',
                {headers}
            )

            return reponse

        }catch(e){
            throw e;
        }

    }


}