<template>
  
  <router-view/>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  padding: 0;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>

<script>

  import HomeView from "./views/HomeView.vue"

  export default {
    components: {
      HomeView
    },

    methods: {

      saveData(){

            const taux = localStorage.getItem('taux_ville')
            const devise = localStorage.getItem('devis_ville')

            const localisation = localStorage.getItem("localisation");


      },

      mounted(){

        window.addEventListener('beforeunload', this.saveData)

      },

      beforeUnmount() {
        
        window.removeEventListener('beforeunload', this.saveData);

      },

    },
  }

</script>