<template>
    
    <div class=" w-full flex items-center justify-center h-screen">
        <div class=" bg-gray-200 h-2/3">
            <div class="flex h-full flex-col md:flex-row">
                <div class="w-full md:w-2/4 md:order-first" style="background: linear-gradient(to bottom, #DEA20A , #FBF7EE );">
                    <div class="px-10 pt-14">
                        <div class="flex items-center justify-center">
                            <img src="@/images/LOGO_GEN_PNG_BLANC.png" class="w-72" alt="">
                            </div>
                            <div class="flex items-center justify-center bg-white py-5 rounded-lg">
                                <p class="sm:text-2xl  text-2xl font-bold uppercase drop-shadow-md">Nouvelle Commande</p>
                            </div>
                        </div>
                    </div>
                    <div class="w-full pt-10 md:w-2/4 px-4 md:px-10 pb-20 md:pb-0 md:pb-10 md:pt-10 text-left md:mt-0 md:overflow-y-auto" style="background-color: #FBF7EE;">
                        <form action="" @submit.prevent="submitCommandIn" ref="restlogin">
                            <div v-for="(item, index) in items" :key="index">
                                <div class="mb-2">
                                    <div class="pl-2 mb-1">
                                        <label class="text-sm font-bold">Produit : {{ index + 1 }}</label>
                                    </div>
                                    <div>
                                        <select name="" v-model="item.produits" id="" class="block w-full px-4 py-1 border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                                            <option value="" disabled selected hidden></option>
                                            <option :value="product.id" v-for="(product, index) in getProduct" :key="index">{{ product.nom }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <div class="pl-2 mb-1">
                                        <label class="text-sm font-bold">Quantité : {{ index + 1 }}</label>
                                    </div>
                                    <input required type="text" name="" v-model="item.quantites" id="" class="px-4 py-1 w-full border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                                </div>
                                <div class="flex w-full justify-between">
                                    <div @click="removeItem(index)" class="px-4 py-2 rounded-lg cursor-pointer text-center text-sm text-white bg-red-500 shadow-lg font-bold ">Retirer</div>
                                </div>
                            </div>
                            <div class="flex items-end justify-end mb-3">
                                <div>
                                    <div @click="addInput" class="px-4 py-2 w-24 rounded-lg cursor-pointer text-center text-black bg-white shadow-lg font-bold text-sm">Ajouter</div>
                                </div>
                            </div>
                            <div class="mb-3 grid grid-cols-2">
                                <div class="mr-5 mb-2">
                                    <div class="pl-2 mb-2">
                                        <label class="text-sm font-bold">Nom</label>
                                    </div>
                                    <input type="text" v-model="nom" name="" id="" class="px-4 py-1 w-full border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                                </div>
                                <div class="ml-5">
                                    <div class="pl-2 mb-2">
                                        <label class="text-sm font-bold">Prenom</label>
                                    </div>
                                    <div>
                                        <input type="text" name="" v-model="prenom" id="" class="px-4 py-1 w-full border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                                    </div>
                                </div>
                                <div class="mr-5 mb-2">
                                    <div class="pl-2 mb-2">
                                        <label class="text-sm font-bold">Téléphone</label>
                                    </div>
                                    <div>
                                        <input type="text" placeholder="+2*********" v-model="telephone" name="" id="" class="px-4 py-1 w-full border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                                    </div>
                                </div>
                                <div class="ml-5">
                                    <div class="pl-2 mb-2">
                                        <label class="text-sm font-bold">Ville</label>
                                    </div>
                                    <div>
                                        <select name="" id="" v-model="province" class="block w-full px-4 py-1 border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                                            <option value="" disabled selected hidden></option>
                                            <option :value="ville.id" v-for="(ville, index) in getVille" :key="index">{{ ville.nom }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="mr-5">
                                    <div class="pl-2 mb-2">
                                        <label class="text-sm font-bold">Adresse</label>
                                    </div>
                                    <div>
                                        <input type="text" name="" v-model="adresse" id="" class="px-4 py-1 w-full border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                                    </div>
                                </div>
                            </div>
                            <div class="mb-7">
                                <div class="pl-2 mb-2">
                                    <label class="text-sm font-bold">Type de Livraison</label>
                                </div>
                                <div>
                                    <select name="" id="" v-model="type_livraison" class="block w-full px-4 py-1 border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                                        <option value="au siège" >Au siège</option>
                                        <option value="à domicile" >Livraison</option>
                                    </select>
                                </div>
                            </div>
                            <div class=" mb-14">
                                <!--button class="text-sm font-bold" style="color: #25A4EC">Ancien client</button-->
                                <button type="submit" class=" w-full px-4 py-1.5 rounded-lg text-white font-bold text-sm" style="background-color: #DEA20A;">Enregistrer</button>
                                <p class="text-red-500 w-80">{{ erreur }}</p>
                            </div>                        
                        </form>
                        <div class="flex justify-between my-4 hidden">
                            <span class="flex text-xl font-bold" style="color:#072A62">Articles : <p class="ml-2">6</p></span>
                            <span class="flex text-xl font-bold" style="color:#072A62">Total : <p class="ml-2">60$</p></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        

</template>
<script>
import Ville from '@/_services/province.services'
import Produit from '@/_services/produit.services'


export default{
    name: 'newcommandancient',

    data() {
        return {
            type_livraison : '',
            pays: '',
            adresse: '',
            province: '',
            nom : '',
            prenom : '',
            telephone : '',
            quantiteError : false,

            getVille: null,
            getProduct: null,
            roles: null,
            erreur: null,
            items: []
        };
    },
    methods: {
        addInput() {
            this.items.push({
                produits: "",
                quantites: "",
            });
        },
        removeItem(index){
            if(this.items.length > 1){
                this.items.splice(index, 1)
            }
        },
        validateNumberInput() {
                this.quantites = parseInt(this.quantites);
                if (isNaN(this.quantites)) {
                    this.quantites = null; // Réinitialiser la valeur si ce n'est pas un nombre valide
                }
            },

        submitCommandIn: function(){

            
            const produitsValues = this.items.map(produit => parseInt(produit.produits));
            const quantitesValues = this.items.map(quantite => parseInt(quantite.quantites));

            if (quantitesValues.some(isNaN)) {
                this.quantiteError = true;
            }
            
            
            this.$store.dispatch('submitCommandIn', {
                ville : this.province,
                adresse : this.adresse,
                type_livraison : this.type_livraison,
                nom : this.nom,
                nbr_produits: produitsValues.length,
                prenom : this.prenom,
                telephone : this.telephone,
                ...quantitesValues.reduce((acc, quant, i) => {
                    acc["quantite" + i] = quant;
                    return acc;
                }, {}),

                ...produitsValues.reduce((acc, prod, i) => {
                    acc["produit" + i] = prod;
                    return acc;
                }, {})
            }).then((response) => {
                if(response.status_code == 200) {
                    this.$router.push('/listecommande')
                }else if(response.status_code != 200) {
                    this.erreur = response.message;
                    
                }
                
            }).catch((error) =>{
                this.erreur = error.message.split('.')[0]
                
            });
            setTimeout(() =>{
                location.reload()
                    }, 
                    5000)

            this.restlogin();
        },

        restlogin(){

            this.province = '';
            this.adresse = '';
            this.type_livraison = '';
            this.nom = '';
            this.produits = '';
            this.prenom = '';
            this.telephone = '';
            this.quantites = '';
            this.$refs.restlogin.reset();
        }
    },

    mounted() {
        this.addInput(); // Ajouter un input lors du chargement du composant

        //get ville
        const ville = new Ville();
        ville.getAllPro()
                .then(prov => {
                    this.getVille = prov.data;
                }).catch(err => {
                    ////.log(err)
                });

        //get produit
        const produitService = new Produit();
            produitService.getAllProduit()
            .then(produits => {
                this.getProduct = produits.data;
            }).catch(err => {
                /////.log(err)
            });
    },
    created(){
        //this.roles = localStorage.getItem("role");
    }
}

</script>