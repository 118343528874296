<template>
    <div>
        
    </div>
    <div class="w-full mb-3 overflow-x-hidden">
                <div class="w-full h-10 flex overflow-x-hidden">
                    <div class="mr-4 w-1/3" v-if="roles !== 'admin' && roles !=='gestionnaire'">
                        <form action="">
                            <button class="border w-full border-black text-white font-bold py-1.5 px-5 rounded-lg" style="background-color: #072A62;">Général</button>
                        </form>
                    </div>
                    <div class="mr-4 w-1/3">
                        <div class="flex">
                            <div class="w-1/4">
                                <label for="" class="font-bold">Du</label>
                            </div>
                            <input type="date" name="" id="" v-model="selectDate.debut" class="text-black h-8  py-4 px-5 rounded-lg border border-black bg-white">
                        </div>
                    </div>
                    <div class="mr-4 w-1/3">
                        <div class="flex">
                            <div class="w-1/4">
                                <label for="" class="font-bold">Au</label>
                            </div>
                            <input type="date" name="" id="" v-model="selectDate.fin" @change="handleDateChange" class="text-black h-8  py-4 px-5 rounded-lg border border-black bg-white">
                        </div>
                    </div>
                </div>
            </div>
</template>

<script>

export default{
    name: "NotFound"
}

</script>