import AgentService from "@/_services/agent.services"

const actions = {
    async getCommandAgent({commit}){

        const token = localStorage.getItem('authToken');
        const id = localStorage.getItem('IdUser');
        
        try{

            const getData = new AgentService();
            const response = await getData.getCommandeAgent(token, id);

            return response

        }catch(error){
            throw error;
        }

    },

    async getCommandGest({commit}){

        const token = localStorage.getItem('authToken');
        const id = localStorage.getItem('IdUser');
        
        try{

            const getData = new AgentService();
            const response = await getData.getCommandeGest(token, id);

            return response

        }catch(error){
            throw error;
        }

    },

    async getListeGest({commit}){
        const token = localStorage.getItem('authToken');
        
        try{

            const getData = new AgentService();
            const response = await getData.getListeGest(token);

            return response

        }catch(error){
            throw error;
        }
    },

    async postAsGestionnaire({commit}, data){

        const token = localStorage.getItem('authToken');
        
        try{
            
            const sendData  = new AgentService();
            const response = await sendData.postAsGestionnaire(data, token);

            //commit('SET_ASSIGNER_LIVREUR', [...state.assignerLivreur, response])

            return response

        }catch(e){

            throw e;
        }

    }
}

export default{
    actions,
}