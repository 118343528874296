<template>
    
    <div class="w-screen overflow-x-hidden " @click="hideDiv">
        <Header></Header>
        <div class="w-full bg-white mb-12 mt-1.5 overflow-x-auto">
            <div class="w-full h-full">
                <div class="w-full px-4 sm:px-36">
                    <div class="my-16 ">
                        <!---h1 class="text-left mb-10 font-bold text-2xl">Commandes</h1-->
                        <div class="w-full h-10" v-if="roles == null">
                            <form action="" class="flex w-full justify-between" @submit.prevent="searchClient">
                                <input type="text" name="" id="" v-model="phoneSearch" placeholder="Entrez votre numéro +24***" class="block border w-62 px-4 py-1.5 rounded-lg " style="background-color: #FBF7EE;">
                                <button type="submit" class="px-4 text-white py-1.5 rounded-lg" style="background-color: #DEA20A;">Consulter</button>
                            </form>
                            <p class="text-red-500" v-show="erreurSearch">{{ erreurSearch }}</p>
                        </div>
                        
                            <div class="grid grid-cols-2 md:grid-cols-3" v-show="roles != null">
                                <div class="flex w-full mb-4" v-show="roles === 'admin' || roles === 'gestionnaire'">
                                    <div class="w-1/4 mr-4">
                                        <label for="" class="font-bold">Agents</label>
                                    </div>
                                    <div class="w-3/4">
                                        <select name="" id="" v-model="filters.agent" @change="handleChangeA" class="block w-full px-4 py-1.5 rounded-lg " style="background-color: #FBF7EE;">
                                            <option :value="agent.id" v-for="(agent, index) in itemBtAg" :key="index">{{ agent.nom }} {{ agent.prenom }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="flex w-full mb-4" v-show="roles === 'admin' || roles === 'gestionnaire'">
                                    <div class="w-1/4 mr-4">
                                        <label for="" class="font-bold">Managers</label>
                                    </div>
                                    <div class="w-3/4">
                                        <select name="" id="" v-model="filters.manager" @change="handleChangeM" class="block w-full px-4 py-1.5 rounded-lg " style="background-color: #FBF7EE;">
                                            <option :value="item.manager['id']" v-for="(item, index) in itemBtM" :key="index">{{ item.nom }} {{ item.prenom }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="flex w-full mb-4" v-show="roles === 'admin' || roles === 'gestionnaire'">
                                    <div class="w-1/4 mr-4">
                                        <label for="" class="font-bold">Livreurs</label>
                                    </div>
                                    <div class="w-3/4">
                                        <select name="" id="" v-model="filters.livreur" @change="handleChangeL" class="block w-full px-4 py-1.5 rounded-lg " style="background-color: #FBF7EE;">
                                            <option :value="item.id" v-for="(item, index) in listLiv" :key="index">{{ item.user.nom }} {{ item.user.prenom }}</option>
                                        </select>
                                    </div>
                                </div>
                                <!--div class="flex w-full mb-4">
                                    <div class="w-1/4 mr-4">
                                        <label for="" class="font-bold">Date</label>
                                    </div>
                                    <div class="w-3/4">
                                        <select name="" id="" v-model="filters.date" class="block w-full px-4 py-1.5 rounded-lg " style="background-color: #FBF7EE;">
                                            <option value="aujourd'hui">Aujourd'hui</option>
                                            <option value="hier">Hier</option>
                                            <option value="semaine">Cette semaine</option>
                                            <option value="mois">Ce mois</option>
                                            <option value="dernier-mois">Mois dernier</option>
                                            <option value="année">Année</option>
                                            <option value="dernière-année">Année dernière</option>
                                        </select>
                                    </div>
                                </div-->
                                <div class="flex w-full mb-4">
                                    <div class="w-1/4 mr-4">
                                        <label for="" class="font-bold">Du</label>
                                    </div>
                                    <div class="w-3/4">
                                        <input type="date" name="" id="" v-model="filters.debut" class="block w-full px-4 py-1.5 rounded-lg " style="background-color: #FBF7EE;">
                                    </div>
                                </div>
                                <div class="flex w-full mb-4">
                                    <div class="w-1/4 mr-4">
                                        <label for="" class="font-bold">Au</label>
                                    </div>
                                    <div class="w-3/4">
                                        <input type="date" name="" id="" v-model="filters.fin" @change="sendDataFiltre" class="block w-full px-4 py-1.5 rounded-lg " style="background-color: #FBF7EE;">
                                            
                                    </div>
                                </div>
                                <div class="flex w-full mb-4">
                                    <div class="w-1/4 mx-4">
                                        <label for="" class="font-bold">Produit</label>
                                    </div>
                                    <div class="w-3/4">
                                        <select name="" @change="sendDataFiltre" v-model="filters.produit" id="" class="block w-full px-4 py-1.5 rounded-lg " style="background-color: #FBF7EE;">
                                            <option :value="pro.id" v-for="(pro, index) in itemProduit" :key="index">{{ pro.nom }}</option>
                                        </select>
                                    </div>
                                </div>                                
                                <div class="flex w-full mb-4">
                                    <div class="w-1/4 mr-4">
                                        <label for="" class="font-bold">Type</label>
                                    </div>
                                    <div class="w-3/4">
                                        <select name="" @change="sendDataFiltre" id="" v-model="filters.type" class="block w-full px-4 py-1.5 rounded-lg " style="background-color: #FBF7EE;">
                                            <option value="au siège">Au siège</option>
                                            <option value="à domicile">Livraison</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="flex w-full mb-4">
                                    <div class="w-1/4 mr-2">
                                        <label for="" class="font-bold">Ville</label>
                                    </div>
                                    <div class="w-3/4">
                                        <select name="" @change="sendDataFiltre" id="" v-model="filters.ville" class="block w-full px-4 py-1.5 rounded-lg " style="background-color: #FBF7EE;">
                                            <option :value="ville.id" v-for="(ville, index) in getVille" :key="index">{{ ville.nom }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="flex w-full mb-4">
                                    <div class="w-1/4 mr-4">
                                        <label for="" class="font-bold">État</label>
                                    </div>
                                    <div class="w-3/4">
                                        <select name="" @change="sendDataFiltre" id="" v-model="filters.statut" class="block w-full px-4 py-1.5 rounded-lg " style="background-color: #FBF7EE;">
                                            <option value="entrant">Entrant</option>
                                            <option value="en cours de traitement">En cours de traitement</option>
                                            <option value="en livraison">En livraison</option>
                                            <option value="livrée">Livrer</option>
                                            <option value="en vérification stock">En vérification</option>
                                            <option value="reportée">Réporter</option>
                                            <option value="injoignable">Injoignable</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="flex mb-4">
                                    <div class="w-1/4 mr-4">
                                        <label for="" class="font-bold">Num Client</label>
                                    </div>
                                    <div class="w-3/4">
                                        <input type="text" @input="sendDataFiltre" name="" id="" v-model="filters.telephone" placeholder="+24***" class="block w-full border px-4 py-1.5 rounded-lg " style="background-color: #FBF7EE;">
                                    </div>
                                </div>
                            </div>
                            <div class="w-full mt-7" v-if="roles !== null">
                                <form action="">
                                    <button type="submit" class="w-40 px-4 text-white py-1.5 rounded-lg" style="background-color: #DEA20A;">Générale</button>
                                </form>
                            </div>
                        
                    </div>
                    <!--Affichage de message de successfull-->
                    <div class="px-4 flex justify-center" v-show="succes">
                        <p class="bg-green-400 w-40 py-2 text-white">{{succes}}</p>
                    </div>
                    <!--Affichage de message de successfull-->


                    <!--Affichage de message de error-->
                    <div class="px-4 flex justify-center" v-show="erreur">
                        <p class="bg-red-600 px-4 py-2 text-white">{{erreur}}</p>
                    </div>
                    <!--Affichage de message de error-->
                    <div class="pb-20">
                        <AssignMulti :id="comAll" v-if="comAll"/>
                        <div class="w-full mb-14" style="background-color: #FBF7EE;">
                            <div>
                                <div class="flex w-full" style="background-color: #DEA20A;">
                                    <div class="text-white text-left pl-2 w-40 py-5 text-md">
                                        Date
                                    </div>
                                    <div class="text-white text-left pl-2 w-40 py-5 text-md">
                                        Nom d'article
                                    </div>
                                    <div class="text-white text-left pl-2 w-40 py-5 text-md">
                                        Total
                                    </div>
                                    <div class="text-white hidden sm:inline text-left pl-2 w-40 py-5 text-md">
                                        Type de commande
                                    </div>
                                    <div class="text-white text-left pl-2 w-40 py-5 text-md">
                                        Adresse de livraison
                                    </div>
                                    <div class="text-white text-left pl-2 w-40 py-5 text-md">
                                        <p v-if="roles == 'manager' || roles == 'agent'|| roles == null || roles == 'livreur'">Livreur</p>
                                        <p v-if="roles == 'admin' ||roles == 'gestionnaire'">Type Livraison</p>
                                    </div>
                                    <div class="text-white text-left pl-2 w-40 py-5 text-md">
                                        État
                                    </div>
                                    <div class="text-white text-left w-40 py-5 text-md" >
                                        Options
                                    </div>
                                </div>
                                <div class=" w-full overflow-y-auto">
                                    <div class="flex w-full my-1.5 p-1" v-for="(com, index) in fetchedData" :key="index">
                                        <input type="checkbox" v-model="comAll" :id="`checkbox-${index}`" :value="com.id" />
                                        <div class="w-40 py-2 px-2 text-sm line-clamp-2 leadin-none overflow-hidden text-ellipsis h-12 text-black text-left">
                                            <p v-text="formData(com.created_at)"></p>
                                        </div>
                                        <div class="w-40  py-2 px-2 text-sm line-clamp-2 leadin-none overflow-hidden text-ellipsis h-12 text-black text-left">
                                            <p class="mr-1 5" v-for="(product, index) in com.produits" :key="index" v-text=" product.nom" ></p>
                                        </div>
                                        <div class="w-40  py-2 px-2 text-sm line-clamp-2 leadin-none overflow-hidden text-ellipsis h-12 text-black text-left">
                                            {{ com.total }}
                                        </div>
                                        <div class="w-40  py-2 px-2 text-sm line-clamp-2 hidden sm:inline leadin-none overflow-hidden text-ellipsis h-12 text-black text-left">
                                            {{ com.type }}
                                        </div>
                                        <div class="w-40  py-2 px-2 text-sm line-clamp-2 leadin-none overflow-hidden text-ellipsis h-12 text-black text-left">
                                            <p v-if="com.adresse !== 'null'">{{ com.adresse }}</p>
                                            <p v-else>...............</p>
                                        </div>
                                        <div class="w-40  py-2 px-2 text-sm line-clamp-2 leadin-none overflow-hidden text-ellipsis h-12 text-black text-left">
                                            <p v-if="roles == 'admin'">{{ com.type_livraison }}</p>
                                            <p v-else v-for="(user, index) in com.livreur" :key="index">{{ user.nom }} {{ user.prenom }}</p>
                                        </div>
                                        <div class="w-40  py-2 px-2 text-sm line-clamp-2 leadin-none overflow-hidden text-ellipsis h-12 text-black text-left">
                                            {{ com.statut }}
                                        </div>
                                        <div class="w-40  py-2 px-1 text-sm line-clamp-2 leadin-none  text-ellipsis h-12 text-black">
                                            <div class="relative flex">
                                                <div class="flex items-center">                                                    
                                                    <img @click="toggleOptions(com.id, $event)" src="@/images/option.png" alt="" class="w-6 h-6 mr-2 cursor-pointer">
                                                </div>
                                            </div>
                                            
                                            <div class="absolute right-0 md:right-10 z-10" v-if="activeId === com.id" @click="preventHideDiv">
                                                
                                                <div class="py-4 px-2 text-left shadow-lg w-40 bg-white text-black rounded-lg">
                                                    <button class="text-left my-1.5 hover:text-blue-600" v-if="(roles === 'admin' || roles === 'gestionnaire') && com.statut !== 'livrée' && com.statut !== 'annulée'" @click="assignerLi('liv')">Assigner à un Livreur</button>

                                                    <button class="text-left my-1.5 hover:text-blue-600" v-if="(roles === 'manager' ) && com.statut !== 'livrée' && com.statut != 'annulée'" @click="assignerAB">Assigner à un Livreur</button>

                                                    <button class="text-left my-1.5 hover:text-blue-600" v-if="(roles === 'admin' || roles === 'gestionnaire') && com.statut !== 'en livraison' && com.statut !== 'annulée'" @click="assignerMa('man')">Assigner à une Manager</button>
                                                    
                                                    <button class="text-left my-1.5 hover:text-blue-600" v-if="roles == 'admin' && com.statut != 'en livraison' && com.statut != 'livrée' && com.statut != 'annulée'" @click="assignerAg('ag')">Assigner à l'Agent</button>

                                                    <button class="text-left my-1.5 hover:text-blue-600" v-if="roles === 'agent'" @click="assignerAB">Assigner au gestionnaire</button>
                                                    
                                                    <button class="text-left my-1.5 hover:text-blue-600" v-if="(roles == 'agent' || roles == 'livreur') && com.statut !== 'livrée' && com.statut != 'annulée'" @click="actionClient(com.id)">Contacter client</button>
                                                    
                                                    <button class="text-left my-1.5 hover:text-blue-600" v-if="roles == 'livreur' && com.statut !== 'annulée' && com.statut !== 'livrée'" @click="redirigerWhaysapp(com.user.telephone)">Contacter l'agent</button>
                                                    
                                                    <button class="text-left my-1.5 hover:text-blue-600" v-if="(roles === 'livreur' || roles === 'manager') && com.statut !== 'annulée' && com.statut !== 'livrée'" @click="comfirmation">Confirmer livraison</button>
                                                    
                                                    <button class="text-left my-1.5 hover:text-blue-600" v-if="(roles == 'admin' || roles === 'gestionnaire' || roles === 'agent') && com.statut != 'livrée' && com.statut != 'annulée'" @click="annulCom(com.id)">Annuler Commande</button>
                                                    
                                                    <button class="text-left my-1.5 hover:text-blue-600" v-if="(roles == 'admin' || roles === 'gestionnaire') && com.statut != 'livrée' && com.statut != 'annulée'" @click="dropCom(com.id)">Supprimer Commande</button>
                                                    
                                                    <button class="text-left my-1.5 hover:text-blue-600" @click="detail(com.id)">Afficher détail</button>
                                                    
                                                    <button class="text-left my-1.5 hover:text-blue-600" v-if="(roles === 'admin' || roles === 'gestionnaire' || roles === 'agent') && com.statut !== 'livrée' && com.statut !== 'annulée'" @click="modifCom(com.id)">Modifier Commande</button>

                                                    <button class="text-left my-1.5 hover:text-blue-600" v-if="(roles === 'agent' || roles === 'admin' || roles === 'gestionnaire') && com.statut !== 'livrée' && com.statut !== 'annulée'" @click="reporter(com.id)">Réporter Commande</button>

                                                    <button class="text-left my-1.5 hover:text-blue-600" v-if="(roles === 'agent' || roles === 'admin' || roles === 'gestionnaire') && com.statut !== 'livrée' && com.statut !== 'annulée'" @click="injoign(com.id)">Client Injoignable</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Les autres colonnes -->
                        </div>
                    </div>
                    <!--Modal-->
                    <!--Assignation administrateur et gestionnaire -->
                    <!--Assignation Manager-->
                    <div v-show="showman" class="fixed inset-0 flex items-center justify-center " @click="preventHideDiv">
                        <div class="absolute bottom-48 shadow-lg w-72 h-56 bg-white">
                            <div class="flex justify-end py-2 pr-2">
                                <img src="@/images/close.png" @click="close" alt="" class="w-3 cursor-pointer" srcset="">
                            </div>
                            <div class="px-2 h-48 overflow-y-auto">
                                <div class="mb-2 border-b pb-1.5" v-for="(user, index) in itemUsersManage" :key="index">
                                    <div class="">
                                        <div class="flex mb-2">
                                            <div class="w-14 flex items-center justify-center  h-12 border rounded-full">
                                                <span class="text-center inline-block align-middle">{{ user['nom'].charAt(0) }}{{ user['prenom'].charAt(0) }}</span> 
                                            </div>
                                            <div class="w-full h-full text-left pl-2">
                                                <div class="h-6 overflow-hidden w-32 text-black font-bold">
                                                    <p class=" line-clamp-2">{{ user['nom'] }} {{ user['prenom'] }}</p>
                                                </div>                                                    
                                            </div>
                                        </div>
                                        <div class="pl-4 pr-4 flex justify-between">
                                            <button type="submit" @click="sendAssigner(user.id)" class="py-1 px-2 rounded-md text-white" style="background-color: #DEA20A;">Assigner</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Livreur-->
                    <div v-show="showliv" class="fixed inset-0 flex items-center justify-center " @click="preventHideDiv">
                        <div class="absolute bottom-48 shadow-lg w-72 h-56 bg-white">
                            <div class="flex justify-end py-2 pr-2">
                                <img src="@/images/close.png" @click="close" alt="" class="w-3 cursor-pointer" srcset="">
                            </div>
                            <div class="px-2 h-48 overflow-y-auto">
                                <div class="mb-2 border-b pb-1.5" v-for="(user, index) in itemUserslivreur" :key="index">
                                    <div class="">
                                        <div class="flex mb-2">
                                            <div class="w-14 flex items-center justify-center  h-12 border rounded-full">
                                                <span class="text-center inline-block align-middle" >{{ user['nom'].charAt(0) }}{{ user['prenom'].charAt(0) }}</span> 
                                            </div>
                                            <div class="w-full h-full text-left pl-2">
                                                <div class="h-6 overflow-hidden w-32 text-black font-bold">
                                                    <p class=" line-clamp-2">{{ user['nom'] }} {{ user['prenom'] }}</p>
                                                </div>                                                      
                                            </div>
                                        </div>
                                        <div class="pl-4 pr-4 flex justify-between">
                                            <!--input type="date" name="" v-model="data_livraison" id="" class="border px-1 border-black" style="background-color: #FBF7EE;"-->
                                            <button type="submit" @click="sendAssigner(user.id)" class="py-1 px-2 rounded-md text-white" style="background-color: #DEA20A;">Assigner</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Agent-->
                    <div v-show="showag" class="fixed inset-0 flex items-center justify-center " @click="preventHideDiv">
                        <div class="absolute bottom-48 shadow-lg w-72 h-56 bg-white">
                            <div class="flex justify-end py-2 pr-2">
                                <img src="@/images/close.png" @click="close" alt="" class="w-3 cursor-pointer" srcset="">
                            </div>
                            <div class="px-2 h-48 overflow-y-auto">
                                <div class="mb-2 border-b pb-1.5" v-for="(user, index) in itemUsersAgent" :key="index">
                                    <div class="">
                                        <div class="flex mb-2">
                                            <div class="w-14 flex items-center justify-center  h-12 border rounded-full">
                                                <span class="text-center inline-block align-middle">{{ user['nom'].charAt(0) }}{{ user['prenom'].charAt(0) }}</span> 
                                            </div>
                                            <div class="w-full h-full text-left pl-2">
                                                <div class="h-6 overflow-hidden w-32 text-black font-bold">
                                                    <p class=" line-clamp-2">{{ user['nom'] }} {{ user['prenom'] }}</p>
                                                </div>                                                    
                                            </div>
                                        </div>
                                        <div class="pl-4 pr-4 flex justify-between">
                                            <button type="submit" @click="sendAssigner(user.id)" class="py-1 px-2 rounded-md text-white" style="background-color: #DEA20A;">Assigner</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Fin Assignation administrateur et gestionnaire -->
                    
                    
                    <div class="fixed inset-0 flex items-center justify-center" v-if="assignerHideAB" @click="preventHideDiv">
                        <!-- Assigner un livreur -->
                        <div class="absolute bottom-48 shadow-lg w-72 h-56 bg-white">
                            <div class="flex justify-end py-2 pr-2">
                                <img src="@/images/close.png" @click="close" alt="" class="w-3 cursor-pointer" srcset="">
                            </div>
                            <!--Fin Assignation agent, manager et livreur action effectué par l'administrateur et gestionnaire-->

                            <div v-if="roles !== null && !chB " class="px-2 h-48 overflow-y-auto">
                                <div class="mb-2 border-b pb-1.5" v-for="user in itemUsers" :key="user.id">
                                    <div class="">
                                        <div class="flex mb-2">
                                            <div class="w-14 flex items-center justify-center  h-12 border rounded-full">                                                
                                                <span class="text-center inline-block align-middle" v-if="roles === 'manager'">{{ user.user['nom'].charAt(0) }}{{ user.user['prenom'].charAt(0) }}</span>
                                                <span class="text-center inline-block align-middle" v-else-if="roles === 'agent'">{{ user['nom'].charAt(0) }}{{ user['prenom'].charAt(0) }}</span>
                                                <!--img :src="getProfil(user.avatar)" class="w-14 h-12" alt="" srcset=""-->
                                            </div>
                                            <div class="w-full h-full text-left pl-2">
                                                <div class="h-6 overflow-hidden w-32 text-black font-bold">
                                                    <p class=" line-clamp-2" v-if="roles === 'manager'">{{ user.user['nom'] }} {{ user.user['prenom'] }}</p>
                                                    <p class=" line-clamp-2" v-if="roles === 'agent'">{{ user['nom'] }} {{ user['prenom'] }}</p>
                                                </div>  
                                                <div class="text-sm font-bold" v-if="roles == 'manager'">{{user.boutique.ville.nom}}</div>
                                                <!--div class="text-sm font-bold" v-else>{{rolesToString(user.roles)}}</div-->
                                            </div>
                                        </div>
                                        <div class="pl-4 pr-4 flex justify-between">
                                            <!--input type="date" name="" v-model="data_livraison" id="" v-if="roles === 'manager'" class="border px-1 border-black" style="background-color: #FBF7EE;"-->
                                            <button type="submit" @click="sendAssigner(user.id)" v-if="roles == 'manager'" class="py-1 px-2 rounded-md text-white" style="background-color: #DEA20A;">Assigner</button>
                                            <button type="submit" @click="sendAssigner(user.gestionnaire['id'])" v-if="roles === 'agent'" class="py-1 px-2 rounded-md text-white" style="background-color: #DEA20A;">Assigner</button>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                            <!--Partie pour l'agent qui assigni à une boutique-->
                            <!--div v-if="roles !== null && chB && !chx" class="px-2 h-48 overflow-y-auto">
                                <div class="mb-2 border-b pb-1.5" v-for="(shop, index) in itemboutique" :key="index">
                                    <div class="">
                                        <div class="flex mb-2">
                                            <div class="w-14 flex items-center justify-center  h-12 border rounded-full">
                                                <span class="text-center inline-block align-middle uppercase" v-if="/*roles === 'agent' || */roles === 'gestionnaire'">{{shop.nom.charAt(0)}}{{shop.nom.charAt(1)}}</span>
                                                img :src="getProfil(user.avatar)" class="w-14 h-12" alt="" srcset=""
                                            </div>
                                            <div class="w-full h-full text-left pl-2">
                                                <div class="h-6 overflow-hidden w-32 text-black font-bold">
                                                    <p class=" line-clamp-2" v-if="/* roles === 'agent' ||*/ roles === 'gestionnaire' ">{{ shop.nom }}</p>
                                                </div>  
                                                <div class="text-sm font-bold" v-if="/*roles == 'agent' ||*/ roles === 'gestionnaire'">{{ shop.ville.nom }}</div>
                                            </div>
                                        </div>
                                        <div class="pl-4 pr-4 flex justify-between">
                                            <button type="submit" @click="sendAssigner(shop.id, chB)" v-if="roles == 'agent' || roles === 'gestionnaire'" class="py-1 px-2 rounded-md text-white" style="background-color: #DEA20A;">Assigner</button>
                                        </div>
                                    </div>
                                </div> 
                            </div-->
                        </div>
                        <!-- Fin Assigner un livreur -->
                        <!--Afficher infos de livraison-->
                        <div v-if="dataClient" @click="preventHideDiv" class="absolute bottom-48 bg-white shadow-lg w-80 h-52 p-4" style="left:59%">
                            <div class="w-full flex h-14 py-3">
                                <div class="mr-2">
                                    <p class="">Client(e) :</p>
                                </div>
                                <div class="text-left h-full w-52 overflow-hidden " v-if="dataClient.client">
                                    {{ dataClient.client.nom }}
                                </div>
                            </div>
                            <div class="w-full flex h-14 py-3">
                                <div class="mr-2">
                                    <p class="">Adresse :</p>
                                </div>
                                <div class="text-left h-full w-52 overflow-hidden ">
                                    {{dataClient.adresse}}
                                </div>
                            </div>
                            <div class="w-full h-14 pt-1">
                                <button class="w-48 py-2 text-2xl rounded-lg text-white" @click="redirigerWhaysapp(dataClient.telephone)" style="background-color: #DEA20A;">Contacter</button>
                            </div>
                        </div>
                        <!--Fin Afficher infos de livraison-->
                    </div>
                </div>
            </div>
        </div>
        <!--Affiche le detail de la commande-->
        <div v-show="modal" @click="preventHideDiv" class="fixed inset-0 flex items-center justify-center">
            <div class="bg-white shadow-md rounded-lg p-6 max-w-sm mx-auto z-50" v-if="fetch_details">
                <h2 class="text-lg font-bold mb-4">Détails commande</h2>
                <div class="text-left flex mb-5 border-b" v-if="fetch_details.client">
                    <p class="mr-3 font-bold">Nom :</p> <p>{{ fetch_details.client.nom }} {{ fetch_details.client.prenom }}</p>
                </div>
                <div class="text-left flex mb-5 border-b">
                    <p class="mr-3 font-bold">Adresse Livraison :</p> <p>{{ fetch_details.adresse }}</p>
                </div>
                <div class="text-left flex mb-5 border-b">
                    <p class="mr-3 font-bold">Ville :</p> <p v-if="fetch_details.ville">{{ fetch_details.ville['nom'] }}</p>
                </div>
                <div class="text-left flex mb-5 border-b" v-for="(product, index) in fetch_details.produits" :key="index">
                    <p class="mr-3 font-bold">Produits & Quantités :</p> <p>{{product.nom}} </p><p v-if="product.pivot">({{ product.pivot.quantite }})</p>
                </div>
                <div class="text-left flex mb-5 border-b">
                    <p class="mr-3 font-bold">Téléphone :</p> <p>{{fetch_details.telephone}} </p>
                </div>
                <div class="text-left flex mb-5 border-b" v-if="fetch_details.livreur && (roles !== null)">
                    <p class="mr-3 font-bold">Livreur :</p> <p>{{fetch_details.livreur.user.nom}} {{fetch_details.livreur.user.prenom}}</p>
                </div>
                <button @click="close" id="closeModalButton" class="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                Fermer
                </button>
            </div>
        </div>
        <!--modification de la commande-->
        <div v-show="modalModif" @click="preventHideDiv" class="fixed inset-0 flex items-center justify-center">
            <div class="bg-white shadow-md rounded-lg p-6 max-w-sm mx-auto z-50" v-if="fetch_modif">
                <div class="flex justify-between">
                    <h2 class="text-lg font-bold mb-4">Modifier commande</h2>
                    <button @click="annuler" id="closeModalButton" class="p-0 text-red-500">
                        Fermer
                    </button>
                </div>
                <div class="overflow-hidden">
                    <div class="h-52 mb-2.5 overflow-x-hodden overflow-y-scroll">
                        <div class="mb-2" v-for="(produit, index) in fetch_modif" :key="index">
                            <div class="text-left flex justify-between mb-5 border-b">
                                <p class="mr-3 font-bold">Produit :</p>
                                <select name="" v-model="produit.id"  class="w-52 border border-gray-500" id="">
                                    <option disabled selected class="text-red-500" >{{ produit.nom }}</option>
                                    <option :value="itemPro.id" v-for="itemPro in itemProduit" :key="itemPro">{{ itemPro.nom }}</option>
                                </select>
                            </div>
                            <div class="text-left flex justify-between mb-5 border-b">
                                <p class="mr-3 font-bold">Quantité:</p> 
                                <input type="text" v-model="produit.quantite" v-if="produit.pivot" :placeholder="produit.pivot.quantite" class="w-52 px-1.5 border border-gray-500" name="" id="">
                                <input type="text" v-model="produit.quantite" v-else class="w-52 px-1.5 border border-gray-500" name="" id="">
                            </div>
                            <div class="flex w-full justify-between">
                                <div @click="removeItem(index)" class="px-4 py-2 rounded-lg cursor-pointer text-center text-sm text-white bg-red-500 shadow-lg font-bold ">Retirer</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div @click="addItem" class="px-4 py-2 rounded-lg cursor-pointer text-center text-sm text-black shadow-lg font-bold " style="background-color: #FBF7EE;">Ajouter</div>
                <p class="text-red-500 my-1.5" v-if="erreurPo">{{erreurPo}}</p>
                <button @click="suivantItem" id="closeModalButton" class="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    Suivant
                </button>
            </div>
        </div>
        <!--Affiche si il veut change le données du client ou qu'il maintient-->
        <div v-show="modalSuivant" @click="preventHideDiv" class="fixed inset-0 flex items-center justify-center">
            <div class="bg-white shadow-md rounded-lg p-6 max-w-sm mx-auto z-50" v-if="fetch_modif">
                <div class="flex justify-between">
                    <h2 class="text-lg font-bold mb-4">Voulez-vous modifier le Nom, Prenom, téléphone ainsi que son adresse du client?</h2>
                </div>
                <div class="flex justify-between">
                    <button @click="suivantItem2" id="closeModalButton" class="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                        Oui
                    </button>
                    <button @click="sendComData('non')" id="closeModalButton" class="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                        Non
                    </button>
                </div>
                
            </div>
        </div>
        <!--Introduire les modification de données du client-->
        <div v-show="modalSuivant1" @click="preventHideDiv" class="fixed inset-0 flex items-center justify-center">
            <div class="bg-white shadow-md rounded-lg p-6 max-w-sm mx-auto z-50" v-if="fetch_modif">
                <div class="mb-3 grid grid-cols-2">
                                <div class="mr-5 mb-2">
                                    <div class="pl-2 mb-2">
                                        <label class="text-sm font-bold">Nom</label>
                                    </div>
                                    <input type="text" v-model="nom" name="" id="" class="px-4 py-1 w-full border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                                </div>
                                <div class="mr-5 mb-2">
                                    <div class="pl-2 mb-2">
                                        <label class="text-sm font-bold">Téléphone</label>
                                    </div>
                                    <div>
                                        <input type="text" v-model="telephone" name="" id="" class="px-4 py-1 w-full border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                                    </div>
                                </div>
                                <div class="mr-5">
                                    <div class="pl-2 mb-2">
                                        <label class="text-sm font-bold">Ville</label>
                                    </div>
                                    <div>
                                        <select name="" id="" v-model="ville" class="block w-full px-4 py-1 border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                                            <option value="" disabled selected hidden></option>
                                            <option :value="ville.id" v-for="(ville, index) in getVille" :key="index">{{ ville.nom }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="mr-5">
                                    <div class="pl-2 mb-2">
                                        <label class="text-sm font-bold">Adresse</label>
                                    </div>
                                    <div>
                                        <input type="text" name="" v-model="adresse" id="" class="px-4 py-1 w-full border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                                    </div>
                                </div>
                            </div>
                <div class="flex justify-between">
                    <button @click="annuler" id="closeModalButton" class="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                        Annuler
                    </button>
                    <button @click="sendComData('oui')" id="closeModalButton" class="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                        Modifier
                    </button>
                </div>
                
            </div>
        </div>
            <div v-if="IdDrop" class="fixed inset-0 flex items-center justify-center">
                <div class="bg-white shadow-md rounded-lg p-6 max-w-sm mx-auto z-50">
                    <h2 class="text-lg font-bold mb-4">Voulez-vous supprimer cet élément?</h2>
                    <div class="text-left flex justify-between">                    
                        <button @click="supprimeCom(IdDrop)" id="closeModalButton" class="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                            Supprimer
                        </button>
                        <button @click="close" id="closeModalButton" class="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                            Fermer
                        </button>
                    </div>
                </div>
            </div>
            <div v-if="IdAnnu" class="fixed inset-0 flex items-center justify-center">
                <div class="bg-white shadow-md rounded-lg p-6 max-w-sm mx-auto z-50">
                    <h2 class="text-lg font-bold mb-4">Voulez-vous annulez cette commande?</h2>
                    <div class="text-left flex justify-between">                    
                        <button @click="confiAnnul(IdAnnu)" id="closeModalButton" class="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                            Annuler
                        </button>
                        <button @click="close" id="closeModalButton" class="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                            Fermer
                        </button>
                    </div>
                </div>
            </div>
            <div v-if="IdComf" class="fixed inset-0 flex items-center justify-center">
                <div class="bg-white shadow-md rounded-lg p-6 max-w-sm mx-auto z-50">
                    <h2 class="text-lg font-bold mb-4">Voulez-vous confirmer la livraison de cette commande?</h2>
                    <div class="text-left flex justify-between">                    
                        <button @click="confirCom(IdComf)" id="closeModalButton" class="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                            Confirmer
                        </button>
                        <button @click="close" id="closeModalButton" class="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                            Fermer
                        </button>
                    </div>
                </div>
            </div>
            <div v-if="IdReport" class="fixed inset-0 flex items-center justify-center">
                <div class="bg-white shadow-md rounded-lg p-6 max-w-sm mx-auto z-50">
                    <h2 class="text-lg font-bold mb-4">Voulez-vous reporter la livraison de cette commande?</h2>
                    <div class="text-left flex justify-between">                    
                        <button @click="comfirmreport" id="closeModalButton" class="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                            Oui
                        </button>
                        <button @click="close" id="closeModalButton" class="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                            Non
                        </button>
                    </div>
                </div>
            </div>    
            <div v-if="ModalReport" class="fixed inset-0 flex items-center justify-center">
                <div class="bg-white shadow-md rounded-lg p-6 max-w-sm mx-auto z-50">
                    <div class="flex justify-end py-2 pr-2">
                        <img src="@/images/close.png" alt="" @click="close" class="w-3 cursor-pointer" srcset="">
                    </div>
                    <form @submit.prevent="comfirmreport">
                        <div class="w-full py-2 px-4 h-24">
                            <div>
                                <input type="date" name="" v-model="datereport" id="" class="mb-4 w-48 px-1" style="background-color: #FBF7EE;">
                            </div>
                            <button type="submit" class="py-1 px-2 w-48 text-white" style="background-color: #DEA20A;">Modifier</button>
                        </div>
                    </form>
                </div>
            </div>     
            <div v-if="IdInjoin" class="fixed inset-0 flex items-center justify-center">
                <div class="bg-white shadow-md rounded-lg p-6 max-w-sm mx-auto z-50">
                    <h2 class="text-lg font-bold mb-4">Voulez-vous rendre cette commande injoignable?</h2>
                    <div class="text-left flex justify-between">                    
                        <button @click="comfirminjoin" id="closeModalButton" class="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                            Oui
                        </button>
                        <button @click="close" id="closeModalButton" class="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                            Non
                        </button>
                    </div>
                </div>
            </div>        
        <Footer></Footer>
    </div>

</template>

<script>

import Header from '@/components/layout/Header.vue'
import Footer from '@/components/layout/Footer.vue'
import {mapActions} from 'vuex'
import UserVueCompte from '@/_services/usersComptes.services'
import Boutique from '@/_services/shop.services'
import Axios from '@/_services/caller.services'
import Produit from '@/_services/produit.services'
import Ville from '@/_services/province.services'
import AssignMulti from '@/components/AssigneMulti.vue'
import AssigAdGes from '@/components/AssignAdmGest.vue'



export default{
    name: 'listecommande',

    components:{
        Header,
        Footer,
        AssignMulti,
        AssigAdGes,
    },

    data(){
        return{
            roles: null,
            fetchedData: null,
            activeId: null,
            assignerHideAB: false,
            itemUsers: [],
            itemUsersAgent: [],
            itemUsersManage: [],
            itemUserslivreur: [],

            //filter
            filters: {
                date:'',
                debut:'',
                fin: '',
                ville:'',
                produit:'',
                type:'',
                agent:'',
                livreur:'',
                statut:'',
                telephone:'',
                manager:'',
            },

            comAll: [],
            chx: null,

            itemsFiltre: null,
            IdDrop: null,
            IdAnnu : null,
            IdComf : null,

            IdReport : null,
            IdInjoin : null,
            ModalReport: false,
            idComfReport : null,
            datereport: '',

            idCom: null,
            itemboutique: null,
            non : 'non',
            oui : 'oui',
            isSendingData: false,

            //search cleint
            phoneSearch: '',
            localisation: '',
            erreurSearch:'',

            //contact client
            showClients: false,
            dataClient: null,

            //assignation livreur
            data_livraison: '',

            //liste livreur
            list_livreur: [],

            //modal detail
            modal: false,
            modalModif: false,
            modalSuivant: false,
            modalSuivant1: false,

            //modification données
            adresse: '',
            ville: '',
            nom : '',
            prenom : '',
            telephone : '',
            client_id: '',
            idModif: '',
            type_livraison: '',
            getVille: [],
            produits: [],

            //details commande pour modal
            fetch_details:[],
            fetch_modif:[],
            listLiv: [],

            //liste produit
            itemProduit: [],

            //message after l'action
            succes: null,
            erreur: null,
            erreurPo: null,
            token: null,
            erreurAS: null,

            //assigner à une boutique
            chB : '',
            itemBtM: [],
            itemBtAg: [],

            showag: false,
            showman: false,
            showliv: false,
        }
    },
    created(){
        this.roles = localStorage.getItem("role");
        this.token = localStorage.getItem('authToken');

       if(this.roles == 'agent'){
            this.getCommandAgentData();
            this.viewLivreur();
        }

        else if(this.roles == 'admin'){

            this.getCommandAll()
            //this.viewLivreur()
            this.fetchData()
            this.ListMana()
            this.ListAgent()
            
        }
        else if(this.roles == null) {

            this.localisation = localStorage.getItem("localisation");
        
        }
        else if(this.roles ==  'livreur')
        {
            this.ListComLivreur();
        
        }
        else if(this.roles == 'manager'){

            this.getListCommandeManager();
            this.viewLivreurManager();
        
        }else if(this.roles === 'gestionnaire'){
            this.getCommandGestData()
            //this.viewLivreur();            
            this.fetchData()
            this.ListMana()
            this.ListAgent()
        }
    },

    methods: {
        //liste livreur
        ...mapActions(['fechDataLivrer']),
            fetchData(){
                this.fechDataLivrer()
                    .then(response => {
                        this.listLiv = response.data;
                        ////.log(this.listLiv)
                    })
                    .catch(error => {
                        // Une erreur s'est produite lors de la récupération des données
                        //.error(error);
                    });
            },


        //commande agent
        ...mapActions(['getCommandAgent']),
        getCommandAgentData(){
            if (!this.filters.date && !this.filters.ville && !this.filters.produit && !this.filters.type){
                this.getCommandAgent()
                .then(response => {
                    this.fetchedData = response['data'];
                    ////.log('Data', this.fetchedData);
                })
                .catch(error => {
                    // Une erreur s'est produite lors de la récupération des données
                    ////.log(error);
                });}
        },

        //commande gestionnaire
        ...mapActions(['getCommandGest']),
        getCommandGestData(){
            if (!this.filters.date && !this.filters.ville && !this.filters.produit && !this.filters.type){
                this.getCommandGest()
                .then(response => {
                    this.fetchedData = response['data'];
                    ////.log(this.fetchedData)
                })
                .catch(error => {
                    // Une erreur s'est produite lors de la récupération des données
                    //.error(error);
                });}
        },

        //commande administrator
        ...mapActions(['getComAll']),
        getCommandAll(){
            if (!this.filters.date && !this.filters.ville && !this.filters.produit && !this.filters.type) {
                // Le filtre n'a pas été lancé, récupérer toutes les données
                this.getComAll()
                .then(response => {
                    this.fetchedData = response.data;
                })
                .catch(error => {
                    // Une erreur s'est produite lors de la récupération des données
                    ////.error(error);
                });
            }
        },

        //commande du manager
        ...mapActions(['getCommandeManager']),
        getListCommandeManager(){
            if (!this.filters.date && !this.filters.ville && !this.filters.produit && !this.filters.type){
                this.getCommandeManager()
                .then(response => {
                    this.fetchedData = response.data;
                    ////.
                })
                .catch(error => {
                    ////.log(error);
                });}
        },

        //Liste de manager
        ...mapActions(['viewprovince']),
            ListMana(){

                this.viewprovince()
                    .then(response =>{
                        this.itemBtM = response.data
                        ////.log(this.itemBtM)
                    })
                    .catch(error => {
                        //.error(error);
                    });

            },

        //Liste de agent
        ...mapActions(['getAgent']),
            ListAgent(){

                this.getAgent()
                    .then(response =>{
                        this.itemBtAg = response.data.data
                        ////.log(this.itemBtAg)
                    })
                    .catch(error => {
                        //.error(error);
                    });

            },

        //liste des tout les gestionnaire de genesisdream unique pour le agent
        ...mapActions(['getListeGest']),
        viewLivreur(){

            this.getListeGest()
                .then(response =>{
                    this.itemUsers = response.data
                    //.log(this.itemUsers)
                })
                .catch(error => {
                    //.error(error);
                });

        },

        //liste des tout les livreurs de genesisdream unique pour le manager
        ...mapActions(['fetchLiveShop']),
        viewLivreurManager(){

            this.fetchLiveShop()
                .then(response =>{
                    this.itemUsers = response.data.data
                    ////.log(this.itemUsers)
                })
                .catch(error => {
                    //.error('liste livreur erreur :',error);
                });

        },

        //liste de commande de livreur
        ...mapActions(['getComLivreur']),
        ListComLivreur(){
            if (!this.filters.date && !this.filters.ville && !this.filters.produit && !this.filters.type){
                this.getComLivreur()
                .then(response => {
                    this.fetchedData = response.data
                    //.log(this.fetchedData)
                })
                .catch(error => {
                    //.log(error);
                });}
        },

        formData(date){
            const dateObj = new Date(date);

            const year = dateObj.getFullYear();
            const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
            const day = dateObj.getDate().toString().padStart(2, '0');

            return `${year}/${month}/${day}`
        },

        //afficher les option
        toggleOptions(id, event){
            event.stopPropagation();
            
            this.idCom = id;

            if(this.activeId === id ){
                this.activeId = null;
            }else{
                this.activeId = id;
            }
        },

        hideDiv(){
            this.activeId = null;
            this.showClients = false;
            this.assignerHideAB = false;
            this.modal = null;
            this.dataClient= null

        },

        preventHideDiv(e){
            e.stopPropagation(); 
        },

        //afficher le div des user à assigner
        assignerAB(id){
            this.assignerHideAB = true;
            this.showClients = false;
            this.activeId = false
            this.chx = id
            //.log(this.chx)
        },
        
        assignerLi(id){
            this.chx = id
            this.showliv = true
            this.showClients = false;
            this.activeId = false
        },

        assignerMa(id){
            this.chx = id
            this.showman = true
            this.showClients = false;
            this.activeId = false
        },

        assignerAg(id){
            this.chx = id
            this.showag = true
            this.showClients = false;
            this.activeId = false
        },

        //Assigner boutique
        assignerB(id){
            this.chB = "boutique"
            this.chx = null
            this.assignerHideAB = true;
            this.showClients = false;
            this.activeId = false
            this.chx = id

        },

        //affiche le role
        rolesToString(roles){
            return roles.map(role => role.name).join(",")
        },
        

        close(){
            this.assignerHideAB = false;
            this.modal = false;
            this.modalModif = false;
            this.IdDrop = null
            this.IdAnnu = null
            this.IdComf = null
            this.IdReport = null
            this.IdInjoin = null
            this.ModalReport = false
            this.chx = null
            this.showag = false
            this.showliv = false
            this.showman = false
        },

        dropCom(id){
           
            this.activeId = null
            this.IdDrop = id
        },

        // action d'assignation de commande
        sendAssigner(idUser, role){
            this.activeId = null;
            this.assignerHideAB = null;

            if(this.isSendingData){
                return;
            }

            /* debut pour l'adminitrateur et */
            if(this.roles == 'admin' || this.roles == 'gestionnaire'){
                
                // Assigner à un livreur
                //.log(this.idCom)
                if(this.chx === 'liv'){
                    const id = this.listLiv.find(liv => liv.user_id == idUser)
                    
                    /*if(this.data_livraison != ''){*/
                        
                        this.$store.dispatch('AssignerLivreur',{

                            id: this.idCom,
                            livreur_id: id.id
                            //date_livraison: this.data_livraison
                            
                        })
                        .then((res)=>{
                            //.log(res)
                            if(res.data.status_code === 200){
                                this.succes = 'Livraison est assignée à un Livreur' 
                                setTimeout(() =>{
                                    this.succes = false;
                                    location.reload();
                                }, 
                                5000)
                            }
                        })
                        .catch((err)=>{
                            //.log(err);
                        }).finally(()=>{
                            this.assignerHideAB = false
                        })        

                        /*}else{

                        this.erreur = 'Veuillez sélectionnée la date de livraison'
                        setTimeout(() =>{
                                    this.erreur = false;
                                }, 
                                5000)
                        }*/

                }
                else if(this.chx === 'ag'){
                    role =  'agent'

                    this.$store.dispatch('SendedAssigner',{
                        role,
                        idUser: idUser,
                        id: this.idCom,
                    })
                    .then((res)=>{
                        
                        if(res.status_code == 200){
                            this.succes = 'Assignation reussi ' + role
                            setTimeout(() =>{
                                this.succes = false;
                                location.reload();
                            }, 
                            5000)
                        }
                    })
                    .catch((err)=>{
                        //.log(err)
                    })
                    .finally(()=>{
                        this.isSendingData = false
                    })
                }
                else if(this.chx === 'man'){
                    role =  'manager'
                    const id = this.itemBtM.find(liv => liv.manager.user_id == idUser)
                    
                    
                    this.$store.dispatch('SendedAssigner',{
                        role,
                        boutique_id: id.manager.id,
                        id: this.idCom
                    })
                    .then((res)=>{
                        //.log(res)
                        if(res.data.status_code === 200){
                            this.succes = 'Assignation reussi ' + role
                            setTimeout(() =>{
                                this.succes = false;
                                location.reload();
                            }, 
                            5000)
                        }
                    })
                    .catch((err)=>{
                        //.log(err)
                    })
                    .finally(()=>{
                        this.isSendingData = false
                    })
                }
                
            }

            //assignation gestionnaire et manager à un livreur
            else if(this.roles == 'manager'){
                
                if(!role){
                    
                    //if(this.data_livraison != ''){

                        this.$store.dispatch('AssignerLivreur',{

                            id: this.idCom,
                            livreur_id: idUser,
                            //date_livraison: this.data_livraison
                            
                        })
                        .then((res)=>{
                            ////.log(res)
                            if(res.data.status_code === 200){
                                this.succes = 'Livraison est assignée à un Livreur' 
                                setTimeout(() =>{
                                    this.succes = false;
                                    location.reload();
                                }, 
                                5000)
                            }
                        })
                        .catch((err)=>{
                            console.log(err);
                        });         

                    /*}else{

                        this.erreur = 'Veuillez sélectionnée la date de livraison'
                        setTimeout(() =>{
                                    this.erreur = false;
                                }, 
                                5000)
                    }*/

                }else{
                    
                    role =  'manager'
                    return
                    this.$store.dispatch('SendedAssigner',{
                        role,
                        boutique_id: idUser,
                        id: this.idCom
                    })
                    .then((res)=>{
                        //.log(res)
                        if(res.data.status_code === 200){
                            this.succes = 'Assignation reussi ' + role
                            setTimeout(() =>{
                                this.succes = false;
                                location.reload();
                            }, 
                            5000)
                        }
                    })
                    .catch((err)=>{
                        //.log(err)
                    })
                    .finally(()=>{
                        this.isSendingData = false
                    })
                }

            }else if(this.roles === 'agent'){

                this.$store.dispatch('postAsGestionnaire',{
                        gestionnaire_id: idUser,
                        id: this.idCom
                    })
                    .then((res)=>{
                        
                        if(res.data.status_code === 200){
                            this.succes = 'Assignation reussi gestionnaire' 
                            setTimeout(() =>{
                                this.succes = false;
                                location.reload();
                            }, 
                            5000)
                        }
                    })
                    .catch((err)=>{
                        //.log(err)
                    })
                    .finally(()=>{
                        this.isSendingData = false
                    })
            }

            /* fin pour l'adminitrateur*/
        },

        //redirection vers whatsapp du client 
        redirigerWhaysapp(numero){
            //.log(numero)
            const message = 'GenesisDream vous salut';

            const url = `https://wa.me/${numero}`;
            window.open(url, '_blank');
            return url;

        },

        //afficher la recherche du client par rapport  son numéro
        async searchClient(){
        
            //const phone = /*'+'+ this.localisation +''+ this.phoneSearch//.replace(/^0+(?=\d)/g, '')
            
                try{
                    const reponse = await Axios.post(
                        'api/client/commandes',
                        {
                            'telephone': this.phoneSearch,
                        }
                    );
                    
                    this.fetchedData = reponse.data.data
                    //.log(this.fetchedData)
                }catch(e){
                    this.erreurSearch = e.response.data.message;
                }finally{

                }
        
        },

        //affichage du div pour contacté le client
        actionClient(id){
            
            const idCom = this.fetchedData.find(com => com.id == id);
            this.dataClient = idCom
            this.activeId = false
            this.showClients = true;
            this.assignerHideAB = false;

        },

        //confirmation du livraison action cote manager et livreur
        async comfirmation(){
            
            this.IdComf = this.idCom
            this.activeId = false

        },

        async confirCom(id){
            const token = localStorage.getItem("authToken");

            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
            
            

            try{

                const response = await Axios.get(
                    `/api/livraison/confirmation/${id}`,
                    {headers}
                )

                //.log(response)
                if(response.data.status_code == 404){
                    this.erreur = response.data.message
                }else if(response.status == 200){
                    this.succes = 'La commande à été livrée avec succes' 
                            setTimeout(() =>{
                                this.succes = false;
                                location.reload();
                            }, 
                            5000)
                }


            }catch(e) {
                //.log(e)
            }
        },

        //annulation de la commande
        annulCom(id){
            this.IdAnnu = id
            this.activeId = false;
        },

        confiAnnul(id){
            
            this.$store.dispatch('annulCom',{
                   
                   id
                   
               })
               .then((res)=>{
                    //=.log(res);
                    this.succes = "Commande annluée"
                    this.IdAnnu = null;
                    setTimeout(() =>{
                        this.succes = false;
                        location.reload();
                    }, 
                    5000)
               })
               .catch((err)=>{
                   //.log(err);
               });
        },

        //suppression de la commande
        supprimeCom(id){
            this.IdDrop = null
            
            this.$store.dispatch('supprimeCom',{
                
                id
                
            })
            .then((res)=>{
                //.log(res);
                this.succes = res.message
                this.activeId = false;
                setTimeout(() =>{
                    this.succes = false;
                    location.reload();
                }, 
                5000)
            })
            .catch((err)=>{
                //.log(err);
            });
        },

        //détail commande modal
        detail(id){

            const idDet = this.fetchedData.find(item => item.id == id);
            this.fetch_details = idDet
            this.modal = true
            this.activeId = false
            ////.log(this.fetch_details)
            //.log(idDet)
        },

        /*getProfil(url){
             //let params = 'https://api.genesisdream.store/public/storage/' + url;
            let params = 'http://127.0.0.1:8000/storage/' + url
            return params
        },*/

        //debut modification de la commande
        modifCom(id){
            this.modalModif = true;
            const idDet = this.fetchedData.find(item => item.id == id);
            this.fetch_modif = idDet.produits
            this.activeId = false
            this.nom = idDet.client.nom
            this.adresse = idDet.adresse
            this.client_id = idDet.client.id
            this.idModif = idDet.id
            this.telephone = idDet.telephone
            this.type_livraison = idDet.type_livraison
            this.ville = idDet.ville_id
            ////.log(this.fetch_modif)            
        
        },

        //ajouter un nouveau champ
        addItem(){
            if (this.fetch_modif.length < 10) {
                this.fetch_modif.push({ produit: '', quantite: '' });
            }

            
        },
        //supprimer le champs
        removeItem(index){
            if(this.fetch_modif.length > 1){
                this.fetch_modif.splice(index, 1)
            }
        },

        annuler(){
            this.modalSuivant1 = false;
            this.modalModif = false;
            setTimeout(() =>{
                location.reload();
            },1000)
            
        },

        //suivant pour confirmé si il garde le meme données de l'utilisateur ou qu'il change
        suivantItem(){
            
            const produitValue = this.fetch_modif.map(item => item.id)
            const quantityValue = this.fetch_modif.map(item => item.quantite)
            
            if(!produitValue.every(item => item !== undefined) && !quantityValue.every(item => item !== undefined)){
               
                this.erreurPo = 'Veuillez remplir le champ produit et quantité'
                return
            }else{  
                this.modalModif = false;
                this.modalSuivant = true              
            }
            this.modalModif = false;
            this.modalSuivant = true    

        },

        suivantItem2(){
            this.modalSuivant1 = true;
            this.modalSuivant = false; 
        },

        

        //envoie de la modification de la commande
        sendComData(choix){
            const produitValue = this.fetch_modif.map(item => item.id).filter(item => item !== undefined)
            const quantityValue = this.fetch_modif.map(item => item.quantite).filter(item => item !== undefined)
            const sendDataArray = []
            
            
            for(let i = 0; i < produitValue.length; i++){
                
                if(choix === 'non'){
                    
                    sendDataArray.push({
                        produit: produitValue[i],
                        quantite: quantityValue[i],
                        client: this.client_id,
                        nom: this.nom,
                        adresse: this.adresse,
                        id : this.idModif,
                        telephone: this.telephone,
                        type_livraison: this.type_livraison,
                        ville: this.ville,
                    });
                    
                }else{
                    sendDataArray.push({
                        produit: produitValue[i],
                        quantite: quantityValue[i],
                        client: this.client_id,
                        nom: this.nom,
                        adresse: this.adresse,
                        id : this.idModif,
                        telephone: this.telephone,
                        type_livraison: this.type_livraison,
                        ville: this.ville,
                    });
                }
            }

            if(sendDataArray.length > 0) {
                ////.log('Données', sendDataArray)
            
                this.senModifCom(sendDataArray)
            }

        },

        async senModifCom(dataArray){
            
            
            const formData = new FormData();
            ////.log(dataArray)
            for(let i = 0; i < dataArray.length; i++){

                const productKey = `produit${i}`;
                const productValue = dataArray[i].produit;
                const quantiteKey = `quantite${i}`;
                const quantiteValue = dataArray[i].quantite;
                
                formData.append(productKey, productValue);
                formData.append(quantiteKey, quantiteValue);

            }

            formData.append('client', dataArray[0]['client'])
            formData.append('nom', dataArray[0]['nom'])
            formData.append('prenom', dataArray[0]['prenom'])
            formData.append('adresse', dataArray[0]['adresse'])
            const id = dataArray[0]['id']
            formData.append('telephone', dataArray[0]['telephone'])
            formData.append('type_livraison', dataArray[0]['type_livraison'])
            formData.append('ville', dataArray[0]['ville'])
            formData.append('nbr_produits', dataArray.length)

            /*for (let [key, value] of formData.entries()) {
                    //.log(key, value);
                }

                return*/

            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.token}`,
                'Access-Control-Allow-Origin':'*',
                'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,PATCH,OPTIONS',                
                'Access-Control-Allow-Headers': "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'"
            }
            
            try{
                const reponse = await Axios.post(
                    `/api/backoffice/commande/${id}`,
                    formData,
                    {headers}
                    );
                
                ////.log(reponse)
                if(reponse.data.status_code === 200){
                    this.succes = "La commande a été modifier"
                    this.modalSuivant = false
                    this.modalSuivant1 = false
                    setTimeout(() =>{
                        location.reload();
                    },1000)
                }
            } catch(error) {
                ////.log(error)
            }
            finally{

            }

        },

        handleChangeA(){
            this.sendDataFiltre()
        },

        handleChangeM(){
            this.sendDataFiltre()
        },

        handleChangeL(){
            this.sendDataFiltre()
        },
        
        //filtre request
        async sendDataFiltre(){
            const headers = {
                'Authorization': `Bearer ${this.token}`,
             }

             if(this.filters.debut > this.filters.fin){
                    alert("La date debut ne peut pas être supérieur à la date fin")
                    this.filters={
                        debut:'',
                        fin:'',
                    }
                    return
                }

            if(this.roles === 'manager'){
               
                try{
                    const response = await Axios.get(
                        '/api/boutique/manager/commandes',
                        { 
                            params: this.filters,
                            headers
                        }
                    )
                    this.fetchedData = response.data.data
                }catch(e) {
                    //.log(e)
                }finally {
                    /*this.filters = {
                        date : "",
                        type : "",
                        produit: "",
                        ville: "",
                    }*/
                }
            }else if(this.roles === 'admin'){
                
                try{
                    const response = await Axios.get(
                        '/api/commande',
                        { 
                            params: this.filters,
                            headers
                        }
                    )
                    this.fetchedData = response.data.data
                    //.log(this.fetchedData)
                }catch(e) {
                    //.log(e)
                }finally {
                    /*this.filters = {
                        date : "",
                        type : "",
                        produit: "",
                        ville: "",
                    }*/
                }
            }else if(this.roles === 'agent'){
                //.log(this.filters)
                
                try{
                    const response = await Axios.get(
                        '/api/agent/commandes',
                        { 
                            params: this.filters,
                            headers
                        }
                    )
                    this.fetchedData = response.data.data
                }catch(e) {
                    //.log(e)
                }finally {
                    this.filters = {
                        date : "",
                        type : "",
                        produit: "",
                        ville: "",
                    }
                }
            }else if(this.roles === 'livreur'){

                try{
                    const response = await Axios.get(
                        '/api/livreur/commandes',
                        { 
                            params: this.filters,
                            headers
                        }
                    )
                    //.log(response.data.data)
                    this.fetchedData = response.data.data
                }catch(e) {
                    //.log(e)
                }finally {
                    this.filters = {
                        date : "",
                        type : "",
                        produit: "",
                        ville: "",
                    }
                }

            }else if(this.roles === 'gestionnaire'){

                try{
                    const response = await Axios.get(
                        '/api/gestionnaire/commandes',
                        { 
                            params: this.filters,
                            headers
                        }
                    )
                    this.fetchedData = response.data.data
                }catch(e) {
                    //.log(e)
                }finally {
                    
                }

            }
            
        },

        //reporte commande
        reporter(id){
            this.activeId = false
            this.IdReport = id
            this.idComfReport = id
        },

        reportModal(){
            this.IdReport = null
            this.ModalReport = true
        },

        async comfirmreport(){
            
            this.$store.dispatch('reportcommande',{
                'id':  this.idComfReport,
                'date': this.datereport,
                'statut': 'reportée'
            })
            .then((res)=>{
                if(res.status === 200){
                            this.succes = `La commande du numéro ${res.data.telephone} est reportée` 
                            setTimeout(() =>{
                                this.succes = false;
                                location.reload();
                            }, 
                            5000)
                        }
            })
            .catch(err=>{
                //.log(err)
            })
            .finally(()=>{
                this.ModalReport = false
                this.datereport = ''
                this.IdReport = null
            });

        },

        // Injoingable
        injoign(id){
            this.activeId = false
            this.IdInjoin = id
        },

        async comfirminjoin(){
            
            this.$store.dispatch('injoincommande',{
                'id':  this.IdInjoin,
                'statut': 'injoignable'
            })
            .then((res)=>{
                if(res.status === 200){
                    this.succes = `La commande du numéro ${res.data.telephone} est injoignable` 
                    setTimeout(() =>{
                        this.succes = false;
                        location.reload();
                    },5000)
                }
            })
            .catch(err=>{
                //.log(err)
            })
            .finally(()=>{
                this.IdInjoin = null
            });

        },
      
    },

    mounted(){
        //affichage des user seul admin et gestion utilise cela
        const Users = new UserVueCompte();
        Users.getUsersAlls() 
            .then(usercompte => {

                if(this.roles === 'admin' || this.roles === 'gestionnaire'){
                    this.itemUsersAgent = usercompte.data.filter(user => {
                    const roles = user.roles.map(role => role.name);
                    
                        return roles.includes("agent")
                    });

                    this.itemUsersManage = usercompte.data.filter(user => {
                        const roles = user.roles.map(role => role.name);

                        return roles.includes("manager")
                    });

                    this.itemUserslivreur = usercompte.data.filter(user => {
                        const roles = user.roles.map(role => role.name);
                        
                        return roles.includes("livreur")
                    });

                }

            }).catch(error => {
                //(error)
            });

        //affichage des boutique
        const boutique = new Boutique();
        boutique.getAllShop()
            .then(res => {
                this.itemboutique = res.data;
            }).catch(error => {
                //(error)
            });

        
        //liste de produit
        const produit = new Produit();
        produit.getAllProduit()
            .then(product => {
                this.itemProduit = product.data
                ////.log(this.itemProduit)
            })
            .catch(error => {
                ////.log(error.product.data.message)
            })

        //get ville
        const ville = new Ville();
        ville.getAllPro()
                .then(prov => {
                    this.getVille = prov.data;
                }).catch(err => {
                    ////.log(err)
                });

        

    },

    computed:{
        filteredItems() {
            return this.itemsFiltre;
        }   
    }

}

</script>