import Axios from './caller.services'

export default class CatServices {

    //Récupérer la liste de categories
    async getAllCat(){
        try {
            const response = await Axios.get('/api/categorie');
            return response.data;
        } catch (error) {
            return [];
        }
    };

    //Récupérer le produit par son ID
    async getCatById(id){
        try {
            const response = await Axios.get(`/api/produits/${id.nom}`);
            return response;
        } catch (error) {
            throw error;
        }
    };

    //Ajouter une nouvelle produit 
    async addCat(nom, token){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        try {
            const response = await Axios.post(
                '/api/backoffice/categorie', 
                nom, 
                {headers});

            return response;
        } catch (error) {
            throw error
        }   
    };

    //modifier 
    async editCat(token, data){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }

        
        try {
            const response = await Axios.post(
                `/api/backoffice/categorie/${data.id}`, 
                {
                  'nom':  data.nom
                }, 
                {headers});

            return response;
        } catch (error) {
            throw error
        }   
    };

     //modifier 
     async dropCat(token, id){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        try {
            const response = await Axios.delete(
                `/api/backoffice/categorie/${id.id}`,
                {headers});

            return response;
        } catch (error) {
            throw error
        }   
    }

}
