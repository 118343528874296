<template>
    <div>
        <div class="w-screen overflow-x-hidden" @click="hideDiv">
            <Header></Header>
 
            <div class="w-full bg-white mb-12 mt-1.5 overflow-x-hidden">
                <div class="w-full h-full">
                    <div class="w-full px-4 sm:px-36">
                        <div class="my-10">
                            <form class="" action="" @submit.prevent="sendDataFiltre">
                                <div class=" grid  grid-cols-2 md:grid-cols-2" v-show="roles != null">
                                    <div class="flex w-full mb-4">
                                        <div class="w-1/4 flex items.center justify-center mr-4">
                                            <label for="" class="font-bold">Type</label>
                                        </div>
                                        <div class="w-3/4">
                                            <select name="" id="" v-model="filters.role" class="block w-full px-4 py-1.5 rounded-lg " style="background-color: #FBF7EE;">
                                                <option value="" disabled selected hidden></option>
                                                <option value="admin">Administrateur</option>
                                                <option value="gestionnaire">Gestionnaire de stock</option>
                                                <option value="manager">Manager</option>
                                                <option value="agent">Agent</option>
                                                <option value="livreur">Livreur</option>
                                            </select>   
                                        </div>
                                    </div>
                                    <div class="flex w-full mb-4">
                                        <div class="w-1/4 mx-4">
                                            <label for="" class="font-bold">Ville</label>
                                        </div>
                                        <div class="w-3/4">
                                            <select name="" id="" v-model="filters.ville" class="block w-full px-4 py-1.5 rounded-lg " style="background-color: #FBF7EE;">
                                                <option :value="vil.id" v-for="(vil, index) in itemProvince" :key="index">{{ vil.nom }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div class="w-full  mt-7 mb-14">
                                        <button type="submit" class="w-40 px-4 text-white py-1.5 rounded-lg" style="background-color: #DEA20A;">Filtrer le resultat</button>
                                </div>
                            </form>
                            <div class="flex">
                                <router-link to="/enregistrement">
                                    <button class="px-4 text-white py-1.5 rounded-lg" style="background-color: #DEA20A;">Enregistrer un utilisateur</button>
                                </router-link>
                            </div>
                        </div>
                        <div class="px-4 flex justify-center" v-show="succes">
                            <p class="bg-red-400 px-1.5 w-auto py-2 text-white">{{succes}}</p>
                        </div>
                        <div class="pb-20">
                            <div class="w-full mb-14 overflow-x-hidden" style="background-color: #FBF7EE;">
                                
                                    <div class="flex w-full overflow-x-hidden" style="background-color: #DEA20A;">
                                        <div class="w-40 text-center text-white text:xs sm:text-sm py-5 text-md">
                                            N°
                                        </div>
                                        <div class="w-52 text-center text-white text:xs sm:text-sm py-5 text-md">
                                            Nom
                                        </div>
                                        <div class="w-52 text-center text-white text:xs sm:text-sm py-5 text-md">
                                            Prénom
                                        </div>
                                        <div class="w-52 text-center text-white text:xs sm:text-sm py-5 text-md">
                                            Type compte
                                        </div>
                                        <div class="w-52 text-center text-white text:xs sm:text-sm py-5 text-md">
                                            Statut
                                        </div>
                                        <div class="w-52 text-center text-white text:xs sm:text-sm py-5 text-md">

                                        </div>
                                    </div>
                                    <div class=" w-full overflow-x-hidden">
                                        <div class="flex w-full my-1.5" v-for="items in sortedItems" :key="items.id">
                                            <div class="w-40 p-2 overflow-x-auto whitespace-nowrap   text-sm  text-center text-black ">
                                                {{ items.id }}
                                            </div>
                                            <div class="w-52 p-2 overflow-x-auto whitespace-nowrap   text-sm  text-center text-black">
                                                {{ items.nom }}
                                            </div>
                                            <div class="w-52 p-2 overflow-x-auto whitespace-nowrap   text-sm  text-center text-black">
                                                {{ items.prenom }}
                                            </div>
                                            <div class="w-52 p-2 overflow-x-auto whitespace-nowrap   text-sm  text-center text-black">
                                                {{ items.roles['0']['name'] }}
                                            </div>
                                            <div class="w-52 p-2 overflow-x-auto whitespace-nowrap   text-sm  text-center text-black">
                                                <p class="text-green-500" v-if="items.statut === 'activé'">{{ items.statut }}</p>
                                                <p class="text-red-500" v-else>{{ items.statut }}</p>
                                            </div>
                                            <div class="w-52 p-2 overflow-x-auto whitespace-nowrap   text-sm  text-center text-black">
                                                <div class="relative inline-flex">
                                                    <div class="flex items-center">
                                                        <img @click="toggleOptions(items.id, $event)" src="@/images/option.png" alt="" class="w-6 h-6 mr-2 cursor-pointer">
                                                    </div>
                                                </div>
                                                <div class="absolute right-0 md:right-10 z-10">
                                                    <div class="py-4 px-2 text-left shadow-lg w-40 bg-white text-black rounded-lg" v-if="activeId === items.id" @click="preventHideDiv">
                                                        <button class="block text-left my-1.5 w-full hover:text-blue-600" @click="redirigerWhaysapp(items.telephone)">Contacter</button>
                                                        <button class="block text-left my-1.5 w-full hover:text-blue-600" v-if="items.statut != 'bloqué'" @click="block(items.id)">Bloquer l'utilisateur</button>
                                                        <button class="text-left my-1.5 hidden w-full hover:text-blue-600" v-if="items.statut === 'bloqué'" @click="block(items.id)">Débloquer l'utilisateur</button>
                                                        <button class="text-left my-1.5 w-full hover:text-blue-600" v-if="items.roles['0']['name'] === 'agent' || items.roles['0']['name'] === 'gestionnaire' || items.roles['0']['name'] === 'manager'" @click="ViewsDashbord(items.id,items.roles['0']['name'])">Dashboard</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                
                                <!-- Les autres colonnes -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="idLivreur" class="fixed inset-0 flex items-center justify-center">
                <div class="bg-white shadow-md rounded-lg p-2 max-w-sm mx-auto z-50">
                    <div class="text-red-500 cursor-pointer flex mb-2 justify-end" @click="close">Fermer</div>
                    
                    <div class="grid grid-cols-2 md:grid-cols-2">
                        <div class="flex mb-4">
                            <div class="w-1/4">
                                <label for="" class="font-bold">Du</label>
                            </div>
                            <div class="w-3/4">
                                <input type="date" name="" v-model="date.debut" id="" placeholder="+24***" class="block border w-62 px-1" style="background-color: #FBF7EE;">
                            </div>
                        </div>
                        <div class="flex mb-4">
                            <div class="w-1/4 mr-4">
                                <label for="" class="font-bold">Au</label>
                            </div>
                            <div class="w-3/4">
                                <input type="date" name="" v-model="date.fin" @change="dateFil" id="" placeholder="+24***" class="block border w-62 px-1" style="background-color: #FBF7EE;">
                            </div>
                        </div>
                        <!--div class="flex w-full mb-4">
                            <div class="w-1/4 mr-4">
                                <label for="" class="font-bold">Date</label>
                            </div>
                            <div class="w-3/4">
                                <select name="" id="" class="block w-full px-1" style="background-color: #FBF7EE;">
                                    <option value="aujourd'hui">Aujourd'hui</option>
                                        <option value="hier">Hier</option>
                                        <option value="semaine">Cette semaine</option>
                                        <option value="mois">Ce mois</option>
                                        <option value="dernier-mois">Mois dernier</option>
                                        <option value="année">Année</option>
                                        <option value="dernière-année">Année dernière</option>
                                </select>
                            </div>
                        </div-->
                    </div>
                    <div class="w-full">
                        <div class="grid grid-cols-2 md:grid-cols-3">
                            <div class="h-32 mr-1 mb-1 shadow-lg pt-0" style="background: linear-gradient(to left top,#DEA20A , #FBF7EE );">
                                <div class="w-full h-18 overflow-hidden px-1 text-ellipsis leading-none">
                                    <p class="text-lg py-4 h-full font-bold text-right text-white line-clamp-1">{{ itemDash['commandes'] }}</p>
                                </div>
                                <div class="w-full h-14 overflow-hidden px-1 text-ellipsis leading-none">
                                    <p class="text-xl font-bold py-2 h-full text-right text-black line-clamp-1">Commandes</p>
                                </div>
                            </div>
                            <div v-if="roleRec !== 'manager'" class="h-32 mr-1 shadow-lg bg-gradient-to-l from-blue-200 to-blue-400">
                                <div class="w-full h-18 overflow-hidden px-1 text-ellipsis leading-none">
                                    <p class="text-lg py-4 h-full font-bold text-right text-white line-clamp-1">{{ itemDash['en cours'] }}</p>
                                </div>
                                <div class="w-full h-14 overflow-hidden px-1 text-ellipsis leading-none">
                                    <p class="text-xl font-bold py-2 h-full text-right text-black line-clamp-2">En cours...</p>
                                </div>
                            </div>

                            <div class="h-32 mr-1 shadow-lg bg-gradient-to-l from-yellow-200 to-yellow-400">
                                <div class="w-full h-18 overflow-hidden px-1 text-ellipsis leading-none">
                                    <p class="text-lg py-4 h-full font-bold text-right text-white line-clamp-1">{{ itemDash['livraisons'] }}</p>
                                </div>
                                <div class="w-full h-14 overflow-hidden px-1 text-ellipsis leading-none">
                                    <p class="text-xl font-bold py-2 h-full text-right text-black line-clamp-2">Livrer</p>
                                </div>
                            </div>
                            <div v-if="roleRec !== 'manager'" class="h-32 mr-1 shadow-lg"  style="background: linear-gradient(to left top, #E70C0C, #F48C8C );">
                                <div class="w-full h-18 overflow-hidden px-1 text-ellipsis leading-none">
                                    <p class="text-lg py-4 h-full font-bold text-right text-white line-clamp-1">{{ itemDash['annulées'] }}</p>
                                </div>
                                <div class="w-full h-14 overflow-hidden px-1 text-ellipsis leading-none">
                                    <p class="text-xl font-bold py-2 h-full text-right text-black line-clamp-2">Annuler</p>
                                </div>
                            </div>

                            <div v-if="roleRec !== 'manager'" class="h-32 mr-1 mb-1 shadow-lg bg-gradient-to-l from-yellow-200 to-yellow-400">
                                <div class="w-full h-18 overflow-hidden px-1 text-ellipsis leading-none">
                                    <p class="text-lg py-4 h-full font-bold text-right text-white line-clamp-1">{{ itemDash['en livraison'] }}</p>
                                </div>
                                <div class="w-full h-14 overflow-hidden px-1 text-ellipsis leading-none">
                                    <p class="text-xl font-bold h-full text-right text-black line-clamp-2">En livraison</p>
                                </div>
                            </div>
                            <div v-if="roleRec === 'manager'" class="h-32 mr-1 mb-1 shadow-lg bg-gradient-to-l from-yellow-200 to-yellow-400">
                                <div class="w-full h-18 overflow-hidden px-1 text-ellipsis leading-none">
                                    <p class="text-lg py-4 h-full font-bold text-right text-white line-clamp-1">{{ itemDash['ventes'] }}</p>
                                </div>
                                <div class="w-full h-14 overflow-hidden px-1 text-ellipsis leading-none">
                                    <p class="text-xl font-bold h-full text-right text-black line-clamp-2">Ventes</p>
                                </div>
                            </div>
                            <div v-if="roleRec === 'manager'" class="h-32 mr-1 mb-1 shadow-lg bg-gradient-to-l from-yellow-200 to-yellow-400">
                                <div class="w-full h-18 overflow-hidden px-1 text-ellipsis leading-none">
                                    <p class="text-lg py-4 h-full font-bold text-right text-white line-clamp-1">{{ itemDash['retraits'] }}</p>
                                </div>
                                <div class="w-full h-14 overflow-hidden px-1 text-ellipsis leading-none">
                                    <p class="text-xl font-bold h-full text-right text-black line-clamp-2">Retraits</p>
                                </div>
                            </div>
                            <div class="h-32 mr-1 shadow-lg bg-gradient-to-l from-blue-200 to-blue-400">
                                <div class="w-full h-18 overflow-hidden px-1 text-ellipsis leading-none">
                                    <p class="text-lg py-4 h-full font-bold text-right text-white line-clamp-1">{{ itemDash['CA'] }}</p>
                                </div>
                                <div class="w-full h-14 overflow-hidden px-1 text-ellipsis leading-none">
                                    <p class="text-xl font-bold py-2 h-full text-right text-black line-clamp-2">Chiffre d'affaire</p>
                                </div>
                            </div>

                            <div class="h-32 mr-1 shadow-lg bg-gradient-to-r from-teal-200 to-teal-400">
                                <div class="w-full h-18 overflow-hidden px-1 text-ellipsis leading-none">
                                    <p class="text-lg py-4 h-full font-bold text-right text-white line-clamp-1">{{ itemDash['profit'] }}</p>
                                </div>
                                <div class="w-full h-14 overflow-hidden px-1 text-ellipsis leading-none">
                                    <p class="text-xl font-bold h-full text-right text-black line-clamp-2">Profit</p>
                                </div>
                            </div>
                            <div v-if="roleRec !== 'agent'" class="h-32 mr-1 shadow-lg bg-gradient-to-r from-teal-200 to-teal-400">
                                <div class="w-full h-18 overflow-hidden px-1 text-ellipsis leading-none">
                                    <p class="text-lg py-4 h-full font-bold text-right text-white line-clamp-1">{{ itemDash['achats'] }}</p>
                                </div>
                                <div class="w-full h-14 overflow-hidden px-1 text-ellipsis leading-none">
                                    <p class="text-xl font-bold h-full text-right text-black line-clamp-2">Achats</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    </div>
</template>

<script>

import Header from '@/components/layout/Header.vue'
import Footer from '@/components/layout/Footer.vue'
import UserVueCompte from '@/_services/usersComptes.services'
import Axios from '@/_services/caller.services'
import Ville from '@/_services/province.services'
import MiniDash from '@/components/minidashoard.vue'
import {mapActions} from 'vuex'


    export default{
        name: 'listusers',
        
        components:{
            Header,
            Footer,
            MiniDash
        },

        data(){
            return {
                itemUsers: [],
                itemDash: [],
                itemBt: [],
                itemBtM: [],
                roles: null,
                roleRec: null,
                token: null,
                activeId: null,
                idLivreur: null,
                succes: null,
                itemProvince: [],
                filters:{
                    role: '',
                    ville: '',
                },

                date:{
                    debut: '',
                    fin: '',
                },

                idUser: null,
                roleId: null,
                
            }
        },

        mounted(){
            const Users = new UserVueCompte();
            Users.getUsersAlls() 
                .then(usercompte => {                 
                    this.itemUsers = usercompte.data;
                    ////.log(this.itemUsers)
                })
            
            const province = new Ville();
            province.getAllPro()
                .then(prov => {
                    this.itemProvince = prov.data;
                })
                .catch(err => {
                    ////.log(err.data.message);
                });
        },

        computed:{
            sortedItems(){
                return this.itemUsers.sort((a,b) => a.id - b.id);
            }
        },
        created(){
            this.roles = localStorage.getItem("role");
            this.token = localStorage.getItem("authToken") 
            if(this.roles == null){
            this.$router.push('/authentification')            
            }

            this.ListGest()
            this.ListMana()
        },

        methods:{
            //liste du manager et gestionnaire
            ...mapActions(['getListeGest']),
            ListGest(){

                this.getListeGest()
                    .then(response =>{
                        this.itemBt = response.data
                        ////.log(response)
                    })
                    .catch(error => {
                        //.error(error);
                    });

            },

            ...mapActions(['viewprovince']),
            ListMana(){

                this.viewprovince()
                    .then(response =>{
                        this.itemBtM = response.data
                    })
                    .catch(error => {
                        //.error(error);
                    });

            },
            //fin liste

            toggleOptions(id, event){
                event.stopPropagation();
                
                this.idCom = id;

                if(this.activeId === id ){
                    this.activeId = null;
                }else{
                    this.activeId = id;
                }
            },

            //redirection vers whatsapp du client 
            redirigerWhaysapp(numero){
                //.log(numero)
                const message = 'GenesisDream vous salut';

                const url = `https://wa.me/${numero}`;
                window.open(url, '_blank');
                return url;

            },

            hideDiv(){
                this.activeId = null;
            },

            async block(id){

                const token = localStorage.getItem("authToken");

                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'Access-Control-Allow-Origin':'*',
                    'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,PATCH,OPTIONS',
                    
                    'Access-Control-Allow-Headers': 'Content-Type, Origin, X-Auth-Token, Authorization'
                }

                try{

                    const response = await Axios.get(
                        `/api/backoffice/compte/block/${id}`,
                        {headers}
                    )

                    //.log(response)

                    if(response.data.status_code === 200){
                        this.succes = "L'utilisation " + response.data.data.nom + " " + response.data.data.prenom + " est bloqué"
                        setTimeout(() =>{
                                    this.succes = false;
                                    location.reload();
                                }, 
                                2500)
                    }

                }catch(error){
                    //.log(error)
                }

            },

            //filtre request
            async sendDataFiltre(){
                const headers = {
                    'Authorization': `Bearer ${this.token}`,
                }
                
                try{
                    const response = await Axios.get(
                        '/api/users',
                        { 
                            params: this.filters,
                            headers
                        }
                    )
                    //this.getDataDash = response.data.data
                    ////.log(response)
                }catch(e) {
                    //.log(e)
                }finally {
                    this.filters = {                                        
                        role:"",
                        ville:"",
                    }
                }
            },

            close(){
                this.idLivreur = null
            },

            //Views Dashboard
            async ViewsDashbord(id, role){
                this.idLivreur = id;
                this.option = null
                this.roleRec = role

                if(role === 'agent'){
                    const headers = {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.token}`,
                        'Access-Control-Allow-Origin':'*',
                        'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,PATCH,OPTIONS',
                        'Access-Control-Allow-Headers': "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'"
                    }

                    
                    try{
                        const reponse = await Axios.get(
                            `/api/dashboard/${id}/agent`,
                            {headers, }
                            );
                        if(!this.date.debut && !this.date.fin){
                            this.itemDash = reponse.data.data
                        }
                        //.log(reponse)
                    } catch(error) {
                        //.error(error);
                        ////.log(error)
                    };
                }else if(role === 'manager'){
                    const idVi = this.itemBtM.find(com => com.id == id);
                    const IdG = idVi.manager['id']
                    const headers = {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.token}`,
                        'Access-Control-Allow-Origin':'*',
                        'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,PATCH,OPTIONS',
                        'Access-Control-Allow-Headers': "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'"
                    }

                    
                    try{
                        const reponse = await Axios.get(
                            `/api/dashboard/${IdG}/kpi-manager`,
                            {headers, }
                            );
                        if(!this.date.debut && !this.date.fin){
                            this.itemDash = reponse.data.data
                        }
                        ////.log(reponse)
                    } catch(error) {
                        //.error(error);
                        ////.log(error)
                    };

                }else if(role === 'gestionnaire'){
                    const idVi = this.itemBt.find(com => com.id == id);
                    const IdG = idVi.gestionnaire['id']
                    const headers = {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.token}`,
                        'Access-Control-Allow-Origin':'*',
                        'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,PATCH,OPTIONS',
                        'Access-Control-Allow-Headers': "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'"
                    }

                    
                    try{
                        const reponse = await Axios.get(
                            `/api/dashboard/${IdG}/agent`,
                            {headers, }
                            );
                        if(!this.date.debut && !this.date.fin){
                            this.itemDash = reponse.data.data
                        }
                        ////.log(reponse)
                    } catch(error) {
                        //.error(error);
                        ////.log(error)
                    };
                }
                
            },

            async dateFil(){
                if(this.roleRec === 'agent'){
                    const headers = {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.token}`,
                        'Access-Control-Allow-Origin':'*',
                        'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,PATCH,OPTIONS',
                        'Access-Control-Allow-Headers': "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'"
                    }

                    
                    try{
                        const reponse = await Axios.get(
                            `/api/dashboard/${this.idLivreur}/agent/${this.date}`,
                            {headers, params: this.date}
                            );
                        this.itemDash = reponse.data.data
                        ////.log(reponse)
                    } catch(error) {
                        //.error(error);
                        ////.log(error)
                    }finally{
                        this.date={
                            debut:'',
                            fin:''
                        }
                    }
                }else if(this.roleRec === 'manager'){
                    const idVi = this.itemBtM.find(com => com.id == this.idLivreur);
                    const IdG = idVi.manager['id']
                    const headers = {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.token}`,
                        'Access-Control-Allow-Origin':'*',
                        'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,PATCH,OPTIONS',
                        'Access-Control-Allow-Headers': "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'"
                    }

                    
                    try{
                        const reponse = await Axios.get(
                            `/api/dashboard/${IdG}/kpi-manager/${this.date}`,
                            {headers, params: this.date  }
                            );
                        this.itemDash = reponse.data.data
                        ////.log(reponse)
                    } catch(error) {
                        //.error(error);
                        ////.log(error)
                    }finally{
                        this.date={
                            debut:'',
                            fin:''
                        }
                    }

                }else if(this.roleRec === 'gestionnaire'){
                    const idVi = this.itemBt.find(com => com.id == this.idLivreur);
                    const IdG = idVi.gestionnaire['id']
                    const headers = {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.token}`,
                        'Access-Control-Allow-Origin':'*',
                        'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,PATCH,OPTIONS',
                        'Access-Control-Allow-Headers': "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'"
                    }

                    
                    try{
                        const reponse = await Axios.get(
                            `/api/dashboard/${IdG}/agent/${this.date}`,
                            {headers, params: this.date }
                            );
                        this.itemDash = reponse.data.data
                        ////.log(reponse)
                    } catch(error) {
                        //.error(error);
                        ////.log(error)
                    }finally{
                        this.date={
                            debut:'',
                            fin:''
                        }
                    }
                }
            }
            
        }
    }

</script>