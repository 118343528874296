<template>
    <div class="w-full h-32 md:h-96 mb-10" style="background-color: #FBF7EE"  >
        <div class="flex w-full h-10 mb-4 py-4 px-6">
                <p class="text-3xl md:text-xl lg:text-2xl text-bold justify-start md:justify-center uppercase">Catégories</p>
        </div>
        <div class="py-2 px-5 w-full h-16 md:h-5/6 mb-10 md:mb-0  overflow-x-hidden overflow-y-hidden md:overflow-y-auto" style="background-color: #FBF7EE">
            
            <div class=" rounded-lg md:h-full overflow-x-auto  md:overflow-x-hidden overflow-y-hidden  md:overflow-y-auto  md:block flex   py-2">
                <div class="py-1 rounded-md mb-2 w-max md:w-full mr-2 md:mr-0 whitespace-nowrap cursor-pointer px-10 text-white" v-for="items in sortedItems" :key="items.id" style="background-color: #DEA20A;">
                    <router-link :to="getDetailUrl(items.nom)">
                        <p class="uppercase text-sm">{{items.nom}}</p>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import CateProduit from '@/_services/category.services'
import CryptoJs from 'crypto-js'


    export default{
        name: "ListCategory",

        data(){
            return {
                itemsCat: [],
            }
        },

        mounted(){
            const cateproduits = new CateProduit();
            cateproduits.getAllCat()
                .then(categories => {
                    this.itemsCat = categories.data
                }).catch(err => {
                    ////.log(err)
                })
        },

        computed:{
            sortedItems(){
                if(this.itemsCat !== null){
                    return this.itemsCat.sort((a,b) => a.id - b.id);

                }else{
                    return
                }
            }
        },

        methods:{
            encrytpId(id){

                const encryptedId = CryptoJs.AES.encrypt(id.toString(), this.encryptionKey).toString();
                return encodeURIComponent(encryptedId);

            },

            getDetailUrl(id){

                const plainText = id;
                const encryptedText = encodeURIComponent(CryptoJs.AES.encrypt(plainText, 'myEncryptionKey').toString());
                return '/ListCatVue/' + encryptedText;

            }
        }
    }

</script>