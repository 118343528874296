<template>
    <div>
        <div class="flex items-center  justify-center h-screen">
            <div class="bg-gray-200">
                <div class="flex h-full flex-col md:flex-row">
                    <div class="w-full md:w-2/4 md:order-first" style="background: linear-gradient(to bottom, #DEA20A , #FBF7EE );">
                        <div class="px-10 pt-14">
                            <div class="flex items-center justify-center">
                                <img src="@/images/LOGO_GEN_PNG_BLANC.png" class="w-72" alt="">
                            </div>
                            <div class="flex items-center justify-center bg-white py-10 rounded-lg">
                                <p class="text-4xl font-bold uppercase drop-shadow-md">connexion</p>
                            </div>
                        </div>
                    </div>
                    <div class="w-full pt-10 md:w-2/4 px-4 md:px-10 pb-20 md:pb-0 md:pb-10 md:pt-10 text-left md:text-center md:mt-0" style="background-color: #FBF7EE;">
                        <form action="" @submit.prevent="login" ref="restlogin">
                            <div class="mb-6 md:mb-12">
                                <div class="pl-2 mb-2">
                                    <label for="phone" class="text-2xl font-bold">Téléphone</label>
                                </div>
                                <input type="text" v-model="telephone" name="" id="" class="px-4 py-2 w-full border rounded-lg border-gray-600 shadow-md shadow-gray-400" required>
                            </div>
                            <div class="mb-12">
                                <div class="pl-2 mb-2">
                                    <label for="password" class="text-2xl font-bold">Mot de passe</label>
                                </div>
                                <input id="password" type="password" name="password" v-model="password" class="px-4 py-2 w-full border rounded-lg border-gray-600 shadow-md shadow-gray-400" required>
                                <div class="mt-4 hidden">
                                    <router-link to="inscription" style="color: #DEA20A;">
                                        Créer Compte
                                    </router-link>
                                </div>
                            </div>
                            <div class="text-right hidden my-10 font-bold text-xl" style="color: #072A62">
                                <router-link to="">
                                    <span>Mot de passe oublié ?</span>
                                </router-link>
                            </div>
                            <div class="">
                                <button type="submit" class="px-4 py-2 w-full rounded-lg text-white uppercase font-bold text-2xl" style="background-color: #DEA20A;">connexion</button>
                            </div>
                            <div v-if="erreur" class="error-message text-red-500">{{ erreur }}</div>
                            
                        </form>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>

export default{
    name: 'authentification',

    data(){
        return {
            telephone: '',
            password: '',
            erreur: '',
        };
    },

    methods: {
        login: function(){
                const self = this; 
                this.$store.dispatch('login',{
                    telephone : this.telephone,
                    password : this.password
                }).then((response)=>{
                    ////.log('Esimbi :',response)
                    
                    if(response.data.data == null && response.data.status_code == 401 || response.status == 401){
                        this.erreur = response.data.message
                    }else{

                        localStorage.setItem("authToken", response.data['data']['access_token']);
                        localStorage.setItem("role", response.data['data']['user']['roles']['0']['name']);
                        localStorage.setItem("IdUser", response.data['data']['user']['id']);

                        if(response['data']['data']['user']['admin'] != null || response['data']['data']['user']['gestionnaire'] != null){
                            self.$router.push('/Dashboard')
                        }  
                        else if(response['data']['data']['user']['agent'] != null){
                            self.$router.push('/listecommande')
                        }
                        else if(response['data']['data']['user']['livreur'] != null){
                            self.$router.push('/listecommande')
                        }
                        else if(response['data']['data']['user']['manager'] != null){
                            self.$router.push('/Dashboard')
                        }

                    }
                    //return response.data.data
                    /**/
                })
                .catch(error => {
                    
                    setTimeout(() =>{
                                this.erreur = error.response.data.message.split('.')[0];
                                location.reload();
                            }, 
                            2000)
                });
                this.restlogin();
                
            },
            restlogin(){
                this.telephone = '';
                this.password = '';
                this.$refs.restlogin.reset()
            }
    },

    computed:{
        /*error() {
            return this.$store.state.login.errors;
        },

        success() {
            return this.$store.state.login.success;
        },*/
    }
};

</script>