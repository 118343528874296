<template>
    <div>
        <div class="flex items-center justify-center h-screen">
            <div class="bg-gray-200">
                <div class="flex h-full flex-col md:flex-row">
                    <div class="w-full md:w-2/4 md:order-first" style="background: linear-gradient(to bottom, #DEA20A , #FBF7EE );">
                        <div class="px-10 py-4 ">
                            <div class="flex items-center justify-center">
                                <img src="@/images/LOGO_GEN_PNG_BLANC.png" class="w-72" alt="">
                            </div>
                            <div class="flex items-center justify-center bg-white py-2 rounded-lg">
                                <p class="text-3xl font-bold uppercase drop-shadow-md">Nouveau catégorie</p>
                            </div>
                        </div>
                    </div>
                    <div class="w-full pt-10 md:w-2/4 px-4 md:px-10 pb-20 md:pb-0 md:pb-10 md:pt-10 text-left md:text-center md:mt-0" style="background-color: #FBF7EE;">
                        <form @submit.prevent="submitCat" ref="restlogin">                            
                            <div class="mb-6 md:mb-12">
                                <div class="pl-2 mb-2">
                                    <label for="nomCat" class="text-2xl font-bold">Nom de la catégorie</label>
                                </div>
                                <input type="text" v-model="namCat" name="" id="" class="px-4 py-2 w-full border rounded-lg border-gray-600 shadow-md shadow-gray-400" required>
                            </div>
                            <div class="">
                                <button type="submit" class="px-4 py-2 w-full rounded-lg text-white font-bold text-2xl" style="background-color: #DEA20A;">Ajouter</button>
                            </div>
                            <div class="text-red-500 mt-"></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default{
    name: 'newcategorie',

    data(){
        return {
            namCat: ''
        }
    },

    computed:{
       
    },
    methods:{

        submitCat : function(){
            this.$store.dispatch('submitCat',{
                nom : this.namCat
            })
            .then((response) =>{
                ////.log(response)
                this.$router.push('/category')
            })
            .catch((error) => {
                ////.log(error);
            });
            this.restlogin();
        },
        restlogin(){
                this.namCat = '';
                this.$refs.restlogin.reset()
            }

    }

}

</script>