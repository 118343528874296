<template>
   
    <div class="w-full overflow-x-hidden">
        <Header></Header>

        <div class="w-full bg-white mb-12 mt-1.5 overflow-x-hidden">
            <div class="w-full h-full">
                <div class="w-full px-4 sm:px-36">
                    <form action="" @submit.prevent="sendDataFiltre">
                        <div class="my-7">
                            <div class="grid grid-cols-2 md:grid-cols-3">
                                <div class="flex w-full mb-4">
                                    <div class="w-1/4 mr-4">
                                        <label for="" class=" font-bold">Ville</label>
                                    </div>
                                    <div class="w-3/4">
                                        <select name="" id="" v-model="filters.ville" class="block w-full px-4 py-1.5 rounded-lg " style="background-color: #FBF7EE;">
                                            <option :value="vil.id" v-for="(vil, index) in getVille" :key=index>{{ vil.nom }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="flex w-full mb-4">
                                <div class="w-1/4 mx-4">
                                        <label for="" class=" font-bold">Boutique</label>
                                    </div>
                                    <div class="w-3/4">
                                        <select name="" id="" v-model="filters.boutique" class="block w-full px-4 py-1.5 rounded-lg" style="background-color: #FBF7EE;">
                                            <option :value="shop.id" v-for="(shop, index) in itemboutique" :key="index">{{ shop.nom }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="flex w-full mb-4">
                                    <div class="w-1/4 mx-4">
                                        <label for="" class=" font-bold">Catégorie</label>
                                    </div>
                                    <div class="w-3/4">
                                        <select name="" id="" v-model="filters.categorie" class="block w-full px-4 py-1.5 rounded-lg " style="background-color: #FBF7EE;">
                                            <option :value="cat.id" v-for="(cat, index) in itemCategory" :key="index">{{ cat.nom }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="flex w-full mb-4">
                                    <div class="w-1/4 mr-2">
                                        <label for="" class=" font-bold text-left">Produit</label>
                                    </div>
                                    <div class="w-3/4">
                                        <select name="" id="" v-model="filters.produit" class="block w-full px-4 py-1.5 rounded-lg " style="background-color: #FBF7EE;">
                                            <option :value="pro.id" v-for="(pro, index) in itemProduit" :key="index">{{ pro.nom }}</option>
                                        </select>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div class="w-full  mb-14">
                            <button type="submit" class="w-40 px-4 text-white py-1.5 rounded-lg" style="background-color: #DEA20A;">Filtrer le resultat</button>
                        </div>
                    </form>


                    <div class="w-full mb-14" style="background-color: #FBF7EE;">
                       
                            <div class="flex w-full" style="background-color: #DEA20A;">
                                <div class="text-white text-left pl-2 w-40 py-5 text-md">
                                    Produit
                                </div>
                                <div class="hidden sm:flex text-white text-left pl-2 w-40 py-5 text-md">
                                    Catégorie
                                </div>
                                <div class="text-white text-left pl-2 w-40 py-5 text-md">
                                    Quantité disponible
                                </div>
                                <div class="text-white text-left pl-2 w-40 py-5 text-md">
                                    Prix unitaire
                                </div>
                                <div class="text-white text-left pl-2 w-40 py-5 text-md">
                                    Taille
                                </div>
                                <div class="text-white text-left pl-2 w-40 py-5 text-md">
                                    Produits expirés
                                </div>
                            </div>
                            <div class="mb-12 w-full overflow-y-auto">
                                <div class="flex w-full my-1.5 " v-for="(stock, index) in getDataDash" :key="index">
                                    <div class="w-40  py-2 px-2 text-sm line-clamp-2 leadin-none overflow-hidden text-ellipsis h-12 text-black text-left">
                                        {{ stock.nom }}
                                    </div>
                                    <div class="hidden sm:inline w-40  py-2 px-2 text-sm line-clamp-2 leadin-none overflow-hidden text-ellipsis h-12 text-black text-left">
                                        <p v-for="(categorie, index) in stock.categories" :key="index">{{ categorie.nom }}</p>
                                    </div>
                                    <div class="w-40  py-2 px-2 text-sm line-clamp-2 leadin-none overflow-hidden text-ellipsis h-12 text-black text-left">
                                        <!--p v-if="stock.stock_total == null">0</p-->
                                        <p>{{ stock.stock_total  }}</p>
                                    </div>
                                    <div class="w-40  py-2 px-2 text-sm line-clamp-2 leadin-none overflow-hidden text-ellipsis h-12 text-black text-left">
                                        {{ stock.prix }} $
                                    </div>
                                    <div class="w-40  py-2 px-2 text-sm line-clamp-2 leadin-none overflow-hidden text-ellipsis h-12 text-black text-left">
                                        {{ stock.taille }}
                                    </div>
                                    <div class="w-40  py-2 px-2 text-sm line-clamp-2 leadin-none overflow-hidden text-ellipsis h-12 text-black text-left">
                                        {{ stock.nombre_produits_expires }} 
                                    </div>
                                </div>
                            </div>
                        
                        <!-- Les autres colonnes -->
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>

</template>

<script>

import Header from '@/components/layout/Header.vue'
import Footer from '@/components/layout/Footer.vue'
import {mapActions} from 'vuex'
import Boutique from '@/_services/shop.services'
import Axios from '@/_services/caller.services'
import Produit from '@/_services/produit.services'
import Ville from '@/_services/province.services'
import Category from '@/_services/category.services'



export default{
    name: 'stockGeneral',

    components:{
        Header,
        Footer
    },

    data(){
    return{
        roles : null,
        getDataDash: [],
        itemboutique: [],
        itemProduit: [],
        getVille: [],
        itemCategory: [],
        filters:{
            produit:'',
            categorie: '',
            boutique: '',
            ville: '',
        },
        token: '',
    }
},

methods: {
    
    //Admin gestion
    ...mapActions(['StockGene']),
    GetDashAd(){
        if(!this.filters.boutique && !this.filters.produit && !this.filters.categorie && !this.filters.ville){
            this.StockGene()
            .then(response =>{
                this.getDataDash = response['data'];
                //.log(this.getDataDash)
            })
            .catch(error =>{
                //('Erreur', error);
            });
        }
        },

        //filtre request
        async sendDataFiltre(){
            const headers = {
                'Authorization': `Bearer ${this.token}`,
            }

            
            try{
                const response = await Axios.get(
                    '/api/stock',
                    { 
                        params: this.filters,
                        headers
                    }
                )
                //console.log(response)
                this.getDataDash = response.data.data
                //.log(this.getDataDash)
            }catch(e) {
                //.log(e)
            }finally {
                this.filters = {
                                        
                    boutique:"",
                    categories:"",
                    produit:"",
                    ville:"",
                }
            }
        }

    },

    created(){
        this.token = localStorage.getItem('authToken');
        this.roles = localStorage.getItem("role");
        if(this.roles == null){
        this.$router.push('/authentification')
    }
        if(this.roles != 'manager'){
            this.GetDashAd()
        }else{
            this.getDash()
        }
    },

    mounted(){
        //affichage des boutique
        const boutique = new Boutique();
        boutique.getAllShop()
            .then(res => {
                this.itemboutique = res.data;
            }).catch(error => {
                //(error)
            });

        
        //liste de produit
        const produit = new Produit();
        produit.getAllProduit()
            .then(product => {
                this.itemProduit = product.data
                //.log(this.itemProduit)
            })
            .catch(error => {
                //.log(error.product.data.message)
            })

        //get ville
        const ville = new Ville();
        ville.getAllPro()
                .then(prov => {
                    this.getVille = prov.data;
                }).catch(err => {
                    ////.log(err)
                });

        const category = new Category();
        category.getAllCat()
          .then(categorie => {
              this.itemCategory = categorie.data;
          })
    }
}

</script>