<template>
    <div>
        <div class="flex items-center justify-center h-screen">
            <div class="bg-gray-200 w-3/5 h-2/3 mx-auto my-auto">
                <div class="flex h-full">
                    <div class="w-2/4" style="background: linear-gradient(to bottom, #DEA20A , #FBF7EE );">
                        <div class="px-10 pt-14">
                            <div class="flex items-center justify-center">
                                <img src="@/images/LOGO_GEN_PNG_BLANC.png" class="w-72" alt="">
                            </div>
                            <div class="flex items-center justify-center bg-white py-10 rounded-lg">
                                <p class="text-3xl font-bold uppercase drop-shadow-md">Modification taux</p>
                            </div>
                        </div>
                    </div>
                    <div class="w-2/4 px-10 pt-10 text-left" style="background-color: #FBF7EE;">
                        <div class="mb-5">
                            <div class="mb-2">
                                <label for="password" class="text-2xl font-bold">Monnaie</label>
                            </div>
                            <div>
                                <select name="" id="" class="block w-full px-4 py-2 border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                                    <option value="" disabled selected hidden></option>
                                    <option value="option1">Option 1</option>
                                    <option value="option2">Option 2</option>
                                    <option value="option3">Option 3</option>
                                    <option value="option4">Option 4</option>
                                    <option value="option5">Option 5</option>
                                </select>
                            </div>
                        </div>
                        <div class="mb-5">
                            <div class="mb-2">
                                <label for="phone" class="text-2xl font-bold">Province</label>
                            </div>
                            <div>
                                <label for="" class="inline-flex items-center mr-2">
                                    <span class="mr-2">Option</span>
                                    <input type="checkbox" name="" id="" class="form-checkbox text-blue-500 h-4 w-4">
                                </label>
                            </div>
                        </div>
                        <div class="mb-16">
                            <div class="pl-2 mb-2">
                                <label for="phone" class="text-2xl font-bold">Taux</label>
                            </div>
                            <input type="text" name="" id="" class="px-4 py-2 w-full border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                        </div>
                        <div class="">
                            <button type="submit" class="px-4 py-2 w-full rounded-lg text-white font-bold text-2xl" style="background-color: #DEA20A;">Ajouter</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>

export default{
    name: 'newtaux',
}

</script>