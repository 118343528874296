import Axios from './caller.services'

export default class AgentService{

    //recupere le commande de l'agent
    async getCommandeAgent(token, id){

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        
        try{

            const reponse = await Axios.get(
                `/api/agent/commandes`,
                {headers});
            return reponse.data;

        } catch(error){
            //console.log(error);
            throw error;
        }

    }

    //recupere le commande de l'agent
    async getCommandeGest(token){

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        
        try{

            const reponse = await Axios.get(
                `/api/gestionnaire/commandes`,
                {headers});
            return reponse.data;

        } catch(error){
            
            throw error;
        }

    }

    //recupere le données du dashoard agent
    async dashbordAgent(token){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Access-Control-Allow-Origin':'*',
            'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,PATCH,OPTIONS',
            
            'Access-Control-Allow-Headers': "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'"
        }
        try{
            const reponse = await Axios.get(
                '/api/dashboard/agent',
                {headers, }
                );
            return reponse.data;
        } catch(error) {
            throw error;
        };
    }

    //recupere le données du dashoard agent
    async dashbordGestion(token){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Access-Control-Allow-Origin':'*',
            'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,PATCH,OPTIONS',
            
            'Access-Control-Allow-Headers': "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'"
        }
        try{
            const reponse = await Axios.get(
                '/api/dashboard/gestionnaire',
                {headers, }
                );
            return reponse.data;
        } catch(error) {
            throw error;
        };
    }

    //recupere la liste de gestionnaire
    async getListeGest(token){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Access-Control-Allow-Origin':'*',
            'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,PATCH,OPTIONS',
            
            'Access-Control-Allow-Headers': "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'"
        }
        try{
            const reponse = await Axios.get(
                '/api/gestionnaire',
                {headers, }
                );
            return reponse.data;
        } catch(error) {
            throw error;
        };
    }

    //assigner une commande aux gestionnaire par le biais de l'agent
    async postAsGestionnaire(data, token){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Access-Control-Allow-Origin':'*',
            'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,PATCH,OPTIONS',
            
            'Access-Control-Allow-Headers': "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'"
        }
       
        const sendData = {
            'gestionnaire_id': data.gestionnaire_id
        };
        try{
            const reponse = await Axios.post(
                `/api/backoffice/commande/gestionnaire/${data.id}`,
                sendData,
                {headers}
            );

            return reponse;
        }catch(error) {
            throw error;
        }; 
    }

}