<template>
    <div v-if="roles === 'admin' || roles === 'gestionnaire' && !chB">
                                <!--Livreur-->
                                <div v-if="dataId === 'liv'" class="px-2 h-48 overflow-y-auto">
                                    <div class="mb-2 border-b pb-1.5" v-for="(user, index) in itemUserslivreur" :key="index">
                                        <div class="">
                                            <div class="flex mb-2">
                                                <div class="w-14 flex items-center justify-center  h-12 border rounded-full">
                                                    <span class="text-center inline-block align-middle" >{{ user['nom'].charAt(0) }}{{ user['prenom'].charAt(0) }}</span> 
                                                </div>
                                                <div class="w-full h-full text-left pl-2">
                                                    <div class="h-6 overflow-hidden w-32 text-black font-bold">
                                                        <p class=" line-clamp-2">{{ user['nom'] }} {{ user['prenom'] }}</p>
                                                    </div>                                                    
                                                </div>
                                            </div>
                                            <div class="pl-4 pr-4 flex justify-between">
                                                <input type="date" name="" v-model="data_livraison" id="" class="border px-1 border-black" style="background-color: #FBF7EE;">
                                                <button type="submit" @click="sendAssigner(user.id)" class="py-1 px-2 rounded-md text-white" style="background-color: #DEA20A;">Assigner</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="dataId === 'ag'" class="px-2 h-48 overflow-y-auto">
                                    <div class="mb-2 border-b pb-1.5" v-for="(user, index) in itemUsersAgent" :key="index">
                                        <div class="">
                                            <div class="flex mb-2">
                                                <div class="w-14 flex items-center justify-center  h-12 border rounded-full">
                                                    <span class="text-center inline-block align-middle">{{ user['nom'].charAt(0) }}{{ user['prenom'].charAt(0) }}</span> 
                                                </div>
                                                <div class="w-full h-full text-left pl-2">
                                                    <div class="h-6 overflow-hidden w-32 text-black font-bold">
                                                        <p class=" line-clamp-2">{{ user['nom'] }} {{ user['prenom'] }}</p>
                                                    </div>                                                    
                                                </div>
                                            </div>
                                            <div class="pl-4 pr-4 flex justify-between">
                                                <button type="submit" @click="sendAssigner(user.id)" class="py-1 px-2 rounded-md text-white" style="background-color: #DEA20A;">Assigner</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="dataId === 'man'" class="px-2 h-48 overflow-y-auto">
                                    <div class="mb-2 border-b pb-1.5" v-for="(user, index) in itemUsersManage" :key="index">
                                        <div class="">
                                            <div class="flex mb-2">
                                                <div class="w-14 flex items-center justify-center  h-12 border rounded-full">
                                                    <span class="text-center inline-block align-middle">{{ user['nom'].charAt(0) }}{{ user['prenom'].charAt(0) }}</span> 
                                                </div>
                                                <div class="w-full h-full text-left pl-2">
                                                    <div class="h-6 overflow-hidden w-32 text-black font-bold">
                                                        <p class=" line-clamp-2">{{ user['nom'] }} {{ user['prenom'] }}</p>
                                                    </div>                                                    
                                                </div>
                                            </div>
                                            <div class="pl-4 pr-4 flex justify-between">
                                                <button type="submit" @click="sendAssigner(user.id)" class="py-1 px-2 rounded-md text-white" style="background-color: #DEA20A;">Assigner</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
</template>

<script>
import UserVueCompte from '@/_services/usersComptes.services'
import {mapActions} from 'vuex'

    export default{
        name: "AssignAdmGest",

        props:{
            id:{
                type: Array,
                required: true
            }
        },

        computed:{

            dataId(){
                console.log(this.id)
                return this.id
            }
            
        },

        mounted(){
            //affichage des user seul admin et gestion utilise cela
            const Users = new UserVueCompte();
            Users.getUsersAlls() 
                .then(usercompte => {

                    if(this.roles === 'admin' || this.roles === 'gestionnaire'){
                        this.itemUsersAgent = usercompte.data.filter(user => {
                        const roles = user.roles.map(role => role.name);
                        
                            return roles.includes("agent")
                        });

                        this.itemUsersManage = usercompte.data.filter(user => {
                            const roles = user.roles.map(role => role.name);

                            return roles.includes("manager")
                        });

                        console.log('manager', this.itemUsersManage)

                        this.itemUserslivreur = usercompte.data.filter(user => {
                            const roles = user.roles.map(role => role.name);
                            
                            return roles.includes("livreur")
                        });

                    }

                }).catch(error => {
                    //(error)
                });
        },

        data(){
            return{
                roles: null,
                itemUsersAgent: [],
                itemUsersManage: [],
                itemUserslivreur: [],
            }
        },

        created(){
            this.roles = localStorage.getItem("role");
            this.fetchData()
            this.ListMana()
            this.ListAgent()
        },

        methods:{

            //liste livreur
            ...mapActions(['fechDataLivrer']),
            fetchData(){
                this.fechDataLivrer()
                    .then(response => {
                        this.listLiv = response.data;
                        ////.log(this.listLiv)
                    })
                    .catch(error => {
                        // Une erreur s'est produite lors de la récupération des données
                        //.error(error);
                    });
            },

            //Liste de manager
            ...mapActions(['viewprovince']),
            ListMana(){

                this.viewprovince()
                    .then(response =>{
                        this.itemBtM = response.data
                        ////.log(this.itemBtM)
                    })
                    .catch(error => {
                        //.error(error);
                    });

            },

            //Liste de agent
            ...mapActions(['getAgent']),
            ListAgent(){

                this.getAgent()
                    .then(response =>{
                        this.itemBtAg = response.data.data
                        ////.log(this.itemBtAg)
                    })
                    .catch(error => {
                        //.error(error);
                    });

            },

            sendAssigner(idUser, role){

                this.activeId = null;
                this.assignerHideAB = null;

                if(this.isSendingData){
                    return;
                }

                if(this.roles == 'admin' || this.roles == 'gestionnaire'){
                    
                    if(this.dataId === 'liv'){
                        const id = this.listLiv.find(liv => liv.user_id == idUser)
                        
                        if(this.data_livraison != ''){
                            
                            this.$store.dispatch('AssignerLivreur',{

                                id: this.idCom,
                                livreur_id: id.id,
                                date_livraison: this.data_livraison
                                
                            })
                            .then((res)=>{
                                //.log(res)
                                if(res.data.status_code === 200){
                                    this.succes = 'Livraison est assignée à un Livreur' 
                                    setTimeout(() =>{
                                        this.succes = false;
                                        location.reload();
                                    }, 
                                    5000)
                                }
                            })
                            .catch((err)=>{
                                //.log(err);
                            });         

                            }else{

                            this.erreur = 'Veuillez sélectionnée la date de livraison'
                            setTimeout(() =>{
                                        this.erreur = false;
                                    }, 
                                    5000)
                            }

                    }
                    else if(this.dataId === 'ag'){
                        role =  'agent'

                        this.$store.dispatch('SendedAssigner',{
                            role,
                            idUser: idUser,
                            id: this.idCom,
                        })
                        .then((res)=>{
                            
                            if(res.status_code == 200){
                                this.succes = 'Assignation reussi ' + role
                                setTimeout(() =>{
                                    this.succes = false;
                                    location.reload();
                                }, 
                                5000)
                            }
                        })
                        .catch((err)=>{
                            //.log(err)
                        })
                        .finally(()=>{
                            this.isSendingData = false
                        })
                    }
                    else if(this.dataId === 'man'){
                        role =  'manager'
                        const id = this.itemBtM.find(liv => liv.manager.user_id == idUser)
                        
                        
                        this.$store.dispatch('SendedAssigner',{
                            role,
                            boutique_id: id.manager.id,
                            id: this.idCom
                        })
                        .then((res)=>{
                            //.log(res)
                            if(res.data.status_code === 200){
                                this.succes = 'Assignation reussi ' + role
                                setTimeout(() =>{
                                    this.succes = false;
                                    location.reload();
                                }, 
                                5000)
                            }
                        })
                        .catch((err)=>{
                            //.log(err)
                        })
                        .finally(()=>{
                            this.isSendingData = false
                        })
                    }
                }

            }

        }

    }

</script>