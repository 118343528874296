import ProvinceService from '@/_services/province.services'

const state = {
    province: [],
    errorProvince: null
};

const mutations = {
    SET_PROVINCE(state, province){
        state.province = province
    },

    SET_ERROR(state, errorProvince){
        state.errorProvince = errorProvince;
    }
};

const actions = {
    
    submitProvince: async ({commit}, provinceData) => {
        const token = localStorage.getItem('authToken');

        try{
            const Province = new ProvinceService()
            const response = await Province.addPro(provinceData, token);
            commit('SET_PROVINCE',[...state.province, response]);
            commit('SET_ERROR', null)

            return response;
        }

        catch(err) {
            commit('SET_ERROR', err.message);
        }

    },

    async editVille({commit}, data){

        const token = localStorage.getItem('authToken');

        try{

            const category = new ProvinceService()
            const response = await category.editVille(token, data)

            return response


        }catch(err) {
            throw err
        }

    },

    async dropVille({commit}, id){

        const token = localStorage.getItem('authToken');
        try{

            const category = new ProvinceService()
            const response = await category.dropVille(token, id)

            return response


        }catch(err) {
            throw err
        }

    }
};

const getters = {
    province: state => state.province,
    errorProvince: state => state.errorProvince
}

export default{
    state,
    getters,
    mutations,
    actions
}

