<template>
    <div>
        <div class="flex items-center justify-center h-screen">
            <div class="bg-gray-200 w-3/5 h-2/3 mx-auto my-auto">
                <div class="flex h-full">
                    <div class="w-2/4" style="background: linear-gradient(to bottom, #DEA20A , #FBF7EE );">
                        <div class="px-10 pt-14">
                            <div class="flex items-center justify-center">
                                <img src="@/images/LOGO_GEN_PNG_BLANC.png" class="w-72" alt="">
                            </div>
                            <div class="flex items-center justify-center bg-white py-10 rounded-lg">
                                <p class="text-3xl font-bold uppercase drop-shadow-md">Diffusion</p>
                            </div>
                        </div>
                    </div>
                    <div class="w-2/4 px-10 pt-10 text-left" style="background-color: #FBF7EE;">
                        <div class="mb-10">
                            <div class="pl-2 mb-2">
                                <label for="phone" class="text-2xl font-bold">Message</label>
                            </div>
                            <textarea name="" id="" cols="30" rows="10" class="px-4 py-2 w-full border rounded-lg border-gray-600 shadow-md shadow-gray-400"></textarea>
                        </div>
                        <div class="">
                            <button type="submit" class="px-4 py-2 w-full rounded-lg text-white font-bold text-2xl" style="background-color: #DEA20A;">Envoyer</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>

export default{
    name: 'diffusion',
}

</script>