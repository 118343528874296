import Axios from './caller.services'


export default class RaviServices  {
    
    async addRavita(ravi){
        try {
            const response = await Axios.post('/api/stock/ravitaillement', ravi);
            return response.data;
        } catch (error) {
            //console.error(error);
            throw error;
        }
    }


    //Cette service enregistre les demandes de ravitaillement 
    async demandeRavi(ravi){
        try {
            const response = await Axios.post('/api/demande', ravi);
            return response.data;
        } catch (error) {
            //console.error(error);
            throw error;
        }
    }

    //affiche l'historique de ravitaillement
    async getStockRavi(token){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Access-Control-Allow-Origin':'*',
            'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,PATCH,OPTIONS',
            
            'Access-Control-Allow-Headers': "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'"
        }

        try{
            const reponse = await Axios.get(
                '/api/stock/ravitaillement',
                {headers, }
                );
            
            return reponse.data;
        } catch(error) {
            //console.error(error);
            throw error;
        };

    }

    //enregistre ravitaillement 
    async postRavi(token, data) {

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Access-Control-Allow-Origin':'*',
            'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,PATCH,OPTIONS',
            
            'Access-Control-Allow-Headers': "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'"
        }


        try{
            const reponse = await Axios.post(
                '/api/stock/ravitaillement',
                data,
                {headers}
            );
            return reponse.data;
        }catch(error) {
            throw error;
        };

    }

    //enregistre ravitaillement qui repond a une demande
    async postRaviId(token, data) {

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Access-Control-Allow-Origin':'*',
            'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,PATCH,OPTIONS',
            
            'Access-Control-Allow-Headers': "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'"
        }
        
        try{
            const reponse = await Axios.post(
                `/api/stock/ravitaillement/${data.id_commande}`,
                data,
                {headers}
            );

            return reponse.data;
        }catch(error) {
            throw error;
        };

    }

}