<template>
    <div>
        <div class="flex items-center justify-center h-screen">
            <div class="bg-gray-200 h-2/3">
                <div class="flex h-full flex-col md:flex-row">
                    <div class="w-full md:w-2/4 md:order-first" style="background: linear-gradient(to bottom, #DEA20A , #FBF7EE );">
                        <div class="px-10 pt-14">
                            <div class="flex items-center justify-center">
                                <img src="@/images/LOGO_GEN_PNG_BLANC.png" class="w-72" alt="">
                            </div>
                            <div class="flex items-center justify-center bg-white py-10 rounded-lg">
                                <p class="text-3xl font-bold uppercase drop-shadow-md">Nouvelle Monnaie</p>
                            </div>
                        </div>
                    </div>
                    <div class="w-full pt-10 md:w-2/4 px-4 md:px-10 pb-20 md:pb-0 md:pb-10 md:pt-10 text-left md:text-left md:mt-0" style="background-color: #FBF7EE;">
                        <form action="" @submit.prevent="submitMoney" ref="restlogin">
                            <div class="mb-7">
                                <div class="pl-2 mb-2">
                                    <label for="phone" class="text-2xl font-bold">Nom</label>
                                </div>
                                <input type="text" name="" v-model="nom" id="" class="px-4 py-2 w-full border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                            </div>
                            <div class="mb-12">
                                <div class="pl-2 mb-2">
                                    <label for="phone" class="text-2xl font-bold">Symbole</label>
                                </div>
                                <input type="text" name="" v-model="symbole" id="" class="px-4 py-2 w-full border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                            </div>
                            <div class="">
                                <button type="submit" class="px-4 py-2 w-full rounded-lg text-white font-bold text-2xl" style="background-color: #DEA20A;">Ajouter</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>

export default{
    name: 'newmoney',

    data(){
        return{
            nom : '',
            symbole : '',
        }
    },

    methods:{
        submitMoney : function(){
            this.$store.dispatch('submitMoney',{
                nom : this.nom,
                symbole : this.symbole
            })
            .then((response) =>{
                this.$router.push('/money')
                ////.log(response)
            })
            .catch((error) => {
                ////.log(error);
            });
            this.restlogin();
        },
        restlogin(){
                this.nom = '';
                this.symbole = '';
                this.$refs.restlogin.reset()
            }
    }

}

</script>