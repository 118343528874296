import Notification from '@/_services/notification.services'

const actions = {

    async getNoti({commit}){
        const token = localStorage.getItem('authToken');

        try{

            const requeteData = new Notification()
            const reponse = await requeteData.getNotification(token)

            return reponse

        }catch(error){
            throw error
        }

    },

    async countNoti({commit}){
        const token = localStorage.getItem('authToken');

        try{

            const requeteData = new Notification()
            const reponse = await requeteData.countNotification(token)

            return reponse

        }catch(error){
            throw error
        }

    }


}

export default{
    actions
}