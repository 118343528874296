<template>
    <div>
        <div class="flex items-center justify-center h-screen">
            <div class="bg-gray-200 h-2/3">
                <div class="flex h-full flex-col md:flex-row">
                    <div class="w-full md:w-2/4 md:order-first" style="background: linear-gradient(to bottom, #DEA20A , #FBF7EE );">
                        <div class="px-10 pt-14">
                            <div class="flex items-center justify-center">
                                <img src="@/images/LOGO_GEN_PNG_BLANC.png" class="w-72" alt="">
                            </div>
                            <div class="flex items-center justify-center bg-white py-10 rounded-lg">
                                <p class="text-3xl font-bold uppercase drop-shadow-md">Ravitaillement stock</p>
                            </div>
                        </div>
                    </div>
                    <div class="w-full pt-10 md:w-2/4 px-4 md:px-10 pb-20 md:pb-0 md:pb-10 md:pt-10 text-left md:mt-0 md:overflow-y-auto" style="background-color: #FBF7EE;">                 
                        <form action="" @submit.prevent="sendRavita">
                            <div v-for="(item, index) in items" :key="index">
                                <div class="mb-3">
                                    <div class="pl-2 mb-2">
                                        <label class="text-xl font-bold">Produit {{ index + 1 }}</label>
                                    </div>
                                    <div>
                                        <select v-model="item.produit" class="block w-full px-4 py-1.5 border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                                        <option value="" disabled selected hidden></option>
                                        <option :value="itemProduit.id" v-for="(itemProduit, prodIndex) in itemProduit" :key="prodIndex">{{ itemProduit.nom }}</option>
                                        </select>
                                    </div>
                                    </div>
                                    <div class="mb-3">
                                    <div class="pl-2 mb-2">
                                        <label class="text-xl font-bold">Coût unitaire {{ index + 1 }} ($)</label>
                                    </div>
                                    <div>
                                        <input type="text" v-model="item.cout" class="px-4 py-1 w-full border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                                    </div>
                                    </div>
                                    <div class="mb-3">
                                    <div class="pl-2 mb-2">
                                        <label class="text-xl font-bold">Quantité {{ index + 1 }}</label>
                                    </div>
                                    <div>
                                        <input type="text" v-model="item.quantite" class="px-4 py-1 w-full border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                                    </div>
                                    </div>
                                    <div class="mb-3">
                                    <div class="pl-2 mb-2">
                                        <label class="text-xl font-bold">Date expiration {{ index + 1 }}</label>
                                    </div>
                                    <div>
                                        <input type="date" v-model="item.dateExpir" class="px-4 py-1 w-full border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                                    </div>
                                </div>
                                <div class="flex w-full justify-between">
                                    <div @click="removeItem(index)" class="px-4 py-2 rounded-lg cursor-pointer text-center text-sm text-white bg-red-500 shadow-lg font-bold ">Retirer</div>
                                </div>
                            </div>
                            <div class="mb-5">
                                <div class="pl-2 mb-2">
                                    <label for="password" class="text-xl font-bold">Boutique</label>
                                </div>
                                <div>
                                    <select name="" v-model="boutique" id="" class="block w-full px-4 py-1.5 border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                                        <option value="" disabled selected hidden></option>
                                        <option :value="boutique.id" v-for="(boutique, index) in itemboutique" :key="index">{{ boutique.nom }}</option>
                                    </select>
                                </div>
                            </div> 
                            <div class="text-red-500" v-if="erreur">
                                {{ erreur }}
                            </div>
                            <div class="flex justify-between mb-5">
                                <div @click="addInput" class="px-4 py-2 rounded-lg cursor-pointer text-center text-2xl text-black bg-white shadow-lg font-bold ">Ajouter</div>
                                <button type="submit" class="px-4 py-2 rounded-lg text-white font-bold text-2xl" style="background-color: #DEA20A;">Enregistrer</button>
                            </div> 
                        </form>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import Produit from '@/_services/produit.services'
import Boutique from '@/_services/shop.services'
import {mapActions} from 'vuex'


export default{
    name: 'ravitaillement',

    data(){
        return {
            itemProduit: [],
            boutique: null,
            itemboutique: null,
            stockG: [],
            erreur: null,
            items:[]
        }
    },

    created(){
        this.GetDashAd()
    },

    mounted(){
        this.addInput();

        const produit = new Produit();
            produit.getAllProduit()
                .then(product => {
                    this.itemProduit = product.data
                })
                .catch(error => {
                    //(error.product.data.message)
                });
        
        //affichage des boutique
        const boutique = new Boutique();
            boutique.getAllShop()
                .then(res => {
                    this.itemboutique = res.data;
                    ////(this.itemboutique)
                }).catch(error => {
                    //(error)
                });
    },

    methods:{

        //Admin gestion stock générale
        ...mapActions(['StockGene']),
        GetDashAd(){
            this.StockGene()
                .then(response =>{
                    this.stockG = response.data;
                    //(this.stockG)
                })
                .catch(error =>{
                    //('Erreur', error.reponse);
                    //this.erreur = error.response.data.message
                });
        },

        /*addInput() {
            this.produits.push({ value: '' });
            this.quantites.push({ value: '' });
            this.dateExpir.push({ value: '' });
            this.couts.push({ value: '' });
        },*/
        addInput() {
            this.items.push({
            produit: "",
            quantite: "",
            cout: "",
            dateExpir: ""
            });
        },

        removeItem(index){
            if(this.items.length > 1){
                this.items.splice(index, 1)
            }
        },

                ///action ravitaillement
        sendRavita() {
            const produitsValues = this.items.map(item => parseInt(item.produit));
            const quantitesValues = this.items.map(item => parseInt(item.quantite));
            const sendDataArray = [];

            if (quantitesValues.some(isNaN)) {
                this.quantiteError = true;
                return;
            }

            for (let i = 0; i < produitsValues.length; i++) {
                const nomProduit = produitsValues[i];
                const stockItem = this.stockG.find(item => item.id === nomProduit);
                //.log(stockItem)
                if (stockItem && stockItem.stock_total === 0) {
                    const idBout = this.itemboutique.find(item => item.id === this.boutique);

                    if (idBout.nom == 'générale') {
                        
                        sendDataArray.push({
                            produit: produitsValues[i],
                            quantite: quantitesValues[i],
                            dateExpir: this.items[i].dateExpir,
                            cout: this.items[i].cout
                        });
                        
                    } else {
                        this.erreur = 'Choisissez la boutique générale car le stock est null';
                        setTimeout(() => {
                        this.succes = false;
                        //location.reload();
                        }, 5000);
                    }
                } else {
                    //.log("eleki awa")
                    if (this.boutique !== null) {
                        const quantityProduit = quantitesValues[i];

                        if (stockItem && quantityProduit <= stockItem.stock_total) {
                        sendDataArray.push({
                            produit: produitsValues[i],
                            quantite: quantitesValues[i],
                            dateExpir: this.items[i].dateExpir,
                            cout: this.items[i].cout
                        });
                        } else {
                            const idBout = this.itemboutique.find(item => item.id === this.boutique);

                            if (idBout.nom == 'générale') {
                                sendDataArray.push({
                                produit: produitsValues[i],
                                quantite: quantitesValues[i],
                                dateExpir: this.items[i].dateExpir,
                                cout: this.items[i].cout
                                });
                            } else {
                                this.erreur = 'Choisissez la boutique générale car la quantité entrée est supérieure au stock';
                                setTimeout(() => {
                                this.succes = false;
                                //location.reload();
                                }, 5000);
                            }
                        }
                    } else {
                        this.erreur = 'Veuillez sélectionner une boutique';
                        setTimeout(() => {
                        this.succes = false;
                        //location.reload();
                        }, 5000);
                    }
                }
            }

            if (sendDataArray.length > 0) {
                this.sendData(sendDataArray);
            }
        },

        sendData(dataArray) {
            const formData = new FormData();

            for (let i = 0; i < dataArray.length; i++) {
                const productKey = `produit${i}`;
                const productValue = dataArray[i].produit;
                const quantiteKey = `stock_initial${i}`;
                const quantiteValue = dataArray[i].quantite;
                const dateExpirKey = `date_expiration${i}`;
                const dateExpirValue = new Date(dataArray[i].dateExpir).toISOString().split('T')[0];
                const coutKey = `prix_acquisition${i}`;
                const coutValue = dataArray[i].cout;

                formData.append(productKey, productValue);
                formData.append(quantiteKey, quantiteValue);
                formData.append(dateExpirKey, dateExpirValue);
                formData.append(coutKey, coutValue);
            }

            formData.append('boutique', this.boutique);
            formData.append('nbr_produits', dataArray.length);
            
            /*for (let [key, value] of formData.entries()) {
                    //.log(key, value);
                }
                */
            this.$store
                .dispatch('sendRavi', formData)
                .then(res => {
                    if (res.status_code === 200) {
                        this.$router.push('/hitoryRavi');
                    } else {
                        this.erreur = res.message;
                        setTimeout(() => {
                            this.succes = false;
                            //location.reload();
                        }, 5000);
                    }
                })
                .catch(err => {
                    this.erreur = err.reponse;
                    setTimeout(() => {
                        this.succes = false;
                        //location.reload();
                    }, 5000);
                }) .finally(() => {
                    // Réinitialiser les valeurs des champs
                    this.items = this.items.map(item => ({
                        produit: '',
                        quantite: '',
                        dateExpir: '',
                        cout: ''
                    }));
                    this.boutique = "";
                });
        }


    }
}

</script>