import { createStore } from 'vuex'
import account from './account.store'
import newsCat from './category.store'
import newMoney from './money.store'
import newProvince from './province.store'
import userCompte from './usersCompte.store'
import requeteShop from './shop.store'
import newproduct from './product.store'
import commandInt from './commandInterne.store'
import manager from './manager.store'
import agent from './agent.store'
import admingestion from './AdminGestion.store'
import assignerLivreur from './assignerLivreur.store'
import Livreur from './livreur.store'
import Config from './configuration.store'
import Notification from './notification.store'
import Dash from './Dashboard.store'


export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    Notification,
    account,
    newsCat,
    newMoney,
    newProvince,
    userCompte,
    requeteShop,
    newproduct,
    commandInt,
    manager,
    agent,
    admingestion,
    assignerLivreur,
    Livreur,
    Config,
    Dash
  }
})
