import AssignerL from '@/_services/assignerLivreur.services'


const state = {
    assignerLivreur : [],
}

const mutations = {

    SET_ASSIGNER_LIVREUR(state, data) {
        state.assignerLivreur = data
    },

}

const actions = {

    // assignation d'une commande à un livreur
    async AssignerLivreur({commit}, data){

        const token = localStorage.getItem('authToken');
        
        /*console.log(data)
        return*/
        try{

            const sendData  = new AssignerL();
            const response = await sendData.postAssign(data, token);

            //commit('SET_ASSIGNER_LIVREUR', [...state.assignerLivreur, response])

            return response

        }catch(e){

            throw e;
        }

    },

    async EditHoraire({commit},data){
        const token = localStorage.getItem('authToken');

        try{
            const sendData =  new AssignerL()
            const response = await sendData.editHoraire(data, token)

            return response
        }catch(e){
            throw e
        }

    }


}

export default{
    state,
    mutations,
    actions
}