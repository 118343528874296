import UserService from '@/_services/account.services'


const state = {
    
    isAuthenticated: !!localStorage.getItem('access_token'),
    userData : null,
    errors : null,
    success: null,
};

const getters = {
    //isAuthenticated: state => state.isAuthenticated,
    //user : state => state.userData
};

const mutations = {
    
    setUserData(state, userData) {
        state.userData = userData;
    },

    logout(state) {
        state.isAuthenticated = false;
    },

    setError(state, errors) {
        state.errors = errors;
    },

    setSuccess(state, success) {
        state.success = success;
    },

    clearSuccess(state) {
        state.success = null;
      },
};

const actions = {
    login:async ({commit}, {telephone, password})=>{        
      commit('setError', null);
      commit('setSuccess', null);
        try {
            const response = await UserService.login(telephone, password);
            //commit("setUserData", response.data['data']['user']);
            
            //console.log(response.data.message)

            commit('setSuccess', 'Login successful.');

            setTimeout(() => {
                commit('clearSuccess');
              }, 3000);


            //localStorage.setItem("authToken", response.data['data']['access_token']);
            //localStorage.setItem("role", response.data['data']['user']['roles']['0']['name']);
            //localStorage.setItem("IdUser", response.data['data']['user']['id']);
            
            return response;
        } catch (error) {
            //commit('setError', error.response);
            throw error;
        }
        
    },

    logout: async ({commit})=>{
        const token = localStorage.getItem('authToken');
        try{
            const response = UserService.logout(token);
            commit('logout', response);  
            return response;  
        } catch(error) {
            commit('setError', error.response);
            throw error;
        }
        /*return "deconnéxion"
        UserService.logout();
        commit('logout');*/
    },

    register:async ({commit}, userRegister)=>{
        try {
            const response = await UserService.register(userRegister);
            commit('resgiter');
            return response;
        } catch (err) {
            //console.log(err);
            throw err;
        }
    }
    
};


export default{
    namespace: true,
    state,
    getters,
    mutations,
    actions
}