import CategoryService from '@/_services/category.services'

const state = {
    categories: [],
    error: null
};

const mutations = {
    SET_CATEGORY(state, categories){
        state.categories = categories
    },

    SET_ERROR(state, error){
        state.error = error;
    }
};

const actions = {
    
    submitCat: async ({commit}, nom) => {
        const token = localStorage.getItem('authToken');

        try{
            const category = new CategoryService()
            const response = await category.addCat(nom, token);
            commit('SET_CATEGORY',[...state.categories, response]);
            commit('SET_ERROR', null)

            return response;
        }

        catch(err) {
            commit('SET_ERROR', err.message);
        }
    },

    async editCat({commit}, data){

        const token = localStorage.getItem('authToken');

        try{

            const category = new CategoryService()
            const response = await category.editCat(token, data)

            return response


        }catch(err) {
            throw err
        }

    },

    async dropCat({commit}, id){

        const token = localStorage.getItem('authToken');
        try{

            const category = new CategoryService()
            const response = await category.dropCat(token, id)

            return response


        }catch(err) {
            throw err
        }

    },

    async getProCat({commit}, nom){

        try{
          
            const request = new CategoryService()
            const reponse = await request.getCatById(nom)

            return reponse

        }catch(err) {
            throw err
        }

    }

};

const getters = {
    categories: state => state.categories,
    error: state => state.error
}

export default{
    state,
    getters,
    mutations,
    actions
}

