<template>
    <div>
        <div class="flex items-center justify-center h-screen">
            <div class="bg-gray-200">
                <div class="flex h-full flex-col md:flex-row">
                    <div class="w-full md:w-2/4 md:order-first" style="background: linear-gradient(to bottom, #DEA20A , #FBF7EE );">
                        <div class="px-10 pt-14">
                            <div class="flex items-center justify-center">
                                <img src="@/images/LOGO_GEN_PNG_BLANC.png" class="w-72" alt="">
                            </div>
                            <div class="flex items-center justify-center bg-white py-10 rounded-lg">
                                <p class="text-3xl font-bold uppercase drop-shadow-md">Nouvelle Province</p>
                            </div>
                        </div>
                    </div>
                    <div class="w-full pt-10 md:w-2/4 px-4 md:px-10 pb-20 md:pb-0 md:pb-10 md:pt-10 text-left md:text-left md:mt-0" style="background-color: #FBF7EE;">
                        <form @submit.prevent="submitProvince" ref="restlogin">
                            <div class="mb-5">
                                <div class="pl-2 mb-2">
                                    <label for="phone" class="text-2xl font-bold">Nom</label>
                                </div>
                                <input type="text" name="" v-model="nom" id="" class="px-4 py-2 w-full border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                            </div>
                            <div class="mb-5">
                                <div class="pl-2 mb-2">
                                    <label for="password" class="text-2xl font-bold">Pays</label>
                                </div>
                                <div>
                                    <select id="pays" name="pays" v-model="pays" class="w-full border border-gray-500 px-3 py-1 rounded-xl"  value="{{old('pays')}}">
                                        <option value="">Sélectionnez un pays</option>
                                        <option value="Afghanistan">Afghanistan</option>
                                        <option value="Albania">Albanie</option>
                                        <option value="Algeria">Algérie</option>
                                        <option value="Andorra">Andorre</option>
                                        <option value="Angola">Angola</option>
                                        <option value="Antigua and Barbuda">Antigua-et-Barbuda</option>
                                        <option value="Argentina">Argentine</option>
                                        <option value="Armenia">Arménie</option>
                                        <option value="Australia">Australie</option>
                                        <option value="Austria">Autriche</option>
                                        <option value="Azerbaijan">Azerbaïdjan</option>
                                        <option value="Bahamas">Bahamas</option>
                                        <option value="Bahrain">Bahreïn</option>
                                        <option value="Bangladesh">Bangladesh</option>
                                        <option value="Barbados">Barbade</option>
                                        <option value="Belarus">Biélorussie</option>
                                        <option value="Belgium">Belgique</option>
                                        <option value="Belize">Belize</option>
                                        <option value="Benin">Bénin</option>
                                        <option value="Bhutan">Bhoutan</option>
                                        <option value="Bolivia">Bolivie</option>
                                        <option value="Bosnia and Herzegovina">Bosnie-Herzégovine</option>
                                        <option value="Botswana">Botswana</option>
                                        <option value="Brazil">Brésil</option>
                                        <option value="Brunei">Brunei</option>
                                        <option value="Bulgaria">Bulgarie</option>
                                        <option value="Burkina Faso">Burkina Faso</option>
                                        <option value="Burundi">Burundi</option>
                                        <option value="Cabo Verde">Cabo Verde</option>
                                        <option value="Cambodia">Cambodge</option>
                                        <option value="Cameroon">Cameroun</option>
                                        <option value="Canada">Canada</option>
                                        <option value="Central African Republic">République centrafricaine</option>
                                        <option value="Chad">Tchad</option>
                                        <option value="Chile">Chili</option>
                                        <option value="China">Chine</option>
                                        <option value="Colombia">Colombie</option>
                                        <option value="Comoros">Comores</option>
                                        <option value="Congo (Brazzaville)">Congo (Brazzaville)</option>
                                        <option value="Congo (Kinshasa)">Congo (Kinshasa)</option>
                                        <option value="Costa Rica">Costa Rica</option>
                                        <option value="Croatia">Croatie</option>
                                        <option value="Cuba">Cuba</option>
                                        <option value="Cyprus">Chypre</option>
                                        <option value="Czech Republic">République tchèque</option>
                                        <option value="Denmark">Danemark</option>
                                        <option value="Djibouti">Djibouti</option>
                                        <option value="Dominica">Dominique</option>
                                        <option value="Dominican Republic">République dominicaine</option>
                                        <option value="Ecuador">Équateur</option>
                                        <option value="Egypt">Égypte</option>
                                        <option value="El Salvador">El Salvador</option>
                                        <option value="Equatorial Guinea">Guinée équatoriale</option>
                                        <option value="Eritrea">Érythrée</option>
                                        <option value="Estonia">Estonie</option>
                                        <option value="Eswatini">Eswatini</option>
                                        <option value="Ethiopia">Éthiopie</option>
                                        <option value="Fiji">Fidji</option>
                                        <option value="Finland">Finlande</option>
                                        <option value="France">France</option>
                                        <option value="Gabon">Gabon</option>
                                        <option value="Gambia">Gambie</option>
                                        <option value="Georgia">Géorgie</option>
                                        <option value="Germany">Allemagne</option>
                                        <option value="Ghana">Ghana</option>
                                        <option value="Greece">Grèce</option>
                                        <option value="Grenada">Grenade</option>
                                        <option value="Guatemala">Guatemala</option>
                                        <option value="Guinea">Guinée</option>
                                        <option value="Guinea-Bissau">Guinée-Bissau</option>
                                        <option value="Guyana">Guyana</option>
                                        <option value="Haiti">Haïti</option>
                                        <option value="Honduras">Honduras</option>
                                        <option value="Hungary">Hongrie</option>
                                        <option value="Iceland">Islande</option>
                                        <option value="India">Inde</option>
                                        <option value="Indonesia">Indonésie</option>
                                        <option value="Iran">Iran</option>
                                        <option value="Iraq">Irak</option>
                                        <option value="Ireland">Irlande</option>
                                        <option value="Israel">Israël</option>
                                        <option value="Italy">Italie</option>
                                        <option value="Jamaica">Jamaïque</option>
                                        <option value="Japan">Japon</option>
                                        <option value="Jordan">Jordanie</option>
                                        <option value="Kazakhstan">Kazakhstan</option>
                                        <option value="Kenya">Kenya</option>
                                        <option value="Kiribati">Kiribati</option>
                                        <option value="Korea, North">Corée du Nord</option>
                                        <option value="Korea, South">Corée du Sud</option>
                                        <option value="Kosovo">Kosovo</option>
                                        <option value="Kuwait">Koweït</option>
                                        <option value="Kyrgyzstan">Kirghizistan</option>
                                        <option value="Laos">Laos</option>
                                        <option value="Latvia">Lettonie</option>
                                        <option value="Lebanon">Liban</option>
                                        <option value="Lesotho">Lesotho</option>
                                        <option value="Liberia">Libéria</option>
                                        <option value="Libya">Libye</option>
                                        <option value="Liechtenstein">Liechtenstein</option>
                                        <option value="Lithuania">Lituanie</option>
                                        <option value="Luxembourg">Luxembourg</option>
                                        <option value="Madagascar">Madagascar</option>
                                        <option value="Malawi">Malawi</option>
                                        <option value="Malaysia">Malaisie</option>
                                        <option value="Maldives">Maldives</option>
                                        <option value="Mali">Mali</option>
                                        <option value="Malta">Malte</option>
                                        <option value="Marshall Islands">Îles Marshall</option>
                                        <option value="Mauritania">Mauritanie</option>
                                        <option value="Mauritius">Maurice</option>
                                        <option value="Mexico">Mexique</option>
                                        <option value="Micronesia">Micronésie</option>
                                        <option value="Moldova">Moldavie</option>
                                        <option value="Monaco">Monaco</option>
                                        <option value="Mongolia">Mongolie</option>
                                        <option value="Montenegro">Monténégro</option>
                                        <option value="Morocco">Maroc</option>
                                        <option value="Mozambique">Mozambique</option>
                                        <option value="Myanmar">Myanmar</option>
                                        <option value="Namibia">Namibie</option>
                                        <option value="Nauru">Nauru</option>
                                        <option value="Nepal">Népal</option>
                                        <option value="Netherlands">Pays-Bas</option>
                                        <option value="New Zealand">Nouvelle-Zélande</option>
                                        <option value="Nicaragua">Nicaragua</option>
                                        <option value="Niger">Niger</option>
                                        <option value="Nigeria">Nigéria</option>
                                        <option value="North Macedonia">Macédoine du Nord</option>
                                        <option value="Norway">Norvège</option>
                                        <option value="Oman">Oman</option>
                                        <option value="Pakistan">Pakistan</option>
                                        <option value="Palau">Palaos</option>
                                        <option value="Panama">Panama</option>
                                        <option value="Papua New Guinea">Papouasie-Nouvelle-Guinée</option>
                                        <option value="Paraguay">Paraguay</option>
                                        <option value="Peru">Pérou</option>
                                        <option value="Philippines">Philippines</option>
                                        <option value="Poland">Pologne</option>
                                        <option value="Portugal">Portugal</option>
                                        <option value="Qatar">Qatar</option>
                                        <option value="Romania">Roumanie</option>
                                        <option value="Russia">Russie</option>
                                        <option value="Rwanda">Rwanda</option>
                                        <option value="Saint Kitts and Nevis">Saint-Christophe-et-Niévès</option>
                                        <option value="Saint Lucia">Sainte-Lucie</option>
                                        <option value="Saint Vincent and the Grenadines">Saint-Vincent-et-les-Grenadines</option>
                                        <option value="Samoa">Samoa</option>
                                        <option value="San Marino">Saint-Marin</option>
                                        <option value="Sao Tome and Principe">Sao Tomé-et-Principe</option>
                                        <option value="Saudi Arabia">Arabie saoudite</option>
                                        <option value="Senegal">Sénégal</option>
                                        <option value="Serbia">Serbie</option>
                                        <option value="Seychelles">Seychelles</option>
                                        <option value="Sierra Leone">Sierra Leone</option>
                                        <option value="Singapore">Singapour</option>
                                        <option value="Slovakia">Slovaquie</option>
                                        <option value="Slovenia">Slovénie</option>
                                        <option value="Solomon Islands">Îles Salomon</option>
                                        <option value="Somalia">Somalie</option>
                                        <option value="South Africa">Afrique du Sud</option>
                                        <option value="South Sudan">Soudan du Sud</option>
                                        <option value="Spain">Espagne</option>
                                        <option value="Sri Lanka">Sri Lanka</option>
                                        <option value="Sudan">Soudan</option>
                                        <option value="Suriname">Suriname</option>
                                        <option value="Sweden">Suède</option>
                                        <option value="Switzerland">Suisse</option>
                                        <option value="Syria">Syrie</option>
                                        <option value="Taiwan">Taïwan</option>
                                        <option value="Tajikistan">Tadjikistan</option>
                                        <option value="Tanzania">Tanzanie</option>
                                        <option value="Thailand">Thaïlande</option>
                                        <option value="Timor-Leste">Timor oriental</option>
                                        <option value="Togo">Togo</option>
                                        <option value="Tonga">Tonga</option>
                                        <option value="Trinidad and Tobago">Trinité-et-Tobago</option>
                                        <option value="Tunisia">Tunisie</option>
                                        <option value="Turkey">Turquie</option>
                                        <option value="Turkmenistan">Turkménistan</option>
                                        <option value="Tuvalu">Tuvalu</option>
                                        <option value="Uganda">Ouganda</option>
                                        <option value="Ukraine">Ukraine</option>
                                        <option value="United Arab Emirates">Émirats arabes unis</option>
                                        <option value="United Kingdom">Royaume-Uni</option>
                                        <option value="United States">États-Unis</option>
                                        <option value="Uruguay">Uruguay</option>
                                        <option value="Uzbekistan">Ouzbékistan</option>
                                        <option value="Vanuatu">Vanuatu</option>
                                        <option value="Vatican City">Cité du Vatican</option>
                                        <option value="Venezuela">Venezuela</option>
                                        <option value="Vietnam">Vietnam</option>
                                        <option value="Yemen">Yémen</option>
                                        <option value="Zambia">Zambie</option>
                                        <option value="Zimbabwe">Zimbabwe</option>
                                    </select>
                                </div>
                            </div>
                            <div class="mb-5">
                                <div class="pl-2 mb-2">
                                    <label for="password" class="text-2xl font-bold">Monnaie</label>
                                </div>
                                <div>
                                    <select name="" v-model="monnaie" id="" class="block w-full px-4 py-2 border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                                        <option value="" disabled selected hidden></option>
                                        <option :value="items.id" v-for="items in sortedItems" :key="items.id">{{ items.nom }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="mb-7">
                                <div class="pl-2 mb-2">
                                    <label for="phone" class="text-2xl font-bold">Taux</label>
                                </div>
                                <input type="text" name="" v-model="taux" id="" class="px-4 py-2 w-full border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                            </div>
                            <div class="">
                                <button type="submit" class="px-4 py-2 w-full rounded-lg text-white font-bold text-2xl" style="background-color: #DEA20A;">Ajouter</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>

import Money from '@/_services/money.services'

export default{
    name: 'newprovince',

    data(){
            return {
                itemMoney: [],
                nom: '',
                pays: '',
                monnaie: '',
                taux: '',
            }
        },

        mounted(){
            const money = new Money();
            money.getAllS()
                .then(monnaie => {
                    this.itemMoney = monnaie.data;
                })
        },

        computed:{
            sortedItems(){
                return this.itemMoney.sort((a,b) => a.id - b.id);
            }
        },
        methods:{
            submitProvince : function(){
                this.$store.dispatch('submitProvince',{
                    nom: this.nom,
                    pays: this.pays,
                    monnaie : this.monnaie,
                    taux: this.taux,
                })
                .then((response) =>{
                    ////.log(response)
                    this.$router.push('/province')
                })
                .catch((error) => {
                    ////.log(error);
                });
                this.restlogin();
            },
            restlogin(){
                    this.nom = '';
                    this.pays = '';
                    this.monnaie = '';
                    this.taux = '';
                    this.$refs.restlogin.reset()
                }
        }
}

</script>