import AdminGestionService from "@/_services/AdminGestion.services";
import Ravitaillement from "@/_services/ravitaillement.services"
import Dashboard from "@/_services/dashboard.services"
import Commande from "@/_services/commandInterne.services"
import Assig from '@/_services/assignerLivreur.services'

const state = {
    assigner: []
}

const mutations = {
    SET_ASSIGNER(state, data) {
        state.assigner = data
    },
}


const actions = {
    //dashoard
    async GetDashAdGe({commit}){
        const token = localStorage.getItem('authToken');

        try{

            const getDataAd = new Dashboard();
            const reponse = await getDataAd.dashbordAdGe(token);
            
            return reponse

        }catch(error){
            throw error;

        }
    },

    async getDashFiltre({commit}, data){
        const token = localStorage.getItem('authToken');

        try{

            const getDataAd = new Dashboard();
            const reponse = await getDataAd.getDashFiltre(token, data);
            
            return reponse

        }catch(error){
            throw error;

        }
    },

    //dashoard
    async annulCom({commit}, id){
        const token = localStorage.getItem('authToken');
        
        try{

            const getDataAd = new Commande();
            const reponse = await getDataAd.annulCom(token, id);
            
            return reponse

        }catch(error){
            throw error;

        }
    },

    

    //dashoard
    async supprimeCom({commit}, id){
        const token = localStorage.getItem('authToken');
        
        try{

            const getDataAd = new Commande();
            const reponse = await getDataAd.supprimeCom(token, id);
            
            return reponse

        }catch(error){
            throw error;

        }
    },

    //stock générale
    async StockGene(){
        const token = localStorage.getItem('authToken');

        try{

            const getDataAd = new AdminGestionService();
            const reponse = await getDataAd.getStockGene(token);
            
            return reponse

        }catch(error){
            throw error;

        }
    },

    //affiche le demande de ravitaillement
    async getDemand(){
        const token = localStorage.getItem('authToken');

        try{

            const getDataAd = new AdminGestionService();
            const reponse = await getDataAd.getDemRavi(token);
            
            return reponse

        }catch(error){
            throw error;

        }
    },

    //demande de ravitaillemet par rapport à la demande
    async sendDataRavi({commit},data){
        const token = localStorage.getItem('authToken');

        try{

            const request = new Ravitaillement();
            const reponse = await request.postRaviId(token, data);

            return reponse;

        }catch(error){
            throw error;
        }

    },

    //ravitailler un boutique sans demande
    async sendRavi({commit},data){
        const token = localStorage.getItem('authToken');

        
        try{

            const request = new Ravitaillement();
            const reponse = await request.postRavi(token, data);

            return reponse;

        }catch(error){
            throw error;
        }

    },

    async getComAll({commit}){

        const token = localStorage.getItem('authToken');

        try{

            const getData = new AdminGestionService();
            const response = await getData.getComAll(token);

            return response

        }catch(error){
            throw error;
        }

    },

    //action pour tous les assignement
    async SendedAssigner({commit}, dataAssign){
        const token = localStorage.getItem('authToken');
        
        //pour la boutique
        if(dataAssign.role == 'manager'){
            
            const data = {
                boutique_id: dataAssign.boutique_id,
                id: dataAssign.id
            }
            
            try{
                const getData = new Assig();
                const reponse = await getData.postAsComShop(data, token);

                //commit('SET_ASSIGNER', [...state.assigner, reponse]);
                
                return reponse
            }catch(err){
                throw err;
            }

        }

        else if(dataAssign.role == 'agent'){
            
            const data = {
                'agent_id': dataAssign.idUser,
                id: dataAssign.id
            }

            try{
                const getData = new Assig();
                const reponse = await getData.postAsComAgent(data, token);

                commit('SET_ASSIGNER', [...state.assigner, reponse]);
                
                return reponse
            }catch(err){
                throw err;
            }

        }

    },

    async getHisRav({commit}){

        const token = localStorage.getItem('authToken');

        try{

            const getData = new Ravitaillement();
            const response = await getData.getStockRavi(token);

            return response

        }catch(error){
            throw error;
        }

    },
}

const getters = {
    assigner: state => state.assigner
}

export default{
    actions,
    mutations,
    //getters,
    state
}