<template>
    <div>
        <div class="w-screen h-screen overflow-y-auto overflow-x-hidden">
            <Header></Header>

            <!--Contenu principal-->  
            
            <div class="w-screen bg-white ">
                <div class="flex justify-center  pt-4 mb-10">
                    <ViewDetails></ViewDetails>
                    <!--ProduitSimilary></ProduitSimilary-->
                </div>
            </div>  
            <Footer></Footer>          
        </div>
    </div>
</template>

<script>

import Header from '@/components/layout/Header.vue'
import ViewDetails from '@/components/ViewsDetails.vue'
import Footer from '@/components/layout/Footer.vue'

    export default {
        name: 'DetailProduit',
        components: {
            Header,
            ViewDetails,
            Footer,
        },
    }

</script>