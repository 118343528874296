<template>
    
    <div class="w-screen h-screen overflow-x-hidden" @click="hideDiv">
        <Header></Header>
        <div class="w-full bg-white">
            <div class="w-full">
                <div class="px-4 md:px-36">
                    <div class="my-16">
                        <div class="flex" >
                            <router-link to="/demanderavi" v-if="roles == 'manager'">
                                <button class="px-4 text-white py-1.5 rounded-lg" style="background-color: #DEA20A;">Demande de Ravitaillement</button>
                            </router-link>
                        </div>
                    </div>
                    <div class="w-full h-96" style="background-color: #FBF7EE;">
                        <div v-if="roles == 'manager'">
                            <div class="flex w-full" style="background-color: #DEA20A;">
                                <div class="w-52 text-white text-center text-black text:xs sm:text-sm pl-2 py-5 text-md">
                                    Date
                                </div>
                                <div class="w-52 text-white text-center text-black text:xs sm:text-sm pl-2 py-5 text-md">
                                    Produit
                                </div>
                                <div class="w-52 text-white text-center text-black text:xs sm:text-sm pl-2 py-5 text-md">
                                    Nombre Produit
                                </div>
                                <div class="w-52 text-white text-center text-black text:xs sm:text-sm pl-2 py-5 text-md">
                                    Etat
                                </div>
                            </div>
                            <div class="h-80 w-full overflow-y-auto">
                                <div class="flex w-full my-1.5" v-for="(demand, index) in fetchData" :key="index">
                                    <div class="w-52 p-2   text-sm  text-center text-black">
                                        <p v-text="formData(demand.created_at)"></p>
                                    </div>
                                    <div class="w-52 p-2   text-sm  text-center text-black">
                                       <p v-for="(produits, index) in demand.produits" :key="index" class="mr-1">{{ produits.nom }},</p>
                                    </div>
                                    <div class="w-52 p-2   text-sm  text-center text-black">
                                        1
                                    </div>
                                    <div class="w-52 p-2   text-sm  text-center text-black">
                                        <p class="font-bold" style="color: #25A4EC" v-if="demand.statut == 'en attente'">En attente</p>
                                        <p class="font-bold" style="color: #DEA20A" v-else>Ravitaillé</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Les autres colonnes -->
                        <div v-if="roles == 'admin' || roles == 'gestionnaire'">
                            <div class="flex w-full" style="background-color: #DEA20A;">
                                <div class="w-52 text-white text-center text-black text:xs sm:text-sm pl-2 py-5 text-md">
                                    Date
                                </div>
                                <div class="w-52 text-white text-center text-black pl-2 py-5 text-md">
                                    Boutique
                                </div>
                                <div class="w-52 text-white   text-center text-black pl-2  py-5 text-md">
                                    Manager
                                </div>
                                <div class="w-52 text-white text-center text-black pl-2  py-5 text-md">
                                    Nombre de produits
                                </div>
                                <div class="w-52 text-white text-center text-black pl-2  py-5 text-md">
                                    Options
                                </div>
                            </div>
                            <div class="h-80 px-2 w-full overflow-y-auto ">
                                <div class="flex w-full my-1.5" v-for="(dem, index) in fetchData" :key="index">
                                    <div class="w-52 p-2   text-sm  text-center text-black   ">
                                        {{formData(dem.created_at)}}
                                    </div>
                                    <div class="w-52 text-sm   p-2  text-center text-black">
                                        {{ dem.boutique.nom }}
                                    </div>
                                    <div class="w-52   p-2 text-center text-black   text-sm">
                                        {{ dem.boutique.manager.user.nom }} {{ dem.boutique.manager.user.prenom }}
                                    </div>
                                    <div v-if="dem.produits"  class="w-52  py-2   text-sm text-center text-black">
                                       {{ dem.produits.length}}
                                    </div>
                                    <div class="w-52 flex  p-2   text-center text-black  overflow-x-auto  text-black">
                                        <button class="text-white text-xs sm:text-sm md:text-md w-full p-2 rounded-md " @click="ravitailler(dem.id,$event)" style="background-color: #DEA20A;">Détails</button>
                                        <button class="w-full mx-4 text-white text-xs sm:text-sm md:text-md p-2 rounded-md" style="background-color: #25A4EC;">
                                            <router-link :to="getDemandUrl(dem.id,dem.boutique.id)">
                                                Ravitailler
                                            </router-link>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="modal" id="myModal" class="fixed inset-1 flex items-center justify-center z-50">
            <div class="bg-white rounded-lg p-6 max-w-sm mx-auto">
                <h2 class="text-lg font-bold mb-4">Détails de produits demandée</h2>
                <div class="text-left flex mb-10 border-b" v-for="(det, index) in detail.produits" :key="index">
                    <p class="mr-3">{{ index + 1 }}</p>
                    <p>{{ det.nom }}</p>
                </div>
                <button @click="close" id="closeModalButton" class="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    Fermer
                </button>
            </div>
        </div>
        <Footer></Footer>
    </div>

</template>

<script>
import Header from '@/components/layout/Header.vue'
import Footer from '@/components/layout/Footer.vue'
import {mapActions} from 'vuex'
import CryptoJs from 'crypto-js'


export default{
    name: "ListRaviBoutique",

    components: {
        Header,
        Footer,
    },

    data(){
        return{
            fetchData: [],
            roles: null,
            modal: false,
            detail: [],
        }
    },

    methods: {

        // affiche les deamande fait parti boutique
        ...mapActions(['GetDemand']),
        GetData(){
            this.GetDemand()
                .then(response =>{
                    this.fetchData = response.data
                    //.log(this.fetchData);
                })
                .catch(error =>{
                    //.log(error)
                });
        },

        formData(date){
            const dateObj = new Date(date);

            const year = dateObj.getFullYear();
            const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
            const day = dateObj.getDate().toString().padStart(2, '0');

            return `${year}/${month}/${day}`
        },

        //get demande de ravita partie administrateur
        ...mapActions(['getDemand']),
        DemandGet(){
            this.getDemand()
                .then(response =>{
                    this.fetchData = response.data
                    //.log(this.fetchData)
                })
                .catch(error =>{
                    //.log(error)
                });
        },

        //action ravitailler
        ravitailler(id, event){
            event.stopPropagation();
            
            
            const idT = this.fetchData.find(item => item.id == id);
            this.modal = true;
            this.detail = idT;
            //.log(this.detail)
        },

        close(){
            this.modal = false;
        },

        hideDiv(){
            this.model =  false;
        },
        
        //cryptage de l'url

        encrytpId(id){

            const encryptedId = CryptoJs.AES.encrypt(id.toString(), this.encryptionKey).toString();
            return encodeURIComponent(encryptedId);

        },

        getDemandUrl(id, idShop){
            
            const plainText = id + '-' + idShop;
            const encryptedText = encodeURIComponent(CryptoJs.AES.encrypt(plainText, 'myEncryptionKey').toString());
            return '/ravitaillementId/' + encryptedText;

        }


    },

    created(){
        this.roles = localStorage.getItem("role");

        if(this.roles == null){
            this.$router.push('/authentification')
        }
        if(this.roles == 'manager'){
            this.GetData();
        }else if(this.roles == 'admin' || this.roles == 'gestionnaire'){
            this.DemandGet();
        }
    },
}

</script>