<template>

    <div class="w-screen h-screen overflow-x-hidden">
                <Header></Header>
                <div class="w-full bg-white">
                    <div class="w-full">
                        <div class="px-4 md:px-36">
                            <div class="my-16">
                                <div class="flex">
                                <button type="submit" class="px-4 text-white py-1.5 rounded-lg" style="background-color: #DEA20A;">
                                    <router-link to="/newmoney">
                                        Ajouter Monnaie
                                    </router-link>
                                </button>
                            </div>
                        </div>
                         <!--Affichage de message de successfull-->
                         <div class="px-4 flex justify-center" v-show="succes">
                            <p class="bg-green-400 px-1.5 py-2 text-white">{{succes}}</p>
                        </div>
                        <!--Affichage de message de successfull-->
    
    
                        <!--Affichage de message de error-->
                        <div class="px-4 flex justify-center" v-show="erreur">
                            <p class="bg-red-600 px-4 py-2 text-white">{{erreur}}</p>
                        </div>
                        <!--Affichage de message de error-->
                        <div class="w-full h-96" style="background-color: #FBF7EE;">
                            <div>
                                <div class="flex w-full" style="background-color: #DEA20A;">
                                    <div class="w-40 text-white text-center text-black pl-2 py-5 text-md">
                                        N°
                                    </div>
                                    <div class="w-52 text-white text-center text-black pl-2 py-5 text-md">
                                        Nom Monnaie
                                    </div>
                                    <div class="w-52 text-white text-center text-black pl-2 py-5 text-md">
                                        Symbole
                                    </div>
                                    <div class="w-52 text-white text-center text-black pl-2 py-5 text-md">
                                        Options
                                    </div>
                                    
                                </div>
                                <div class="h-96 w-full overflow-y-auto">
                                    <div class="flex w-full my-1.5" v-for="(items, index) in sortedItems" :key="index">
                                        <div class="w-40 p-2 overflow-x-auto whitespace-nowrap   text-sm  text-center text-black">
                                            {{ index + 1 }}
                                        </div>
                                        <div class="w-52 p-2 overflow-x-auto whitespace-nowrap   text-sm  text-center text-black">
                                            {{ items.nom }}
                                        </div>
                                        <div class="w-52 p-2 overflow-x-auto whitespace-nowrap   text-sm  text-center text-black">
                                            {{ items.symbole }}
                                        </div>
                                        <div class="w-52 p-2 overflow-x-auto whitespace-nowrap   text-sm  text-center text-black">
                                            <button @click="openModal(items.id)" class="pw-max text-white text-xs sm:text-sm md:text-md w-full p-2 rounded-md" style="background-color: #25A4EC;">
                                                    Modifier
                                            </button>
                                            <button @click="drop(items.id)" class="w-max mx-4 text-white text-xs sm:text-sm md:text-md p-2 rounded-md bg-red-500">
                                                Supprimer 
                                            </button>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <!-- Les autres colonnes -->
                        </div>
                    </div>
                </div>
            </div>
            <div v-show="modal" v-if="getId" class="fixed inset-0 flex items-center justify-center">
                    <div class="bg-white shadow-md rounded-lg p-6 max-w-sm mx-auto z-50">
                        <h2 class="text-lg font-bold mb-4">Modification</h2>
                        <div class="text-left flex justify-between mb-5">
                            <p class="mr-3 font-bold">Nom :</p> <input type="text" v-model="name" class="border px-1 border-black" name="" id="">
                        </div>
                        <div class="text-left flex justify-between mb-5">
                            <p class="mr-3 font-bold">Symbole :</p> <input type="text" v-model="symbole" class="border px-1 border-black" name="" id="">
                        </div>
                        <p class="text-red-500">{{msg}}</p>
                        <div class="text-left flex justify-between">                    
                            <button @click="modifCat" id="closeModalButton" class="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                Modifier
                            </button>
                            <button @click="close" id="closeModalButton" class="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                                Fermer
                            </button>
                        </div>
                    </div>
                </div>
                <div v-if="IdDrop" class="fixed inset-0 flex items-center justify-center">
                    <div class="bg-white shadow-md rounded-lg p-6 max-w-sm mx-auto z-50">
                        <h2 class="text-lg font-bold mb-4">Voulez-vous supprimer cet élément?</h2>
                        <div class="text-left flex justify-between">                    
                            <button @click="dropCat(IdDrop)" id="closeModalButton" class="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                Supprimer
                            </button>
                            <button @click="close" id="closeModalButton" class="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
                                Fermer
                            </button>
                        </div>
                    </div>
                </div>
            <Footer></Footer>
        </div>
        
    </template>

<script>

import Header from '@/components/layout/Header.vue'
import Footer from '@/components/layout/Footer.vue'
import Money from '@/_services/money.services'


    export default{
        name: 'money',

        components:{
            Header,
            Footer,
        },

        data(){
            return {
                itemMoney: [],
                name: '',
                symbole: '',
                modal: false,
                idCat: '',
                getId: [],
                succes: null,
                erreur: null,
                msg: null,
                IdDrop: null,
            }
        },

        mounted(){
            const money = new Money();
            money.getAllS()
                .then(monnaie => {
                    this.itemMoney = monnaie.data;
                })
        },

        computed:{
            sortedItems(){
                return this.itemMoney.sort((a,b) => a.id - b.id);
            }
        },

        methods: {

            close(){
                this.modal = false;
                this.IdDrop = null
            },

            openModal(id){
                this.idCat = id;

                if(this.modal === id){
                    this.modal = null;
                }else{
                    this.modal = id
                    const idCom = this.itemMoney.find(com => com.id == id);
                    this.getId = idCom
                    this.name = this.getId.nom
                    this.symbole = this.getId.symbole
                    //console.log(this.getId)

                }

            },

            modifCat(){
                if(!this.name || !this.symbole){
                    this.msg = "Aucun champ n'est peut être vide"
                }else{
                    this.$store.dispatch('editMoney',{
                        nom : this.name,
                        id : this.idCat,
                        symbole: this.symbole
                    })
                    .then((response) => {
                        this.modal = false;
                        //(response)
                        if(response.status === 200){
                            this.succes = "Monnaie modifier " + response.data.nom
                            setTimeout(() =>{
                                            this.succes = false;
                                            location.reload();
                                        }, 
                                        5000)
                        }
                    })
                    .catch((error) => {
                        
                    })
                    .finally(() => {
                        this.nameCate =''
                    });
                }
                
            },

            drop(id){

                this.IdDrop = id;

            },

            dropCat(id){
                this.IdDrop = null
                this.$store.dispatch('dropMoney',{
                    id : id
                })
                .then((response) => {
                    //(response)
                    if(response.status === 200){
                        this.succes = "Monnaie supprimer avec succes "
                        setTimeout(() =>{
                                        this.succes = false;
                                        location.reload();
                                    }, 
                                    5000)
                    }else{
                        
                    }
                })
                .catch((error) => {
                    
                })
                .finally(() => {
                    return
                });
            }

        }

    }

</script>