<template>
    <div class="grid grid-cols-2 md:grid-cols-2">
        <div class="flex mb-4">
            <div class="w-1/4">
                <label for="" class="font-bold">Du</label>
            </div>
            <div class="w-3/4">
                <input type="date" v-model="date.debut" name="" id="" placeholder="+24***" class="block border w-62 px-1" style="background-color: #FBF7EE;">
            </div>
        </div>
        <div class="flex mb-4">
            <div class="w-1/4 mr-4">
                <label for="" class="font-bold">Au</label>
            </div>
            <div class="w-3/4">
                <input type="date" v-model="date.fin" name="" id="" @change="dateFil" placeholder="+24***" class="block border w-62 px-1" style="background-color: #FBF7EE;">
            </div>
        </div>
        <!--div class="flex w-full mb-4">
            <div class="w-1/4 mr-4">
                <label for="" class="font-bold">Date</label>
            </div>
            <div class="w-3/4">
                <select name="" id="" class="block w-full px-1" style="background-color: #FBF7EE;">
                    <option value="aujourd'hui">Aujourd'hui</option>
                        <option value="hier">Hier</option>
                        <option value="semaine">Cette semaine</option>
                        <option value="mois">Ce mois</option>
                        <option value="dernier-mois">Mois dernier</option>
                        <option value="année">Année</option>
                        <option value="dernière-année">Année dernière</option>
                </select>
            </div>
        </div-->
    </div>
    <div class="w-full">
        <div class="grid grid-cols-2 md:grid-cols-3">
            <div class="h-32 mr-1 mb-1 shadow-lg pt-0" style="background: linear-gradient(to left top,#DEA20A , #FBF7EE );">
                <div class="w-full h-18 overflow-hidden px-1 text-ellipsis leading-none">
                    <p class="text-lg py-4 h-full font-bold text-right text-white line-clamp-1">{{ itemDash['commandes'] }}</p>
                </div>
                <div class="w-full h-14 overflow-hidden px-1 text-ellipsis leading-none">
                    <p class="text-xl font-bold py-2 h-full text-right text-black line-clamp-1">Commandes</p>
                </div>
            </div>
            <div class="h-32 mr-1 shadow-lg bg-gradient-to-l from-yellow-200 to-yellow-400">
                <div class="w-full h-18 overflow-hidden px-1 text-ellipsis leading-none">
                    <p class="text-lg py-4 h-full font-bold text-right text-white line-clamp-1">{{ itemDash['livraisons'] }}</p>
                </div>
                <div class="w-full h-14 overflow-hidden px-1 text-ellipsis leading-none">
                    <p class="text-xl font-bold py-2 h-full text-right text-black line-clamp-2">Livrer</p>
                </div>
            </div>
            <div class="h-32 mr-1 shadow-lg"  style="background: linear-gradient(to left top, #E70C0C, #F48C8C );">
                <div class="w-full h-18 overflow-hidden px-1 text-ellipsis leading-none">
                    <p class="text-lg py-4 h-full font-bold text-right text-white line-clamp-1">{{ itemDash['annulées'] }}</p>
                </div>
                <div class="w-full h-14 overflow-hidden px-1 text-ellipsis leading-none">
                    <p class="text-xl font-bold py-2 h-full text-right text-black line-clamp-2">Annuler</p>
                </div>
            </div>

            <div class="h-32 mr-1 mb-1 shadow-lg bg-gradient-to-l from-yellow-200 to-yellow-400">
                <div class="w-full h-18 overflow-hidden px-1 text-ellipsis leading-none">
                    <p class="text-lg py-4 h-full font-bold text-right text-white line-clamp-1">{{ itemDash['en livraison'] }}</p>
                </div>
                <div class="w-full h-14 overflow-hidden px-1 text-ellipsis leading-none">
                    <p class="text-xl font-bold h-full text-right text-black line-clamp-2">En livraison</p>
                </div>
            </div>
            <div class="h-32 mr-1 shadow-lg bg-gradient-to-l from-blue-200 to-blue-400">
                <div class="w-full h-18 overflow-hidden px-1 text-ellipsis leading-none">
                    <p class="text-lg py-4 h-full font-bold text-right text-white line-clamp-1">{{ itemDash['CA'] }}</p>
                </div>
                <div class="w-full h-14 overflow-hidden px-1 text-ellipsis leading-none">
                    <p class="text-xl font-bold py-2 h-full text-right text-black line-clamp-2">Chiffre d'affaire</p>
                </div>
            </div>

            <div class="h-32 mr-1 shadow-lg bg-gradient-to-r from-teal-200 to-teal-400">
                <div class="w-full h-18 overflow-hidden px-1 text-ellipsis leading-none">
                    <p class="text-lg py-4 h-full font-bold text-right text-white line-clamp-1">{{ itemDash['profit'] }}</p>
                </div>
                <div class="w-full h-14 overflow-hidden px-1 text-ellipsis leading-none">
                    <p class="text-xl font-bold h-full text-right text-black line-clamp-2">Profit</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Axios from '@/_services/caller.services'
    
    export default{
        name: "minidashboard",

        data(){
            return {
                token: null,
                itemDash: [],
                roles: null,
                date:{
                    debut:'',
                    fin:'',
                }
            }
        },

        props:{
            id:{
                type: Number,
                required: true,
            }
        },

        computed: {
            idRecu(){
                return this.id
            }
        },

        created() {
            this.token = localStorage.getItem("authToken");
            this.roles = localStorage.getItem("role")
            if(this.roles === 'admin' || this.roles === 'gestionnaire'){
                this.GetDashLiv()
            }
        },

        methods: {

            //voir dashboard du livreur
            async GetDashLiv(){
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`,
                    'Access-Control-Allow-Origin':'*',
                    'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,PATCH,OPTIONS',
                    'Access-Control-Allow-Headers': "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'"
                }

                //.log(this.date)
                try{
                    const reponse = await Axios.get(
                        `/api/dashboard/${this.idRecu}/livreur`,
                        {headers, }
                        );
                    if(!this.date.debut && !this.date.fin){
                        this.itemDash = reponse.data.data
                    }
                    ////.log(reponse.data.data)
                } catch(error) {
                    //.error(error);
                    throw error;
                };
            },

            async dateFil(){
                const headers = {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`,
                    'Access-Control-Allow-Origin':'*',
                    'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,PATCH,OPTIONS',
                    'Access-Control-Allow-Headers': "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'"
                }

                
                try{
                    const reponse = await Axios.get(
                        `/api/dashboard/${this.idRecu}/livreur/${this.date}`,
                        {headers, params : this.date}
                        );
                    this.itemDash = reponse.data.data
                    ////.log(reponse.data.data)
                } catch(error) {
                    //.error(error);
                    throw error;
                }finally {
                    this.date={
                        debut:'',
                        fin:'',
                    }
                }
            }
        }


    }

</script>