import ManagerService from '@/_services/manager.service'

const state = {
    livreData: null,
    idShop: null,
    stockShop: null,
}

const mutations = {
    setDataLivShop(state, data){
        state.livreData = data
    },

    setIdShop(state, id){
        state.idShop = id

    },

    setStockShop(state, data){
        state.stockShops = data
    },
}

const actions = {

    async fetchLiveShop({commit}){
        const token = localStorage.getItem('authToken');

        try{

            const getData = new ManagerService();
            const reponse = await getData.getLivreurManger(token);
            
            commit('setDataLivShop', reponse)
            //commit('setIdShop', reponse.data['0']['boutique']['id'])
            //localStorage.setItem('idShop', reponse.data['0']['boutique']['id'])
            
            return reponse

        }catch(error){
            throw error;

        }
    },

    async getStockBotique({commit}){
        const token = localStorage.getItem('authToken');
        const boutique_id = localStorage.getItem('idShop');

        try{

            const getData = new ManagerService();
            const reponse = await getData.getStock(boutique_id,token);
            
            commit('setStockShop', reponse)
            return reponse

        }catch(error){
            throw error.reponse;

        }
    },

    async SubmitDemand({commit}, DataDemand){
        const token = localStorage.getItem('authToken');
        
        try{
            const request = new ManagerService();

            const reponse = await request.demandeRavi(DataDemand, token);

            return reponse
        }catch(error){
            throw error.reponse;
        };

    },

    async GetDemand({commit}){
        const token = localStorage.getItem('authToken');

        try{

            const getData = new ManagerService();
            const reponse = await getData.getDemand(token);
            
             
            return reponse

        }catch(error){
            throw error.reponse;

        }
    },    

    async GetDashboard({commit}){
        const token = localStorage.getItem('authToken');

        try{

            const getData = new ManagerService();
            const reponse = await getData.getDashoard(token);
            
             
            return reponse

        }catch(error){
            throw error.reponse;

        }
    },   
    
    async getCommandeManager({commit}){
        const token = localStorage.getItem('authToken');

        try{

            const getData = new ManagerService();
            const reponse = await getData.getCommandeManger(token);

            return reponse

        }catch(error){
            throw error;
        }

    },

}

export default {
    actions,
    state,
    mutations,
}