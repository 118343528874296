import ConfigService from '@/_services/configuration.services'


const actions = {

    async contact({commit}, data){

        try{
            const token = localStorage.getItem('authToken');

        const request = new ConfigService()
        const reponse = await request.configContact(data, token)

        return reponse

        }catch (err) {
            throw err
        }
    },

    async Info({commit}, data){

        try{
            const token = localStorage.getItem('authToken');

        const request = new ConfigService()
        const reponse = await request.configInfo(data, token)

        return reponse

        }catch (err) {
            throw err
        }
    },

    async Securite({commit}, data){

        try{
            const token = localStorage.getItem('authToken');

        const request = new ConfigService()
        const reponse = await request.configSecurite(data, token)

        return reponse

        }catch (err) {
            throw err
        }
    }

}

export default{
    actions
}