import Livreur from '@/_services/livreurs.services'

const actions = {

    async getLivreur({commit}){

        const token = localStorage.getItem('authToken');

        try{

            const getData = new Livreur();
            const response = await getData.getAllLivre(token);

            return response

        }catch(error){
            throw error;
        }

    },

    async getComLivreur({commit}){

        const token = localStorage.getItem('authToken');

        try{

            const getData = new Livreur()
            const response = await getData.getCommandeLivreur(token);

            return response

        }catch(error){
            throw error;
        }

    },

    async getStocks({commit},id){
        const token = localStorage.getItem('authToken');

        try{

            const getData = new Livreur()
            const response = await getData.getStokLivre(token,id);

            return response

        }catch(error){
            throw error;
        }

    }

}

export default{
    actions
}