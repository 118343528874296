<template>
       
    <div class="mb-8 md:mt-10 bg-white shadow-md rounded-lg overflow-hidden h-88 pb-4 w-full" v-for="(item, index) in items" :key="index">
        <router-link :to="getDetail(item.id)">
            <div class="h-60">
                <img :src="getPhotoUrl(item.images['0']['chemin'])" class="w-full h-full object-center" alt="" srcset="">
            </div>
            <div class="h-20 p-1 mb-2">
                <div class="text-left overflow-hidden text-ellipsis p-2 h-10 mb-2">
                    <p class="line-clamp-2 h-full leading-none">{{ item.nom }}</p>
                </div>
                <div class="flex justify-end mx-2">    
                    <div class="px-1.5" style="background-color: #25A4EC;">
                        <span class="text-white text-sm">{{ item.prix }} $</span>
                    </div>
                </div>
            </div>
        </router-link>
        <button class="w-full">
            <router-link :to="getDetail(item.id)">
                <div class="px-1">
                    <div class="cursor-pointer py-1 mx-2" style="background-color: #DEA20A;">
                        <span class="text-sm text-white uppercase">Commandez maintenant</span>
                    </div>
                </div>
            </router-link>
        </button>

    </div>           
<!--div class="my-8">
    <button class="p-2 rounded-lg text-white cursor-pointer" style="background-color: #DEA20A;" @click="loadMore" v-if="displayedItems.length < items.length">Afficher plus</button>
</div-->

</template>

<script>
import ProduitService from '@/_services/produit.services'
import CryptoJs from 'crypto-js'
import Province from '@/_services/province.services'

export default{
name: "ContentArt",


data() {
    return {
        items: [], // Vos données d'éléments
        displayedItems: [], // Les éléments affichés actuellement
        taux: null, //
        devise: null, //
        localisation: null, //
        itemProvince: null,
        ville: null, //
        loadCount: 0
    }
},

created() {

    this.taux = localStorage.getItem('taux_ville')
    this.ville = localStorage.getItem('ville')
    this.devise = localStorage.getItem('devis_ville')            
    this.localisation = localStorage.getItem("localisation");
    //.log(this.localisation)
    
    this.fetchData()
    this.loadCount++;
},

mounted() {
    
    //Liste produit
   /*const produitService = new ProduitService();
    produitService.getAllProduit()
    .then(produits => {
        this.items = produits.data;
        this.displayedItems = this.items.slice(0, 15);
    }).catch(err => {//(err)
        });
    */
    //fin liste produit

    //liste de ville
    const province = new Province();
    
    province.getAllPro()
        .then(prov => {
            const data = prov.data.find(ville => ville.nom == this.ville)
             
            if(!this.taux){
                
            }else{
                if(data.taux != this.taux) {
                    localStorage.setItem("taux_ville", data.taux);
                }
            }
            
        })


},
methods: {
    loadMore() {
        const currentLength = this.displayedItems.length;
        const remainingItems = this.items.slice(currentLength, currentLength + 15);
        this.displayedItems = this.displayedItems.concat(remainingItems);
    },

    redirectToLogin(){
        this.$router.push('/authentification');
    },

    getPhotoUrl(url){
        let params = 'https://api.genesisdream.store/public/storage/' + url;
        //let params = 'http://127.0.0.1:8000/storage/' + url
        //(params);
        return params
    },

    //cryptage de l'url

    encrytpId(id){

        const encryptedId = CryptoJs.AES.encrypt(id.toString(), this.encryptionKey).toString();
        return encodeURIComponent(encryptedId);

    },

    getDetail(id, idShop){

        const plainText = id + '-' + idShop;
        const encryptedText = encodeURIComponent(CryptoJs.AES.encrypt(plainText, 'myEncryptionKey').toString());
        return '/detailProduit/' + encryptedText;

    },

    async fetchData() {
        try {
            const produitService = new ProduitService();
            const response = await produitService.getAllProduit();
            this.items = response.data;
            if (this.loadCount % 2 === 1) {
                this.shuffleArticles();
            } // Appel du mélange après la récupération des produits
            this.displayedItems = this.items.slice(0, 15);
        } catch (error) {
            console.error('Erreur lors de la récupération des produits', error);
        }
    },

    shuffleArticles(){

        const shuffledItems = [...this.items];
        let currentIndex = shuffledItems.length;
        let temporaryValue, randomIndex;

        while (currentIndex !== 0) {
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            temporaryValue = shuffledItems[currentIndex];
            shuffledItems[currentIndex] = shuffledItems[randomIndex];
            shuffledItems[randomIndex] = temporaryValue;
        }

        // Mettez à jour this.items avec la copie mélangée
        this.items = shuffledItems;


    }

    },
    computed: {
        
        tokenExists(){
            return localStorage.getItem('authToken')
        }
    },

}

</script>