<template>
    <div>
        <div class="flex items-center justify-center h-screen">
            <div class="bg-gray-200 h-2/3">
                <div class="flex h-full flex-col md:flex-row">
                    <div class="w-full md:w-2/4 md:order-first" style="background: linear-gradient(to bottom, #DEA20A , #FBF7EE );">
                        <div class="px-10 pt-14">
                            <div class="flex items-center justify-center">
                                <img src="@/images/LOGO_GEN_PNG_BLANC.png" class="w-72" alt="">
                            </div>
                            <div class="flex items-center justify-center bg-white py-10 rounded-lg">
                                <p class="text-3xl font-bold uppercase drop-shadow-md">Nouvelle Boutique</p>
                            </div>
                        </div>
                    </div>
                    <div class="w-full pt-10 md:w-2/4 px-4 md:px-10 pb-20 md:pb-0 md:pb-10 md:pt-10 text-left md:text-left md:mt-0" style="background-color: #FBF7EE;">
                        <div class="mb-7">
                            <div class="pl-2 mb-2">
                                <label for="phone" class="text-2xl font-bold">Nom de la boutique</label>
                            </div>
                            <input type="text" name="" v-model="nom" id="" class="px-4 py-2 w-full border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                        </div>
                        <div class="mb-7">
                            <div class="pl-2 mb-2">
                                <label class="text-2xl font-bold">Manager</label>
                            </div>
                            <div>
                                <select name="" id="" v-model="manager"  class="block w-full px-4 py-2 border uppercase rounded-lg border-gray-600 shadow-md shadow-gray-400">
                                    <option value="" disabled selected hidden></option>
                                    <option :value="itemManager.manager.id" class="uppercase" v-for="itemManager in sortedItemsManager" :key="itemManager.id" >{{ itemManager.nom }} {{ itemManager.prenom }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="mb-14">
                            <div class="pl-2 mb-2">
                                <label class="text-2xl font-bold">Ville</label>
                            </div>
                            <div>
                                <select name="" id="" v-model="province" class="block w-full px-4 py-2 border rounded-lg uppercase border-gray-600 shadow-md shadow-gray-400">
                                    <option value="" disabled selected hidden></option>
                                    <option :value="items.id" class="uppercase"  v-for="items in sortedItems" :key="items.id">{{ items.nom}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="">
                            <button type="submit" ref="restlogin" @click="submitShop" class="px-4 py-2 w-full rounded-lg text-white font-bold text-2xl" style="background-color: #DEA20A;">Ajouter</button>
                            <p class="text-red-500 font-bold" v-if="error">{{ error }}</p>
                            <p class="text-green-500 font-bold" v-if="succes">{{ succes }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>

import {mapActions} from 'vuex'

import Province from '@/_services/province.services'

export default{
    name: 'newshop',

    data(){
        return{            
            province: '',  
            manager: '',
            nom: '',          
            itemProvince: [],
            fetchedData: [],
            error: '',
            succes:''
        }
    },

    methods: {
        ...mapActions(['fectDataManager']),

        fetchData() {
            
            this.fectDataManager()
                .then(response => {
                    this.fetchedData = response;
                    //console.log(this.fetchedData)
                })
                .catch(error => {
                    // Une erreur s'est produite lors de la récupération des données
                    console.log(error);
                });
            },


            submitShop : function(){
                this.$store.dispatch('submitShop',{
                    nom : this.nom,
                    manager : this.manager,
                    ville : this.province
                }).then((response) => {
                    
                    if(response.status_code === 200){
                        this.succes = 'Votre Boutique à été créer avec succes'
                        setTimeout(() =>{
                            this.$router.push('/shopVue')
                        },3000)
                    }else{
                        this.error = response.message
                    }
                    //
                }).catch((err) => {
                    this.error = err.message.split('.')
                })
                .finally(()=>{
                    this.nom = '';
                    this.manager = '';
                    this.province = '';
                })
            },

            restlogin(){
                    this.nom = '';
                    this.pays = '';
                    this.monnaie = '';
                    this.taux = '';
                    this.$refs.restlogin.reset()
                }
    },

    mounted(){

        const province = new Province();
            
        province.getAllPro()
            .then(prov => {
                this.itemProvince = prov.data;
            })
            .catch((error) => {
                ////.log(error);
            });
        },

        computed:{
            sortedItems(){
                return this.itemProvince.sort((a,b) => a.id - b.id);
            },

            sortedItemsManager(){
                return this.fetchedData.sort((a,b) => a.id - b.id);
            },
        },

        created(){
            //this.$store.dispatch('viewprovince');
            this.fetchData()

        }
}

</script>