import UserCompteService from '@/_services/usersComptes.services'



const state = {
    userCompte: [],
    erroruserCompte: null,
    dataManager: null,
};

const mutations = {
    SET_USERCOMPTE(state, userCompte){
        state.userCompte = userCompte
    },

    SET_ERROR(state, erroruserCompte){
        state.erroruserCompte = erroruserCompte;
    },

    //Récuperer les données du manager
    setDataManager(state, data){
        state.dataManager = data;
    },
};

const actions = {
    
    submitUserCompte: async ({commit}, userCompteData) => {
        const token = localStorage.getItem('authToken');
        try{
            const userCompte = new UserCompteService()
            const response = await userCompte.addUserComptey(userCompteData, token);
            //commit('SET_USERCOMPTE',[...state.userCompte, response]);
            //commit('SET_ERROR', null)

            return response;
        }

        catch(err) {
            //commit('SET_ERROR', err.message);
            throw err;
        }

    },

    viewprovince: async ({commit}) => {
        try{
            const userCompte = new UserCompteService()
            
            const token = localStorage.getItem('authToken');

            const managerVue = await userCompte.getManager(token);
            //commit('SET_USERCOMPTE', managerVue)
            return managerVue
        }catch(err) {
            console.error(err);
        }
    },

    getAgent: async ({commit}) => {
        try{
            const userCompte = new UserCompteService()
            
            const token = localStorage.getItem('authToken');

            const managerVue = await userCompte.getDataLAgent(token);
            //commit('SET_USERCOMPTE', managerVue)
            return managerVue
        }catch(err) {
            console.error(err);
        }
    },

    //get manager

    async fectDataManager({commit}){
        const token = localStorage.getItem('authToken');

        try{
            
            const getData = new UserCompteService();
            const response = await getData.getDataManager(token);
            
            
            commit('setDataManager', response);
            
            return response.data

        }catch(error){
            console.error(error);

        };

    },

    async fechDataLivrer({commit}){
        const token = localStorage.getItem('authToken');

        try{
            
            const getData = new UserCompteService();
            const response = await getData.getDataLivrer(token);
            
            
            commit('setDataManager', response);
            
            return response.data

        }catch(error){
            console.error(error);
            throw error;
        };

    }

};

const getters = {
    //userCompte: state => state.userCompte,
    //erroruserCompte: state => state.erroruserCompte,
}

export default{
    state,
    getters,
    mutations,
    actions
}

