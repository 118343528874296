<template>
    
    <div class=" w-full flex items-center justify-center h-screen">
        <div class=" bg-gray-200 w-5/6 md:w-3/5 h-2/3 mx-auto my-auto">
            <div class="flex flex-col md:flex-row h-full">
                <div class="w-full h-54 md:h-full  md:w-2/4" style="background: linear-gradient(to bottom, #DEA20A , #FBF7EE );">
                    <div class="w-full px-10 pt-2 md:pt-14">
                        <div class="flex items-center justify-center">
                            <img src="@/images/LOGO_GEN_PNG_BLANC.png" class="w-32 md:w-72" alt="">
                        </div>
                        <div class="flex items-center justify-center bg-white py-5 rounded-lg">
                            <p class="sm:text-2xl  text-2xl font-bold uppercase drop-shadow-md">Newsletter</p>
                        </div>
                    </div>
                </div>
                <div class="w-full h-full md:w-2/4 px-10 pt-16 md:pt-28 text-left" style="background-color: #FBF7EE;">
                    <form @submit.prevent="send" action="">
                        <div class="mb-5">
                            <div class="pl-2 mb-2">
                                <label for="phone" class="text-2xl font-bold">Nom</label>
                            </div>
                            <input required type="text" v-model="nom" id="" class="px-4 py-2 w-full border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                        </div>
                        <div class="mb-10">
                            <div class="pl-2 mb-2">
                                <label for="phone" class="text-2xl font-bold">Email</label>
                            </div>
                            <input type="text" v-model="email" required name="" id="" class="px-4 py-2 w-full border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                        </div>
                        <div class="mb-10">
                            <button type="submit" class="px-4 py-2 w-full rounded-lg text-white font-bold text-2xl" style="background-color: #DEA20A;">Inscription</button>
                        </div>
                            <p class="text-red-500" v-show="erreur">{{ erreur }}</p>
                    </form>
                </div>
            </div>
        </div>
    </div>
        
   
</template>

<script>
import Axios from '@/_services/caller.services'

export default{
    name: 'newsletter',

    data(){
        return{
            nom: '',
            email: '',
            erreur: '',
        }
    },

    methods: {

        async send(){
            
            

            try{
                const reponse = await Axios.post(
                    'api/abonnement',
                    {
                        'nom': this.nom,
                        'email': this.email
                    }
                );
                this.$router.push('/')
                //.log(reponse);
            }catch(e){
                this.erreur = e.response.data.message;
            };

            this.nom = '';
            this.email = '';
        }

    }
}

</script>