<template>
    
    <div class="p-0 m-0">
        <div class="w-screen h-screen overflow-x-hidden p-0 m-0">
            <Header></Header>

            <div class="w-screen p-0 m-0">
                <div class="w-full  px-0 md:px-14  flex flex-col md:flex-row">
                    <!-- Content catégories -->
                    <div class="w-full md:w-60">
                        <ListCategory></ListCategory>
                        
                    </div>
                    <!-- Content catégories -->

                    <!-- Contenu article -->
                    <div class=" px-10 w-full grid  grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4  py-2 overflow-x-hidden mb-14">
                        <ContentArt></ContentArt>
                    </div>
                       
                    <!-- Contenu article -->

                </div>
            </div>
            <Footer></Footer>
        </div>
    </div>

</template>

<script>

import Header from '@/components/layout/Header.vue'
import Footer from '@/components/layout/Footer.vue'
import ContentArt from '@/components/ContentArt.vue'
import ListCategory from '@/components/ListeCat.vue'
import Province from '@/_services/province.services'

    export default{
        name: 'HomeView',
        
        components: {
            Header,
            Footer,
            ContentArt,
            ListCategory
        },

    }

</script>