import Axios from './caller.services'


export default class AdminGestionService{

    /* Debut action administrator et gestionnaire */


   
    //Stock générale

    async getStockGene(token){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Access-Control-Allow-Origin':'*',
            'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,PATCH,OPTIONS',
            
            'Access-Control-Allow-Headers': 'Content-Type, Origin, X-Auth-Token, Authorization'
        }

        try{
            const reponse = await Axios.get(
                '/api/stock',
                {headers, }
                );
            return reponse.data;
        } catch(error) {
            console.log(error)
            throw error;
        };

    };

    //Cette route affiche les commandes traitées par la boutique de la boutique
    async getCommandShop(token, id){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Access-Control-Allow-Origin':'*',
            'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,PATCH,OPTIONS',
            
            'Access-Control-Allow-Headers': 'Content-Type, Origin, X-Auth-Token, Authorization'
        }

        try{
            const reponse = await Axios.get(
                `/api/boutique/commandes/${id}`,
                {headers, }
                );
            
            return reponse.data;
        } catch(error) {
            console.error(error);
            throw error;
        };
    };

    

    /* Fin partie administrator et gestion */

    /* Debut actions uniquement admin */
    

    //get demande ravitaillement
    async getDemRavi(token){

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Access-Control-Allow-Origin':'*',
            'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,PATCH,OPTIONS',
            
            'Access-Control-Allow-Headers': 'Content-Type, Origin, X-Auth-Token, Authorization'
        }


        try{
            const reponse = await Axios.get(
                '/api/backoffice/demande',
                {headers}
            );

            return reponse.data;
        }catch(error) {
            throw error;
        };
        
    }

    //get commande all
    async getComAll(token){

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Access-Control-Allow-Origin':'*',
            'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,PATCH,OPTIONS',
            
            'Access-Control-Allow-Headers': 'Content-Type, Origin, X-Auth-Token, Authorization'
        }


        try{
            const reponse = await Axios.get(
                '/api/commande',
                {headers}
            );

            return reponse.data;
        }catch(error) {
            throw error;
        };
        
    }

    /* Fin actions uniquement admin */


}