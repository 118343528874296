<template>
    <div class="overflow-x-hidden">
        <div class="h-68 w-full pt-8">
            <div class="flex mb-3">
                <div class="mr-3">
                    <p class="text-xl font-bold">Dashboard</p>
                </div>
                <div>
                    <p class="text-xl"></p>
                </div>
            </div>
            <div class="w-full mb-3 overflow-x-hidden" v-if="roles === 'admin'">
                <div class="w-full h-10 flex overflow-x-hidden">
                    <div class="mr-4 w-1/3">
                        <form action="">
                            <button class="border w-full border-black text-white font-bold py-1.5 px-5 rounded-lg" style="background-color: #072A62;">Général</button>
                        </form>
                    </div>
                    <div class="mr-4 w-1/3">
                        <select name="" id="" v-model="filters.ville" @change="handleChangeV" class="block w-full px-4 py-1.5 border uppercase rounded-lg border-gray-600 ">
                            <option class="uppercase" :value="ville.id" v-for="(ville, index) in getVille" :key="index">{{ ville.nom }}</option>
                        </select>
                    </div>
                    <div class="w-1/3 mr-4 ">
                        <select name="" id="" v-model="filters.boutique" @change="handleChangeB" class="block w-full px-4 py-1.5 border uppercase rounded-lg border-gray-600 ">
                            <option class="uppercase" :value="shop.id" v-for="(shop, index) in getBoutique" :key="index">{{ shop.nom }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="w-full mb-3 overflow-x-hidden">
                <div class="w-full h-10 flex overflow-x-hidden">
                    <div class="mr-4 w-1/3" v-if="roles !== 'admin'">
                        <form action="">
                            <button class="border w-full border-black text-white font-bold py-1.5 px-5 rounded-lg" style="background-color: #072A62;">Général</button>
                        </form>
                    </div>
                    <div class="mr-4 w-1/3">
                        <div class="flex">
                            <div class="w-1/4">
                                <label for="" class="font-bold">Du</label>
                            </div>
                            <input type="date" name="" id="" v-model="filters.debut" class="text-black h-8  py-4 px-5 rounded-lg border border-black bg-white">
                        </div>
                    </div>
                    <div class="mr-4 w-1/3">
                        <div class="flex">
                            <div class="w-1/4">
                                <label for="" class="font-bold">Au</label>
                            </div>
                            <input type="date" name="" v-model="filters.fin" id="" @change="sendDataFiltre" class="text-black h-8  py-4 px-5 rounded-lg border border-black bg-white">
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full ">
                <div class="flex w-full flex-wrap mb-7 h-40 sm:h-30 md:h-26 lg:h-20 ">
                    <div class="mr-2 my-2">
                        <button class="border border-black text-black  py-1 px-1 w-max rounded-lg bg-white" :disabled="isButtonClicked" @click="sendDataFiltre(date='aujourd\'hui')">Aujourd'hui</button>
                    </div>
                    <div class="mr-2 my-2">
                        <button class="border border-black text-black  py-1 px-1 w-max rounded-lg bg-white" :disabled="isButtonClicked" @click="sendDataFiltre(date='hier')">Hier</button>
                    </div>
                    <div class="mr-2 my-2">
                        <button class="border border-black text-black  py-1 px-1 w-max rounded-lg bg-white" :disabled="isButtonClicked" @click="sendDataFiltre(date='semaine')">Cette semaine</button>
                    </div>
                    <div class="mr-2 my-2">
                        <button class="border border-black text-black  py-1 px-1 w-max rounded-lg bg-white" :disabled="isButtonClicked" @click="sendDataFiltre(date='mois')">Ce Mois</button>
                    </div>
                    <div class="mr-2 my-2">
                        <button class="border border-black text-black  py-1 px-1 w-max rounded-lg bg-white" :disabled="isButtonClicked" @click="sendDataFiltre(date='dernier-mois')">Mois Dernier</button>
                    </div>
                    <div class="mr-2 my-2">
                        <button class="border border-black text-black  py-1 px-1 w-max rounded-lg bg-white" :disabled="isButtonClicked" @click="sendDataFiltre(date='année')">Cette Année</button>
                    </div>
                    <div class="mr-2 my-2">
                        <button class="border border-black text-black  py-1 px-1 w-max rounded-lg bg-white" :disabled="isButtonClicked" @click="sendDataFiltre(date='dernière-année')">Année Dernière</button>
                    </div>
                    
                </div>
            </div>
            
        </div>        
    </div>
    <div class=" w-full  pb-24">
        <!--Admin-->
        <div class=" w-full">
            <div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4  mb-10">
                <div class="h-44  shadow-lg pt-0" style="background: linear-gradient(to left top,#DEA20A , #FBF7EE );">
                    <div class="w-full h-24 overflow-hidden px-3 text-ellipsis leading-none">
                        <p class="text-4xl py-7 h-full font-bold text-right text-white line-clamp-1">{{ getDataDash['commandes'] }}</p>
                    </div>
                    <div class="w-full h-20 overflow-hidden px-3 text-ellipsis leading-none">
                        <p class="text-xl font-bold py-4 h-full text-right text-black line-clamp-1">Commandes</p>
                    </div>
                </div>
                <div v-if="roles !== 'livreur' && roles !== 'manager' && roles !== 'gestionnaire'" class="h-44  shadow-lg pt-0" style="background: linear-gradient(to left top,#DEA20A , #FBF7EE );">
                    <div class="w-full h-24 overflow-hidden px-3 text-ellipsis leading-none">
                        <p class="text-4xl py-7 h-full font-bold text-right text-white line-clamp-1">{{ getDataDash['en cours'] }}</p>
                    </div>
                    <div class="w-full h-20 overflow-hidden px-3 text-ellipsis leading-none">
                        <p class="text-xl font-bold py-4 h-full text-right text-black line-clamp-1">Commandes en cours...</p>
                    </div>
                </div>
                <div v-if="roles === 'gestionnaire'" class="h-44  shadow-lg pt-0" style="background: linear-gradient(to left top,#DEA20A , #FBF7EE );">
                    <div class="w-full h-24 overflow-hidden px-3 text-ellipsis leading-none">
                        <p class="text-4xl py-7 h-full font-bold text-right text-white line-clamp-1">{{ getDataDash['en vérification'] }}</p>
                    </div>
                    <div class="w-full h-20 overflow-hidden px-3 text-ellipsis leading-none">
                        <p class="text-xl font-bold py-4 h-full text-right text-black line-clamp-1">En vérification</p>
                    </div>
                </div>
                <div class="h-44  shadow-lg bg-gradient-to-l from-blue-200 to-blue-400" v-if=" roles === 'admin'" >
                    <div class="w-full h-24 overflow-hidden px-3 text-ellipsis leading-none">
                        <p class="text-4xl py-7 h-full font-bold text-right text-white line-clamp-1">{{ getDataDash['entrant'] }}</p>
                    </div>
                    <div class="w-full h-20 overflow-hidden px-3 text-ellipsis leading-none">
                        <p class="text-xl font-bold py-4 h-full text-right text-black line-clamp-2">Commandes entrant</p>
                    </div>
                </div>

                <div class="h-44  shadow-lg bg-gradient-to-l from-green-200 to-green-400" v-if=" roles === 'admin' || roles === 'gestionnaire'">
                    <div class="w-full h-24 overflow-hidden px-3 text-ellipsis leading-none">
                        <p class="text-4xl py-7 h-full font-bold text-right text-white line-clamp-1">{{ getDataDash['en livraison'] }}</p>
                    </div>
                    <div class="w-full h-20 overflow-hidden px-3 text-ellipsis leading-none">
                        <p class="text-xl font-bold py-4 h-full text-right text-black line-clamp-2">Commandes Comfirmées</p>
                    </div>
                </div>

                <div v-if="roles !== 'manager'" class="h-44  shadow-lg bg-gradient-to-l from-gray-500 to-gray-700">
                    <div class="w-full h-24 overflow-hidden px-3 text-ellipsis leading-none">
                        <p class="text-4xl py-7 h-full font-bold text-right text-white line-clamp-1">{{ getDataDash['en livraison'] }}</p>
                    </div>
                    <div class="w-full h-20 overflow-hidden px-3 text-ellipsis leading-none">
                        <p class="text-xl font-bold py-4 h-full text-right text-black line-clamp-1">Commandes en Livraison</p>
                    </div>
                </div>
                <div class="h-44  shadow-lg bg-gradient-to-l from-gray-500 to-gray-700">
                    <div class="w-full h-24 overflow-hidden px-3 text-ellipsis leading-none">
                        <p class="text-4xl py-7 h-full font-bold text-right text-white line-clamp-1">{{ getDataDash['livraisons'] }}</p>
                    </div>
                    <div class="w-full h-20 overflow-hidden px-3 text-ellipsis leading-none">
                        <p class="text-xl font-bold py-4 h-full text-right text-black line-clamp-1">Commandes Livrées</p>
                    </div>
                </div>
                <div class="h-44  shadow-lg bg-gradient-to-r from-lime-200 to-lime-400" v-if=" roles === 'admin' ">
                    <div class="w-full h-24 overflow-hidden px-3 text-ellipsis leading-none">
                        <p class="text-4xl py-7 h-full font-bold text-right text-white line-clamp-1">{{ getDataDash['retraits'] }}</p>
                    </div>
                    <div class="w-full h-20 overflow-hidden px-3 text-ellipsis leading-none">
                        <p class="text-xl font-bold py-4 h-full text-right text-black line-clamp-1">Commandes au siège</p>
                    </div>
                </div>
                <div v-if=" roles === 'admin' || roles === 'manager'" class="h-44  shadow-lg bg-gradient-to-r from-indigo-300 to-indigo-500">
                    <div  class="w-full h-24 overflow-hidden px-3 text-ellipsis leading-none">
                        <p class="text-4xl py-7 h-full font-bold text-right text-white line-clamp-1">{{ getDataDash['ventes'] }}</p>
                    </div>
                    <div class="w-full h-20 overflow-hidden px-3 text-ellipsis leading-none">
                        <p class="text-xl font-bold py-4 h-full text-right text-black line-clamp-1">Total ventes</p>
                    </div>
                </div>
                <div class="h-44  shadow-lg" v-if=" roles === 'admin' || roles === 'gestionnaire' || roles === 'agent' || roles === 'livreur'" style="background: linear-gradient(to left top, #E70C0C, #F48C8C );">
                    <div class="w-full h-24 overflow-hidden px-3 text-ellipsis leading-none">
                        <p class="text-4xl py-7 h-full font-bold text-right text-white line-clamp-1">{{ getDataDash['annulées'] }}</p>
                    </div>
                    <div class="w-full h-20 overflow-hidden px-3 text-ellipsis leading-none">
                        <p class="text-xl font-bold py-4 h-full text-right text-black line-clamp-1">Commandes Annulées</p>
                    </div>
                </div>


                <div v-if=" roles === 'admin' || roles === 'gestionnaire' || roles === 'manager'" class="h-44  shadow-lg bg-gradient-to-r from-orange-200 to-orange-400" >
                    <div class="w-full h-24 overflow-hidden px-3 text-ellipsis leading-none">
                        <p class="text-4xl py-7 h-full font-bold text-right text-white line-clamp-1">{{ getDataDash['achats'] }} $</p>
                    </div>
                    <div class="w-full h-20 overflow-hidden px-3 text-ellipsis leading-none">
                        <p class="text-xl font-bold py-4 h-full text-right text-black line-clamp-1">Coût D'achat</p>
                    </div>
                </div>
                <div v-if="roles === 'admin' || roles === 'gestionnaire' || roles === 'manager'" class="h-44  shadow-lg bg-gradient-to-r from-cyan-200 to-cyan-400">
                    <div class="w-full h-24 overflow-hidden px-3 text-ellipsis leading-none">
                        <p class="text-4xl py-7 h-full font-bold text-right text-white line-clamp-1">{{ getDataDash['CA'] }} $</p>
                    </div>
                    <div class="w-full h-20 overflow-hidden px-3 text-ellipsis leading-none">
                        <p class="text-xl font-bold py-4 h-full text-right text-black line-clamp-1">Chiffre d'affaire</p>
                    </div>
                </div>
                <div v-if=" roles === 'admin' || roles === 'gestionnaire' || roles === 'manager'" class="h-44  shadow-lg bg-gradient-to-r from-teal-200 to-teal-400">
                    <div class="w-full h-24 overflow-hidden px-3 text-ellipsis leading-none">
                        <p class="text-4xl py-7 h-full font-bold text-right text-white line-clamp-1">{{ getDataDash['profit'] }} $</p>
                    </div>
                    <div class="w-full h-20 overflow-hidden px-3 text-ellipsis leading-none">
                        <p class="text-xl font-bold py-4 h-full text-right text-black line-clamp-1">Profit</p>
                    </div>
                </div>
                <div v-if=" roles === 'admin' || roles === 'manager'" class="h-44  shadow-lg bg-gradient-to-r from-teal-200 to-teal-400">
                    <div class="w-full h-24 overflow-hidden px-3 text-ellipsis leading-none">
                        <p class="text-4xl py-7 h-full font-bold text-right text-white line-clamp-1">{{ getDataDash['retraits'] }} $</p>
                    </div>
                    <div class="w-full h-20 overflow-hidden px-3 text-ellipsis leading-none">
                        <p class="text-xl font-bold py-4 h-full text-right text-black line-clamp-1">Retraits</p>
                    </div>
                </div>
                <div v-if=" roles === 'admin'" class="h-44  shadow-lg bg-gradient-to-r from-rose-200 to-rose-400" >
                    <div class="w-full h-24 overflow-hidden px-3 text-ellipsis leading-none">
                        <p class="text-4xl py-7 h-full font-bold text-right text-white line-clamp-1">{{ getDataDash['manques'] }} $</p>
                    </div>
                    <div class="w-full h-20 overflow-hidden px-3 text-ellipsis leading-none">
                        <p class="text-xl font-bold py-4 h-full text-right text-black line-clamp-1">Manque </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
import {mapActions} from 'vuex'
import Ville from '@/_services/province.services'
import Shop from '@/_services/shop.services'
import Axios from '@/_services/caller.services'


export default{

    name: "ResultFilre",

    data(){
        return{
            roles : null,
            getDataDash: [],
            getVille: [],
            getBoutique: [],
            selectDate: null,
            token: null,
            isButtonClicked: false,
            filters: {
                ville: '',
                boutique: '',
                debut: '',
                fin: '',
            }
        }
    },

    methods: {
        //manager
        ...mapActions(['GetDashboard']),
        getDash(){
            this.GetDashboard()
            .then(response => {
                        this.getDataDash = response['data'];
                        ////.log(this.getDataDash);
                    })
                    .catch(error => {
                        // Une erreur s'est produite lors de la récupération des données
                        //.error(error);
                    });
        },

        //gestionnaire
        ...mapActions(['GetDashGestion']),
        gestionDash(){
            this.GetDashGestion()
            .then(response => {
                        this.getDataDash = response['data'];
                        //.log(this.getDataDash);
                    })
                    .catch(error => {
                        // Une erreur s'est produite lors de la récupération des données
                        //.log(error);
                    });
        },

        //Admin gestion
        ...mapActions(['GetDashAdGe']),
        GetDashAd(){
            if(!this.selectDate){
                this.GetDashAdGe()
                .then(response =>{
                    this.getDataDash = response['data'];
                    ////.log(this.getDataDash);
                })
                .catch(error =>{
                    //('Erreur', error);
                });
            }
        },

        //Agent get dashboard
        ...mapActions(['GetDashAgent']),
        GetDashAgnt(){
            this.GetDashAgent()
            .then(reponse =>{
                this.getDataDash = reponse.data
            })
            .catch(error =>{
                //.log(error)
            })

        },

        //Livreur get Dashboard
        ...mapActions(['GetDashLivreur']),
        GetDashLiv(){
            this.GetDashLivreur()
            .then(reponse =>{
                this.getDataDash = reponse.data
                ////.log(this.getDataDash);
            })
            .catch(error =>{
                //.log(error)
            })

        },

        //Filtre
        handleButtonClick(date) {
            this.isButtonClicked = true;
            this.sendDataFiltre(date);
        },

        handleDateChange() {
            this.isButtonClicked = false;
            this.sendDataFiltre(this.selectDate);
        },

        handleChangeV(){
            this.sendDataFiltre(null)
        },

        handleChangeB(){
            this.sendDataFiltre(null)
        },

        async sendDataFiltre(date_){
            const headers = {
                'Authorization': `Bearer ${this.token}`,
            }

            

                if(this.filters.debut > this.filters.fin){
                    alert("La date debut ne peut pas être supérieur à la date fin")
                    this.filters={
                        debut:'',
                        fin:'',
                    }
                    return
                }
            
            
            if(this.roles === 'admin'){
                const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
                if(dateFormatRegex.test(date_)){
                    this.filters.date = date_
                    
                    try{
                        const response = await Axios.get(
                            `/api/dashboard/kpi`,
                            { 
                                params: this.filters,
                                headers
                            }
                        )
                        
                        this.getDataDash = response.data.data
                        
                        
                    }catch(e) {
                        //.log(e)
                    }finally {
                        this.selectDate =""
                        
                    }

                }else{
                    
                    try{
                        const response = await Axios.get(
                            `/api/dashboard/kpi/${date_}`,
                            { 
                                params: this.filters,
                                headers
                            }
                        )
                        
                        this.getDataDash = response.data.data
                        
                    }catch(e) {
                        //.log(e)
                    }finally {
                        this.selectDate =""
                        
                    }
                    
                } 
            }else if(this.roles === 'manager'){
                const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/;
                
                
                if(this.filters.debut){
                    //this.filters.date = date_

                    //.log('filtre 1', this.filters)

                    try{
                        const response = await Axios.get(
                            '/api/dashboard/kpi-manager',
                            {
                                params: this.filters,
                                headers                                
                            }
                        )
                        //.log(response)
                        this.getDataDash = response.data.data
                        ////.log('vue 1',this.getDataDash)
                        
                        
                    }catch(e) {

                        //.log(e)

                    }finally {
                        this.selectDate ="",
                        this.filters={
                            debut:'',
                            fin:'',
                        }
                    }

                }else{
                    
                    //.log('filtre 2', this.filters)
                    

                    try{
                        const response = await Axios.get(
                            `/api/dashboard/kpi-manager/${date_}`,
                            {
                                headers,
                                
                            }
                        )
                        //.log(response)
                        this.getDataDash = response.data.data
                        ////.log('vue 2',this.getDataDash)
                        
                    }catch(e) {
                        //.log(e)
                    }finally {
                        this.selectDate ="",
                        this.filters={
                            debut:'',
                            fin:'',
                        }
                    }
                }
            }else if(this.roles === 'agent'){
                
                if(this.filters.debut){
                    //this.filters.date = date_

                    ////.log(this.filters)
                    try{
                        const response = await Axios.get(
                            '/api/dashboard/agent',
                            {
                                params: this.filters,
                                headers                                
                            }
                        )
                        //.log(response)
                        this.getDataDash = response.data.data
                        ////.log('vue 1',this.getDataDash)
                        
                        
                    }catch(e) {

                        //.log(e)

                    }finally {
                        this.selectDate ="",
                        this.filters={
                            debut:'',
                            fin:'',
                        }
                    }

                }else{
                    try{
                        const response = await Axios.get(
                            `/api/dashboard/agent/${date_}`,
                            {
                                headers,
                                
                            }
                        )
                        //.log(response)
                        this.getDataDash = response.data.data
                        ////.log('vue 2',this.getDataDash)
                        
                    }catch(e) {
                        //.log(e)
                    }finally {
                        this.selectDate ="",
                        this.filters={
                            debut:'',
                            fin:'',
                        }
                    }
                }
            }else if(this.roles === 'livreur'){
                
                if(this.filters.debut){
                    //this.filters.date = date_

                    ////.log(this.filters)
                    try{
                        const response = await Axios.get(
                            '/api/dashboard/livreur',
                            {
                                params: this.filters,
                                headers                                
                            }
                        )
                        //.log(response)
                        this.getDataDash = response.data.data
                        ////.log('vue 1',this.getDataDash)
                        
                        
                    }catch(e) {

                        //.log(e)

                    }finally {
                        this.selectDate ="",
                        this.filters={
                            debut:'',
                            fin:'',
                        }
                    }

                }else{
                    try{
                        const response = await Axios.get(
                            `/api/dashboard/livreur/${date_}`,
                            {
                                headers,
                                
                            }
                        )
                        //.log(response)
                        this.getDataDash = response.data.data
                        ////.log('vue 2',this.getDataDash)
                        
                    }catch(e) {
                        //.log(e)
                    }finally {
                        this.selectDate ="",
                        this.filters={
                            debut:'',
                            fin:'',
                        }
                    }
                }
            }else if(this.roles === 'gestionnaire'){
                
                if(this.filters.debut){
                    try{
                        const response = await Axios.get(
                            '/api/dashboard/gestionnaire',
                            {
                                params: this.filters,
                                headers                                
                            }
                        )
                        //.log(response)
                        this.getDataDash = response.data.data
                        //.log(this.getDataDash)
                        
                        
                    }catch(e) {

                        //.log(e)

                    }finally {
                        this.selectDate ="",
                        this.filters={
                            debut:'',
                            fin:'',
                        }
                    }
                }else{
                    try{
                        const response = await Axios.get(
                            `/api/dashboard/gestionnaire/${date_}`,
                            {
                                headers                                
                            }
                        )
                        //.log(response)
                        this.getDataDash = response.data.data
                        
                        
                    }catch(e) {

                        //.log(e)

                    }finally {
                        this.selectDate ="",
                        this.filters={
                            debut:'',
                            fin:'',
                        }
                    }
                }

            }
            
        }
    },

    created(){
        this.roles = localStorage.getItem("role");
        this.token = localStorage.getItem('authToken');
        if(this.roles === 'admin'){
            this.GetDashAd()
        }else if(this.roles === 'manager'){
            this.getDash()
        }
        else if(this.roles === 'agent'){
            this.GetDashAgnt()
        }
        else if(this.roles === 'livreur'){
            this.GetDashLiv()
        }else if(this.roles === 'gestionnaire'){
            this.gestionDash()
        }
    },

    mounted(){
        const province = new Ville();            
            province.getAllPro()
                .then(prov => {
                    this.getVille = prov.data;
                })

        const shop = new Shop();
            shop.getAllShop()
                .then(shop => {
                    this.getBoutique = shop.data;
                    ////.log(this.itemShop)
                })
    }

}

</script>