import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import DetailProduit from '@/views/DetailProduit.vue'
import Panier from '@/views/Panier.vue'
import Notification from '@/views/Notification.vue'
import listecommande from '@/views/ListOrder.vue'
import Dashboard from '@/views/ViewsDashbord.vue'
import Authentification from '@/views/Authentification.vue'
import NewShop from '@/views/News/NewShop.vue'
import NewCategorie from '@/views/News/NewCate.vue'
import NewMoney from '@/views/News/NewMoney.vue'
import NewProvince from '@/views/News/NewProvince.vue'
import configuration from '@/views/ConfigurationCompte.vue'
import ModificationCat from '@/views/Modification/ModifCat.vue'
import ModifiMoney from '@/views/Modification/ModifMoney.vue'
import ModifProvince from '@/views/Modification/ModifProvince.vue'
import ModifShop from '@/views/Modification/ModifShop.vue'
import NewsLetter from '@/views/InscriptionNewsLetter.vue'
import Diffusion from '@/views/Diffusion.vue'
import DemandeRavi from '@/views/DemandeRavi.vue'
import GiveToCall from '@/views/GiveToCallCenter.vue'
import VerifTaux from '@/views/VerificationTaux.vue'
import Save from '@/views/Enregistrement.vue'
import Ravitaillement from '@/views/Ravitaillement.vue'
import RavitaillementId from '@/views/RavitaillementId.vue'
import HistoryRavi from '@/views/HistoryRavi.vue'
import StockShop from '@/views/StockBoutique.vue'
import StockGeneral from '@/views/StockGeneral.vue'
import ListUsers from '@/views/ListUsers.vue'
import Listlivrer from '@/views/ListLivreur.vue'
import Newtaux from '@/views/News/NewTaux.vue'
import NewCommand from '@/views/News/NewCommand.vue'
import ModifiCommand from '@/views/Modification/ModifCommande.vue'
import NewCommandAnci from '@/views/News/NewCommandAnci.vue'
import ConfirationClient from '@/views/InscriptionClient.vue'
import Category from '@/views/CategorieVue.vue'
import Province from '@/views/Province.vue'
import Money from '@/views/MoneyVue.vue'
import ShopVue from '@/views/ShopVue.vue'
import ProduitV from '@/views/ProduitVue.vue'
import NewProduct from '@/views/News/NewProduct.vue'
import ListRaviBoutique from '@/views/ListRaviBoutique.vue'
import NotFound from '@/views/NotFound.vue'
import ListCatVue from '@/views/ListCatVue.vue'
import Ventes from '@/views/Ventes.vue'

const routes = [
  //route admin
  {
    path: '/Ventes',
    name: 'Ventes',
    component: Ventes
  },

  {
    path: '/category',
    name: 'category',
    component: Category
  },
  {
    path: '/province',
    name: 'province',
    component: Province
  },
  {
    path: '/newproduct',
    name: 'newproduct',
    component: NewProduct
  },
  {
    path: '/produit',
    name: 'produit',
    component: ProduitV
  },
  {
    path: '/money',
    name: 'money',
    component: Money
  },
  {
    path: '/ListRaviBoutique',
    name: 'ListRaviBoutique',
    component: ListRaviBoutique
  },
  {
    path: '/shopVue',
    name: 'shopVue',
    component: ShopVue
  },

  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/detailProduit/::encryptedId',
    name: 'detailProduit',
    component: DetailProduit
  },
  {
    path: '/ListCatVue/::encryptedId',
    name: 'ListCategoryVue',
    component: ListCatVue
  },
  {
    path: '/panier',
    name: 'panier',
    component: Panier
  },
  {
    path: '/notification',
    name: 'notification',
    component: Notification
  },
  {
    path: '/listecommande',
    name: 'listecommande',
    component: listecommande
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard
  },
  {
    path : '/authentification',
    name: 'authentification',
    component: Authentification
  },
  {
    path: '/newshop',
    name: 'newshop',
    component: NewShop
  },
  {
    path: '/newcategorie',
    name: 'newcategorie',
    component: NewCategorie
  },
  {
    path: '/newmoney',
    name: 'newmoney',
    component: NewMoney
  },
  {
    path: '/newprovince',
    name: 'newprovince',
    component: NewProvince
  },
  {
    path: '/configuration',
    name: 'configuration',
    component: configuration
  },
  {
    path: '/modificationtegorie',
    name: 'modificationtegorie',
    component: ModificationCat
  },
  {
    path: '/modifimoney',
    name: 'modifimoney',
    component: ModifiMoney
  },
  {
    path: '/modifprovince',
    name: 'modifprovince',
    component: ModifProvince
  },
  {
    path: '/modifshop',
    name: 'modifshop',
    component: ModifShop
  },
  {
    path: '/newsletter',
    name: 'newsletter',
    component: NewsLetter
  },
  {
    path: '/diffusion',
    name: 'diffusion',
    component: Diffusion
  },
  {
    path: '/demanderavi',
    name: 'demanderavi',
    component: DemandeRavi
  },
  {
    path: '/giveToCallCenter',
    name: 'giveToCallCenter',
    component: GiveToCall
  },
  {
    path: '/verifactiontaux',
    name: 'verifactiontaux',
    component : VerifTaux
  },
  {
    path: '/enregistrement',
    name: 'enregistrement',
    component: Save
  },
  {
    path: '/ravitaillement',
    name: 'ravitaillement',
    component: Ravitaillement
  },  
  {
    path: '/ravitaillementId/:encryptedId',
    name: 'ravitaillementId',
    component: RavitaillementId
  },
  {
    path: '/404',
    name: 'NotFound',
    component: NotFound
  },
  {
    path: '/hitoryRavi',
    name: 'hitoryRavi',
    component: HistoryRavi
  },
  {
    path: '/stockShop',
    name: 'stockShop',
    component: StockShop
  },
  {
    path: '/stockGeneral',
    name: 'stockGeneral',
    component: StockGeneral
  },
  {
    path: '/listusers',
    name: 'listusers',
    component: ListUsers
  },
  {
    path: '/listlivrer',
    name: 'listlivrer',
    component: Listlivrer
  },
  {
    path: '/newtaux',
    name: 'newtaux',
    component: Newtaux
  },
  {
    path: '/newcommande',
    name: 'newcommande',
    component: NewCommand
  },
  {
    path: '/modificommande',
    name: 'modificommande',
    component: ModifiCommand
  },
  {
    path: '/newcommandancient',
    name: 'newcommandancient',
    component: NewCommandAnci
  },
  {
    path: '/inscriptionclient',
    name: 'inscriptionclient',
    component: ConfirationClient
  }, // Autres routes...
  {
    path: '/:catchAll(.*)',
    redirect: '/404'
  }

  
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
