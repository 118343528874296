<template>
    <div class="h-96 border-r w-full ">
        <div class="flex justify-center items-center mb-2">
            <img :src="getPhotoUrl(mainImage)" class="h-64 object-center w-64 rounded-full" alt="" srcset="">
        </div>
        <div class="flex justify-start border-t pt-2 cursor-pointer">
            <div class="w-full  mx-2" v-for="(photo, photoIndex) in images" :key="photoIndex">
                <img :src="getPhotoUrl(photo.chemin)" class="h-28 w-28" alt="" srcset="">
            </div>
        </div>
    </div>
</template>
<script>
import ProductService from '@/_services/produit.services'
import CryptoJS from 'crypto-js'

    export default{
        name: 'ViewPhoto',

        data() {
            return {
                mainImage: '',
                images: [],
                idPro:'',
                
            };
        },
        methods: {
            getImagePath(image) {
                return require(`${image}`);
            },

            changeImage(imageSrc) {
                this.mainImage = imageSrc;
            },

            getPhotoUrl(url){
                let params = 'https://api.genesisdream.store/public/storage/' + url;
                //let params = 'http://127.0.0.1:8000/storage/' + url
                return params
            },

            //decryptage url
            decryptId(encryptedId) {
                const decryptedText = CryptoJS.AES.decrypt(decodeURIComponent(encryptedId), 'myEncryptionKey').toString(CryptoJS.enc.Utf8);
                const [id, idShop] = decryptedText.split('-');
                return {
                    id: parseInt(id, 10),
                    idShop: parseInt(idShop, 10)
                };
            },
        },

        created() {
            const encryptedId = this.$route.params.encryptedId;
            const { id, idShop } = this.decryptId(encryptedId);

            this.idPro = id;
        },

        mounted(){
            const getProduct = new ProductService()
            
            getProduct.getProduitById(this.idPro)
                .then(res =>{
                    this.mainImage = res.data['0']['images']['0']['chemin']
                    this.images = res.data['0']['images']
                })
                .catch(err =>{
                    //('Erreur', err)
                });
        },

    }

</script>