import Axios from './caller.services'


export default class LivreurServices{

    //Récupération de la site de livreur

    async getAllLivre(token){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }

        try {
            const response = await Axios.get(
                '/api/livreur',
                {headers}
                );
            return response.data;
        } catch (error) {
            //.error(error);
            throw error;
        }
    }

    // Cette route affiche le stock des produits en possession chez le livreur
    async getStokLivre(token,id) {

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }

        try {
            const response = await Axios.get(
                `/api/livreur/${id.id}/stock`,
                {headers}
                );
            return response;
        } catch (error) {
            throw error;
        }
    };

    // affiche la liste de commande chez le livreur

    async getCommandeLivreur(token){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }

        try{
            const reponse = await Axios.get(
                '/api/livreur/commandes',
                {headers}
                );
            
            return reponse.data;
        } catch(error) {
            throw error;
        };
    };

    //recupere le données du dashoard livreur
    async dashbordLivreur(token){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Access-Control-Allow-Origin':'*',
            'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,PATCH,OPTIONS',
            
            'Access-Control-Allow-Headers': "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'"
        }
        try{
            const reponse = await Axios.get(
                '/api/dashboard/livreur',
                {headers, }
                );
            return reponse.data;
        } catch(error) {
            throw error;
        };
    }


}