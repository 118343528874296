import MoneyService from '@/_services/money.services'

const state = {
    money: [],
    errorMoney: null
};

const mutations = {
    SET_MONEY(state, money){
        state.money = money
    },

    SET_ERROR(state, errorMoney){
        state.errorMoney = errorMoney;
    }
};

const actions = {
    
    submitMoney: async ({commit}, moneyData) => {
        const token = localStorage.getItem('authToken');

        try{
            const monnaie = new MoneyService()
            const response = await monnaie.addMoney(moneyData, token);
            commit('SET_MONEY',[...state.money, response]);
            commit('SET_ERROR', null)

            return response;
        }

        catch(err) {
            commit('SET_ERROR', err.message);
        }
    },

    async editMoney({commit}, data){

        const token = localStorage.getItem('authToken');

        try{

            const category = new MoneyService()
            const response = await category.editMoney(token, data)

            return response


        }catch(err) {
            throw err
        }

    },

    async dropMoney({commit}, id){

        const token = localStorage.getItem('authToken');
        try{

            const category = new MoneyService()
            const response = await category.dropMoney(token, id)

            return response


        }catch(err) {
            throw err
        }

    }

};

const getters = {
    money: state => state.money,
    errorMoney: state => state.errorMoney
}

export default{
    state,
    getters,
    mutations,
    actions
}

