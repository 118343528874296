<template>
    <div>
        <div class="flex items-center justify-center h-screen">
            <div class="bg-gray-200">
                <div class="flex h-full flex-col md:flex-row">
                    <div class="w-full md:w-2/4 md:order-first" style="background: linear-gradient(to bottom, #DEA20A , #FBF7EE );">
                        <div class="px-10 pt-14">
                            <div class="flex items-center justify-center">
                                <img src="@/images/LOGO_GEN_PNG_BLANC.png" class="w-72" alt="">
                            </div>
                            <div class="flex items-center justify-center bg-white py-10 rounded-lg">
                                <p class="text-3xl font-bold uppercase drop-shadow-md">Enregistrement</p>
                            </div>
                        </div>
                    </div>
                    <div class="w-full pt-10 md:w-2/4 px-4 md:px-10 pb-20 md:pb-0 md:pb-10 md:pt-10 text-left md:text-left md:mt-0" style="background-color: #FBF7EE;">
                        <form @submit.prevent="submitUserCompte" ref="restlogin">
                            <div v-if="roles !== 'manager'" class="mb-2">
                                <div class="pl-2 mb-1.5">
                                    <label for="password" class="text-xl font-bold">Type de compte</label>
                                </div>
                                <div>
                                    <select name="" id="" v-model="role" class="block w-full px-4 py-1.5 border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                                        <option value="" disabled selected hidden></option>
                                        <option value="admin">Administrateur</option>
                                        <option value="gestionnaire">Gestionnaire de stock</option>
                                        <option value="manager">Manager</option>
                                        <option value="agent">Agent</option>
                                        <option value="livreur">Livreur</option>
                                    </select>
                                </div>
                            </div>
                            <div class="mb-2">                            
                                <div class="pl-2 mb-1.5">
                                    <label for="phone" class="text-xl font-bold">Nom</label>
                                </div>
                                <input type="text" v-model="nom" name="" id="" class="px-4 py-1.5 w-full border rounded-lg border-gray-600 shadow-md shadow-gray-400" required>
                            </div>
                            <div class="mb-2">                            
                                <div class="pl-2 mb-1.5">
                                    <label for="phone"  class="text-xl font-bold">Prénom</label>
                                </div>
                                <input type="text" name="" v-model="prenom" id="" class="px-4 py-1.5 w-full border rounded-lg border-gray-600 shadow-md shadow-gray-400" required>
                            </div>
                            <div class="text-right mb-2 text-xl flex" style="color: #072A62">
                                <div class="mr-8">
                                    <div class="text-left mb-2">
                                        <label for="password" class="text-xl font-bold">Pays</label>
                                    </div>
                                    <div>
                                        <select id="pays" name="pays" v-model="countries" class="w-full border border-gray-500 px-3 py-1 rounded-xl"  value="{{old('pays')}}">
                                            <option value="243">République démocratique du Congo</option>
                                            <option value="93">Afghanistan</option>
                                            <option value="27">Afrique du Sud</option>
                                            <option value="355">Albanie</option>
                                            <option value="213">Algérie</option>
                                            <option value="49">Allemagne</option>
                                            <option value="371">Andorre</option>
                                            <option value="244">Angola</option>
                                            <option value="966">Arabie saoudite</option>
                                            <option value="54">Argentine</option>
                                            <option value="374">Arménie</option>
                                            <option value="297">Aruba</option>
                                            <option value="61">Australie</option>
                                            <option value="43"> Autriche</option>
                                            <option value="994">Azerbaïdjan</option>
                                            <option value="1">Bahamas</option>
                                            <option value="973">Bahreïn</option>
                                            <option value="880">Bangladesh</option>
                        
                                            <option value="501">Belize</option>
                
                                            <option value="32">Belgique</option>
                                            <option value="229">Bénin</option>
                                            <option value="1">Bermudes</option>
                                            <option value="975">Bhoutan</option>
                        
                                            <option value="374">Biélorussie</option>
                                            <option value="591">Bolivie</option>
                                            <option value="387">Bosnie-Herzégovine</option>
                                            <option value="55">Bresil</option>
                                            <option value="673"> Brunei</option>
                    
                                            <option value="267">Botswana</option>
                                            <option value="359">Bulgarie</option>
                                            <option value="226">Burkina Faso</option>
                                            <option value="257">Burundi</option>
                                            <option value="885">Cambodge</option>

                    
                                            <option value="237">Cameroun</option>
                                            <option value="1">Canada</option>
                                            <option value="238"> Cap-vert</option>
                                            <option value="56">Chili</option>
                                            <option value="86">Chine</option>
                                            <option value="357">Chypre</option>
                                            <option value="57">Colombie</option>
                                            <option value="269"> Comores</option>
                                            <option value="225">Côte d\'Ivoire</option>
                                            <option value="82">Corée du Sud</option>

                                            <option value="506">Costa Rica</option>
                                            <option value="385">Croatie</option>
                                            <option value="53">Cuba</option>
                    
                                            <option value="45"> Danemark</option>
                                            <option value="253">Djibouti</option>
                                            <option value="20">Égypte</option>
                                            <option value="971">Émirats arabes unis</option>
                                            <option value="34">Espagne</option>
                                            <option value="1">Etats Unis</option>
                        
                                            <option value="372">Estonie</option>
                                            <option value="251">Éthiopie</option>
                                            <option value="593">Équateur</option>
                                            <option value="679">Fidji</option>

                                            <option value="358">Finlande</option>
                                            <option value="33">France</option>
                                            <option value="241">Gabon</option>
                                            <option value="220">Gambie</option>
                                            <option value="995">Géorgie</option>
                        
                                            <option value="233">Ghana</option>
                                            <option value="350">Gibraltar</option>
                                            <option value="590">Guadeloupe</option>
                                            <option value="502">Guatemala</option>
                                            <option value="224">Guinée</option>
                                            <option value="245">Guinée-Bissau</option>
                                            <option value="240">Guinée équatoriale</option>
                                            <option value="592">Guyana</option>
                    
                                            <option value="594">Guyane française</option>
                                            <option value="30">Grèce</option>
                                            <option value="1">Grenade</option>
                        
                                            <option value="299">Groenland</option>
                                            <option value="509">Haiti</option>
                                            <option value="504">Honduras</option>

                                            <option value="36">Hongrie</option>
                                            <option value="262">Ile de La Réunion</option>
                        
                                            <option value="230">Ile Maurice</option>
                        
                                            <option value="91">Inde</option>
                                            <option value="62">Indonésie</option>
                                            <option value="964">Irak</option>
                                            <option value="98">Iran</option>
                                            <option value="353">Irlande</option>
                                            <option value="354">Islande</option>
                                            <option value="972">Israël</option>
                                            <option value="39">Italie</option>
                                            <option value="1">Jamaïque</option>
                                            <option value="81">Japon</option>

                                            <option value="44">Jersey</option>
                                            <option value="962">Jordanie</option>
                                            <option value="7">Kazakhstan</option>
                                            <option value="254">Kazakhstan</option>
                                            <option value="996">Kirghizistan</option>
                                            <option value="965">Koweït</option>
                                            <option value="856">Laos</option>
                    
                                            <option value="266">Lesotho</option>
                                            <option value="371">Lettonie</option>
                                            <option value="961">Liban</option>
                                            <option value="231">Liberia</option>
                                            <option value="218">Libye</option>
                                            <option value="423">Lechtenstein</option>
                                            <option value="370">Lituanie</option>
                                            <option value="352">Luxembourg</option>
                                            <option value="389">Macédoine</option>
                                            <option value="261">Madagascar</option>
                        
                                            <option value="60">Malaisie</option>
                                            <option value="265">Malawi</option>
                                            <option value="960">Maldives</option>
                                            <option value="223">Mali</option>
                                            <option value="356">Malte</option>
                                            <option value="212">Maroc</option>
                                            <option value="596">Martinique</option>
                        
                                            <option value="222">Mauritanie</option>
                                            <option value="52">Mexique</option>
                        
                                            <option value="373">Moldavie</option>
                                            <option value="377">Monaco</option>
                                            <option value="976">Mongolie</option>
                        
                                            <option value="382">Monténégro</option>
                                            <option value="258">Mozambique</option>
                                            <option value="264">Namibie</option>
                                            <option value="977">Nepal</option>
                                            <option value="505">Nicaragua</option>

                                            <option value="227">Niger</option>
                                            <option value="234">Nigeria</option>
                                            <option value="47">Norvège</option>
                                            <option value="64">Nouvelle-Zélande</option>
                                            <option value="968">Oman</option>
                                            <option value="256">Ouganda</option>
                                            <option value="998">Ouzbékistan</option>
                                            <option value="92">Pakistan</option>
                                            <option value="970">Palestine</option>
                                            <option value="507">Panama</option>
                                            <option value="595">Paraguay</option>
                        
                                            <option value="31">Pays-Bas</option>
                                            <option value="51">Pérou</option>
                                            <option value="51">Philippines</option>
                        
                                            <option value="48">Pologne</option>
                                            <option value="63">Polynésie française</option>
                                            <option value="1">Porto Rico</option>
                                            <option value="351">Portugal</option>
                                            <option value="974">Qatar</option>
                                            <option value="236">République centrafricaine</option>
                                            <option value="243">République démocratique du Congo</option>
                                            <option value="242">République du Congo</option>
                                            <option value="1">République Dominicaine</option>
                                            <option value="503">République du Salvador</option>
                                            <option value="420">République tchèque</option>
                                            <option value="40">Roumanie</option>
                                            <option value="44">Royaume-Uni</option>
                                            <option value="7">Russie</option>
                                            <option value="250">Rwanda</option>
                                            <option value="378">Saint-Marin</option>
                                            <option value="239">Sao Tomé-et-Principe</option>
                        
                                            <option value="221">Sénégal</option>
                                            <option value="381">Serbie</option>
                                            <option value="248">Seychelles</option>
                                            <option value="232">Sierra Leone</option>
                                            <option value="65">Singapour</option>
                    
                                            <option value="421">Slovaquie</option>
                                            <option value="386">Slovénie</option>
                                            <option value="252">Somalie</option>
                                            <option value="249">Soudan</option>
                                            <option value="46">Suède</option>
                                            <option value="41">Suisse</option>
                                            <option value="94">Sri Lanka</option>
                                            <option value="268">Swaziland</option>
                                            <option value="963">Syrie</option>
                        
                                            <option value="992">Tadjikistan</option>
                                            <option value="255">Tanzanie</option>
                                            <option value="235">Tchad</option>
                                            <option value="66">Thaïlande</option>
                                            <option value="886">Taïwan</option>
                        
                                            <option value="228">Togo</option>
                                            <option value="676">Tonga</option>
                                            
                                            <option value="216">Tunisie</option>
                                            <option value="993">Turkménistan</option>
                                            <option value="90">Turquie</option>
                                            <option value="1">Trinité-et-Tobago</option>
                                            
                                            <option value="380">Ukraine</option>
                                            <option value="598">Uruguay</option>
                                            <option value="58">Venezuela</option>
                                            <option value="84">Vietnam</option>
                                            
                                            <option value="967">Yémen</option>
                                            <option value="260">Zambie</option>
                                            <option value="263">Zimbabwe</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="">
                                    <div class="text-left mb-2">
                                        <label for="password" class="text-xl font-bold">Téléphone</label>
                                    </div>
                                    <input required type="text" v-model="telephone" name="" id="" placeholder="82*******" class="px-4 py-1.5 w-52 border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                                </div>
                            </div>
                            <div class="mb-8" v-if="role === 'livreur'">
                                <div class="pl-2 mb-1.5">
                                    <label for="password" class="text-xl font-bold">Boutique</label>
                                </div>
                                <div>
                                    <select name="" id="" v-model="boutique" class="block w-full px-4 py-1.5 border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                                        <option value="" disabled selected hidden></option>
                                        <option :value="shop.id" v-for="(shop, index) in itemShop" :key="index">{{ shop.nom }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="mb-8" v-if="role === 'manager'">
                                <div class="pl-2 mb-1.5">
                                    <label for="password" class="text-xl font-bold">Ville</label>
                                </div>
                                <div>
                                    <select name="" id="" v-model="province" class="block w-full px-4 py-1.5 border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                                        <option value="" disabled selected hidden></option>
                                        <option :value="items.id"  v-for="items in sortedItems" :key="items.id">{{ items.nom }}</option>
                                    </select>
                                </div>
                            </div>
                            <p class="text-red-500">{{ erreur }}</p>
                            <div class="mt-4">
                                <button type="submit" class="px-4 py-2 w-full rounded-lg text-white font-bold text-xl" style="background-color: #DEA20A;">Enregistrer</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>

import Province from '@/_services/province.services'
import Shop from '@/_services/shop.services'

export default{
    name: 'enregistrement',

    data(){
        return {
            role: '',
            nom : '',
            prenom : '',
            telephone: '',
            countries: '',
            password: '000000',
            itemProvince: [],
            province: '',
            boutique: '',
            itemShop: [],
            roles: null,
            erreur:null,
            IdUser: null,
        }
    },

    methods:{
        submitUserCompte : function(){
            if(!this.countries){
                this.erreur = "Veuillez selectionnez le pays"
                return this.erreur
            }


            if(this.roles === 'manager'){
                this.role = 'livreur'
                let id = this.itemShop.find(id => id.manager.user_id == this.IdUser)
                this.boutique = id.id
                this.province = id.ville.nom
            }

            
            if(!this.role){
                this.erreur = "Veuillez selectionnez le type de compte"
                return this.erreur
            }

            this.$store.dispatch('submitUserCompte',{
                role : this.role,
                nom : this.nom,
                prenom : this.prenom,
                telephone : '+'+ this.countries + this.telephone,
                password : this.password,                
                province: this.province,
                boutique: this.boutique,
            })
            .then((response) =>{
                if(response.data.status_code == 200){
                    if(this.roles !== 'manager'){
                        this.$router.push('/listusers')
                    }
                    else{
                        this.$router.push('/listlivrer')
                    }
                }else{
                    ////.log(response)
                }
                
            })
            .catch((error) => {
                if(error.response){
                    this.erreur = error.response.data.message.split('.')
                }else{
                    this.erreur = error.data.message.split('.')
                }
                
            })
            .finally(()=>{
                this.erreur = ''
            })
            this.restlogin();
        },
        restlogin(){
                this.nom = '';
                this.prenom = '';
                this.telephone = '';
                this.role = '';
                this.countries = '';
                this.province = '';
                this.boutique = '';
                this.$refs.restlogin.reset()
            }
    },
    mounted(){
            const province = new Province();
            const boutique = new Shop();
            
            province.getAllPro()
                .then(prov => {
                    this.itemProvince = prov.data;
                })
                .catch(err => {
                    ////.log(err.data.message);
                });
            
            boutique.getAllShop()
                .then(shops => {
                    this.itemShop = shops.data
                    //.log(this.itemShop)
                })
                .catch(err => {
                    ////.log(err.data.message);
                });
                
        },

        computed:{
            sortedItems(){
                return this.itemProvince.sort((a,b) => a.id - b.id);
            }
        },
        created(){
            this.roles = localStorage.getItem("role");
            this.IdUser = localStorage.getItem("IdUser");
            if(this.roles == null){
                this.$router.push('/authentification')
            }
        }
}

</script>