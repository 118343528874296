import Axios from './caller.services'

export default class ProvinceServices {
    
    //Récupérer la liste de categories
    async getAllPro(){
        try {
            const response = await Axios.get('/api/ville');
            return response.data;
        } catch (error) {
            //.error(error);
            throw error
        }
    };

    //Récupérer le produit par son ID
    async getProById(id){
        try {
            const response = await Axios.get(`/api/ville/nom/${id}`);
            return response.data;
        } catch (error) {
            //.error(error);
            throw error
        }
    };

    //Ajouter une nouvelle produit 
    async addPro(provinceData, token){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        try {
            const response = await Axios.post(
                '/api/backoffice/ville', 
                provinceData, 
                {headers});

            return response;
        } catch (error) {
            throw error;
        }
    };

    //modifier 
    async editVille(token, data){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }

        
        try {
            const response = await Axios.post(
                `/api/backoffice/ville/${data.id}`, 
                {
                  'nom':  data.nom,
                  'pays': data.pays,
                  'taux': data.taux,
                  'monnaie_id': data.monnaie
                }, 
                {headers});

            return response;
        } catch (error) {
            throw error
        }   
    };

     //supprimer 
     async dropVille(token, id){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        try {
            const response = await Axios.delete(
                `/api/backoffice/ville/${id.id}`,
                {headers});

            return response;
        } catch (error) {
            throw error
        }   
    }

}