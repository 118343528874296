import Axios from './caller.services'

export default class CommandeServices{

    //Commande interne que les utisateur de l'app utiliseront
    async addcommandIn(commandData, token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
        try {
            const response = await Axios.post(
                '/api/call-center/occasionnel/commande', 
                commandData,
                {headers}
                );
            return response.data;
        } catch (error) {
            throw error.response.data;
        }
    }


    async addcommandInCl(commandData) {
        
        try {
            const response = await Axios.post(
                '/api/occasionnel/commande', 
                commandData,
                );
            return response;
        } catch (error) {
            throw error;
        }
    };


    //annulation commande administrateur
    async annulCom(token, id){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Access-Control-Allow-Origin':'*',
            'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,PATCH,OPTIONS',
            
            'Access-Control-Allow-Headers': "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'"
        }

        try{
            const reponse = await Axios.get(
                `/api/backoffice/commande/annuler/${id.id}`,
                {headers, }
                );
            
            return reponse.data;
        } catch(error) {
            throw error;
        };
    };

    //Supprimer commande administrateur
    async supprimeCom(token, id){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Access-Control-Allow-Origin':'*',
            'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,PATCH,OPTIONS',
            
            'Access-Control-Allow-Headers': "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'"
        }

        try{
            const reponse = await Axios.delete(
                `/api/backoffice/commande/${id.id}`,
                {headers, }
                );
            
            return reponse.data;
        } catch(error) {
            throw error;
        };
    };

    //Supprimer commande administrateur
    async modifCom(data, token){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Access-Control-Allow-Origin':'*',
            'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,PATCH,OPTIONS',
            
            'Access-Control-Allow-Headers': "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'"
        }
        
        const id = data.get('id');
        data.delete('id');
        const newData = new FormData();

        for (let [key, value] of data.entries()) {
            if (key !== 'id') {
                newData.append(key, value);
            }
        }
   
        return data;
        try{
            const reponse = await Axios.post(
                `/api/backoffice/commande/${id}`,
                newData,
                {headers, }
                );
            
            return reponse.data;
        } catch(error) {
            throw error;
        };
    };

    async reportcommande(data, token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Access-Control-Allow-Origin':'*',
            'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,PATCH,OPTIONS',
            
            'Access-Control-Allow-Headers': "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'"
        }

        const statut = {
            'statut' : data.statut
        }

        try{
            const reponse = await Axios.post(
                `/api/commande/state/${data.id}`,
                statut,
                {headers}
                )
            
            return reponse;
        } catch(error) {
            throw error;
        };
    }

    async injoincommande(data, token) {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Access-Control-Allow-Origin':'*',
            'Access-Control-Allow-Methods': 'GET,POST,PUT,DELETE,PATCH,OPTIONS',
            
            'Access-Control-Allow-Headers': "'Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token'"
        }

        const statut = {
            'statut' : data.statut
        }

        try{
            const reponse = await Axios.post(
                `/api/commande/state/${data.id}`,
                statut,
                {headers}
                )
            
            return reponse;
        } catch(error) {
            throw error;
        };
    }


}