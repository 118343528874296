<template>
    <div class="w-screen h-screen overflow-x-hidden p-0 m-0">
        <Header></Header>
        <div class="w-screen mt-2 p-0 m-0">
            <div class="py-5 px-10 text-left">
                <h1 class="text-2xl font-bold">{{ idCat }}</h1>
            </div>
            <div class="w-full px-0 md:px-14 flex flex-col md:flex-row">
                <div class="px-10 w-full grid grid-clos-1 sm:grid-cols-2 md:grid-cols-3 gap-4 py-2 overflow-x-hidden mb-14">
                    <div class="mb-8 md:mt-10 bg-white shadow-md rounded-lg overflow-hidden h-88 pb-4 w-full" v-for="(item, index) in items" :key="index">
                        <router-link v-if="item.produit" :to="getDetail(item.produit.id)">
                            <div class="h-60">
                                <img :src="getPhotoUrl(item.images['0']['chemin'])" class="w-full h-full object-center" alt="" srcset="">
                            </div>
                            <div class="h-20 p-1 mb-2">
                                <div class="text-left overflow-hidden text-ellipsis p-2 h-10 mb-2">
                                    <p class="line-clamp-2 h-full leading-none">{{ item.produit.nom }}</p>
                                </div>
                                <div class="flex justify-end mx-2">    
                                    <div class="px-1.5" style="background-color: #25A4EC;">
                                        <span class="text-white text-sm" v-if="localisation == 243">{{ item.produit.prix }} {{ devise }}</span>
                                        <span class="text-white text-sm" v-else>{{ item.produit.prix * taux }} {{ devise }}</span>
                                    </div>
                                </div>
                            </div>
                        </router-link>
                        <button class="w-full">
                            <router-link :to="getDetail(item.produit.id)">
                                <div class="px-1">
                                    <div class="cursor-pointer py-1 mx-2" style="background-color: #DEA20A;">
                                        <span class="text-sm text-white uppercase">Commandez maintenant</span>
                                    </div>
                                </div>
                            </router-link>
                        </button>

                    </div>
                </div>
            </div>
        </div>  
        <Footer></Footer>
    </div>
</template>

<script>
import Header from '@/components/layout/Header.vue'
import Footer from '@/components/layout/Footer.vue'
import CryptoJS from 'crypto-js'
import Province from '@/_services/province.services'


export default{
    name: 'ListCategoryVue',
    
    components:{
        Header,
        Footer,
    },

    data(){
        return {
            idCat: '',
            items: [],
            taux: null, //
            devise: null, //
            localisation: null, //
            itemProvince: null,
            ville: null,
        }
    },

    methods:{
        //decryptage url
        decryptId(encryptedId) {
                const decryptedText = CryptoJS.AES.decrypt(decodeURIComponent(encryptedId), 'myEncryptionKey').toString(CryptoJS.enc.Utf8);
                const [id] = decryptedText;
                return decryptedText
            },

        getProCat(){
            this.$store.dispatch('getProCat',{
                nom: this.idCat
            })
            .then(reponse =>{
                this.items = reponse.data.data
            })
            .catch(err =>{
                //.log(err)
            })
            .finally(() =>{

            })
        },

        getPhotoUrl(url){
            let params = 'https://api.genesisdream.store/public/storage/' + url;
            //let params = 'http://127.0.0.1:8000/storage/' + url
            //(params);
            return params
        },

        //cryptage de l'url

        encrytpId(id){

            const encryptedId = CryptoJS.AES.encrypt(id.toString(), this.encryptionKey).toString();
            return encodeURIComponent(encryptedId);

        },

        getDetail(id, idShop){

            const plainText = id + '-' + idShop;
            const encryptedText = encodeURIComponent(CryptoJS.AES.encrypt(plainText, 'myEncryptionKey').toString());
            return '/detailProduit/' + encryptedText;

        }

    },

    created() {
            const encryptedId = this.$route.params.encryptedId;
            const id = this.decryptId(encryptedId); 
            this.idCat = id; 
            
            this.getProCat()

            this.taux = localStorage.getItem('taux_ville')
            this.ville = localStorage.getItem('ville')
            this.devise = localStorage.getItem('devis_ville')            
            this.localisation = localStorage.getItem("localisation");

    },

    mounted(){

        //liste de ville
        const province = new Province();
        
        province.getAllPro()
            .then(prov => {
                const data = prov.data.find(ville => ville.nom == this.ville)
                
                if(!this.taux){
                    
                }else{
                    if(data.taux != this.taux) {
                        localStorage.setItem("taux_ville", data.taux);
                    }
                }
                
            })

    }

}

</script>