<template>
    <div>
        <div class="flex items-center justify-center h-screen">
            <div class="bg-gray-200 w-3/5 h-3/4 mx-auto my-auto">
                <div class="flex h-full">
                    <div class="w-2/4" style="background: linear-gradient(to bottom, #DEA20A , #FBF7EE );">
                        <div class="px-10 pt-14">
                            <div class="flex items-center justify-center">
                                <img src="@/images/LOGO_GEN_PNG_BLANC.png" class="w-72" alt="">
                            </div>
                            <div class="flex items-center justify-center bg-white py-10 rounded-lg">
                                <p class="text-3xl font-bold uppercase drop-shadow-md">Nouvelle Commande</p>
                            </div>
                        </div>
                    </div>
                    <div class="w-2/4 px-10 pt-12 text-left overflow-y-auto" style="background-color: #FBF7EE;">
                        <form action="" @submit.prevent="submitCommand">
                            <div class="mb-2" v-for="(produit, index) in produits" :key="index">
                                <div class="pl-2 mb-1">
                                    <label for="password" class="text-lg font-bold">Produit : {{ index + 1 }}</label>
                                </div>
                                <div>
                                    <select name="" v-model="produit.value" id="" class="block w-full px-4 py-1 border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                                        <option value="" disabled selected hidden></option>
                                        <option value="option1">Option 1</option>
                                        <option value="option2">Option 2</option>
                                        <option value="option3">Option 3</option>
                                        <option value="option4">Option 4</option>
                                        <option value="option5">Option 5</option>
                                    </select>
                                </div>
                            </div>
                            <div class="mb-3" v-for="(quantite, index) in quantites" :key="index">
                                <div class="pl-2 mb-1">
                                    <label for="password" class="text-lg font-bold">Quantité : {{ index + 1 }}</label>
                                </div>
                                <input type="text" name="" v-model="quantite.value" id="" class="px-4 py-1 w-full border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                            </div>
                            <div class="flex items-end justify-end mb-3">
                                <div>
                                    <button @click="addInput" class="px-4 py-2 w-24 rounded-lg text-black bg-white shadow-lg font-bold text-lg">Ajouter</button>
                                </div>
                            </div>
                            <div class="mb-3 grid grid-cols-2">
                                <div class="mr-5 mb-2">
                                    <div class="pl-2 mb-2">
                                        <label for="password" class="text-lg font-bold">Client</label>
                                    </div>
                                    <div>
                                        <select name="" id="" v-model="client" class="block w-full px-4 py-1 border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                                            <option value="" disabled selected hidden></option>
                                            <option value="option1">Option 1</option>
                                            <option value="option2">Option 2</option>
                                            <option value="option3">Option 3</option>
                                            <option value="option4">Option 4</option>
                                            <option value="option5">Option 5</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="ml-5">
                                    <div class="pl-2 mb-2">
                                        <label for="password" class="text-lg font-bold">Pays</label>
                                    </div>
                                    <div>
                                        <select name="" id="" v-model="pays" class="block w-full px-4 py-1 border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                                            <option value="" disabled selected hidden></option>
                                            <option value="option1">Option 1</option>
                                            <option value="option2">Option 2</option>
                                            <option value="option3">Option 3</option>
                                            <option value="option4">Option 4</option>
                                            <option value="option5">Option 5</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="mr-5 mb-2">
                                    <div class="pl-2 mb-2">
                                        <label for="password" class="text-lg font-bold">Province</label>
                                    </div>
                                    <div>
                                        <select name="" id="" v-model="province" class="block w-full px-4 py-1 border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                                            <option value="" disabled selected hidden></option>
                                            <option value="option1">Option 1</option>
                                            <option value="option2">Option 2</option>
                                            <option value="option3">Option 3</option>
                                            <option value="option4">Option 4</option>
                                            <option value="option5">Option 5</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="ml-5">
                                    <div class="pl-2 mb-2">
                                        <label for="password" class="text-lg font-bold">Adresse</label>
                                    </div>
                                    <div>
                                        <select name="" id="" v-model="adress" class="block w-full px-4 py-1 border rounded-lg border-gray-600 shadow-md shadow-gray-400">
                                            <option value="" disabled selected hidden></option>
                                            <option value="option1">Option 1</option>
                                            <option value="option2">Option 2</option>
                                            <option value="option3">Option 3</option>
                                            <option value="option4">Option 4</option>
                                            <option value="option5">Option 5</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-3 flex justify-between">
                                <div class="font-bold">
                                    <label for="" class="inline-flex items-center mr-2">
                                        <span class="mr-2">Livraison</span>
                                        <input type="radio" v-model="type_livraison" name="radioGroup" id="" class="form-checkbox text-blue-500 h-4 w-4">
                                    </label>
                                </div>
                                <div class="font-bold">
                                    <label for="" class="inline-flex items-center mr-2">
                                        <span class="mr-2">Au siège</span>
                                        <input type="radio" name="radioGroup" v-model="type_livraison" id="" class="form-checkbox text-blue-500 h-4 w-4">
                                    </label>
                                </div>
                            </div>
                            <div class="flex justify-between">
                                <button class="text-lg font-bold" style="color: #25A4EC">Nouveau client</button>
                                <button type="submit" class="px-4 py-1.5 rounded-lg text-white font-bold text-lg" style="background-color: #DEA20A;">Enregistrer</button>
                            </div>
                        </form>
                        <div class="flex justify-between mt-4">
                            <span class="flex text-xl font-bold" style="color:#072A62">Articles : <p class="ml-2">6</p></span>
                            <span class="flex text-xl font-bold" style="color:#072A62">Total : <p class="ml-2">60$</p></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>

export default{
    name: 'newcommande',
    data() {
    return {
        produits: [],
        quantites: [],
        type_livraison : '',
        pays: '',
        adress: '',
        province: '',
        client: '',
        quantiteError : false,
    };
  },
  methods: {
    addInput() {
      this.produits.push({ value: '' });
      this.quantites.push({ value: '' });
    },

    validateNumberInput() {
            this.quantites = parseInt(this.quantites);
            if (isNaN(this.quantites)) {
                this.quantites = null; // Réinitialiser la valeur si ce n'est pas un nombre valide
            }
        },

    submitCommand: function(){

        const produitsValues = this.produits.map(produit => produit.value);
        const quantitesValues = this.quantites.map(quantite => parseInt(quantite.value));

        if (quantitesValues.some(isNaN)) {
            this.quantiteError = true;
            return; // Sortir de la fonction si une quantité invalide est détectée
        }

        this.$store.dispatch('submitCommand', {
            nom : this.nom,
            client : this.client,
            province : this.province,
            adresse : this.adresse,
            ...quantitesValues.reduce((acc, quant, i) => {
                acc["quantite" + i] = quant;
                return acc;
            }, {}),

            ...produitsValues.reduce((acc, prod, i) => {
                acc["produit" + i] = prod;
                return acc;
            }, {})
        }).then((response) => {
            this.$route.push('/listecommande')
        }).catch((error) =>{
            console.log('Error :',error)
        })
        
    }
  },

  mounted() {
    this.addInput(); // Ajouter un input lors du chargement du composant
  }
    
}

</script>